import ForumPageTemplate from './ForumPageTemplate'

const InfoBlokSinglePage = () => (
  <ForumPageTemplate
    title='INFO BLOK'
    apiGetPath='info-block'
    apiPostPath='info-block-post'
    navigationPath='info_blok'
    commentType='info_blok'
    chatImagePath='infoblock'
  />
)

export default InfoBlokSinglePage
