import { useContext } from 'react'
import classes from './premiumPredloziButton.module.css'
import AuthContext from '../context/AuthProvider'
import { betkoInfoPopup } from '../utils/notifications'
import { useNavigate } from 'react-router-dom'

const PremiumPredloziButton = () => {
  const { auth } = useContext(AuthContext)
  const SIX_MONTHS_IN_MS = 6 * 30 * 24 * 60 * 60 * 1000
  const userPaidToDate = auth?.paidToDate || 0
  const isAdmin = auth?.status === "Admin"
  const navigate = useNavigate()

  const handlePremiumButtonClick = () => {
    if (isAdmin || Date.now() - userPaidToDate >= SIX_MONTHS_IN_MS) {
      navigate("/bet_kurs_novo/6549642d3ca6f705bb3fc418")
    } else {
      betkoInfoPopup("Ovaj sadržaj je dostupan samo korisnicima pretplatničkih paketa.")
    }
  }

  return (
    <button onClick={handlePremiumButtonClick} className={classes.premiumButton}>
        KLIKNI I POGLEDAJ PREMIUM PREDLOGE
    </button>
  )
}

export default PremiumPredloziButton
