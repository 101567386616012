import React, { useEffect, useRef, useState } from 'react'
import classes from './SingleAnswerComment.module.css'
import { SERVER_URL } from '../../../helpers/urls'
import { dateWithHours } from '../../../utils/DateWithHours'
import { renderTextWithLinksAndHtml } from '../../../utils/renderTextWithLinksAndHtml'
import { betkoPopupWithAction } from '../../../utils/BetkoPopupModal'
import RatingPopup from '../../RatingPopup'
import { betkoInfoPopup } from '../../../utils/notifications'
import TextToLink from '../../../utils/TextToLink'

const SingleAnswerComment = ({
  item,
  isHomePage,
  handleGiftPress,
  parentIndex,
  editComment,
  editCommentValue,
  setEditCommentValue,
  setEditComment,
  handleDeleteComment,
  handleSaveComment,
  showComments,
  handleShowComments,
  handleReactToComment,
  user,
  answersParentComment,
  handleRateComment,
  alreadyRatedComment,
  handleReportCommentPress,
  handleBlockUserPress,
}) => {
  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false)
  const menuRef = useRef(null)
  const commentEditRef = useRef(null)
  const [averageRating, setAverageRating] = useState(0)
  const commentDate = item.editDate ? item.editDate : item.createdAt
  const cuttedInnerHtml = item?.comment.substring(0, 450)
  const innerHtml = item?.comment
  const commentLikes = +item.likes.length + +item.adminLikes
  const commentDislikes = +item.dislikes.length + +item.adminDislikes
  const alreadyLiked = !!item.likes.includes(user?._id)
  const alreadyDisliked = !!item.dislikes.includes(user?._id)

  const calculateAverageRating = (ratings) => {
    if (!ratings?.length) return 0 // Avoid division by zero
    return (
      ratings.reduce((acc, ratingObj) => acc + ratingObj?.rating, 0) /
      ratings.length
    )
  }

  const handleRatingClick = async (num, item) => {
    const success = await handleRateComment({ number: num, comment: item })

    if (success) {
      setAverageRating(calculateAverageRating)
      const updatedRatings = [...item.ratings, { rating: num }]
      setAverageRating(calculateAverageRating(updatedRatings))
    }
  }

  useEffect(() => {
    if (item) {
      setAverageRating(calculateAverageRating(item.ratings))
    }
  }, [item])

  const handleRateCommentPress = (comment) => {
    betkoPopupWithAction(
      'Oceni komentar',
      'Koliko ste zadovoljni sa ovom analizom?',
      (closePopup) => (
        <RatingPopup
          closePopup={closePopup}
          comment={comment}
          handleRatingClick={handleRatingClick}
        />
      )
    )
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOptionsMenuOpen(false)
    }
  }

  const toggleMenu = (e) => {
    e.stopPropagation()
    setIsOptionsMenuOpen((prev) => !prev)
  }

  const focusParentTextArea = (user) => {
    const textAreaId = answersParentComment.parent + '-textarea'
    const textarea = document.getElementById(textAreaId)

    if (textarea) {
      textarea.focus()

      const usernameTag = `@${user.userName} `
      const textWithoutPreviousTag = textarea.value.replace(/^@\S+\s/, '')

      textarea.value = usernameTag + textWithoutPreviousTag

      localStorage.setItem('activeAnswerUserId', user._id)
      localStorage.setItem('activeAnswerUserUsername', user.userName)
    }
  }

  const highlightTags = (text) => {
    if (typeof text !== 'string') return { cleanText: text, jsx: text }

    const jsxParts = text.split(/(@\w+)/g).map((part, index) =>
      part.startsWith('@') ? (
        <span key={index} style={{ color: 'var(--a-color)' }}>
          {part}
        </span>
      ) : (
        ''
      )
    )

    const cleanText = text.replace(/@\w+/g, '')

    return { cleanText, jsx: jsxParts }
  }

  const { cleanText, jsx } = highlightTags(innerHtml)
  const { cleanText: cuttedCleanText } = highlightTags(cuttedInnerHtml)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (editComment && commentEditRef.current) {
      commentEditRef.current.focus()
    }
  }, [editComment])

  const handleCancelEdit = () => {
    setEditComment(false)
    setEditCommentValue(item)
  }

  const handleDeleteCommentPress = (comment) => {
    betkoPopupWithAction(
      'Brisanje odgovora',
      'Jesi li siguran da želiš obrisati ovaj odgovor?',
      (closePopup) => (
        <div className={classes.commentEditSaveButtonWrapper}>
          <button
            onClick={() => {
              handleDeleteComment(comment)
              closePopup()
            }}
          >
            Obriši
          </button>
          <button onClick={closePopup}>Odustani</button>
        </div>
      )
    )
  }

  return (
    <div className={classes.singleAnswerComment}>
      <div className={classes.divider}></div>
      <div className={classes.singleAnswerCommentInner}>
        <div className={classes.commentHeader}>
          <div className={classes.logoWrapper}>
            {item?.owner?.team && item?.owner?.team?.teamImage ? (
              <img
                src={`${SERVER_URL}/${item?.owner?.team?.teamImage?.url}`}
                alt='Dres Tima'
              />
            ) : (
              <img src='../teamPlaceholder.png' alt='Dres Tima' />
            )}
          </div>
          <div className={classes.userInfo}>
            <span className={classes.userName}>{item?.owner?.userName}</span>
            <span className={classes.userRole}>
              <div className={classes.userIcon}>
                <img src='/icons/comments/user.svg' alt='korisnik' />
              </div>
              <span>
                {item?.owner?.status === 'Admin'
                  ? 'Administrator'
                  : item?.owner?.team?.name}{' '}
              </span>
            </span>
          </div>
          <div className={classes.commentHeaderBtns} ref={menuRef}>
            <button
              className={classes.tipsterBtn}
              onClick={() =>
                // navigate(`/mister_tipster/profil?user=${item?.owner?._id}`)
                betkoInfoPopup('Uskoro! "Mister Tipster profil !"')
              }
            >
              <img
                src='/icons/comments/commentsTipster.svg'
                alt='Mister tipster'
              />
            </button>

            <button
              className={classes.giftBtn}
              onClick={(e) => {
                handleGiftPress(item?.gift)
              }}
            >
              <img
                className={item?.gift !== undefined ? classes.hasGift : ''}
                src='/icons/comments/commentsGift.svg'
                alt='poklon'
              />
            </button>
            <button onClick={(e) => toggleMenu(e)}>
              <img
                src='/icons/comments/commentsMoreOptions.svg'
                alt='vise opcija'
              />
            </button>
            {isOptionsMenuOpen && (
              <ul className={classes.optionsMenu}>
                <li>
                  <button
                    disabled={item?.reports?.includes(user?._id)}
                    onClick={() => handleReportCommentPress(item)}
                  >
                    <img
                      src='/icons/comments/commentsReport.svg'
                      alt='prijavi korisnika'
                    />
                    <span>Prijavi korisnika</span>
                  </button>
                </li>
                <li>
                  <button
                    disabled={item?.owner?.blockedBy?.some(
                      (owner) => owner.id === user?._id
                    )}
                    onClick={() => handleBlockUserPress(item?.owner)}
                  >
                    <img
                      src='/icons/comments/commentsBlockUser.svg'
                      alt='blokiraj korisnika'
                    />
                    <span>Blokiraj korisnika</span>
                  </button>
                </li>
                <li className={classes.idLi}>
                  <button>
                    <span>ID: {item?.owner?._id}</span>
                  </button>
                </li>
                <li className={classes.idLi}>
                  <button>
                    <span>Broj ocena: {item?.ratings?.length || 0}</span>
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className={classes.commentTimeAndEditWrapper}>
          <div className={classes.commentTime}>
            Odgovor na br. {parentIndex} • {dateWithHours(commentDate)}
          </div>
          {user && user._id === item.owner._id && (
            <div className={classes.commentEditButtonWrapper}>
              <span
                onClick={() => {
                  setEditComment(true)
                  setEditCommentValue(item)
                }}
              >
                Uredi
              </span>
              <span onClick={() => handleDeleteCommentPress(item)}>Obriši</span>
            </div>
          )}
        </div>
        {item.adminComment && (
          <div className={classes.adminCommentMessage}>
            <div className='imageWrapper'>
              <img
                src='/icons/comments/commentsAdminMessage.svg'
                alt='Poruka admina'
              />
            </div>
            <div className={classes.adminCommentMessageContent}>
              <span className={classes.adminCommentTitle}>PORUKA OD BETKA</span>
              <span className={classes.adminCommentText}>
                {/* {'JOS MALO I PRELAZIS IGRICU SAMO TAKO NASTAVI!'} */}
                {item.adminComment}
              </span>
            </div>
          </div>
        )}
        <div className={classes.commentBody}>
          {editComment && editCommentValue._id === item._id ? (
            <div className={classes.commentEditWrapper}>
              <textarea
                className={classes.textAreaWrapper}
                ref={commentEditRef}
                name='editComment'
                value={editCommentValue?.comment || ''}
                rows={5}
                onChange={(e) =>
                  setEditCommentValue((prev) => {
                    return {
                      ...prev,
                      comment: e.target.value,
                    }
                  })
                }
              />
              <div className={classes.commentEditSaveButtonWrapper}>
                <button onClick={() => handleSaveComment(item)}>Sačuvaj</button>
                <button onClick={handleCancelEdit}>Otkaži</button>
              </div>
            </div>
          ) : showComments.includes(item._id) ? (
            <div className={classes.commentContent}>
              {item.owner.roles?.Admin === 5151 ? (
                <div>
                  {jsx}
                  <span
                    dangerouslySetInnerHTML={renderTextWithLinksAndHtml(
                      cleanText
                    )}
                  />
                </div>
              ) : (
                <>
                  {jsx}
                  <TextToLink text={cleanText} />
                </>
              )}
            </div>
          ) : (
            <div className={classes.commentContent}>
              {item.owner.roles?.Admin === 5151 ? (
                <div>
                  {jsx}
                  <span
                    dangerouslySetInnerHTML={renderTextWithLinksAndHtml(
                      cuttedCleanText
                    )}
                  />
                </div>
              ) : (
                <>
                  {jsx}
                  <TextToLink text={cuttedCleanText} />
                </>
              )}
            </div>
          )}
        </div>
        <div className={classes.commentShowMore}>
          {item?.comment?.length >= 170 && (
            <button type='button' onClick={() => handleShowComments(item)}>
              {showComments.includes(item._id) ? (
                <span>...Sakrij</span>
              ) : (
                <span>...Prikaži još</span>
              )}
            </button>
          )}
        </div>
        <div className={classes.commentFeedbackOptions}>
          <div className={classes.feedbackBtnsWrapper}>
            <button
              disabled={alreadyLiked}
              onClick={(e) =>
                handleReactToComment({
                  type: 'like',
                  action: { payload: item },
                })
              }
            >
              <img
                src={`/icons/comments/commentsLike${
                  alreadyLiked ? 'Filled' : ''
                }.svg`}
                alt='like'
              />

              <span>{commentLikes}</span>
            </button>
            <button
              disabled={alreadyDisliked}
              onClick={(e) =>
                handleReactToComment({
                  type: 'dislike',
                  action: { payload: item },
                })
              }
            >
              <img
                src={`/icons/comments/commentsDislike${
                  alreadyDisliked ? 'Filled' : ''
                }.svg`}
                alt='dislike'
              />
              <span>{commentDislikes}</span>
            </button>
            <button
              className={classes.ratingWrapper}
              onClick={() => {
                if (alreadyRatedComment(item)) {
                  betkoInfoPopup('Već ste dali ocenu ovom komentaru')
                  return
                }

                handleRateCommentPress(item)
              }}
            >
              <img
                src={`/icons/comments/commentsRating${
                  alreadyRatedComment(item) ? 'Filled' : ''
                }.svg`}
                alt='rating'
              />
              <span>
                {averageRating.toFixed(2) > 0 ? averageRating.toFixed(2) : ''}
              </span>
            </button>
          </div>
          <button
            className={classes.responseBtn}
            onClick={() => focusParentTextArea(item.owner)}
          >
            Odgovori
          </button>
        </div>
      </div>
    </div>
  )
}

export default SingleAnswerComment
