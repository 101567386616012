import React, { useEffect, useState } from 'react'
import classes from './sideImagesWide.module.css'
import AdVideoPlayer from './AdVideoPlayer'
import axios from '../config/axios'
import { getLinkTarget } from '../utils/adHelper'
import { useLocation } from 'react-router-dom'

const SERVER_URL = process.env.REACT_APP_SERVER_URL
const placeholderAdPath = '/tiger.webp'

const SideImagesWide = ({ side }) => {
  const [ads, setAds] = useState([])
  const [forSmallerScreens, setForSmallerScreens] = useState(false)

  const location = useLocation()
  const adContainerHeight =
    location.pathname === '/' ? 'calc(100vh - 175px)' : 'calc(100vh - 125px)'

  const mergeAlternatingAds = (leftAds = [], rightAds = []) => {
    const maxLength = Math.max(leftAds.length, rightAds.length)
    const mergedAds = []

    for (let i = 0; i < maxLength; i++) {
      if (leftAds[i]) mergedAds.push({ ...leftAds[i], position: 'left' })
      if (rightAds[i]) mergedAds.push({ ...rightAds[i], position: 'right' })
    }

    return mergedAds
  }

  // const loadFromLocalStorage = (side) => {
  //   const storageKey = side ? `cachedAds-${side}` : 'cachedAds-merged'
  //   const cachedAds = localStorage.getItem(storageKey)

  //   if (cachedAds) {
  //     try {
  //       const parsedAds = JSON.parse(cachedAds) || []
  //       setAds(Array.isArray(parsedAds) ? parsedAds : [])
  //       if (storageKey === 'cachedAds-merged') setForSmallerScreens(true)
  //     } catch (error) {
  //       console.error('Error parsing cached ads:', error)
  //       setAds([])
  //     }
  //   }
  // }
  //
  // useEffect(() => {
  //   loadFromLocalStorage(side)
  // }, [side])

  useEffect(() => {
    const fetchAndStoreAds = async (section) => {
      try {
        // const storageKey = section ? `cachedAds-${section}` : 'cachedAds-merged'

        let adsData = []
        if (['left', 'right', 'comments'].includes(section)) {
          const response = await axios.get(
            `${SERVER_URL}/get-all-shown-adds?section=${section}`
          )
          adsData = response.data || []
        } else {
          const [leftResponse, rightResponse] = await Promise.all([
            axios.get(`${SERVER_URL}/get-all-shown-adds?section=left`),
            axios.get(`${SERVER_URL}/get-all-shown-adds?section=right`),
          ])

          const leftAds = leftResponse.data || []
          const rightAds = rightResponse.data || []
          adsData = mergeAlternatingAds(leftAds, rightAds)
          setForSmallerScreens(true)
        }

        setAds(Array.isArray(adsData) ? adsData : [])
        // localStorage.setItem(storageKey, JSON.stringify(adsData))
      } catch (error) {
        console.error('Error fetching ads:', error)
        setAds([])
      }
    }

    fetchAndStoreAds(side)
  }, [side])

  const handleVideoClick = (ad) => {
    if (ad?.link) {
      window.open(ad.link, getLinkTarget(ad.link), 'noopener,noreferrer')
    }
  }

  return (
    <div className={side === 'comments' ? classes.commentAdsWrapper : ''}>
      <div
        className={`${classes.wrapper} ${
          forSmallerScreens ? classes.wrapperMobile : ''
        }`}
        style={{ height: side === 'comments' ? adContainerHeight : '100%' }}
      >
        {ads.map((ad, index) => (
          <div className={classes.adItem} key={index}>
            {ad ? (
              ad.type === 'video' ? (
                <div onClick={() => handleVideoClick(ad)}>
                  <AdVideoPlayer
                    className={classes.video}
                    src={`${SERVER_URL}/${ad.localPath}`}
                  />
                </div>
              ) : (
                <a
                  href={ad.link}
                  target={getLinkTarget(ad.link)}
                  rel='noopener noreferrer'
                >
                  <img src={`${SERVER_URL}/${ad.localPath}`} alt='Ad' />
                </a>
              )
            ) : (
              <img src={placeholderAdPath} alt='Ad' />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default SideImagesWide
