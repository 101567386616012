import classes from './promo.module.css'
import SideImagesWide from '../components/SideImagesWide'
import { useState } from 'react'

const Promo = () => {
  const [showSideAds, setShowSideAds] = useState(true)

  return (
    <>
      <div className={classes.promoContainer}>
        <button
          onClick={() => {
            if (!showSideAds) setShowSideAds(true)
          }}
        >
          <span>TOP</span>
        </button>
        <button
          onClick={() => {
            if (showSideAds) setShowSideAds(false)
          }}
        >
          <span>TIP</span>
        </button>
      </div>
      <div className={classes.container}>
        <SideImagesWide side={showSideAds ? undefined : 'comments'} />
      </div>
    </>
  )
}

export default Promo
