import ForumPageTemplate from './ForumPageTemplate'

const BetKalkulatoriTutorijalSinglePage = () => (
  <ForumPageTemplate
    title='KALKULATORI TUTORIJAL'
    apiGetPath='tutorials'
    apiPostPath='tutorial'
    navigationPath='bet_kalkulatori_tutorijal'
    chatImagePath='tutorials'
  />
)

export default BetKalkulatoriTutorijalSinglePage
