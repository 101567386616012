import React, { useState, useEffect, useRef } from 'react'
import axios from '../config/axios'
import classes from './adminManageAds.module.css'
import AdVideoPlayer from '../components/AdVideoPlayer'
import AdminAdSelectionSection from './AdminAdSelectionSection'
import { useAdsManagement } from './AdminManageAds'
import { Modal } from 'antd'
import Pagination from '../components/Pagination'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const AdminManageMobileAds = () => {
  const {
    handleUpload,
    handleDeleteButtonPress,
    refetchTrigger,
    handleMoveAd,
    handleSaveChanges,
    handleLinkChange,
    refetchShownAdsTrigger,
  } = useAdsManagement()

  const [file, setFile] = useState(null)
  const [link, setLink] = useState('')
  const [editedLinks, setEditedLinks] = useState({})
  const [isHero, setIsHero] = useState(true)

  const [heroMobileAds, setHeroMobileAds] = useState([])
  const [activeSelection, setActiveSelection] = useState({
    index: null,
    section: null,
  })

  const [shownAdsMap, setShownAdsMap] = useState({
    hero: [],
  })

  const [totalHeroPages, setTotalHeroPages] = useState(0)
  const [totalHeroCount, setTotalHeroCount] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  const limit = 20
  const topRef = useRef()

  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    const fetchAds = async (page, limit) => {
      try {
        const heroResponse = await axios.get(
          `${SERVER_URL}/get-all-adds?page=${page}&limit=${limit}&forMobile=true&isHero=true`
        )

        const {
          ads: heroAds,
          totalCount: heroCount,
          totalPages: heroPages,
        } = heroResponse.data

        setHeroMobileAds(heroAds)

        setTotalHeroCount(heroCount)
        setTotalHeroPages(heroPages)
      } catch (error) {
        console.error('Fetch error:', error)
      }
    }

    fetchAds(currentPage, limit)
  }, [refetchTrigger, currentPage])

  useEffect(() => {
    const fetchShownMobileAds = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/get-all-shown-adds?forMobile=true`
        )
        const allShownAds = response.data

        setShownAdsMap((prevState) => {
          const heroAds = allShownAds
            .filter((ad) => ad.position?.hero !== -1)
            .sort((a, b) => a.position?.hero - b.position?.hero)

          return {
            ...prevState,
            hero: heroAds,
          }
        })
      } catch (error) {
        console.error('Error fetching ads:', error)
      }
    }

    fetchShownMobileAds()
  }, [refetchShownAdsTrigger])

  const handleSelectContainer = (index, section) => {
    setActiveSelection({ index, section })
    setIsModalVisible(true)
  }

  const handleSelectAd = (ad) => {
    if (activeSelection.index === null) return
    const updatedAdsInSection = [...shownAdsMap.hero]
    const newPosition = updatedAdsInSection.length

    const updatedAd = {
      ...ad,
      isSelected: true,
      hasUpdated: false,
      position: { ...ad.position, hero: newPosition },
    }

    updatedAdsInSection.push(updatedAd)

    setShownAdsMap((prev) => ({
      ...prev,
      hero: updatedAdsInSection,
    }))

    setActiveSelection({ index: null, section: null })
    setIsModalVisible(false)
  }

  const handleOnMove = (index, section, direction) => {
    handleMoveAd(index, section, direction, setShownAdsMap)
  }

  const handleRemoveSelectedAd = (adId) => {
    const section = 'hero'
    setShownAdsMap((prevMap) => {
      const updatedMap = { ...prevMap }

      if (updatedMap[section]) {
        updatedMap[section] = updatedMap[section].map((ad) => {
          if (ad?._id === adId) {
            return {
              ...ad,
              position: { ...ad.position, [section]: -1 },
              hasUpdated: true,
              isSelected: false,
            }
          }
          return ad
        })
      }

      return updatedMap
    })
  }

  return (
    <div className={classes.container}>
      <h1>Upravljanje mobilnim reklamama:</h1>
      <div className={classes.innerContainer}>
        <div className={classes.uploadContainer}>
          <h2>Dodaj novu reklamu u galeriju:</h2>
          <div className={classes.uploadSection}>
            <input
              type='text'
              placeholder='Unesite link reklame'
              value={link}
              onChange={(e) => setLink(e.target.value)}
              className={classes.inputField}
            />
            <label className={classes.btn}>
              Izaberi sliku/video
              <input
                type='file'
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    setFile(e.target.files[0])
                  }
                }}
                className={classes.hiddenInput}
              />
            </label>
            {file && <p className={classes.fileName}>{file.name}</p>}
            <button
              onClick={() =>
                handleUpload(
                  file,
                  link,
                  isHero,
                  setFile,
                  setLink,
                  setIsHero,
                  true
                )
              }
              className={classes.uploadButton}
            >
              Objavi reklamu
            </button>
          </div>
        </div>
        <div className={classes.saveChangesContainer}>
          <button
            // disabled={Object.keys(editedLinks).length === 0}
            className={classes.uploadButton}
            onClick={() =>
              handleSaveChanges(
                editedLinks,
                setEditedLinks,
                shownAdsMap,
                setShownAdsMap
              )
            }
          >
            Ažuriraj podatke ispod
          </button>
        </div>
      </div>

      <AdminAdSelectionSection
        title='Hero banner'
        ads={shownAdsMap.hero}
        section='hero'
        activeSelection={activeSelection}
        onSelect={handleSelectContainer}
        onRemove={handleRemoveSelectedAd}
        onLinkChange={(adId, newLink, originalLink) =>
          handleLinkChange(adId, newLink, originalLink, setEditedLinks)
        }
        editedLinks={editedLinks}
        onMove={handleOnMove}
      />

      <Modal
        title='Galerija reklama'
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800}
        style={{
          background: '#303030',
          opacity: 1,
          borderRadius: '0.5rem',
        }}
      >
        <div ref={topRef} className={classes.paginationWrapper}>
          <div
            className={`${classes.selectedAdsContainer} ${classes.allAdsContainer}`}
          >
            {(heroMobileAds || []).map((ad) => {
              const isSelected = shownAdsMap[activeSelection.section]?.some(
                (selectedAd) =>
                  selectedAd &&
                  selectedAd._id === ad._id &&
                  selectedAd.position?.[activeSelection.section] !== -1
              )

              return (
                <div key={ad._id} className={classes.adsWrapper}>
                  {ad.type === 'video' ? (
                    <div
                      onClick={() => !isSelected && handleSelectAd(ad)}
                      className={isSelected ? classes.disabledAd : ''}
                    >
                      <AdVideoPlayer src={`${SERVER_URL}/${ad.localPath}`} />
                    </div>
                  ) : (
                    <img
                      onClick={() => !isSelected && handleSelectAd(ad)}
                      src={`${SERVER_URL}/${ad.localPath}`}
                      alt='Ad'
                      className={`${classes.adContainer} ${
                        isSelected ? classes.disabledAd : ''
                      }`}
                    />
                  )}
                  <button
                    className={classes.btn}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleDeleteButtonPress(ad._id)
                    }}
                  >
                    Obriši
                  </button>
                </div>
              )
            })}
          </div>
          <div className={classes.pagination}>
            <Pagination
              setCurrentPage={setCurrentPage}
              limit={limit}
              currentPage={currentPage}
              totalCount={totalHeroCount}
              totalPages={totalHeroPages}
              buttonLimit={3}
              topRef={topRef}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AdminManageMobileAds
