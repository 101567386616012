import { useState } from "react"
import classes from "./Redesign/SingleComment/SingleComment.module.css"
import { betkoErrorPopup, betkoSuccessPopup } from "../utils/notifications"
import axios from "../config/axios"
import { SERVER_URL } from "../helpers/urls"

const BlockUserPopup = ({ closePopup, user, setIsLoginModalOpen, setRefetchTrigger }) => {
  const [blockReason, setBlockReason] = useState("")
  const [loadingBtn, setLoadingBtn] = useState(false)

  const handleBlock = async () => {
    if (!user || Object.keys(user).length < 1) {
      setIsLoginModalOpen(true);
      return;
    }

    if (!blockReason) {
      betkoErrorPopup("Unesite razlog blokiranja.");
      return;
    }

    const payload = {
      userId: user._id,
      reason: blockReason,
    };

    setLoadingBtn(true);

    try {
      const res = await axios.post(`${SERVER_URL}/user/block-user/${user.email}`, payload);

      if (res.status === 200) {
        betkoSuccessPopup(res.data.message);
        setRefetchTrigger((prev) => !prev);
        setBlockReason("");
      }
    } catch (error) {
      console.error(error);
      betkoErrorPopup("Greška prilikom blokiranja.");
    } finally {
      setLoadingBtn(false);
      closePopup();
    }
  };

  return (
    <div className={classes.commentEditWrapper} style={{minWidth:"250px"}}>
        <textarea
          className={classes.textAreaWrapper}
          rows={6}
          value={blockReason}
          onChange={(e) => setBlockReason(e.target.value)}
        />
        <div className={classes.commentEditSaveButtonWrapper}>
          <button
            className={classes.confirmButton}
            disabled={loadingBtn}
            onClick={handleBlock}
          >
            {loadingBtn ? "Blokiranje..." : "Blokiraj"}
          </button>
          <button className={classes.cancelButton} onClick={closePopup}>
            Odustani
          </button>
        </div>
    </div>
  )
}

export default BlockUserPopup
