import React, { useState, useEffect, useRef } from 'react'
import axios from '../config/axios'
import classes from './adminManageAds.module.css'
import { Modal } from 'antd'
import Pagination from '../components/Pagination'
import {
  betkoErrorPopup,
  betkoInfoPopup,
  betkoPopup,
  betkoSuccessPopup,
} from '../utils/notifications'

import AdVideoPlayer from '../components/AdVideoPlayer'
import AdminAdSelectionSection from './AdminAdSelectionSection'
import { betkoPopupWithAction } from '../utils/BetkoPopupModal'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const AdminManageAds = () => {
  const {
    handleUpload,
    handleDeleteButtonPress,
    refetchTrigger,
    handleMoveAd,
    handleSaveChanges,
    handleLinkChange,
    refetchShownAdsTrigger,
  } = useAdsManagement()

  const [file, setFile] = useState(null)
  const [link, setLink] = useState('')
  const [editedLinks, setEditedLinks] = useState({})
  const [isHeroImage, setIsHeroImage] = useState(false)

  const [adsList, setAdsList] = useState([])
  const [heroAds, setHeroAds] = useState([])

  const [activeSelection, setActiveSelection] = useState({
    index: null,
    section: null,
  }) // "main", "left", "right", "hero", "comments"

  const [shownAdsMap, setShownAdsMap] = useState({
    main: [null, null, null],
    left: [],
    right: [],
    hero: [],
    comments: [],
  })

  const [totalNonHeroPages, setTotalNonHeroPages] = useState(0)
  const [totalNonHeroCount, setTotalNonHeroCount] = useState(0)
  const [totalHeroPages, setTotalHeroPages] = useState(0)
  const [totalHeroCount, setTotalHeroCount] = useState(0)

  const [currentTotalCount, setCurrentTotalCount] = useState(0)
  const [currentTotalPages, setCurrentTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const limit = 20
  const topRef = useRef()

  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    const fetchAds = async (page, limit) => {
      try {
        const [nonHeroResponse, heroResponse] = await Promise.all([
          axios.get(
            `${SERVER_URL}/get-all-adds?page=${page}&limit=${limit}&forMobile=false&isHero=false`
          ),
          axios.get(
            `${SERVER_URL}/get-all-adds?page=${page}&limit=${limit}&forMobile=false&isHero=true`
          ),
        ])

        const {
          ads: nonHeroAds,
          totalCount: nonHeroCount,
          totalPages: nonHeroPages,
        } = nonHeroResponse.data
        const {
          ads: heroAds,
          totalCount: heroCount,
          totalPages: heroPages,
        } = heroResponse.data

        setAdsList(nonHeroAds)
        setHeroAds(heroAds)

        setTotalHeroCount(heroCount)
        setTotalHeroPages(heroPages)
        setTotalNonHeroCount(nonHeroCount)
        setTotalNonHeroPages(nonHeroPages)
      } catch (error) {
        console.error('Fetch error:', error)
      }
    }

    fetchAds(currentPage, limit)
  }, [refetchTrigger, currentPage])

  useEffect(() => {
    const fetchShownAds = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/get-all-shown-adds?forMobile=false`
        )
        // const allShownAds = response.data

        const allShownAds = response.data.map((ad) => ({
          ...ad,
          ...(ad.position === undefined && { hasUpdated: true }),
          position: ad.position ?? {
            main: -1,
            left: -1,
            right: -1,
            hero: -1,
            comments: -1,
          },
        }))

        setShownAdsMap((prevState) => {
          const validAds = allShownAds.filter((ad) => ad.position?.main !== -1)
          validAds.sort((a, b) => a.position?.main - b.position?.main)

          const result = [null, null, null]

          validAds.forEach((ad) => {
            if (ad.position?.main >= 0 && ad.position?.main < 3) {
              result[ad.position?.main] = ad
            }
          })

          const leftAds = allShownAds
            .filter((ad) => ad.position?.left !== -1)
            .sort((a, b) => a.position?.left - b.position?.left)

          const rightAds = allShownAds
            .filter((ad) => ad.position?.right !== -1)
            .sort((a, b) => a.position?.right - b.position?.right)

          const heroAds = allShownAds
            .filter((ad) => ad.position?.hero !== -1)
            .sort((a, b) => a.position?.hero - b.position?.hero)

          const commentsAds = allShownAds
            .filter((ad) => ad.position?.comments !== -1)
            .sort((a, b) => a.position?.comments - b.position?.comments)

          return {
            ...prevState,
            main: result,
            left: leftAds,
            right: rightAds,
            hero: heroAds,
            comments: commentsAds,
          }
        })
      } catch (error) {
        console.error('Error fetching ads:', error)
      }
    }

    fetchShownAds()
  }, [refetchShownAdsTrigger])

  const handleSelectContainer = (index, section) => {
    setActiveSelection({ index, section })
    setIsModalVisible(true)

    if (section === 'hero') {
      setCurrentTotalCount(totalHeroCount)
      setCurrentTotalPages(totalHeroPages)
    } else {
      setCurrentTotalCount(totalNonHeroCount)
      setCurrentTotalPages(totalNonHeroPages)
    }
  }

  const handleSelectAd = (ad, section) => {
    const index =
      activeSelection.section === 'main' ? activeSelection.index : null
    const updatedAdsInSection = [...shownAdsMap[section]]
    const newPosition = index !== null ? index : updatedAdsInSection.length

    const updatedAd = {
      ...ad,
      isSelected: true,
      hasUpdated: false,
      position: { ...ad.position, [section]: newPosition },
    }

    if (section === 'main' && index !== null) {
      updatedAdsInSection[index] = updatedAd
    } else {
      updatedAdsInSection.push(updatedAd)
    }

    setShownAdsMap((prev) => ({
      ...prev,
      [section]: updatedAdsInSection,
    }))

    setActiveSelection({ index: null, section: null })
    setIsModalVisible(false)
  }

  const handleOnMove = (index, section, direction) => {
    handleMoveAd(index, section, direction, setShownAdsMap)
  }

  const handleRemoveShownAd = (adId, section) => {
    setShownAdsMap((prevMap) => {
      const updatedMap = { ...prevMap }

      if (updatedMap[section]) {
        updatedMap[section] = updatedMap[section].map((ad) => {
          if (ad?._id === adId) {
            return {
              ...ad,
              position: { ...ad.position, [section]: -1 },
              hasUpdated: true,
              isSelected: false,
            }
          }
          return ad
        })
      }

      return updatedMap
    })
  }

  return (
    <div className={classes.container}>
      <h1>Upravljanje reklamama: </h1>

      <div className={classes.innerContainer}>
        <div className={classes.uploadContainer}>
          <h2>Dodaj novu reklamu u galeriju:</h2>
          <div className={classes.uploadSection}>
            <input
              type='text'
              placeholder='Unesite link reklame'
              value={link}
              onChange={(e) => setLink(e.target.value)}
              className={classes.inputField}
            />
            <label className={classes.btn}>
              Izaberi sliku/video
              <input
                type='file'
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    setFile(e.target.files[0])
                  }
                }}
                className={classes.hiddenInput}
              />
            </label>
            {file && <p className={classes.fileName}>{file.name}</p>}
            <label>
              <input
                type='checkbox'
                checked={isHeroImage}
                onChange={() => setIsHeroImage(!isHeroImage)}
              />
              Hero reklama
            </label>
            <button
              onClick={() =>
                handleUpload(
                  file,
                  link,
                  isHeroImage,
                  setFile,
                  setLink,
                  setIsHeroImage
                )
              }
              className={classes.uploadButton}
            >
              Objavi reklamu
            </button>
          </div>
        </div>
        <div className={classes.saveChangesContainer}>
          <button
            // disabled={Object.keys(editedLinks).length === 0}
            className={classes.uploadButton}
            onClick={() =>
              handleSaveChanges(
                editedLinks,
                setEditedLinks,
                shownAdsMap,
                setShownAdsMap
              )
            }
          >
            Ažuriraj podatke ispod
          </button>
        </div>
      </div>

      <AdminAdSelectionSection
        title='Hero banner'
        ads={shownAdsMap.hero}
        section='hero'
        activeSelection={activeSelection}
        onSelect={handleSelectContainer}
        onRemove={handleRemoveShownAd}
        onLinkChange={(adId, newLink, originalLink) =>
          handleLinkChange(adId, newLink, originalLink, setEditedLinks)
        }
        editedLinks={editedLinks}
        onMove={handleOnMove}
      />

      <AdminAdSelectionSection
        title='Glavni deo'
        ads={shownAdsMap.main}
        section='main'
        onSelect={handleSelectContainer}
        onLinkChange={(adId, newLink, originalLink) =>
          handleLinkChange(adId, newLink, originalLink, setEditedLinks)
        }
        onRemove={handleRemoveShownAd}
        isFixedSlots={true}
        editedLinks={editedLinks}
        onMove={handleOnMove}
      />

      <AdminAdSelectionSection
        title='Leva strana'
        ads={shownAdsMap.left}
        section='left'
        activeSelection={activeSelection}
        onSelect={handleSelectContainer}
        onRemove={handleRemoveShownAd}
        onLinkChange={(adId, newLink, originalLink) =>
          handleLinkChange(adId, newLink, originalLink, setEditedLinks)
        }
        editedLinks={editedLinks}
        onMove={handleOnMove}
      />

      <AdminAdSelectionSection
        title='Desna strana'
        ads={shownAdsMap.right}
        section='right'
        activeSelection={activeSelection}
        onSelect={handleSelectContainer}
        onRemove={handleRemoveShownAd}
        onLinkChange={(adId, newLink, originalLink) =>
          handleLinkChange(adId, newLink, originalLink, setEditedLinks)
        }
        editedLinks={editedLinks}
        onMove={handleOnMove}
      />

      <AdminAdSelectionSection
        title='Sredina'
        ads={shownAdsMap.comments}
        section='comments'
        activeSelection={activeSelection}
        onSelect={handleSelectContainer}
        onRemove={handleRemoveShownAd}
        onLinkChange={(adId, newLink, originalLink) =>
          handleLinkChange(adId, newLink, originalLink, setEditedLinks)
        }
        editedLinks={editedLinks}
        onMove={handleOnMove}
      />

      <Modal
        title={'Galerija reklama'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800}
        style={{
          background: '#303030',
          opacity: 1,
          borderRadius: '0.5rem',
        }}
      >
        <div ref={topRef} className={classes.paginationWrapper}>
          <div
            className={`${classes.ShownAdsContainer} ${classes.allAdsContainer}`}
          >
            {(
              (activeSelection.section === 'hero' ? heroAds : adsList) || []
            ).map((ad) => {
              const isSelected = shownAdsMap[activeSelection.section]?.some(
                (selectedAd) =>
                  selectedAd &&
                  selectedAd._id === ad._id &&
                  selectedAd.position?.[activeSelection.section] !== -1
              )

              return (
                <div key={ad._id} className={classes.adsWrapper}>
                  {ad.type === 'video' ? (
                    <div
                      onClick={() =>
                        !isSelected &&
                        handleSelectAd(ad, activeSelection.section)
                      }
                      className={isSelected ? classes.disabledAd : ''}
                    >
                      <AdVideoPlayer src={`${SERVER_URL}/${ad.localPath}`} />
                    </div>
                  ) : (
                    <img
                      onClick={() =>
                        !isSelected &&
                        handleSelectAd(ad, activeSelection.section)
                      }
                      src={`${SERVER_URL}/${ad.localPath}`}
                      alt='Ad'
                      className={`${classes.adContainer} ${
                        isSelected ? classes.disabledAd : ''
                      }`}
                    />
                  )}
                  <button
                    className={classes.btn}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleDeleteButtonPress(ad._id)
                    }}
                  >
                    Obriši
                  </button>
                </div>
              )
            })}
          </div>
          <div className={classes.pagination}>
            <Pagination
              setCurrentPage={setCurrentPage}
              limit={limit}
              currentPage={currentPage}
              totalCount={currentTotalCount}
              totalPages={currentTotalPages}
              buttonLimit={3}
              topRef={topRef}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export const useAdsManagement = () => {
  const [refetchTrigger, setRefetchTrigger] = useState(false)
  const [refetchShownAdsTrigger, setRefetchShownAdsTrigger] = useState(false)

  const isValidURL = (url) => {
    try {
      new URL(url)
      return true
    } catch (error) {
      return false
    }
  }

  const handleUpload = async (
    file,
    link,
    isHeroImage,
    setFile,
    setLink,
    setIsHeroImage,
    forMobile = false
  ) => {
    if (!file) {
      betkoErrorPopup('Morate izabrati fajl.')
      return
    }

    const maxSize = 10 * 1024 * 1024

    if (file.size > maxSize) {
      betkoErrorPopup('Veličina videa ne može biti veća od 10mb.')
      return
    }

    const formData = new FormData()

    if (link) {
      if (!isValidURL(link)) {
        betkoErrorPopup('Morate ukucati validan link.')
        return
      }
      formData.append('link', link)
    }

    formData.append('file', file)
    formData.append('isHeroImage', isHeroImage)
    formData.append('forMobile', forMobile)

    const fileExtension = file.name.split('.').pop().toLowerCase()
    const fileType = ['mp4', 'mov', 'avi'].includes(fileExtension)
      ? 'video'
      : 'image'

    try {
      const response = await axios.post(
        `${SERVER_URL}/upload-adds-image-or-video`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            type: fileType,
          },
        }
      )

      if (response.status === 201) {
        betkoSuccessPopup('Reklama uspešno postavljena!')
        setRefetchTrigger((prev) => !prev)
        setFile(null)
        setLink('')
        setIsHeroImage(forMobile)
      }
    } catch (error) {
      console.error('Upload error:', error)
      betkoErrorPopup('Greška pri postavljanju reklame.')
    }
  }

  const handleDeleteButtonPress = (adId) => {
    betkoPopupWithAction(
      'Brisanje reklame',
      'Da li ste sigurni da želite da obrišete ovu sliku/video?',
      (closePopup) => (
        <div className={classes.deleteButtonWrapper}>
          <button
            onClick={() => {
              handleDeletion(adId)
              closePopup()
            }}
          >
            Obriši
          </button>
          <button onClick={closePopup}>Odustani</button>
        </div>
      )
    )
  }

  const handleDeletion = async (adId) => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/delete-adds-image/${adId}`
      )

      if (response.status === 200) {
        betkoSuccessPopup('Reklama uspešno obrisana!')
        setRefetchTrigger((prev) => !prev)
      }
    } catch (error) {
      console.error('Deletion error:', error)
      betkoErrorPopup('Greška pri brisanju reklame.')
    }
  }

  const handleMoveAd = (ad, section, direction, setShownAdsMap) => {
    setShownAdsMap((prevMap) => {
      const updatedAds = [...prevMap[section]]

      const index = updatedAds.findIndex((item) => item._id === ad._id)
      if (index === -1) return prevMap

      let swapIndex = index

      if (direction === 'left') {
        for (let i = index - 1; i >= 0; i--) {
          if (updatedAds[i].position[section] !== -1) {
            swapIndex = i
            break
          }
        }
      } else if (direction === 'right') {
        for (let i = index + 1; i < updatedAds.length; i++) {
          if (updatedAds[i].position[section] !== -1) {
            swapIndex = i
            break
          }
        }
      }

      if (swapIndex !== index) {
        const temp = {
          ...updatedAds[swapIndex],
          hasMoved: true,
          position: { ...updatedAds[swapIndex].position, [section]: index },
        }

        updatedAds[swapIndex] = {
          ...updatedAds[index],
          hasMoved: true,
          position: { ...updatedAds[index].position, [section]: swapIndex },
        }

        updatedAds[index] = temp
      }

      return { ...prevMap, [section]: updatedAds }
    })
  }

  const handleLinkChange = (adId, newLink, originalLink, setEditedLinks) => {
    setEditedLinks((prev) => {
      if (newLink === originalLink) {
        const { [adId]: _, ...rest } = prev
        return rest
      }
      return { ...prev, [adId]: newLink }
    })
  }

  const mergeAds = (shownAdsMap) => {
    const mergedAds = {}

    for (const section in shownAdsMap) {
      const updatedAds = shownAdsMap[section].filter((ad) => ad?.hasUpdated)

      updatedAds.forEach((ad) => {
        if (!mergedAds[ad._id]) {
          mergedAds[ad._id] = {
            ...ad,
            position: { ...ad.position },
          }
        } else {
          // prefer negative values
          for (const key in ad.position) {
            if (ad.position[key] === -1) {
              mergedAds[ad._id].position[key] = -1
            }
          }
        }
      })
    }

    for (const section in shownAdsMap) {
      const selectedAds = shownAdsMap[section].filter((ad) => ad?.isSelected)

      selectedAds.forEach((ad) => {
        if (!mergedAds[ad._id]) {
          mergedAds[ad._id] = {
            ...ad,
            position: { ...ad.position },
          }
        } else {
          // prefer non negative values
          for (const key in ad.position) {
            if (
              ad.position[key] !== -1 &&
              mergedAds[ad._id].position[key] === -1
            ) {
              mergedAds[ad._id].position[key] = ad.position[key]
            }
          }
        }
      })
    }

    for (const section in shownAdsMap) {
      const movedAds = shownAdsMap[section].filter((ad) => ad?.hasMoved)

      movedAds.forEach((ad) => {
        if (!mergedAds[ad._id]) {
          mergedAds[ad._id] = {
            ...ad,
            position: { ...ad.position },
          }
        } else {
          // preserve movement updates
          mergedAds[ad._id].hasMoved = true
        }
      })
    }

    const finalAds = Object.values(mergedAds)
    const adsWithMultipleFlags = finalAds.filter(
      (ad) =>
        (ad.hasUpdated && ad.isSelected) ||
        (ad.hasUpdated && ad.hasMoved) ||
        (ad.isSelected && ad.hasMoved)
    )

    adsWithMultipleFlags.forEach((ad) => {
      if (ad.hasUpdated) {
        mergedAds[ad._id + '_hasUpdated'] = {
          ...ad,
          isSelected: false,
          hasMoved: false,
        }
      }
      if (ad.isSelected) {
        mergedAds[ad._id + '_isSelected'] = {
          ...ad,
          hasUpdated: false,
          hasMoved: false,
        }
      }
      if (ad.hasMoved) {
        mergedAds[ad._id + '_hasMoved'] = {
          ...ad,
          hasUpdated: false,
          isSelected: false,
        }
      }
    })

    const mergedAdsMap = Object.values(mergedAds)
    return mergedAdsMap
  }

  const handleSaveChanges = async (
    editedLinks,
    setEditedLinks,
    shownAdsMap,
    setShownAdsMap
  ) => {
    const invalidLinks = Object.entries(editedLinks).filter(
      ([, link]) => !isValidURL(link)
    )

    if (invalidLinks.length > 0) {
      betkoPopup(
        'Greška',
        'Nevažeći linkovi:',
        `\n${invalidLinks.map(([, link]) => `'${link}'`).join('\n')}`
      )
      return
    }

    const linkUpdates = Object.entries(editedLinks).map(([adId, link]) => ({
      adId,
      link,
    }))

    const mergedAds = mergeAds(shownAdsMap)

    const updates = Object.values(mergedAds).map((ad) => ({
      adId: ad._id,
      link: linkUpdates.find((lu) => lu.adId === ad._id)?.link || ad.link,
      position: ad.position,
    }))

    linkUpdates.forEach((linkUpdate) => {
      if (!updates.some((update) => update.adId === linkUpdate.adId)) {
        updates.push({
          adId: linkUpdate.adId,
          link: linkUpdate.link,
          position: linkUpdate.position,
        })
      }
    })

    console.log('Sending updates:', updates)
    if (!updates || updates.length === 0) {
      betkoInfoPopup('Niste napravili ni jednu izmenu.')
      return
    }

    try {
      const response = await fetch(`${SERVER_URL}/update-adds`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ updates }),
      })

      if (!response.ok) {
        console.error(response.statusText)
        betkoErrorPopup('Došlo je do greške pri čuvanju promena.')
        return
      }

      betkoSuccessPopup('Reklame su uspešno ažurirane!')

      setEditedLinks({})
      setShownAdsMap({
        main: [null, null, null],
        left: [],
        right: [],
        hero: [],
        comments: [],
      })
      setRefetchShownAdsTrigger((prev) => !prev)
    } catch (error) {
      console.error('Update error:', error)
      betkoErrorPopup('Došlo je do greške pri čuvanju promena.')
    }
  }

  return {
    handleUpload,
    handleDeleteButtonPress,
    refetchTrigger,
    handleMoveAd,
    handleLinkChange,
    handleSaveChanges,
    refetchShownAdsTrigger,
  }
}
