import { useState, useEffect } from 'react'
import axios from '../config/axios'

const useChatImage = (apiGetPath) => {
  const [chatImageLink, setChatImageLink] = useState(null)

  useEffect(() => {
    const fetchChatImage = async () => {
      try {
        const img = await axios.get(`/chat-image/${apiGetPath}`)
        setChatImageLink(img.data?.link)
      } catch (err) {
        console.error('Error fetching chat image:', err)
        setChatImageLink(null)
      }
    }

    fetchChatImage()
  }, [apiGetPath])

  return chatImageLink
}

export default useChatImage
