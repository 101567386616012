import React, { useContext, useEffect, useRef, useState } from 'react'
import classes from './shopHeaderNew.module.css'
import { MdHome } from 'react-icons/md'
import { FaBalanceScaleLeft } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthContext from '../../context/AuthProvider'
import CartContext from '../../context/CartContext'
import {Badge, message } from 'antd'
import { betkoErrorPopup } from '../../utils/notifications'

const ShopHeaderNew = ({
  setIsOpenLoginModal,
  onChangeSearchTerm,
  searchTerm,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const navigate = useNavigate()
  const location = useLocation()
  const { auth } = useContext(AuthContext)
  const isLogged = auth && Object.keys(auth).length > 0
  const searchTermRef = useRef(null)
  const from = location?.state?.from
  const { cartWithData } = useContext(CartContext)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getClassNameForPath = (currentPath) => {
    return location?.search?.includes(currentPath) ||
      location?.pathname === '/betko_shop'
      ? classes['selectedMenuItem']
      : ''
  }

  return (
    <div className={classes.shopHeader}>
      <div className={classes.header}>
        <div onClick={() => navigate('/betko_shop')} style={{cursor:"pointer"}}>
        <img  src="/icons/shop/home-shop.svg" alt="shop home" />
        <h1>BETKO SHOP</h1>
        </div>
       
        <button
  onClick={() => {
    if (cartWithData.length < 1) {
      betkoErrorPopup('Vaša korpa je prazna!')
      return
    }
    if (!auth?._id) {
      setIsOpenLoginModal(true)
      return
    }
    navigate('/betko_shop/moja-korpa')
  }}
  style={{ background: 'none', border: 'none', padding: 0, position: 'relative' }}
>
  <Badge count={auth?._id ? cartWithData?.length : 0} showZero={false}  style={{color:'#252F3B', backgroundColor:'#D6FF00'}}>
    <img src='/icons/shop/shoping-cart.svg' alt='Korpa' style={{ width: 24, height: 24 }} />
  </Badge>
</button>

      </div>
    </div>
  )
}

export default ShopHeaderNew
