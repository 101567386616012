import AdVideoPlayer from '../components/AdVideoPlayer'
import classes from './adminManageAds.module.css'

const AdminAdSelectionSection = ({
  title,
  ads,
  section,
  activeSelection = {},
  onSelect,
  onRemove,
  onLinkChange,
  isFixedSlots = false,
  editedLinks,
  onMove,
}) => {
  const hasValidLeft = (index, section, ads) => {
    for (let i = index - 1; i >= 0; i--) {
      if (ads[i].position[section] !== -1) {
        return true
      }
    }
    return false
  }

  const hasValidRight = (index, section, ads) => {
    for (let i = index + 1; i < ads.length; i++) {
      if (ads[i].position[section] !== -1) {
        return true
      }
    }
    return false
  }

  return (
    <>
      <h2>Reklame trenutno prikazane na sajtu ({title})</h2>
      <div className={classes.selectedAdsContainer}>
        {ads
          .map((ad) => {
            if (section === 'main' && ad?.position[section] === -1) {
              return null
            }
            return ad
          })
          .filter((ad) => {
            if (section === 'main') {
              return true
            }

            return ad?.position[section] !== -1
          })
          .map((ad, index) => {
            return (
              <div className={classes.adsWrapper} key={index}>
                {ad && section !== 'main' && (
                  <div className={classes.arrowContainer}>
                    <button
                      className={classes.arrowBtn}
                      onClick={() => onMove(ad, section, 'left')}
                      disabled={!hasValidLeft(index, section, ads)}
                    >
                      {'<'}
                    </button>

                    <button
                      className={classes.arrowBtn}
                      onClick={() => onMove(ad, section, 'right')}
                      disabled={!hasValidRight(index, section, ads)}
                    >
                      {'>'}
                    </button>
                  </div>
                )}

                <div
                  className={`${classes.adContainer} ${
                    activeSelection.index === index &&
                    activeSelection.section === section
                      ? classes.active
                      : ''
                  }`}
                  onClick={() => {
                    if (isFixedSlots) onSelect(index, section)
                  }}
                >
                  {ad ? (
                    ad.type === 'video' ? (
                      <AdVideoPlayer
                        className={classes.adContainer}
                        src={`${process.env.REACT_APP_SERVER_URL}/${ad.localPath}`}
                      />
                    ) : (
                      <img
                        className={classes.adContainer}
                        src={`${process.env.REACT_APP_SERVER_URL}/${ad.localPath}`}
                        alt='Ad'
                      />
                    )
                  ) : (
                    <p>Odaberi novu reklamu iz galerije na ovom mestu</p>
                  )}
                </div>

                {ad ? (
                  <>
                    <input
                      type='text'
                      className={classes.inputField}
                      value={
                        editedLinks[ad._id] !== undefined
                          ? editedLinks[ad._id]
                          : ad.link
                      }
                      onChange={(e) =>
                        onLinkChange(ad._id, e.target.value, ad.link)
                      }
                      placeholder='Unesi link'
                    />
                    <button
                      className={classes.btn}
                      onClick={() => onRemove(ad._id, section)}
                    >
                      Sakrij
                    </button>
                  </>
                ) : null}
              </div>
            )
          })}

        {!isFixedSlots && (
          <div
            className={`${classes.adContainer} ${classes.adContainerMargin} ${
              activeSelection.index === ads.length &&
              activeSelection.section === section
                ? classes.active
                : ''
            }`}
            onClick={() => onSelect(ads.length, section)}
          >
            <p>Odaberi novu reklamu iz galerije</p>
          </div>
        )}
      </div>
    </>
  )
}

export default AdminAdSelectionSection
