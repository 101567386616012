import ForumPageTemplate from './ForumPageTemplate'

const StatistikaSinglePage = () => (
  <ForumPageTemplate
    title='STATISTIKA'
    apiGetPath='statistics'
    apiPostPath='statistics-post'
    navigationPath='statistika'
    commentType='statistika'
    chatImagePath='statistic'
  />
)

export default StatistikaSinglePage
