import { useContext } from "react";
import axios from "../config/axios";
import AuthContext from "../context/AuthProvider";

const useLogout = () => {
  const { setAuth } = useContext(AuthContext);

  const logout = async () => {
    try {
      await axios.get("/logout", {
        withCredentials: true,
      });
      
      // Clear auth state
      setAuth({});
    } catch (error) {
      console.error("Greška prilikom odjave:", error);
      // Still clear auth on error to prevent stuck states
      setAuth({});
    }
  };

  return logout;
};

export default useLogout;
