import React, { useState } from 'react'
import classes from './BetkoFamily.module.css'

const BetkoPlus = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleButton = (index) => {
    setIsOpen(isOpen === index ? null : index)
  }
  return (
    <div className={`${classes.content}`}>
      <div className={classes.headerContainer}>
        <h1>NA BETKU TE ČEKA SPORTSKO TIPSTERSKA ''EUREKA''!</h1>
      </div>
      <div className={classes.contentContainer}>
          <button onClick={() => toggleButton(1)} className={`${classes.dropdownButton} ${isOpen === 1 ? classes.active : ''}`}>
            <div className={classes.titleWrapper}>
              <span>Šta te čeka na ''Betko plus'' meniju ?</span>
              <img
                 src={isOpen === 1 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                 alt={isOpen === 1 ? 'Sakrij' : 'Prikaži'}
                 className={classes.toggleIcon}
              />
            </div>
            {isOpen === 1 && (
              <>
                <hr className={classes.divider}/>
                <div className={classes.textWrapper}>
                  <p>Čekaju te mnoge inovacije u oblasti tipovanja, zabave i tvoje rekreacije.</p>
                </div>
              </>
            )}
          </button>
          <button onClick={() => toggleButton(2)} className={`${classes.dropdownButton} ${isOpen === 2 ? classes.active : ''}`}>
            <div className={classes.titleWrapper}>
              <span>Što se inovacija tiče...</span>
              <img
                 src={isOpen === 2 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                 alt={isOpen === 2 ? 'Sakrij' : 'Prikaži'}
                 className={classes.toggleIcon}
              />
            </div>
            {isOpen === 2 && (
              <>
                <hr className={classes.divider}/>
                <div className={classes.textWrapper}>
                  <p>Na ovom mestu će sa nama, mnogi naši projekti ugledati svetlost dana! Jedan po jedan, od kojih je svaki vredan!</p>
                </div>
              </>
            )}
          </button>
          <button onClick={() => toggleButton(3)} className={`${classes.dropdownButton} ${isOpen === 3 ? classes.active : ''}`}>
            <div className={classes.titleWrapper}>
              <span>Posećuj sajt naš...</span>
              <img
                 src={isOpen === 3 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                 alt={isOpen === 3 ? 'Sakrij' : 'Prikaži'}
                 className={classes.toggleIcon}
              />
            </div>
            {isOpen === 3 && (
              <>
                <hr className={classes.divider}/>
                <div className={classes.textWrapper}>
                  <p>O svim inovacijama obavestićemo te da znaš... Na početnoj stranici platforme ove, javićemo ti uvek, kada te ''Betko plus'' pozove !</p>
                </div>
              </>
            )}
          </button>
          <button onClick={() => toggleButton(4)} className={`${classes.dropdownButton} ${isOpen === 4 ? classes.active : ''}`} style={{marginBottom:'16px'}}>
            <div className={classes.titleWrapper}>
              <span>Tvoje je samo...</span>
              <img
                 src={isOpen === 4 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                 alt={isOpen === 4 ? 'Sakrij' : 'Prikaži'}
                 className={classes.toggleIcon}
              />
            </div>
            {isOpen === 4 && (
              <>
                <hr className={classes.divider}/>
                <div className={classes.textWrapper}>
                  <p>Betkotip.com da pratiš i posetom svojom svima do znanja da staviš ! Da uz ''Betko plus'', ‘’Plus na Plus’’ praviš !</p>
                </div>
              </>
            )}
          </button>
      </div>
      
    </div>
  )
}

export default BetkoPlus
