import LayoutWithCalcAndSideImgs from '../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'
import PremiumPredloziButton from '../components/PremiumPredloziButton'
import ForumPostList from '../components/ForumPostList'
import classes from './betKursNovo.module.css'

const BetKursNovo = () => {
  return (
    <LayoutWithCalcAndSideImgs title='PREMIUM PAKETI'>
      <PremiumPredloziButton />
      <div className={classes.postsContainer}>
        <ForumPostList postUrl='/getAllBetKursNovoPosts' type='betKursNovo' />
      </div>
    </LayoutWithCalcAndSideImgs>
  )
}

export default BetKursNovo
