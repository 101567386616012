import { createContext, useContext, useRef } from 'react'

const RefContext = createContext(null)

export const RefProvider = ({ children }) => {
  const homeRef = useRef(null)

  return (
    <RefContext.Provider value={{ homeRef }}>{children}</RefContext.Provider>
  )
}

export const useHomeRef = () => useContext(RefContext)
