import classes from './Tabele.module.css'
import classesLT from './forumPage.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import SideImages from '../components/Redesign/SideImages/SideImages'
import RangTabela from './RangTabela'
import MesecnaTabela from './MesecnaTabela'
import GodisnjaTabela from './GodisnjaTabela'
import GlobalnaTabela from './GlobalnaTabela'
import { useState } from 'react'

const Tabele = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const activeTable = searchParams.get('table') || 'rang'

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const isMobile = window.innerWidth <= 768

  return (
    <div className={classesLT.container}>
      <div className={classesLT.sideImagesContainer}>
        <SideImages />
      </div>
      <div className={`${classesLT.postsPageContainer} ${classes.content}`}>
        <h1 className={classes.titleContainer}>
          <button
            onClick={() => navigate('/mister_tipster/match')}
            className={classes.backButton}
          >
            <img src='/icons/white-left-arrow.svg' alt='nazad' />
            Nazad
          </button>{' '}
          MISTER TIPSTER
        </h1>
        <button
          onClick={() => navigate('/mister_tipster/match')}
          className={classes.backButtonMobile}
        >
          <img src='/icons/white-left-arrow.svg' alt='nazad' />
          Nazad
        </button>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className={`${isMenuOpen ? classes.activeButton : ''} ${
            classes.menuButton
          }`}
        >
          {activeTable === 'rang' && 'Rang tabela'}
          {activeTable === 'month' && 'Mesečna tabela'}
          {activeTable === 'year' && 'Godišnja tabela'}
          {activeTable === 'global' && 'Globalna tabela'}
          <img
            src='/icons/submenu-active.svg'
            alt='submenu'
            className={isMenuOpen ? classes.submenuActive : ''}
          />
        </button>
        {(isMenuOpen || !isMobile) && (
          <div className={classes.tableButtonsContainer}>
            {[
              { key: 'rang', label: 'Tabela ranga' },
              { key: 'month', label: 'Mesečna tabela' },
              { key: 'year', label: 'Godišnja tabela' },
              { key: 'global', label: 'Globalna tabela' },
            ].map(({ key, label }) => (
              <button
                key={key}
                onClick={() => navigate(`?table=${key}`)}
                className={activeTable === key ? classes.tableButtonActive : ''}
              >
                {key === 'globalna' ? (
                  <img src='/icons/tipster/globe.svg' alt='globe' />
                ) : (
                  <div className={classes.calendarImage}>
                    <img
                      src='/icons/tipster/calendar-transparent.svg'
                      alt='calendar'
                    />
                    <img
                      className={classes.calendarImageInside}
                      src='/icons/tipster/calendar-transparent-dates.svg'
                      alt='calendar2'
                    />
                  </div>
                )}

                {label}
              </button>
            ))}
          </div>
        )}
        <div className={classes.tableContent}>
          {activeTable === 'rang' && <RangTabela />}
          {activeTable === 'month' && <MesecnaTabela />}
          {activeTable === 'year' && <GodisnjaTabela />}
          {activeTable === 'global' && <GlobalnaTabela />}
        </div>
      </div>
    </div>
  )
}

export default Tabele
