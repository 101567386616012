import React, { useEffect, useState } from 'react'
import classes from './MisterTipsterProfil.module.css'
import classesLT from './forumPage.module.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import SideImages from '../components/Redesign/SideImages/SideImages'
import MisterTipsterTipping from '../components/MisterTipsterTipping'
import { betkoErrorPopup } from '../utils/notifications'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const MisterTipsterProfil = () => {
  const [tipsterProfile, setTipsterProfile] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()
  const userId = new URLSearchParams(window.location.search).get('user')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const isMobile = window.innerWidth <= 768

  useEffect(() => {
    const fetchTipsterProfile = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/mister-tipster-profile/${userId}/${currentPage}`
        )

        setTipsterProfile(response?.data)
      } catch (err) {
        betkoErrorPopup(
          err?.response?.data?.message || 'Greška! Molimo pokušajte kasnije!'
        )
      }
    }

    fetchTipsterProfile()
  }, [currentPage, userId])

  return (
    <div className={classesLT.container}>
      <div className={classesLT.sideImagesContainer}>
        <SideImages />
      </div>
      <div
        className={`${classesLT.postsPageContainer} ${classes.contentContainer}`}
      >
        <h1 className={classes.titleContainer}>
          <button onClick={() => navigate(-1)} className={classes.backButton}>
            <img src='/icons/white-left-arrow.svg' alt='nazad' />
            Nazad
          </button>{' '}
          MISTER TIPSTER NALOG
        </h1>
        <button
          onClick={() => navigate(-1)}
          className={classes.backButtonMobile}
        >
          <img src='/icons/white-left-arrow.svg' alt='nazad' />
          Nazad
        </button>

        <div className={classes.profileContainer}>
          <div className={classes.mobileTableContainer}>
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={`${isMenuOpen ? classes.activeButton : ''} ${
                classes.menuButton
              }`}
            >
              <div>
                <img
                  src='/icons/tipster/calendar-transparent.svg'
                  alt='calendar'
                />
                <img
                  class={classes.calendarImageInside}
                  src='/icons/tipster/calendar-transparent-dates.svg'
                  alt='calendar2'
                />
                Tabele
              </div>
              <img
                src='/icons/submenu-active.svg'
                alt='submenu'
                className={isMenuOpen ? classes.submenuActive : ''}
              />
            </button>
            {(isMenuOpen || !isMobile) && (
              <>
                <button
                  onClick={() =>
                    navigate('/mister_tipster/tabele?table=rang&page=1')
                  }
                >
                  Tabela ranga
                </button>
                <button
                  onClick={() =>
                    navigate('/mister_tipster/tabele?table=month&page=1')
                  }
                >
                  Mesečna tabela
                </button>
                <button
                  onClick={() =>
                    navigate('/mister_tipster/tabele?table=year&page=1')
                  }
                >
                  Godišnja tabela
                </button>
                <button
                  onClick={() => {
                    navigate('/mister_tipster/tabele?table=global&page=1')
                  }}
                >
                  Globalna tabela
                </button>
              </>
            )}
          </div>
          <div className={classes.userInfo}>
            <div className={classes.userDetailsContainer}>
              <img
                src={
                  tipsterProfile?.user?.team?.teamImage?.url
                    ? `${SERVER_URL}/${tipsterProfile?.user?.team?.teamImage?.url}`
                    : '/teamPlaceholder.png'
                }
                alt='dres tima'
              />
              <div className={classes.userDetails}>
                <span className={classes.userName}>
                  {tipsterProfile?.user?.userName}
                </span>
                <span className={classes.userRole}>
                  <div className={classes.userIcon}>
                    <img src='/icons/comments/user.svg' alt='korisnik' />
                  </div>
                  <span>
                    {tipsterProfile?.user?.status === 'Admin'
                      ? 'Administrator'
                      : tipsterProfile?.user?.team?.name}{' '}
                  </span>
                </span>
                {/* <span>Rank: {tipsterProfile?.user?.rank}</span> */}
              </div>
            </div>

            <div className={classes.scoreContainer}>
              <div className={classes.scoreInner}>
                <button
                  onClick={() =>
                    navigate('/mister_tipster/tabele?table=rang&page=1')
                  }
                >
                  <img src='/icons/tipster/calendar.svg' alt='calendar' />
                  Tabela ranga
                </button>
                <span className={classes.rank}>
                  #{tipsterProfile?.user?.positionTenDay}
                </span>
                <span className={classes.score}>
                  {tipsterProfile?.user?.tablePoints?.rankPoints}
                </span>
              </div>
              <div className={classes.scoreInner}>
                <button
                  onClick={() =>
                    navigate('/mister_tipster/tabele?table=month&page=1')
                  }
                >
                  <img src='/icons/tipster/calendar.svg' alt='calendar' />
                  UK. Mesečna tabela
                </button>
                <span className={classes.rank}>
                  #{tipsterProfile?.user?.positionMonth}
                </span>
                <span className={classes.score}>
                  {tipsterProfile?.user?.tablePoints?.monthPoints}
                </span>
              </div>
            </div>
          </div>
        </div>

        <MisterTipsterTipping
          tipsterProfile={tipsterProfile}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>

      <div className={classes.sideContainer}>
        <div className={classes.tableButtons}>
          <span>
            <img src='/icons/tipster/calendar-transparent.svg' alt='calendar' />
            <img
              class={classes.calendarImageInside}
              src='/icons/tipster/calendar-transparent-dates.svg'
              alt='calendar2'
            />
            Tabele
          </span>
          <button
            onClick={() => navigate('/mister_tipster/tabele?table=rang&page=1')}
          >
            Tabela ranga
          </button>
          <button
            onClick={() =>
              navigate('/mister_tipster/tabele?table=month&page=1')
            }
          >
            Mesečna tabela
          </button>
          <button
            onClick={() => navigate('/mister_tipster/tabele?table=year&page=1')}
          >
            Godišnja tabela
          </button>
          <button
            onClick={() => {
              navigate('/mister_tipster/tabele?table=global&page=1')
            }}
          >
            Globalna tabela
          </button>
        </div>
        <div className={classes.sideAdContainer}>
          <img
            onClick={() => navigate('/mister_tipster/match')}
            src='/login-image-big-new2.jpg'
            alt='mistertipster'
          />
        </div>
      </div>
    </div>
  )
}

export default MisterTipsterProfil
