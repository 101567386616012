import { createContext, useContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const savedAuth = sessionStorage.getItem('betkoTipAuth');
    return savedAuth ? JSON.parse(savedAuth) : {};
  });
  const [refetch, setRefetch] = useState(false);
  const [login, setLogin] = useState(true);

  const updateAuth = (newAuth) => {
    setAuth(newAuth);
    if (Object.keys(newAuth).length > 0) {
      sessionStorage.setItem('betkoTipAuth', JSON.stringify(newAuth));
    } else {
      sessionStorage.removeItem('betkoTipAuth');
    }
  };

  return (
    <AuthContext.Provider value={{ 
      auth, 
      setAuth: updateAuth,
      setRefetch, 
      refetch, 
      setLogin, 
      login 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

export const useAuthContext = () => {
  return useContext(AuthContext);
};