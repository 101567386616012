import classes from './MisterTipsterOpisIgreNew.module.css'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const MisterTipsterOpisIgreNew = () => {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const [openImg, setOpenImg] = useState(null)

    const toggleButton = (index) => {
      setIsOpen(isOpen === index ? null : index)
    }
    
    return ( 
        <div className={classes.mainContainer}>
            <div className={classes.headerContainer}>
                <h1>
                    <button className={classes.backButtonDesktop} onClick={() => navigate(-1)}>
                        <img src="/icons/white-left-arrow.svg" alt="back button" />
                        Nazad
                    </button>
                    OPIS IGRE
                </h1>
                <button className={classes.backButtonMobile} onClick={() => navigate(-1)}>
                  <img src="/icons/white-left-arrow.svg" alt="back button" />
                   Nazad
                </button>
            </div>
            <div className={classes.contentContainer}>
                <h2 className={classes.contentHeader}>
                    OTVORI I OSVOJI !
                </h2>
                <p className={classes.green}>TAKMIČI SE I OSVOJI TAKMIČARSKE NAGRADE !</p>
                <div>
                <div>
                    <button 
                      onClick={() => toggleButton(1)} 
                      className={`${classes.dropdownButton} ${isOpen === 1 ? classes.active : ''}`}
                      >
                      <div className={classes.titleWrapper}>
                        <span>Ovo takmičenje se odvija...</span>
                        <img
                          src={isOpen === 1 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                          alt={isOpen === 1 ? 'Sakrij' : 'Prikaži'}
                          className={classes.toggleIcon}
                        />
                      </div>

                      {isOpen === 1 && (
                        <>
                          <hr className={classes.divider} />
                          <div className={classes.textWrapper}>
                            <p>...U desetodnevnim, tridesetodnevnim i godišnjim ciklusima.</p>
                          </div>
                        </>
                      )}
                    </button>
                </div>

                    <div>
                    <button 
                      onClick={() => toggleButton(2)} 
                      className={`${classes.dropdownButton} ${isOpen === 2 ? classes.active : ''}`}
                      >
                      <div className={classes.titleWrapper}>
                        <span>Pobednici...</span>
                        <img
                          src={isOpen === 2 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                          alt={isOpen === 2 ? 'Sakrij' : 'Prikaži'}
                          className={classes.toggleIcon}
                        />
                      </div>

                      {isOpen === 2 && (
                        <>
                          <hr className={classes.divider} />
                          <div className={classes.textWrapper}>
                            <p>...Svakog od ciklusa osvajaju takmičarske nagrade !</p>
                          </div>
                        </>
                      )}
                    </button>
                    </div>
                    <div>
                    <button 
                      onClick={() => toggleButton(3)} 
                      className={`${classes.dropdownButton} ${isOpen === 3 ? classes.active : ''}`}
                      >
                      <div className={classes.titleWrapper}>
                        <span>Pravila igre... </span>
                        <img
                          src={isOpen === 3 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                          alt={isOpen === 3 ? 'Sakrij' : 'Prikaži'}
                          className={classes.toggleIcon}
                        />
                      </div>

                      {isOpen === 3 && (
                        <>
                          <hr className={classes.divider} />
                          <div className={classes.textWrapper}>
                            <p>...Možeš pogledati u okviru menija "Pravila igre" u svom "Mister Tipster" nalogu. </p>
                          </div>
                        </>
                      )}
                    </button>
                    </div>
                    <div>
                    <button 
                      onClick={() => toggleButton(4)} 
                      className={`${classes.dropdownButton} ${isOpen === 4 ? classes.active : ''}`}
                      >
                      <div className={classes.titleWrapper}>
                        <span>Trajanje takmičenja... </span>
                        <img
                          src={isOpen === 4 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                          alt={isOpen === 4 ? 'Sakrij' : 'Prikaži'}
                          className={classes.toggleIcon}
                        />
                      </div>

                      {isOpen === 4 && (
                        <>
                          <hr className={classes.divider} />
                          <div className={classes.textWrapper}>
                            <p>...Po ciklusima, kao i takmičarske nagrade za pobednike istih, prikazani su ti u "Fondu
                              takmičarskih nagrada". </p>
                          </div>
                        </>
                      )}
                    </button>
                    </div>
                    <div>
                    <button 
                      onClick={() => toggleButton(5)} 
                      className={`${classes.dropdownButton} ${isOpen === 5 ? classes.active : ''}`}
                      >
                      <div className={classes.titleWrapper}>
                        <span>Šta je to što treba da uradiš da bi postao deo ovog takmičenja ? </span>
                        <img
                          src={isOpen === 5 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                          alt={isOpen === 5 ? 'Sakrij' : 'Prikaži'}
                          className={classes.toggleIcon}
                        />
                      </div>

                      {isOpen === 5 && (
                        <>
                          <hr className={classes.divider} />
                          <div className={classes.textWrapper}>
                            <p>...Vrlo je jednostavno. Sve što treba da uradiš da bi automatski postao deo takmičenja jeste da se registruješ na betkotip.com</p>
                          </div>
                        </>
                      )}
                    </button>
                    </div>
                    <div>
                    <button 
                      onClick={() => toggleButton(6)} 
                      className={`${classes.dropdownButton} ${isOpen === 6 ? classes.active : ''}`}
                      >
                      <div className={classes.titleWrapper}>
                        <span>Ok. Registrovao si se, šta sad ? </span>
                        <img
                          src={isOpen === 6 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                          alt={isOpen === 6 ? 'Sakrij' : 'Prikaži'}
                          className={classes.toggleIcon}
                        />
                      </div>

                      {isOpen === 6 && (
                        <>
                          <hr className={classes.divider} />
                          <div className={classes.textWrapper}>
                            <p>...Uloguj se na podatke koje si postavio pri registraciji, da bi učestvovao u igri, i možeš da počneš !</p>
                          </div>
                        </>
                      )}
                    </button>
                    </div>
                    <div>
                    <button 
                      onClick={() => toggleButton(7)} 
                      className={`${classes.dropdownButton} ${isOpen === 7 ? classes.active : ''}`}
                      >
                      <div className={classes.titleWrapper}>
                        <span>Šta dalje treba da uradiš? </span>
                        <img
                          src={isOpen === 7 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                          alt={isOpen === 7 ? 'Sakrij' : 'Prikaži'}
                          className={classes.toggleIcon}
                        />
                      </div>

                      {isOpen === 7 && (
                        <>
                          <hr className={classes.divider} />
                          <div className={classes.textWrapper}>
                            <p>...Uđi u svoj ''Tipster Match'', odaberi svoje tipove za postavljene utakmice, i to je to. </p>
                          </div>
                        </>
                      )}
                    </button>
                    </div>
                    <div>
                    <button 
                      onClick={() => toggleButton(8)} 
                      className={`${classes.dropdownButton} ${isOpen === 8 ? classes.active : ''}`}
                      >
                      <div className={classes.titleWrapper}>
                        <span>Obeleži da želiš da primaš e-mail, i...   </span>
                        <img
                          src={isOpen === 8 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                          alt={isOpen === 8 ? 'Sakrij' : 'Prikaži'}
                          className={classes.toggleIcon}
                        />
                      </div>

                      {isOpen === 8 && (
                        <>
                          <hr className={classes.divider} />
                          <div className={classes.textWrapper}>
                            <p>...Svaki dan ćeš dobiti od nas e-mail kako bismo te podsetili da odigraš svoj dnevni ''Mister Tipster Match''. Odigraj ga i ti si svoj deo posla obavio. </p>
                          </div>
                        </>
                      )}
                    </button>
                    </div>
                    <div>
                    <button 
                      onClick={() => toggleButton(9)} 
                      className={`${classes.dropdownButton} ${isOpen === 9 ? classes.active : ''}`}
                      >
                      <div className={classes.titleWrapper}>
                        <span>Kako da pratiš rezultate svoje igre ?  </span>
                        <img
                          src={isOpen === 9 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                          alt={isOpen === 9 ? 'Sakrij' : 'Prikaži'}
                          className={classes.toggleIcon}
                        />
                      </div>

                      {isOpen === 9 && (
                        <>
                          <hr className={classes.divider} />
                          <div className={classes.textWrapper}>
                            <p> ...Kada si odigrao svoj ''Mister Tipster Match'' nakon završetka
  tipovanih match-eva'' zbir pogođenih kvota se prenosi u tabele
  takmičenja. U zavisnosti od toga o kom je periodu takmičenja reč, zbir
  pogođenih kvota svih takmičara se prenosom u tabele selektuje. Na osnovu
  toga se određuje plasman takmičara. Jednostavnim ulazom u tabelu po
  Izboru. U svakom trenutku možeš videti svoj trenutni plasman, kao i
  plasman po završetku ciklusa. </p>
                          </div>
                        </>
                      )}
                    </button>
                    </div>
                    <div>
                    <button 
                      onClick={() => toggleButton(10)} 
                      className={`${classes.dropdownButton} ${isOpen === 10 ? classes.active : ''}`}
                      >
                      <div className={classes.titleWrapper}>
                        <span> Koliko je takmičenje zanimljivo? </span>
                        <img
                          src={isOpen === 10 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                          alt={isOpen === 10 ? 'Sakrij' : 'Prikaži'}
                          className={classes.toggleIcon}
                        />
                      </div>

                      {isOpen === 10 && (
                        <>
                          <hr className={classes.divider} />
                          <div className={classes.textWrapper}>
                            <p> ...Takmičenje je veoma zanimljivo iz više razloga. Najpre, obrt i
  preokreti jesu vrlo mogući, jer je u opticaju veći zbir kvota. Može se
  ne retko desiti da jedan takmičar pogodi veći deo istih ili sve, a drugi,
  koji je pre tog dana bio bolje rangiran, promaši veći deo istih ili sve. To
  su situacije koje mogu da te u jednom danu dovedu, primera radi,
  sa petog, šestog mesta na tabeli do prvog, drugog ili trećeg 
  mesta i osvajanja takmičarskih nagrada!</p>
                          </div>
                        </>
                      )}
                    </button>
                      
                    </div>
                    <div>
                    <button 
                      onClick={() => toggleButton(11)} 
                      className={`${classes.dropdownButton} ${isOpen === 11 ? classes.active : ''}`}
                      >
                      <div className={classes.titleWrapper}>
                        <span>Nemoj da propuštaš dane u igri...</span>
                        <img
                          src={isOpen === 11 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                          alt={isOpen === 11 ? 'Sakrij' : 'Prikaži'}
                          className={classes.toggleIcon}
                        />
                      </div>

                      {isOpen === 11 && (
                        <>
                          <hr className={classes.divider} />
                          <div className={classes.textWrapper}>
                            <p>...Ako želiš da ti budeš taj koji će osvojiti takmičarsku nagradu, takmiči se svaki dan i postani pobednik!</p>
                          </div>
                        </>
                      )}
                    </button>
                    </div>
                    <div>
                    <button 
                      onClick={() => toggleButton(12)} 
                      className={`${classes.dropdownButton} ${isOpen === 12 ? classes.active : ''}`}
                      >
                      <div className={classes.titleWrapper}>
                        <span>Takmičenja se anuliraju bodovno...</span>
                        <img
                          src={isOpen === 12 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                          alt={isOpen === 12 ? 'Sakrij' : 'Prikaži'}
                          className={classes.toggleIcon}
                        />
                      </div>

                      {isOpen === 12 && (
                        <>
                          <hr className={classes.divider} />
                          <div className={classes.textWrapper}>
                            <p>...Rang takmičenje na svakih deset dana, mesečno na trideset, a godišnje na godinu dana i počinje se novi ciklus, gde svi takmičari u isti ulaze sa nula bodova. Ukoliko si imao možda lošiji period gađanja ishoda u prošlom ciklusu, to u sledećem ciklusu nema nikakvo značenje, jer se u novi ciklus uključuješ ravnopravno sa svim drugim takmičarima.</p>
                          </div>
                        </>
                      )}
                    </button>
                    </div>
                    <div>
                    <button 
                      onClick={() => toggleButton(13)} 
                      className={`${classes.dropdownButton} ${isOpen === 13 ? classes.active : ''}`}
                      >
                      <div className={classes.titleWrapper}>
                        <span>Klikom na korisničko ime bilo kog takmičara...</span>
                        <img
                          src={isOpen === 13 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                          alt={isOpen === 13 ? 'Sakrij' : 'Prikaži'}
                          className={classes.toggleIcon}
                        />
                      </div>

                      {isOpen === 13 && (
                        <>
                          <hr className={classes.divider} />
                          <div className={classes.textWrapper}>
                            <p> ...Možeš pogledati njegov učinak u tipovanju, kao i njegove postavljene tipove za aktuelni tipsterski dan, kao što svaki takmičar može pogledati i tvoje rezultate gađanja. Sve ovo možeš pogledati i na ''Mister Tipster profilu'' na svakom od komentara registrovanih takmičara ili direktnim klikom na takmičara u samim tabelama takmičenja u okviru betkotip.com platforme, Naravno, pre toga je potrebno da se registruješ i da budeš ulogovan na betkotip.com.</p>
                          </div>
                        </>
                      )}
                    </button>
                      
                    </div>
                    <div>
                    <button 
                      onClick={() => toggleButton(14)} 
                      className={`${classes.dropdownButton} ${isOpen === 14 ? classes.active : ''}`}
                      style={{marginBottom:'16px'}}
                      >
                      <div className={classes.titleWrapper}>
                        <span>Registruj se, uloguj se, pa da počnemo !</span>
                        <img
                          src={isOpen === 14 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
                          alt={isOpen === 14 ? 'Sakrij' : 'Prikaži'}
                          className={classes.toggleIcon}
                        />
                      </div>

                      {isOpen === 14 && (
                        <>
                          <hr className={classes.divider} />
                          <div className={classes.textWrapper}>
                            <p> ...Pogledaj tipove svih takmičara ! Takmiči se i osvoji ! Budi pobednik !</p>
                          </div>
                        </>
                      )}
                    </button>
                    </div>
                </div>
                
                
            </div>
        </div>
    )
}
export default MisterTipsterOpisIgreNew