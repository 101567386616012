import React, { useState } from 'react'
import MisterTipsterSingleMatch from '../components/MisterTipsterSingleMatch'
import classesMT from '../pages/MisterTipster.module.css'
import PaginationAlt from './PaginationAlt'

const MisterTipsterTipping = ({
  tipsterProfile,
  currentPage,
  setCurrentPage,
}) => {
  const [selectedBets, setSelectedBets] = useState([])

  const formatDate = (dateString) => {
    if (!dateString) return ''

    const date = new Date(dateString)
    if (isNaN(date)) return dateString

    return date.toLocaleDateString('sr-RS', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
  }

  return (
    <div
      className={`${classesMT.contentContainer} ${classesMT.contentContainer}`}
    >
      {tipsterProfile?.paginatedData &&
        tipsterProfile?.paginatedData.length > 0 && (
          <>
            <div className={classesMT.tipsterHeader}>
              <img src='/icons/tipster/thunder.svg' alt='thunder' />
              TIPOVANJE
              <img src='/icons/tipster/thunder.svg' alt='thunder' />
            </div>

            <div className={classesMT.tipsterMatchContainer}>
              <span>
                PAROVI - {formatDate(tipsterProfile?.paginatedData[0]?.date)}
              </span>
              <div className={classesMT.headerSelection}>1</div>
              <div className={classesMT.headerSelection}>X</div>
              <div className={classesMT.headerSelection}>2</div>
            </div>

            {tipsterProfile?.paginatedData[0]?.pair?.map((match) => (
              <>
                <MisterTipsterSingleMatch
                  key={match._id}
                  isMatchPage={false}
                  pair={match}
                  selectedBets={selectedBets}
                  setSelectedBets={setSelectedBets}
                  userBets={tipsterProfile?.paginatedData}
                  isTipsterProfile={true}
                  isTipsterProfileTable={true}
                />
              </>
            ))}

            <PaginationAlt
              currentPage={currentPage}
              totalPages={tipsterProfile?.totalPages}
              onPageChange={(number) => setCurrentPage((prev) => prev + number)}
            />
          </>
        )}
    </div>
  )
}

export default MisterTipsterTipping
