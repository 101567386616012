import { forwardRef, useContext, useEffect, useRef, useState } from 'react'
import classes from './comments.module.css'
import classesSC from './Redesign/SingleComment/SingleComment.module.css'
import axios from '../config/axios'
import { useNavigate, useLocation } from 'react-router-dom'
import { FaRegComment } from 'react-icons/fa'
import AuthContext from '../context/AuthProvider'
import Pagination from './Pagination'
import Spinner from './Spinner'
import LoginModalNovo from './LoginModalNovo'
import {
  betkoErrorPopup,
  betkoInfoPopup,
  betkoSuccessPopup,
} from '../utils/notifications'
import { betkoPopupWithAction } from '../utils/BetkoPopupModal'
import BlockUserPopup from './BlockUserPopup'
import CommentWithAnswers from './CommentWithAnswers'

const Comments = forwardRef(
  (
    {
      user,
      id: defaultId,
      title,
      commentType: commentTypeDefault,
      adminComment,
      isHomePage,
      setCommentCounter,
    },
    ref
  ) => {
    // Default values for 'id' and 'commentType' need to be updated later for comments on the home page.
    const [id, setId] = useState(defaultId)
    const [commentType, setCommentType] = useState(commentTypeDefault)
    const stringId = id.toString()
    const SERVER_URL = process.env.REACT_APP_SERVER_URL
    const navigate = useNavigate()
    const location = useLocation()
    const { setRefetch, setAuth } = useContext(AuthContext)
    const commentsContainerRef = useRef(null)

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)

    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)

    // states
    const [comment, setComment] = useState({})
    const [answerOnCommentValue, setAnswerOnCommentValue] = useState('')
    const [editComment, setEditComment] = useState(false)
    const [editCommentValue, setEditCommentValue] = useState({})
    // delete modal
    const [selectedItem, setSelectedItem] = useState({})
    const [selectedComment, setSelectedComment] = useState({})
    // answer on comment
    const [answerOnComment, setAnswerOnComment] = useState(false)
    const [activeAnswer, setActiveAnser] = useState('')
    const [activeAnswerOwner, setActiveAnswerOwner] = useState('')
    const [parentComment, setParentComment] = useState('')
    const [showComments, setShowComments] = useState([])

    // login / register modal
    // const [loginModal, setLoginModal] = useState(false)
    const [registerModal, setRegisterModal] = useState(false)

    const [sliceValue] = useState(50)

    // All comments
    const [allComments, setAllComments] = useState(null)
    const [refetchTrigger, setRefetchTrigger] = useState(false)

    // Answers
    const [answersParentComment, setAnswersParentComment] = useState(null)
    const [answers, setAnswers] = useState(null)
    const [answersPage, setAnswersPage] = useState({})

    // Pagination
    const [totalCount, setTotalCount] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const queryParams = new URLSearchParams(location.search)
    const currentPageFromUrl = parseInt(queryParams.get('page')) || 1
    const [currentPage, setCurrentPage] = useState(currentPageFromUrl)

    useEffect(() => {
      setCurrentPage(currentPageFromUrl)
    }, [currentPageFromUrl])

    // reset comment field when the post changes
    useEffect(() => {
      setComment({ value: '', commentId: '' })
      setId(defaultId)
    }, [defaultId])

    // Update URL when currentPage changes
    useEffect(() => {
      if (isHomePage) {
        navigate(`?page=${currentPage}`)
      }
    }, [currentPage, isHomePage, navigate])

    const [pinnedComment, setPinnedComment] = useState(null)
    const needPinnedComment = isHomePage && currentPage === 1

    const commentIdFromUrl = queryParams.get('commentId')

    const fetchRepliesCountForComment = async (commentId) => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/countCommentResponses/${commentId}`,
          { withCredentials: false }
        )
        return response?.data?.responsesCount
      } catch (error) {
        console.error(
          `Error fetching replies count for comment ${commentId}:`,
          error
        )
        return 0
      }
    }

    const fetchAllComments = async () => {
      let pinnedComm = null
      let highlightedComm = null

      try {
        const commentsResponse = await axios.get(
          `${SERVER_URL}/allCommentsNew/${currentPage}/${defaultId}`,
          { withCredentials: false }
        )

        if (!isHomePage) {
          setCommentCounter(() => ({
            commentsLength: commentsResponse?.data?.numberOfParents,
            answersLength: commentsResponse?.data?.numberOfResponses,
            totalComments:
              commentsResponse?.data?.numberOfParents +
              commentsResponse?.data?.numberOfResponses,
          }))

          setTotalPages(
            Math.ceil(commentsResponse?.data?.numberOfParents / sliceValue)
          )
        }

        if (needPinnedComment) {
          try {
            const response = await axios.get(`${SERVER_URL}/getPinnedComment`)
            setPinnedComment(response?.data?.pinnedComment)
            pinnedComm = response?.data?.pinnedComment
          } catch (error) {
            console.error('Error fetching pinned comment:', error)
          }
        }

        if (commentIdFromUrl) {
          try {
            const response = await axios.get(
              `/get-comment-by-id/${commentIdFromUrl}`
            )
            highlightedComm = response.data.comment
          } catch (error) {
            console.error('Error fetching highlighted comment:', error)
          }
        }

        let comments = commentsResponse?.data?.comments || []

        if (needPinnedComment && pinnedComm?._id) {
          comments = [pinnedComm, ...comments]
        }

        if (highlightedComm?._id) {
          comments = [highlightedComm, ...comments]
        }

        const commentsWithRepliesCountPromises = comments.map(
          async (comment) => {
            const repliesCount = await fetchRepliesCountForComment(comment._id)
            return { ...comment, repliesCount }
          }
        )

        // Wait for all promises to resolve
        const commentsWithRepliesCount = await Promise.all(
          commentsWithRepliesCountPromises
        )

        setAllComments(commentsWithRepliesCount)
        setTotalCount(commentsResponse?.data?.numberOfParents)
        if (isHomePage) setTotalPages(commentsResponse?.data?.pages)
      } catch (error) {
        console.error(error)
      }
    }

    useEffect(() => {
      fetchAllComments()

      return () => {}
    }, [refetchTrigger, currentPage, defaultId])

    const handleChangeComment = (e) => {
      setComment({ value: e.target.value, commentId: defaultId })
    }

    const handleChangeReplyPage = (commentId, direction) => {
      setAnswersPage((prev) => ({
        ...prev,
        [commentId]: Math.max(1, (prev[commentId] || 1) + direction),
      }))
    }

    const handleAnswerOnComment = async ({ e, item }) => {
      setActiveAnser(item._id)

      const answerOwner = localStorage.getItem('activeAnswerUserId')
      if (answerOwner) {
        setActiveAnswerOwner(answerOwner)
      } else {
        setActiveAnswerOwner(item?.owner?._id)
      }

      if (isHomePage) {
        setCommentType(item?.type)
        setId(item?.post)
      }

      if (item.parent === null) {
        setParentComment(item._id)
      } else {
        setParentComment(item.parent)
      }

      if (answerOnComment) {
        setSelectedItem({})
      } else {
        setSelectedItem(item)
      }
    }

    const handleDeleteComment = async (item) => {
      if (isHomePage) {
        setCommentType(item?.type)
        setId(item?.post)
      }
      try {
        await axios
          .delete(`/deleteComment/${item._id}?postId=${id}&type=${commentType}`)
          .then((res) => {
            if (res.status === 200) {
              setSelectedItem({})
              setSelectedComment({})
              // trigger fetch comments
              setRefetchTrigger((prev) => !prev)
              // notification
              betkoSuccessPopup('Komentar uspešno obrisan')
            }
          })
          .catch((error) => {
            console.error(error)
          })
      } catch (error) {
        console.error(error)
      }
    }

    const handleSaveComment = async (item) => {
      if (editCommentValue.comment === '' || !editCommentValue) {
        betkoErrorPopup('Komentar ne može biti prazan.')
        return
      }
      try {
        const payload = {
          comment: editCommentValue?.comment,
        }

        await axios
          .put(`/updateComment/${item._id}`, payload, {
            withCredentials: false,
          })
          .then((res) => {
            if (res.status === 200) {
              setEditComment(false)
              setEditCommentValue('')
              setRefetchTrigger((prev) => !prev)

              // notification
              betkoSuccessPopup('Uspešno ste izmenili komentar')
            }
          })
          .catch((error) => {
            console.error(error)
            // notification
            betkoErrorPopup('Došlo je do greške prilikom izmene komentara.')
          })
      } catch (error) {
        console.error(error)
        // notification
        betkoErrorPopup('Došlo je do greške prilikom izmene komentara.')
      }
    }

    const fetchReplayComments = async () => {
      try {
        const page = answersPage[answersParentComment.parent] || 1

        await axios
          .get(
            `${SERVER_URL}/allCommentsNew/${page}/${answersParentComment.post}/${answersParentComment.parent}`,
            {
              withCredentials: false,
            }
          )
          .then((res) => {
            if (res.status === 200) {
              setAnswers(res.data.comments)
            }
          })
          .catch((error) => {
            console.error(error)
          })
      } catch (error) {
        console.error(error)
      }
    }

    useEffect(() => {
      !!answersParentComment && fetchReplayComments()
    }, [answersParentComment, refetchTrigger])

    const handleReportComment = async (item) => {
      if (!item?._id) return

      const payload = {
        userId: user._id,
      }

      try {
        await axios
          .put(`${SERVER_URL}/commentReports/${item._id}`, payload, {
            withCredentials: false,
          })
          .then((res) => {
            setRefetchTrigger((prev) => !prev)
            betkoSuccessPopup('Uspešno ste prijavili komentar.')
          })
          .catch((error) => {
            console.error(error)
            betkoErrorPopup('Greška prilikom prijavljivanja komentara.')
          })
      } catch (error) {
        console.error(error)
        betkoErrorPopup('Greška prilikom prijavljivanja komentara.')
      }
    }

    const buildTree = (items, parent = null) => {
      const tree = []

      items &&
        items.length > 0 &&
        items.forEach((item) => {
          if (item.parent === parent) {
            const children = buildTree(items, item._id)
            if (children.length > 0) {
              item.children = children
            }

            if (!tree.some((existingItem) => existingItem._id === item._id)) {
              tree.push(item)
            }
          }
        })

      return tree
    }

    const treeData = buildTree(allComments)

    const handleReactToComment = async ({ type, action }) => {
      if (!user || Object.keys(user).length === 0) {
        setIsLoginModalOpen(true)
        return
      }

      // Map types to endpoints based on user status
      const endpoints = {
        like: user.status === 'Admin' ? '/adminLikes' : '/likeComment',
        dislike: user.status === 'Admin' ? '/adminDisLikes' : '/dislike',
      }

      const endpoint = endpoints[type]
      if (!endpoint) {
        console.log('Invalid reaction type')
        return
      }

      try {
        const res = await axios.put(
          `${endpoint}/${action.payload._id}`,
          {
            userId: user._id,
            commentId: action.payload._id,
          },
          {
            withCredentials: false,
          }
        )

        if (
          (user.status === 'Admin' && res.status === 201) ||
          res.status === 200
        ) {
          setRefetchTrigger((prev) => !prev)
        }
      } catch (error) {
        console.error(error)
      }
    }

    const changeRatingColor = ({ averageRating }) => {
      if (averageRating === 0) {
        return classes.blackRate
      }
      if (averageRating <= 1.49) {
        return classes.crimsonRate
      }
      if (averageRating > 1.49 && averageRating <= 2.49) {
        return classes.redRate
      }
      if (averageRating > 2.49 && averageRating <= 3.49) {
        return classes.orangeRate
      }
      if (averageRating > 3.49 && averageRating <= 4.49) {
        return classes.lightgreenRate
      }
      if (averageRating > 4.49) {
        return classes.greenRate
      }
    }
    const changeRatingBackGroundColor = ({ averageRating }) => {
      if (averageRating === 0) {
        return classes.blackRateBg
      }
      if (averageRating <= 1.49) {
        return classes.crimsonRateBg
      }
      if (averageRating > 1.49 && averageRating <= 2.49) {
        return classes.redRateBg
      }
      if (averageRating > 2.49 && averageRating <= 3.49) {
        return classes.orangeRateBg
      }
      if (averageRating > 3.49 && averageRating <= 4.49) {
        return classes.lightgreenRateBg
      }
      if (averageRating > 4.49) {
        return classes.greenRateBg
      }
    }

    const activeRateColor = (item) => {
      const findUser = item?.ratings?.find((i) => i.user === user._id)

      if (findUser) {
        return changeRatingColor({ averageRating: findUser.rating })
      }

      return ''
    }

    const alreadyRatedComment = (item) => {
      let checkCase = false
      if (item.ratings.some((i) => i.user === user._id)) {
        checkCase = true
      }

      return checkCase
    }

    const handleAddComment = async ({ e, type, commentId }) => {
      e.preventDefault()

      if (user && Object.keys(user).length < 1) {
        setIsLoginModalOpen(true)
        return
      }
      if (
        user.commentRestricted &&
        new Date(user.commentRestricted).setHours(0, 0, 0, 0) > currentDate
      ) {
        const restrictedDate = new Date(user.commentRestricted)
        restrictedDate.setHours(0, 0, 0, 0)
        const diff = restrictedDate - currentDate
        const millisecondsInDay = 1000 * 60 * 60 * 24
        const differenceInDays = Math.floor(diff / millisecondsInDay)
        const days =
          differenceInDays === 36525 ? 'trajno' : `${differenceInDays} dan/a`

        betkoInfoPopup(
          'Pažnja! Zabranjeno Vam je komentarisanje još ' + days + '.'
        )
        return
      }
      if (
        user.status !== 'Admin' &&
        commentType === 'bet_kurs_novo' &&
        type === 'comment'
      ) {
        betkoInfoPopup(
          'Samo Admin može da komentariše, vi možete da postavljate odgovore na komentare !'
        )
        return
      }

      if (adminComment && user.status !== 'Admin' && type === 'comment') {
        betkoInfoPopup(
          'Samo Admin može da komentariše, vi možete da postavljate odgovore na komentare !'
        )
        return
      }

      let payload = {}
      let currentyComment
      if (commentId) currentyComment = comment[commentId]
      else currentyComment = comment.value
      switch (type) {
        case 'comment':
          if (currentyComment === '' || !currentyComment) {
            betkoErrorPopup('Prvo unesite komentar.')
            return
          }

          payload = {
            owner: user._id,
            comment: currentyComment,
            parent: null,
            type: commentType,
            post: id,
            answerTo: null,
          }
          break
        case 'answer':
          if (currentyComment === '' || !currentyComment) {
            betkoErrorPopup('Prvo unesite odgovor.')
            return
          }
          // TODO: MAKE SEPARATE  ANS AND COMM IF NEEDED
          payload = {
            owner: user._id,
            comment: currentyComment,
            parent: parentComment?._id || parentComment,
            type: commentType,
            post: id,
            answerTo: activeAnswerOwner,
          }

          localStorage.removeItem('activeAnswerUserId')
          localStorage.removeItem('activeAnswerUserUsername')
          break
        default:
          console.log('Default state of add comment')
      }
      try {
        await axios
          .post(`/addComment`, payload, { withCredentials: false })
          .then((res) => {
            if (res.status === 200) {
              setComment((prev) => ({
                ...prev,
                value: '',
              }))
              setAnswerOnComment('')
              setSelectedItem({})
              setSelectedComment({})
              setAnswerOnCommentValue('')
              // trigger fetch
              setRefetch((prev) => !prev)
              setRefetchTrigger((prev) => !prev)
              // notification
              if (type === 'answer') {
                betkoSuccessPopup('Odgovor uspešno kreiran')
              } else {
                betkoSuccessPopup('Komentar uspešno kreiran')
              }
            }
          })
          .catch((error) => {
            console.error(error)
          })
      } catch (error) {
        console.error(error)
      }
    }

    const handleShowComments = (item) => {
      if (showComments.includes(item._id)) {
        const filteredComments = showComments.filter((i) => i !== item._id)
        setShowComments(filteredComments)
      } else {
        setShowComments((prev) => {
          return [...prev, item._id]
        })
      }
    }

    const handleRateComment = async ({ number, comment }) => {
      if (user && Object.keys(user).length < 1) {
        setIsLoginModalOpen(true)
        return false
      }

      const payload = {
        number,
        userId: user._id,
      }
      try {
        await axios
          .post(`${SERVER_URL}/rateComment/${comment._id}`, payload, {
            withCredentials: false,
          })
          .then((res) => {
            if (res.status === 200) {
              // trigger fetch comments
              setRefetchTrigger((prev) => !prev)
              return true
            }
          })
          .catch((error) => {
            console.error(error)
          })
      } catch (error) {
        console.error(error)
      }

      return false
    }

    const restricted =
      user && Object.keys(user).length > 0
        ? user.commentRestricted === true
          ? true
          : false
        : true
    const restrictedTextArea = () => {
      let checkCase = false

      if (commentType === 'bet_kurs_novo') {
        if (user && Object.keys(user).length > 0) {
          if (user.status === 'Admin') {
            if (
              user.commentRestricted &&
              new Date(user.commentRestricted).setHours(0, 0, 0, 0) >
                currentDate
            ) {
              checkCase = true
            } else {
              checkCase = false
            }
          } else {
            checkCase = true
          }
        } else {
          checkCase = true
        }
      } else {
        if (user && Object.keys(user).length > 0) {
          if (adminComment) {
            if (user.status === 'Admin') {
              if (
                user.commentRestricted &&
                new Date(user.commentRestricted).setHours(0, 0, 0, 0) >
                  currentDate
              ) {
                checkCase = true
              } else {
                checkCase = false
              }
            } else {
              checkCase = true
            }
          } else {
            if (
              user.commentRestricted &&
              new Date(user.commentRestricted).setHours(0, 0, 0, 0) >
                currentDate
            ) {
              checkCase = true
            } else {
              checkCase = false
            }
          }
        } else {
          checkCase = true
        }
      }

      return checkCase
    }

    const handleShowPopUpLogin = ({ type }) => {
      if (user && Object.keys(user).length < 1) {
        setIsLoginModalOpen(true)
        return
      }
      if (
        user.commentRestricted &&
        new Date(user.commentRestricted).setHours(0, 0, 0, 0) > currentDate
      ) {
        const restrictedDate = new Date(user.commentRestricted)
        restrictedDate.setHours(0, 0, 0, 0)
        const diff = restrictedDate - currentDate
        const millisecondsInDay = 1000 * 60 * 60 * 24
        const differenceInDays = Math.floor(diff / millisecondsInDay)
        const days =
          differenceInDays === 36525 ? 'trajno' : `${differenceInDays} dan/a`

        betkoInfoPopup(
          'Pažnja! Zabranjeno Vam je komentarisanje još ' + days + '.'
        )
        return
      }
      if (
        user.status !== 'Admin' &&
        commentType === 'bet_kurs_novo' &&
        type === 'comment'
      ) {
        betkoInfoPopup(
          'Samo Admin može da komentariše, vi možete da postavljate odgovore na komentare !'
        )
        return
      }

      if (adminComment && user.status !== 'Admin' && type === 'comment') {
        betkoInfoPopup(
          'Samo Admin može da komentariše, vi možete da postavljate odgovore na komentare !'
        )
        return
      }
    }

    const handleBlockUserPress = (userToBlock) => {
      if (!user || Object.keys(user).length === 0) {
        setIsLoginModalOpen(true)
        return
      }

      betkoPopupWithAction(
        'Blokiranje korisnika',
        'Unesite razlog blokiranja korisnika.',
        (closePopup) => (
          <BlockUserPopup
            closePopup={closePopup}
            user={userToBlock}
            setIsLoginModalOpen={setIsLoginModalOpen}
            setRefetchTrigger={setRefetchTrigger}
          />
        )
      )
    }

    const restrictedBool = restrictedTextArea()

    useEffect(() => {
      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTop = 0
      }
    }, [currentPage])

    const paginationProps = {
      setCurrentPage: setCurrentPage,
      limit: sliceValue,
      currentPage: currentPage,
      totalCount: totalCount,
      totalPages: totalPages,
      buttonLimit: 3,
      topRef: commentsContainerRef,
    }

    if (!allComments) {
      return (
        <div className={classes.allCommentsLoaderWrapper}>
          <Spinner />
        </div>
      )
    }

    const handleReportCommentPress = (item) => {
      if (!user || Object.keys(user).length === 0) {
        setIsLoginModalOpen(true)
        return
      }

      betkoPopupWithAction(
        'Prijava komentara',
        'Da li ste sigurni da želite da prijavite komentar?',
        (closePopup) => (
          <div className={classesSC.commentEditSaveButtonWrapper}>
            <button
              onClick={() => {
                handleReportComment(item)
                closePopup()
              }}
            >
              Prijavi
            </button>
            <button onClick={closePopup}>Odustani</button>
          </div>
        )
      )
    }

    const handleGiftPress = (giftCommentValue) => {
      const gift = giftCommentValue ? giftCommentValue : 'TAKMIČI SE I OSVOJI!'
      const showCongrats = giftCommentValue ? true : false

      betkoPopupWithAction(
        'Opis Nagrade',
        gift,
        showCongrats
          ? 'Čestitam na osvojenoj nagradi, vidimo se u sledećem kolu!'
          : ''
      )
    }

    return (
      <div
        ref={commentsContainerRef}
        className={classes.mainCenter}
        id='commentsContainer'
      >
        {!isHomePage && (
          <div ref={ref} className={classes.commentInputContainer}>
            <div className={classes.leaveCommentContainer}>
              <div className={classes.logInContainer}>
                {user && Object.keys(user).length > 0 && (
                  <span>{user.username}</span>
                )}
              </div>
              <div className={classes.newCommentContainer}>
                <div
                  className={classes.textAreaWrapper}
                  onClick={(e) => handleShowPopUpLogin({ type: 'comment' })}
                >
                  <div className={classes.messageIcon}>
                    <img src='/icons/comments/message.svg' alt='Poruka' />
                  </div>
                  <textarea
                    style={{ pointerEvents: restrictedBool && 'none' }}
                    disabled={restrictedBool}
                    placeholder='Unesite komentar'
                    value={comment.value}
                    onChange={(e) => handleChangeComment(e)}
                  />
                </div>

                <button
                  className={classes.sendIcon}
                  onClick={(e) =>
                    handleAddComment({
                      e: e,
                      type: 'comment',
                    })
                  }
                  type='button'
                >
                  <img src='/icons/comments/message.svg' alt='Pošalji poruku' />
                  Postavite komentar
                </button>
              </div>
            </div>
          </div>
        )}

        {treeData && treeData.length > 0 ? (
          treeData
            .filter((comment) => comment && comment.owner)
            .map((item, index) => {
              let commentIndex =
                totalCount - (currentPage - 1) * sliceValue - index

              if (pinnedComment?._id && currentPage === 1) {
                commentIndex += 1
              }

              return (
                <div key={index}>
                  <CommentWithAnswers
                    item={item}
                    commentIndex={commentIndex}
                    isHomePage={isHomePage}
                    editComment={editComment}
                    setEditComment={setEditComment}
                    setEditCommentValue={setEditCommentValue}
                    editCommentValue={editCommentValue}
                    handleSaveComment={handleSaveComment}
                    handleDeleteComment={handleDeleteComment}
                    showComments={showComments}
                    handleShowComments={handleShowComments}
                    handleReactToComment={handleReactToComment}
                    user={user}
                    handleAddComment={handleAddComment}
                    comment={comment}
                    setComment={setComment}
                    handleAnswerOnComment={handleAnswerOnComment}
                    answersParentComment={answersParentComment}
                    setAnswersParentComment={setAnswersParentComment}
                    answersPage={answersPage}
                    setAnswersPage={setAnswersPage}
                    changeRatingColor={changeRatingColor}
                    changeRatingBackGroundColor={changeRatingBackGroundColor}
                    handleRateComment={handleRateComment}
                    alreadyRatedComment={alreadyRatedComment}
                    activeRateColor={activeRateColor}
                    currentPage={
                      commentIdFromUrl && commentIdFromUrl === item._id
                        ? 1
                        : currentPage
                    }
                    setIsLoginModalOpen={setIsLoginModalOpen}
                    handleReportCommentPress={handleReportCommentPress}
                    handleBlockUserPress={handleBlockUserPress}
                    handleGiftPress={handleGiftPress}
                    answers={answers}
                    handleChangeReplyPage={handleChangeReplyPage}
                    classes={classes}
                    commentIdFromUrl={commentIdFromUrl}
                    index={index}
                  />
                </div>
              )
            })
            .slice(0, sliceValue)
        ) : (
          <div className={classes.noCommentsContainer}>
            <FaRegComment className={classes.noCommentsContainerIcon} />
            <span>Još uvek nema komentara</span>
          </div>
        )}
        <Pagination {...paginationProps} />

        <LoginModalNovo
          isOpen={isLoginModalOpen}
          setIsOpen={setIsLoginModalOpen}
        />

        <div
          onClick={() => {
            setIsLoginModalOpen(false)
            setRegisterModal(false)
          }}
          className={`${classes.loginOverlay} ${
            isLoginModalOpen || registerModal ? classes.active : ''
          }`}
        />
      </div>
    )
  }
)

export default Comments
