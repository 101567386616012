import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PravilaIgreData from './PravilaIgreData'
import classes from './PravilaIgreNew.module.css'
import LayoutWithCalcAndSideImgs from '../../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'


const PravilaIgre = () => {
  const [openIndex, setOpenIndex] = useState(null)
  const navigate = useNavigate()

  const toggleText = (index) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <LayoutWithCalcAndSideImgs title={'PRAVILA IGRE'}>

    <div className={classes.pageContainer}>
        
    <button className={classes.backButton} onClick={() => navigate(-1)}> <img src="/icons/arrow-left.svg" alt="back button" /> Nazad</button>
    

    {Object.keys(PravilaIgreData).map((key, index) => (
  <button 
    key={key} 
    onClick={() => toggleText(index)} 
    className={`${classes.titleButton} ${openIndex === index ? classes.active : ''}`}
  >
    <div className={classes.titleWrapper}>
      <span>{PravilaIgreData[key].title}</span>
      <img
        src={openIndex === index ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"}
        alt={openIndex === index ? 'Sakrij' : 'Prikaži'}
        className={classes.toggleIcon}
      />
    </div>

    {openIndex === index && (
      <>
        <hr className={classes.divider} />
        <div className={classes.textWrapper}>
          {PravilaIgreData[key].texts.map((text, i) => (
            <p key={i}>{text}</p>
          ))}
        </div>
      </>
    )}
  </button>
))}



    </div>
    </LayoutWithCalcAndSideImgs>
  )
}

export default PravilaIgre
