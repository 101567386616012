import ForumPageTemplate from './ForumPageTemplate'

const BetKursNovoSinglePage = () => (
  <ForumPageTemplate
    title='PREMIUM'
    apiGetPath='betcoursenew'
    apiPostPath='betcoursenew'
    navigationPath='bet_kurs_novo'
    commentType='bet_kurs_novo'
    chatImagePath='betcoursenew'
  />
)

export default BetKursNovoSinglePage
