import classes from './contact.module.css'
import contactImg from '../images/kontakt.png'

const Contact = () => {
  return (
    <div className={classes.mainCenter}>
      <h1>Kontakt</h1>
      <p>
        Mi težimo ka tome da uvek da u svakom trenutku budemo dostupni za Vas.
        Takođe, želimo da Vam ukažemo maksimalno poštovanje, pri čemu Vam
        stojimo na apsolutnom raspolaganju u svakom pogledu. Mi u interakciji sa
        Vama ne želimo da gradimo pogrešne, već prave vrednosti, koliko i zdrav
        i kvalitetan, kao prijateljski i pristupačan odnos. U skladu sa takvim
        pristupom, direktno smo vam dostupni 0-24h : - Na društvenim mrežama
        ‘’Instagram’’ i ‘’Facebook’’ odgovaramo na Vaše poruke u najkraćem
        mogućem roku. - Na chatu, koji Vam je na samom sajtu, posredstvom naših
        stranica na društvenim mrežama, odgovaramo kada vidimo Vašu poruku. -
        Putem e-mail adrese betko.podrska@gmail.com odgovaramo kada vidimo Vašu
        poruku. U svakom trenutku nas možete direktno i konkretno kontaktirati.
      </p>
      <img src={contactImg} alt='kontakt' />
    </div>
  )
}

export default Contact
