import { useState, useEffect } from 'react'
import axios from '../config/axios'
import classes from './UserPackages.module.css'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const UserPackages = ({
  setPackages,
  selectedPackage,
  setSelectedPackage,
  allPackages,
  setAllPackages,
  handlePackageSelect,
  onPackageSelect,
}) => {
  useEffect(() => {
    const getAllPackages = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/get-all-package`)
        setAllPackages(response?.data?.allPackages || [])
      } catch (err) {
        console.error('Error fetching packages:', err)
      }
    }

    getAllPackages()
  }, [])

  return (
    <div className={classes.packagesContainer}>
      {allPackages.length > 0 ? (
        allPackages.map((pkg) => {
          return (
            <div
              key={pkg._id}
              className={classes.singleProductCard}
              onClick={() => onPackageSelect(pkg)}
            >
              <h3>BETKO paket</h3>

              <p className={classes.price}>{pkg.price} €</p>
              <span>
                {pkg?.packageDuration}{' '}
                {pkg?.packageDuration === 1 ? 'dan' : 'dana'}
              </span>
              <hr className={classes.divider} />
              <p className={classes.discount}>
                Popust - {pkg.discountPercentage}%
              </p>
              <p className={classes.calculatedPrice}>
                Dnevna cena : {pkg.dayPrice?.toFixed(2)} €
              </p>
              <p className={classes.calculatedPrice}>
                Mesečna cena : {pkg.monthPrice?.toFixed(2)} €
              </p>
            </div>
          )
        })
      ) : (
        <p>Nema dostupnih paketa.</p>
      )}
    </div>
  )
}

export default UserPackages
