import { useNavigate, useLocation } from 'react-router-dom'
import classes from './adminAddNewBetCourseForm.module.css'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useState, useRef, useEffect } from 'react'
import { Modal, Input, InputNumber, Select, Button, Checkbox } from 'antd'
import Axios from 'axios'
import { DeleteOutlined } from '@ant-design/icons'
import Pagination from '../components/Pagination'
import { betkoErrorPopup, betkoSuccessPopup } from './../utils/notifications'

const { TextArea } = Input
const { Option } = Select

const AdminAddNewForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [title, setTitle] = useState()
  const [chapters, setChapters] = useState([]) //svi chapteri
  const [chapter, setChapter] = useState() //izabrani chapter
  const [reRender, setReRender] = useState()
  const [imageType, setImageType] = useState('')
  // const [textField, setTextField] = useState("");
  const [images, setImages] = useState([])
  const [filteredImages, setFilteredImages] = useState([])
  const [videos, setVideos] = useState([])
  // const [selectedFeatureImage, setSelectedFeatureImage] = useState();
  // const [selectedMarketingImage, setSelectedMarketingImage] = useState();
  const [items, setItems] = useState([])
  // const [marketingLink, setMarketingLink] = useState("");
  const [itemIndex, setItemIndex] = useState([])
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [isVisibleVideoModal, setIsVisibleVideoModal] = useState(false)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)
  const axiosPrivate = useAxiosPrivate()
  const SERVER_URL = process.env.REACT_APP_SERVER_URL

  const handleRemoveFromAdding = (index) => {
    let array = items
    array.splice(index, 1)

    setItems(array)
    setReRender(!reRender)
  }

  const handleSelectVideo = (video) => {
    let arr = items

    arr[itemIndex].video = video

    setItems(arr)

    setIsVisibleVideoModal(false)
    setReRender(!reRender)
  }

  const handleSubmit = async () => {
    try {
      const data = {
        title,
        chapter,
        // featureImage: selectedFeatureImage?._id,
        // marketingImage: selectedMarketingImage?._id,
        // marketingLink: marketingLink,
        // textField: textField,
        items,
      }

      if (!data.title) {
        betkoErrorPopup('Unesite naslov posta!')
        return
      }
      // if (!data.featureImage) {
      //   notification.error({ message: "Izaberite naslovnu sliku!" });
      //   return;
      // }
      // if (!data.marketingImage) {
      //   notification.error({ message: "Izaberite marketing sliku!" });
      //   return;
      // }
      // if (!data.marketingLink) {
      //   data.marketingLink = "https://betkotip.com";
      // }

      // if (!data.textField) {
      //   notification.error({ message: "Unesite text polje!" });
      //   return;
      // }

      if (data.items) {
        for (let i = 0; i < data.items.length; i++) {
          if (data.items[i].type === 'img') {
            if (data.items[i].image === '') {
              betkoErrorPopup('Stavka ne može postojati bez izabrane slike!')
              return
            }
          }

          if (data.items[i].type === 'text') {
            if (data.items[i].description === '') {
              betkoErrorPopup('Stavka ne može postojati bez opisa!')
              return
            }
            if (data.items[i].color === '') {
              betkoErrorPopup('Stavka ne može postojati bez odabrane boje!')
              return
            }
          }
        }
      }

      setIsSaveButtonDisabled(true)

      await axiosPrivate.post(`${SERVER_URL}/betcourse`, data)

      betkoSuccessPopup('Uspešno objavljen bet kurs post.')

      setTimeout(() => {
        window.location.reload()
      }, 300)
    } catch (error) {
      console.log(error)
      betkoErrorPopup(error)
    }
  }

  const handleShowModal = (e) => {
    setImageType(e)
    setIsVisibleModal(true)
  }

  const handleCancel = () => {
    setIsVisibleModal(false)
    setIsVisibleVideoModal(false)
  }

  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(null)

  const paginationProps = {
    currentPage: pageNumber,
    setCurrentPage: setPageNumber,
    buttonLimit: 3,
    totalPages: totalPages,
  }

  const fetchImages = async () => {
    try {
      const imagesArr = await Axios.get(
        `${SERVER_URL}/images/get-all?page=${pageNumber}&limit=50`,
        {
          withCredentials: false,
        }
      )
      setImages(imagesArr.data.results)
      setFilteredImages(imagesArr.data.results)
      setTotalPages(imagesArr.data.totalPages)
    } catch (error) {
      console.log('err', error)
    }
  }

  const fetchChapters = async () => {
    try {
      const chap = await axiosPrivate.get(`${SERVER_URL}/betcoursechapters`)

      setChapters(chap.data.result)
    } catch (error) {
      console.log('err', error)
    }
  }

  useEffect(() => {
    fetchImages()
    fetchChapters()
    fetchVideos()
  }, [pageNumber])

  const handleSelectImage = (image) => {
    // if (imageType === "featureImage") {
    //   setSelectedFeatureImage(image);
    // } else
    // if (imageType === "marketingImage") {
    //   setSelectedMarketingImage(image);
    // } else
    if (imageType === 'itemImage') {
      let arr = items

      arr[itemIndex].image = image

      setItems(arr)
    }
    setIsVisibleModal(false)
  }

  const handleChangeTextArea = (e, index) => {
    let array = items
    array[index].description = e.target.value

    setItems(array)
  }

  const handleChangeTextColor = (e, index) => {
    let array = items
    array[index].color = e

    setItems(array)
  }

  const handleChangeTextFontSize = (e, index) => {
    let array = items
    array[index].fontSize = e

    setItems(array)
  }

  const handleChangeBold = (e, index) => {
    let array = items
    array[index].bold = !array[index].bold

    setItems(array)
  }

  const handleChangeItalic = (e, index) => {
    let array = items
    array[index].italic = !array[index].italic

    setItems(array)
  }

  const handleChangeMarginTop = (e, index) => {
    let array = items
    array[index].marginTop = e

    setItems(array)
  }

  const handleChangeMarginBottom = (e, index) => {
    let array = items
    array[index].marginBottom = e

    setItems(array)
  }

  const handleChangeImageLink = (e, index) => {
    let array = items
    array[index].imageLink = e.target.value

    setItems(array)
  }

  const handleChangeChapter = (e) => {
    setChapter(e)
  }

  const fetchVideos = async () => {
    try {
      const videosArr = await Axios.get(`${SERVER_URL}/videos/get-all`, {
        withCredentials: false,
      })

      setVideos(videosArr.data)
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleShowVideoModal = () => {
    setIsVisibleVideoModal(true)
  }

  const filterImages = (e) => {
    if (e) {
      let filtered = images.filter((item) => item.imageType === e)

      setFilteredImages(filtered)
    } else {
      setFilteredImages(images)
    }
  }

  return (
    <div className={classes.container}>
      <h1 style={{ marginLeft: '10px' }}>Nova objava za bet kurs (stari)</h1>
      <div className={classes.main}>
        <p>Naslov:</p>
        <input
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        ></input>
      </div>

      <div className={classes.main}>
        <p>Poglavlje:</p>
        <Select
          className={classes.chapterSelect}
          onChange={(e) => {
            handleChangeChapter(e)
          }}
        >
          {chapters &&
            chapters.length > 0 &&
            chapters.map((item, index) => {
              return <Option value={item._id}>{item.title}</Option>
            })}
        </Select>
      </div>

      <div>
        <button
          className={classes.addImageBtn}
          onClick={() => {
            setItems([
              ...items,
              {
                type: 'text',
                description: '',
                color: '',
                bold: false,
                italic: false,
                marginTop: 0,
                marginBottom: 0,
              },
            ])
          }}
        >
          Dodaj tekst
        </button>
        <button
          className={classes.addImageBtn}
          onClick={() => {
            let array = items
            let obj = { type: 'img', image: '' }
            array.push(obj)

            setItems([...array])
          }}
        >
          Dodaj sliku
        </button>
        <button
          className={classes.addImageBtn}
          onClick={() => {
            let array = items
            let obj = { type: 'video', video: '' }
            array.push(obj)

            setItems([...array])
          }}
        >
          Dodaj video
        </button>
      </div>

      {items && items.length > 0 && (
        <div className={classes.labelRow}>Stavke </div>
      )}

      {items &&
        items.length > 0 &&
        items.map((item, index) => {
          if (item.type === 'text') {
            return (
              <div className={classes.textitem}>
                <div className={classes.textItemLeft}>
                  <div className={classes.item}>
                    <p>Tekst:</p>
                    <TextArea
                      className={classes.textAreaInput}
                      onChange={(e) => {
                        handleChangeTextArea(e, index)
                      }}
                    ></TextArea>
                  </div>
                  <div className={classes.item}>
                    <p>Bold:</p>
                    <Checkbox
                      onChange={(e) => {
                        handleChangeBold(e, index)
                      }}
                      type='checkbox'
                    ></Checkbox>
                  </div>
                  <div className={classes.item}>
                    <p>Italic:</p>
                    <Checkbox
                      onChange={(e) => {
                        handleChangeItalic(e, index)
                      }}
                      type='checkbox'
                    ></Checkbox>
                  </div>
                  <div className={classes.item}>
                    <p>Boja teksta:</p>
                    <Select
                      onChange={(e) => {
                        handleChangeTextColor(e, index)
                      }}
                      className={classes.colorSelect}
                    >
                      <Option value='#D6FF00'>Florascent žuta</Option>
                      <Option value='#FFFFFF'>Bela</Option>
                      <Option value='#DBDBDD'>Tamnija bela</Option>
                      <Option value='#454A59'>Svetlo siva</Option>
                      <Option value='#353945'>Srednja siva</Option>
                      <Option value='#2B3240'>Tamno siva</Option>
                      <Option value='#000000'>Crna</Option>
                      <Option value='#2E3551'>Verzija plave</Option>
                      <Option value='#CABB90'>Bež</Option>
                    </Select>
                  </div>
                  <div className={classes.item}>
                    <p>Veličina teksta:</p>
                    <Select
                      value={item.fontSize}
                      onChange={(e) => {
                        handleChangeTextFontSize(e, index)
                      }}
                      className={classes.colorSelect}
                    >
                      <Option value='10'>10px</Option>
                      <Option value='16'>16px</Option>
                      <Option value='20'>20px</Option>
                      <Option value='30'>30px</Option>
                      <Option value='40'>40px</Option>
                      <Option value='50'>50px</Option>
                    </Select>
                  </div>
                  <div className={classes.item}>
                    <p>Margine (top-bottom):</p>
                    <InputNumber
                      onChange={(e) => {
                        handleChangeMarginTop(e, index)
                      }}
                      type='number'
                      max={10}
                      className={classes.inputNumberMargin}
                      min={0}
                    ></InputNumber>
                    <InputNumber
                      onChange={(e) => {
                        handleChangeMarginBottom(e, index)
                      }}
                      type='number'
                      max={10}
                      className={classes.inputNumberMargin}
                      min={0}
                    ></InputNumber>
                  </div>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => handleRemoveFromAdding(index)}
                ></Button>
              </div>
            )
          } else if (item.type === 'img') {
            return (
              <div className={classes.imageitem}>
                <div className={classes.imageItemLeft}>
                  <img
                    style={{ width: '50px', borderRadius: '10px' }}
                    src={`${SERVER_URL}/${item.image.url}`}
                  />
                  <Input
                    onChange={(e) => {
                      handleChangeImageLink(e, index)
                    }}
                    className={classes.inputNumberMargin}
                    style={{ marginRight: '10px' }}
                  ></Input>
                  <button
                    className={classes.addImageBtn}
                    onClick={() => {
                      handleShowModal('itemImage')
                      setItemIndex(index)
                    }}
                  >
                    {items[index] && items[index].image.url
                      ? items[index].image.url
                      : 'Izaberite sliku'}
                  </button>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => handleRemoveFromAdding(index)}
                ></Button>
              </div>
            )
          } else {
            return (
              <div className={classes.imageitem}>
                <div className={classes.imageItemLeft}>
                  {item && item.video && item.video.url && (
                    <video
                      key={`${SERVER_URL}/${item.video.url}`}
                      width='320'
                      height='240'
                      controls
                    >
                      <source
                        src={`${SERVER_URL}/${item.video.url}`}
                        type='video/mp4'
                      />
                    </video>
                  )}
                  <button
                    className={classes.addImageBtn}
                    onClick={() => {
                      handleShowVideoModal()
                      setItemIndex(index)
                    }}
                  >
                    {items[index] && items[index].video.url
                      ? items[index].video.url
                      : 'Izaberite video'}
                  </button>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => handleRemoveFromAdding(index)}
                ></Button>
              </div>
            )
          }
        })}
      <Modal
        width={'80%'}
        footer={[]}
        title='Kliknite sliku da biste je odabrali'
        visible={isVisibleModal}
        onCancel={handleCancel}
      >
        <div>
          <Select
            style={{ minWidth: '200px' }}
            onChange={(e) => {
              filterImages(e)
            }}
            defaultValue={''}
          >
            <Option value=''>Sve</Option>
            <Option value='home'>Početna strana</Option>
            <Option value='gallery'>Gallery</Option>
          </Select>
        </div>
        <div className={classes.imagesList}>
          {filteredImages &&
            filteredImages.length > 0 &&
            filteredImages.map((item, index) => {
              return (
                <div className={classes.imageItem}>
                  <img
                    onClick={() => {
                      handleSelectImage(item)
                    }}
                    className='img'
                    src={`${SERVER_URL}/${item.url}`}
                    alt={item.url}
                  />
                </div>
              )
            })}
          <Pagination {...paginationProps} />
        </div>
      </Modal>
      <Modal
        width={'80%'}
        footer={[]}
        title='VIDEO GALERIJA'
        visible={isVisibleVideoModal}
        onCancel={handleCancel}
      >
        <div className={classes.videosList}>
          {videos &&
            videos.length > 0 &&
            videos.map((item, index) => {
              return (
                <div className={classes.videoItem}>
                  <video width='320' height='240' controls>
                    <source
                      src={`${SERVER_URL}/${item.url}`}
                      type='video/mp4'
                    />
                  </video>
                  <Button
                    onClick={() => {
                      handleSelectVideo(item)
                    }}
                    className={classes.selectVideoButton}
                  >
                    Izaberite video
                  </Button>
                </div>
              )
            })}
        </div>
      </Modal>
      <div className={classes.statistikaFooter}>
        <button
          disabled={isSaveButtonDisabled}
          className={classes.submitButton}
          onClick={() => {
            handleSubmit()
          }}
        >
          Sačuvaj
        </button>
      </div>
    </div>
  )
}

export default AdminAddNewForm
