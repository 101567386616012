import classes from './opstiUslovi.module.css'

const OpstiUslovi = () => {
  return (
    <div className={classes.container}>
      <h1>POŠTOVANI KORISNIČE. DOBRO NAM DOŠAO.</h1>
      <p>
        Ispred svega želimo da ti se obratimo s' poštovanjem, i da ti priđemo
        prijateljski, a ne poslovično, što je samo po sebi drugačije u odnosu na
        hladnu formu obraćanja ka tebi. Sa tobom želimo da gradimo zdrav odnos i
        zdravu saradnju na obostrano zadovoljstvo. Sama definicija ''OPŠTI
        USLOVI'', nama deluje grubo, pri čemu se smatra da se isti
        podrazumevaju, i da su sa tvoje strane prihvaćeni pri korišćenju usluga
        našeg sajta. Dakle, već samim korišćenjem sajta ti potvrđuješ da
        prihvataš ono što spada u pravila vezana za isti i za korišćenje istog.
        U slučaju da je drugačije, želimo da te obavestimo da sajt podleže
        zaštiti lične intelektualne svojine, i da je svakako zaštićen na
        potreban način.
      </p>
      <p>Pod nazivom ''BETKO'', podrazumeva se sajt na kom se nalazite.</p>
      <h3>OPŠTA PRAVILA</h3>
      <ul>
        <li>
          BETKO ničim niti pruža, niti će pružiti podršku maloletnim llicima u
          korišćenju usluga našeg sajta.
        </li>
        <li>
          Pri registraciji na naš sajt potrebno je da potvrdite da ste navršili
          osamnaest (18) godina.
        </li>
        <li>
          Ukoliko ste maloletno lice, a potvrdili ste da ste navršili osamnaest
          godina, budite svesni sopstvene, kao i odgovornosti vaših roditelja
          ili staratelja za to.
        </li>
        <li>
          BETKO nema odgovornost za vaše postupke, BETKO nije organizator
          kockarske igre, i BETKO nema pravo da vas legitimiše, niti želi da to
          radi.
        </li>
      </ul>
      <ul>
        <li>
          BETKO ničim niti podržava, niti će podržati kocku kao oblik igre.
        </li>
        <li>
          Unutar BET KURS-a, i kroz celokupan koncept sajta, BETKO upozorava
          korisnike istog od opasnosti koje donosi kocka, i usmerava svoje
          korisnike na izbegavanje te opasnosti, ukazujuću u svom edukativnom
          delu, da je kocka onoliko koliko nije znanje, kao i onoliko koliko
          nije matematika.
        </li>
        <li>
          BETKO vas usmerava na upravo sve ono što je suprotno kocki, te vam
          kroz mnoge prikazane upise šalje poruku o opasnostima koje kocka kao
          oblik igre sa sobom nosi.
        </li>
        <li>
          BETKO se ničim niti bavi, niti će se baviti, bilo kakvim insajderskim
          informacijama, dojavama o nameštanju utakmica ili slično, niti u
          besplatnom delu korišćenja sajta, niti u delu koji se nepodrazumeva
          besplatnim za korišćenje.
        </li>
      </ul>
      <h3>BESPLATAN SADRŽAJ</h3>
      <ul>
        <li>
          BETKO se u okviru sadržaja kom je pristup besplatan, bavi temama
          sporta, statistike i predloga za igru, sportskih kvizova, kao i
          činjeničnim podacima vezanim za kvote, tj. koeficijente iz ponuda
          organizatora igre, postavljenih u aktuelnom trenutku ponude istih
          (kvote su promenjiva kategorija, i nije moguće je definisati ih kao
          preciznu postavku, jer se menjaju bukvalno iz minuta u minut, skladno
          kretanju uplata za igru, koje sami organizatori igre primaju)
        </li>
        <li>
          BETKO se u slučaju svih objava unutar besplatnog sadržaja ograđuje od
          odgovornosti u situacijama ukoliko je neki uopšteni podatak ili
          statistički podatak netačan u potpunosti, jer i mi sami pretražujemo
          informacije putem globalne mreže, i prema saznanjima do kojih dolazimo
          kreiramo sadržaj objava.
        </li>
        <li>
          BETKO tekstovi su originalni i autentični, pisani ručno, a ne
          prenešeni bilo kakvim copy-paste metodama.
        </li>
        <li>
          Sve fotografije na našem sajtu su originalne izrade, ili su u
          suprotnom preuzete isključivo sa sajtova koji dozvoljavaju besplatnu
          upotrebu fotografija za komercijalne namene, ili su plaćene u
          preuzimanju da bi se legitimno mogle objaviti.
        </li>
      </ul>
      <h3>BET KURS + PREMIUM</h3>
      <ul>
        <li>
          BETKO se bavi edukacijom igrača, na sticanju njihovog znanja o igri i
          o svim njenim pozitivnim i negativnim stranama, usmeravajući vas na
          igru kao na vid apsolutno kontrolisane zabave, planiranog troška na
          istu, koji ničim ne treba da izlazi iz okvira koji neugrožavaju vas i
          vašu okolinu.
        </li>
        <li>
          BETKO u okviru PREMIUM menija ne daje direktne instrukcije za igru,
          već se bavi temama statističkih zapažanja i eventualnih predloga
          tipova. Korisnici BETKO usluga igraju na svoju ličnu odgovornost.
        </li>
        <li>
          BETKO nema ništa protiv organizatora igre na globalnom, lokalnom ili
          pojedinačnom nivou.
        </li>
        <li>
          BETKO ukazujući na sve mane i vrline vezane za celokupno tržište koje
          se odnosi na istu, kroz svoja zapažanja zadržava pravo na svoje
          mišljenje, pri čemu ničim neosporava, nevređa ili slično, bilo koju
          drugu stranu, niti ima tako nešto za nameru.
        </li>
        <li>
          BETKO vodi računa o korektnosti svog pristupa ka svim stranama koje
          čine igru i tržište iste.
        </li>
        <li>BETKO poštuje sve strane u igri.</li>
        <li>
          BETKO tekstovi su originalni i autentični, pisani ručno, a ne
          prenešeni bilo kakvim copy-paste metodama.
        </li>
        <li>
          Sve fotografije na našem sajtu su originalne izrade, ili su u
          suprotnom preuzete isključivo sa sajtova koji dozvoljavaju besplatnu
          upotrebu fotografija za komercijalne namene, ili su plaćene u
          preuzimanju da bi se legitimno mogle objaviti.
        </li>
      </ul>
      <h3>VAŠI PREDLOZI</h3>
      <ul>
        <li>
          BETKO ne daje garancije za prolaznost svojih tipova, BETKO predlaže
          tipove. Ti biraš da li ćeš naše predloge sprovesti u igri ili ne, kao
          i koje hoćeš, a koje ne, i prihvataš potpunu ličnu odgovornost za to.
        </li>
        <li>
          BETKO prikazuje kvote, tj. koeficijente u skladu sa trenutnom ponudom
          organizatora igre, i nepodleže odgovornosti za preciznost istih, jer
          su kvote promenjiva kategorija, koja se prema uplatama koje primaju
          organizatori igre menjaju stalno, bukvalno iz minuta u minut. S' toga
          nije moguće garantovati tačnost i maksimalnu preciznost prikaza
          podataka istih.
        </li>
      </ul>
      <h3>BET KALKULATORI</h3>
      <ul>
        <li>
          BET KALKULATORI su sastavni deo BETKO ponude, a izvedeni su iz
          autentičnih načina obrade podataka koju vrše naši, i sa naše strane
          izrađeni matematički algoritmi.
        </li>
        <li>
          BET KALKULATORI i BETKO algoritmi vam ostavljaju prostor za unos vaših
          podataka, i na osnovu tog unosa kroz matematičke operacije koje se
          obavljaju u pozadini sajta, iznose svoje predloge za tipove i za igru,
          prikazane u procentualnim šansama ili u numeričkim prosecima rezultata
          vaših unosa.
        </li>
        <li>
          BETKO algoritmi vrše obračun koji vam predlaže tipove ili proseke
          šansi za iste, istovremeno nepodrazumevajući to garancijom
          prolaznosti, već isključivo predlogom za igru, dok to da li želite da
          je sprovedete u delo ili ne, ostaje na vašem izboru i odluci pri
          istom, kao i na vašoj ličnoj odgovornosti.
        </li>
      </ul>
      <h3>BETKO KA KORISNICIMA</h3>
      <ul>
        <li>BETKO posluje legitimno.</li>
        <li>
          Kompletan sadržaj sajta, osim ako to nije naznačeno drugačije, jeste
          privatna svojina, dok su delovi sajta u zakupu, kao što je to slučaj
          sa dodacima vezanim za komentare (DISQUS) i ostale plugin-e ili api-je
          u legitimnom zakupu sajta na kom se nalazite. Ti delovi sajta podležu
          pravilima izdavača usluga koje naš sajt legitimno koristi.
        </li>
        <li>
          BETKO svaku uplatu pokriva otključavanjem saržaja za period za koji je
          predviđeno otključavanje sadržaja prema cenovniku uplata i sadržajem
          koji je predviđen u okviru iste.
        </li>
        <li>
          BETKO u okviru svog poslovanja izdaje na korišćenje registrovanim
          firmama, kompanijama ili agencijama koje se bave konsultantskim
          uslugama, prostor za koji je predviđen prrtplatnički sadržaj, kao i
          marketinški prostor na sajtu za potrebe njihovih klijenata. BETKO
          zadržava pravo da prostor na sajtu koji iznajmljuje, iznajmi kome to
          želi i pod kojim uslovima to želi.{' '}
        </li>
        <li>
          BETKO zadržava pravo da dodeli besplatnu uslugu korišćenja sajta ka
          korisnicima za koje smatra da iz bilo kog razloga imaju pravo na taj
          benefit i na tu povoljnost, uključujući periode za koje BETKO sam
          odredi da su adekvatni.Takođe, BETKO pri organizaciji kvizova znanja,
          zadržava apsolutno pravo, da korisnicima koji osvoje besplatne pakete,
          otključa iste na upotrebu. Betko kvizovi i kviz pitanja su
          takmičarskog tipa u nadmetanju u znanju. Betko kvizovi nisu nagradne
          igre i nisu igre na sreću !
        </li>
        {/* <li>
          BETKO zadržava pravo na organizaciju akcija pri kojima korisnike za
          lojalnost može nagrađuje sa dodatnim danima mogućnosti besplatnog
          korišćenja određenih ili svih usluga sajta.
        </li>
        <li>
          BETKO direktno neorganizuje nikakve akcije u kojima korisnicima deli
          novac ili bilo kakva materijalna dobra.
        </li>
        <li>
          BETKO direktno neorganizuje nikakve takmičarske igre u kojima
          korisnicima deli novac ili bilo kakva materijalna dobra.
        </li> */}
        <li>
          BETKO zadržava pravo na organizaciju akcija pri kojima korisnike za
          lojalnost može nagrađuje sa dodatnim danima mogućnosti besplatnog
          korišćenja određenih ili svih usluga sajta.
        </li>
        <li>
          BETKO direktno neorganizuje nikakve nagradne igre i igre na sreću u
          kojima korisnicima deli novac ili bilo kakva materijalna dobra. BETKO
          kviz pitanja nisu nagradne igre, i isključivo su takmičarskog
          karaktera, pri čemu BETKO zadržava prava da korisnicima otključa
          besplatno sadržaj sajta za pokazane rezultate u okviru takmičarskih
          kvizova ili kviz pitanja
        </li>
      </ul>
      <h3>BETKO KA ORGANIZATORIMA IGRE</h3>
      <ul>
        <li>
          BETKO poziva sve organizatore igre na saradnju, bilo kroz sam sajt ili
          kroz šire opcije iste.
        </li>
        <li>
          BETKO želi da sarađuje sa svima i želi korektnu saradnju sa svim
          stranama.
        </li>
        <li>
          BETKO ima projekte i može da vam ponudi mnogo toga što može bitno da
          unapredi vaše poslovanje.
        </li>
      </ul>
      <h3>BETKO MARKETING</h3>
      <ul>
        <li>
          BETKO u ponudi sajta ima marketinški prostor namenjen svim
          zainteresovanim stranama za saradnju. BETKO je spreman da pregovara sa
          vama o vašem korišćenju istog.
        </li>
        <li>
          Cenovnik marketinških usluga je zavistan od mesta prostora koji se
          zakupljuje na sajtu, kao i od cena istih koje određuju registrovane
          firme, kompanije ili agencije, sa kojima BETKO sarađuje.
        </li>
      </ul>

      <h3>KOMENTARI</h3>
      <ul>
        <li>
          Svaki deo sajta u kom se nalaze KOMENTARI, jeste u našem zakupu plugin
          ili api usluge izdavača iste, a to je disqus.com
        </li>

        <li>
          Pravila koja važe za komentarisanje korisnika apsolutno i celokupno
          podležu pravilima koje ste kao korisnik prihvatili registrujući se na
          disqus.com. Data pravila možete pročitati na sledećoj web adresi :
          https://disqus.com/privacy-policy/
        </li>

        <li>
          BETKO ničim neprihvata i nema odgovornost za bilo čije, bilo kakvo, i
          bilo koje kršenje pravila koja ste kao korisnik prihvatili pri
          regitraciji na disqus.com. Registracijom na disqus.com prihvatate
          ličnu odgovornost za ponašanje pri komentarisanju, za autorska prava
          bilo kog tipa sadržaja koji postavljate u komentarima, i za sve
          navedeno u pravilima na disqus.com. BETKO nema i neprihvata vašu
          odgovornost za vaše eventualno kršenje pravila koja su navedena na
          disqus.com stranici.
        </li>

        <li>
          BETKO vas usmerava da pročitate pravila disqus.com , ali vam
          istovremeno naznačava da je prvenstveno potrebno da prilikom
          komentarisanja, vodite računa o pristojnosti pri istom, kao i o tome
          da nedelite razne video sadržaje, foto sadržaje, linkove i slično, na
          koje neposedujete lična autorska prava. Takođe, BETKO vas usmerava, da
          nedelite sadržaje koji nisu prilagođeni deci, što se ispred svega
          odnosi na nasilne sadržaje, sadržaje koji sadrže seksualne teme,
          psovke, alkohol, pušenje, narkomaniju i slično.
        </li>
        <li>
          BETKO kao zakupac disqus.com komentara, kao i disqus.com ima pravo da
          odobri, izbriše, prenese komentar u ‘’Spam’’ bilo koji komentar, bilo
          kog korisnika. Takođe, BETKO ima apsolutno pravo, kao i disqus.com da
          banuje, tj. Da isključi korisnika iz komunikacije iz bilo kog razloga.
        </li>
        <li>
          Bilo kakvo eventualno isključenje ili bilo kakva akcija vezana za
          disqus.com nije povezana da pretplatničkim paketima, niti sa bilo
          kojim drugim delom sajta, niti sa bilo kojom stranicom, koja u sebi
          nema sadržaj komentarisanja, čiji se proces odvija preko disqus.com
        </li>

        <li>
          Kada je u pitanju sportsko klađenje, držite se predloga, zdrave
          diskusije, komentarisanja na temu sporta i sportske prognoze, a
          izbegavajte sadržaj koji na bilo koji način promoviše kocku kao oblik
          igre. Naš sajt nepromoviše ni jednu od datih, nepoželjnih tema na
          njemu. Naš sajt promoviše sport i sportske teme, matematiku kao
          osnovni oblik sportskog klađenja, i sportsku prognozu kao nadmetanje u
          pogađanju sportskih rezultata, predloga, sportskih analiza, zapažanja,
          i mišljenja, korisnika sadržaja koji se nalazi na našem sajtu.
        </li>
        <li>
          Za bilo kakvo kršenje bilo čijih autorskih prava na bilo koju vrstu
          sadržaja, kao i za bilo kakvu vrstu vređanja snosite ličnu i
          personalnu odgovornost, pri komentarisanju. Naš sajt, ničim nepodleže
          odgovornosti za vaše lične objave, što isto važi i za sve kompanije
          koje sarađuju sa nama. Za vaše lične objave ste odgovorni samo vi
          lično i niko drugi. Sadržaj koji nije primeren za decu, a koji biste
          eventualno postavili, takođe podleže isključivo vašoj odgovornosti.
          Nije teško biti pristojan, normalan i komentarisati u skladu sa onim
          što su elementarne civilizacijske norme, i pridržavati se nekih
          kodeksa koji spadaju u iste.
        </li>
      </ul>

      <h3>INTELEKTUALNA SVOJINA I AUTORSKA PRAVA</h3>

      <ul>
        <li>
          Svaki pokušaj nelegitimnog kopiranja same ideje našeg sajta kao
          zakonski zaštićene intelektualne svojine, kao i svaki pokušaj kršenja
          prava na bilo koji segment sajta koji podleže našim pravima, sa naše
          strane će u najkraćem mogućem roku, najpre biti ispraćen kratkom
          opomenom, a ukoliko na istu u najkraćem mogućem roku odgovor ne bude
          adekvatan, preduzećemo dalje potrebne korake protiv ovakvog delovanja.
        </li>
      </ul>

      <h3>TEHNIČKA PITANJA VEZANA ZA RAD SAJTA</h3>

      <ul>
        <li>
          BETKO apsolutno vodi računa o svim tehničkim pitanjima i o tome da
          sajt funkcioniše na najbolji mogući način, jer je zadovoljstvo
          korisnika u interesu svih nas zajedno, koji kao pružaoci usluga ili
          kao korisnici istih učestvujemo u radu samog sajta prioritet.
        </li>
        <li>
          BETKO se iz predostrožnosti bez obzira na činjenicu da su tehnička
          pitanja vezana za sam sajt kao i za celokupan rad istog prioritet,
          ograđuje od tehničkih problema i od bilo kojih i bilo kakvih
          situacija, koje mogu na bilo koji ili na bilo kakav način da nastanu u
          njegovom radu. Svaki korisnik sajta ovim pravilom prihvata apsolutnu i
          potpunu ličnu odgovornost pri korišćenju sajta uključujući u istu i
          besplatan i pretplatnički sadržaj, kao i kompletan sadržaj koji je
          vezan za marketinški prostor (važi za zakupce istog).
        </li>
        <li>
          BETKO u slučaju bilo kakvih žalbi, pritužbi, tužbi ili bilo čega
          sličnog, vezano za bilo kakve ili bilo koje tehničke probleme nema i
          neprihvata nikakvu odgovornost za iste. Korisnici pri registraciji i
          pri zakupu paketa, prihvataju apsolutnu i potpunu ličnu odgovornost za
          iste, kao i zakupci marketinškog sadržaja na sajtu.
        </li>

        <li>
          Ponavljamo. BETKO apsolutno vodi računa o svim tehničkim pitanjima, i
          navedena pravila su vezana za predostrožnost.
        </li>
        {/* 
        <li>
          Daćemo Vam jedan primer. Zamislite recimo da dođe do neke opšte
          havarije na mestu gde se nalaze naše baze podataka,pada servera recimo
          ili slično. U situaciji navedenog primera sajt može da bude recimo u
          privremenom zastoju. Mi unapred želimo da se ogradimo od takvih,
          sličnih i svakakvih doslovce i bukvalno mogućih situacija, jer ne
          želimo da predviđamo okolnosti,i budućnost koje bilo kakve okolnosti
          mogu da donesu, bez obzira što su procentualne šanse za to mizerno
          male, da se tako nešto i u realnosti dogodi.
        </li> */}
      </ul>

      <h3>PODACI KOJE OSTAVLJATE NA NAŠEM SAJTU</h3>
      <ul>
        <li>
          BETKO nekoristi podatke IP adresa niti bilo koje druge podatke za bilo
          kakvo prikupljanje istih i analizu sopstvenog rada, niti se bavi bilo
          kakvim prikupljanjem, a još manje bilo kakvom zloupotrebom vaših
          podataka.
        </li>
        <li>
          BETKO od vas traži registracione podatke koji podrazumevaju vašu
          jedinstvenu e-mail adresu i vaš pasword. Na osnovu vaše e-mail adrese
          mi izvršavamo otključavanje paketa za koje ste izvršili uplatu ili vam
          po bilo kom osnovu otključavamo pretplatničke pakete bez naknade.
        </li>
        <li>
          Kada je u pitanju vaša IP adresa, zbog poslovne politike pokrivanja
          interesa marketinških potreba zakupaca marketinškog prostora, sajt
          registruje podatak iz koje zemlje korisnik dolazi, kako bi se
          marketinška poruka koju šalje zakupac marketinškog prostora u istoj,
          prikazala na geo lokaciji sa koje korisnik sajta dolazi. I to je
          jedina i isključiva svrha prikaza vaše IP adrese, koja nam može biti
          dostupna, ali istovremeno BETKO ničim niti koristi, niti će koristiti
          te podatke ni za kakvo njihovo prikupljanje ili bilo kakvu
          zloupotrebu.
        </li>
        <li>
          BETKO za korišćenje definisanja geo-lokacije koristi usluge plugin-a,
          tj. Api-ja koji legitimno plaća. Te je i po tom pitanju sve legitimno
          sa naše strane.
        </li>
      </ul>
      <h3>
        KORIŠĆENJEM NAŠEG SAJTA PRIHVATATE I POTVRĐUJETE SAGLASNOST SA SVIM
        NAVEDENIM UNUTAR DEFINISANIH PRAVILA SAJTA.
      </h3>
    </div>
  )
}

export default OpstiUslovi
