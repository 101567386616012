import React, { useEffect } from 'react'
import classes from './SuccessfulPayment.module.css'
import { FaCheck } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { betkoErrorPopup, betkoSuccessPopup } from '../utils/notifications'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const SuccessfulPayment = () => {
  const params = Object.fromEntries(new URLSearchParams(window.location.search))
  const navigate = useNavigate()

  useEffect(() => {
    const sendSuccessPaymentInfo = async () => {
      try {
        const response = await axios.post(`${SERVER_URL}/create-transaction`, {
          ...params,
        })
        betkoSuccessPopup("Uspešno sačuvana transakcija !")
      } catch (error) {
        betkoErrorPopup("Pokušajte ponovo ili se obratite našoj korisničkoj podršci!")
      }
    }

    if (params.Success === '1') {
      sendSuccessPaymentInfo()
    } else {
      navigate('/korisnicki_paketi/kupi-paket')
    }
  }, [])

  return (
    <div className={classes.successPageContianer}>
      <div className={classes.iconWrapper}>
        <img src="/icons/successful-payment.svg" alt="Successful payment" />
      </div>
      <h2>
      Nalog za plaćanje je uspešno poslat!
      </h2>
      <p>
      Vaš nalog za plaćanje je uspešno poslat u banku. Nakon što plaćanje bude izvršeno, dobićete potvrdu putem push notifikacije, ukoliko su aktivirane na vašem uređaju. 
      </p>
      
      <p>Vaš pretplatnički paket će biti aktiviran u roku od 24h.</p>
      <button onClick={() => navigate('/')}>OK.</button>
    </div>
  )
}

export default SuccessfulPayment
