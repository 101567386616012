import React, { useContext, useEffect, useRef, useState } from 'react'
import classes from './betkoShopPageNew.module.css'
import BetkoShopLayout from './BetkoShopLayout'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'

import ProductCardNew from '../../components/BetkoShop/ProductCardNew'
import { CloseOutlined } from '@ant-design/icons'
import CategoriesSidebar from '../../components/BetkoShop/CategoriesSidebar'
import axios from 'axios'
import { Badge, message, Modal } from 'antd'

import ProductPreviewNew from '../../components/BetkoShop/ProductPreviewNew'
import CartContext from '../../context/CartContext'
import Spinner from '../../components/Spinner'
import CardPlaceHolder from '../../components/BetkoShop/CardPlaceHolder'
import AuthContext from '../../context/AuthProvider'
import LoginModalNovo from '../../components/LoginModalNovo'
import { set } from 'date-fns'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const BetkoShopPageNew = ({ onChangeSearchTerm }) => {
  const navigate = useNavigate()
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [allCategories, setAllCategories] = useState([])
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [sortByPrice, setSortByPrice] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [allProducts, setAllProducts] = useState(null)
  const [refetchTrigger, setRefetchTrigger] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedTab, setSelectedTab] = useState('')
  const [hideTimer, setHideTimer] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [selecteImagePreview, setSelecteImagePreview] = useState(null)
  const { cartWithData } = useContext(CartContext)
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const searchTermRef = useRef(null)
  const from = location?.state?.from
  const placeholdersNeeded = Math.max(
    0,
    3 - (allProducts?.paginatedData?.length || 0)
  )
  const { auth } = useContext(AuthContext)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const productsContainerRef = useRef(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const selectedProductId = searchParams.get('productId')
  const shopType = searchParams.get('shopType')
  const searchTerm = searchParams.get('searchTerm')
  const sort = searchParams.get('sort')

  useEffect(() => {
    setSelectedCategory(null)
    setSelectedProduct(null)
    const newParams = new URLSearchParams(searchParams)
    setSearchParams(newParams)
    setCurrentPage(1)
  }, [shopType])

  useEffect(() => {
    const getAllCategories = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/categories-get-all/${null}/${null}/`
        )
        setAllCategories(response?.data)
      } catch (error) {
        setAllCategories([])
        message.error(
          error?.response?.data?.message || 'Greška pri preuzimanju kategorija!'
        )
      }
    }

    getAllCategories()
  }, [])

  useEffect(() => {
    const getAllProducts = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${SERVER_URL}/get-all-product/${currentPage}/10/${
            searchTerm || null
          }/${selectedCategory || null}/${shopType || null}/${sort || null}`
        )
        setAllProducts(response?.data)
      } catch (error) {
        setAllProducts([])
      }
      setIsLoading(false)
    }
    getAllProducts()
  }, [
    searchTerm,
    refetchTrigger,
    selectedCategory,
    currentPage,
    shopType,
    sort,
  ])
  useEffect(() => {
    const getSelectedProduct = async () => {
      try {
        const product = await axios.get(
          `${SERVER_URL}/get-product-by-id/${selectedProductId}`
        )
        setSelectedProduct(product?.data)
      } catch {
        setSelectedProduct(null)
        selectedProductId && message.error('Greška pri preuzimanju proizvoda! ')
      }
    }

    getSelectedProduct()
  }, [selectedProductId])
  const onSortChange = () => {
    const sortOption = sortByPrice !== 'asc' ? 'asc' : 'desc'
    setSortByPrice(sortOption || null)
    const params = new URLSearchParams(searchParams)
    params.set('sort', sortOption || null)
    setSearchParams(params)
  }
  const toggleModal = () => {
    setIsOpen(!isOpen)
  }
  const handleNavigation = (shopType) => {
    setSelectedTab(shopType)
    setHideTimer(shopType === 'sport-tourist')
    navigate(`/betko_shop/shop?shopType=${shopType}`)
  }
  if (isMobile && selectedProduct) {
    return (
      <BetkoShopLayout>
        <div className={classes.headerWrapper}>
          <div className={classes.desktopHolder}>
            <div className={classes.headerNavigation}>
              <div
                className={`${classes.navItem} ${
                  selectedTab === 'shop' ? classes.active : ''
                }`}
                onClick={() => handleNavigation('shop')}
              >
                <img src='/icons/shop/shoping-cart.svg' alt='Korpa' />
                <p>Shop</p>
              </div>
              <div
                className={`${classes.navItem} ${
                  selectedTab === 'auction' ? classes.active : ''
                }`}
                onClick={() => handleNavigation('auction')}
              >
                <img src='/icons/shop/licitacije.svg' alt='Licitacije' />
                <p>Licitacije</p>
              </div>
              <div
                className={`${classes.navItem} ${
                  selectedTab === 'sport-tourist' ? classes.active : ''
                }`}
                onClick={() => handleNavigation('sport-tourist')}
              >
                <img src='/icons/shop/sport-turist.svg' alt='Shop-turist' />
                <p>Sport turist</p>
              </div>
            </div>
            <div className={classes.searchContainer}>
              <div className={classes.inputWrapper}>
                <img
                  src='/icons/shop/search.svg'
                  alt='Search'
                  className={classes.searchIcon}
                />
                <input
                  type='text'
                  placeholder='Pretraga'
                  value={searchTerm}
                  onChange={onChangeSearchTerm}
                  ref={searchTermRef}
                  className={classes.searchInput}
                />
              </div>
            </div>
          </div>

          <div className={classes.sectionMenu}>
            {[
              { type: 'shop', icon: 'shoping-cart-green', title: 'Shop' },
              {
                type: 'licitacije',
                icon: 'licitacije-green',
                title: 'Licitacije',
              },
              {
                type: 'sport-turist',
                icon: 'sport-turist-green',
                title: 'Sport turist',
              },
            ].map(({ type, icon, title }) => (
              <div key={type}>
                <div className={classes.shopSectionHeader}>
                  <img src={`/icons/shop/${icon}.svg`} alt={title} />
                  <h2>{title}</h2>
                </div>
                <div className={classes.sectionButtonsWrapper}>
                  <button
                    onClick={() =>
                      navigate(
                        `/betko_shop/uslovi?menu=${type}&title=uslovi-za-korisnike`
                      )
                    }
                  >
                    Uslovi za korisnike
                  </button>
                  <button
                    onClick={() =>
                      navigate(
                        `/betko_shop/uslovi?menu=${type}&title=saznaj-vise`
                      )
                    }
                  >
                    Saznaj više
                  </button>
                  <button
                    onClick={() =>
                      navigate(
                        `/betko_shop/uslovi?menu=${type}&title=uslovi-za-ponudjace`
                      )
                    }
                  >
                    Uslovi za ponuđače
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <ProductPreviewNew
          product={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      </BetkoShopLayout>
    )
  }
  return (
    <BetkoShopLayout>
      <div className={classes.pageContainer}>
        <div className={classes.headerWrapper}>
          <div className={classes.desktopHolder}>
            <div className={classes.headerNavigation}>
              <div
                className={`${classes.navItem} ${
                  selectedTab === 'shop' ? classes.active : ''
                }`}
                onClick={() => handleNavigation('shop')}
              >
                <img src='/icons/shop/shoping-cart.svg' alt='Korpa' />
                <p>Shop</p>
              </div>
              <div
                className={`${classes.navItem} ${
                  selectedTab === 'auction' ? classes.active : ''
                }`}
                onClick={() => handleNavigation('auction')}
              >
                <img src='/icons/shop/licitacije.svg' alt='Licitacije' />
                <p>Licitacije</p>
              </div>
              <div
                className={`${classes.navItem} ${
                  selectedTab === 'sport-tourist' ? classes.active : ''
                }`}
                onClick={() => handleNavigation('sport-tourist')}
              >
                <img src='/icons/shop/sport-turist.svg' alt='Shop-turist' />
                <p>Sport turist</p>
              </div>
            </div>
            <div className={classes.searchContainer}>
              <div className={classes.inputWrapper}>
                <img
                  src='/icons/shop/search.svg'
                  alt='Search'
                  className={classes.searchIcon}
                />
                <input
                  type='text'
                  placeholder='Pretraga'
                  value={searchTerm}
                  onChange={onChangeSearchTerm}
                  ref={searchTermRef}
                  className={classes.searchInput}
                />
              </div>
              <button
                className={classes.sortBtnDesktop}
                onClick={() => onSortChange()}
              >
                <span>
                  Sortiraj:{' '}
                  {sortByPrice === 'asc' ? 'pri najskuplje' : 'pri najeftinije'}
                </span>
              </button>
            </div>
          </div>

          <div className={classes.sectionMenu}>
            {[
              { type: 'shop', icon: 'shoping-cart-green', title: 'Shop' },
              {
                type: 'licitacije',
                icon: 'licitacije-green',
                title: 'Licitacije',
              },
              {
                type: 'sport-turist',
                icon: 'sport-turist-green',
                title: 'Sport turist',
              },
            ].map(({ type, icon, title }) => (
              <div key={type}>
                <div className={classes.shopSectionHeader}>
                  <img src={`/icons/shop/${icon}.svg`} alt={title} />
                  <h2>{title}</h2>
                </div>
                <div className={classes.sectionButtonsWrapper}>
                  <button
                    onClick={() =>
                      navigate(
                        `/betko_shop/uslovi?menu=${type}&title=uslovi-za-korisnike`
                      )
                    }
                  >
                    Uslovi za korisnike
                  </button>
                  <button
                    onClick={() =>
                      navigate(
                        `/betko_shop/uslovi?menu=${type}&title=saznaj-vise`
                      )
                    }
                  >
                    Saznaj više
                  </button>
                  <button
                    onClick={() =>
                      navigate(
                        `/betko_shop/uslovi?menu=${type}&title=uslovi-za-ponudjace`
                      )
                    }
                  >
                    Uslovi za ponuđače
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={classes.desktopWrapper}>
          {isMobile ? (
            <>
              <div className={classes.buttonContainer}>
                <button
                  className={classes.categoryButton}
                  onClick={toggleModal}
                >
                  <img src='/icons/shop/shop-categories.svg' alt='Kategorije' />
                  Kategorije
                </button>
                <button
                  className={classes.sortBtnMobile}
                  onClick={() => onSortChange()}
                >
                  <span>
                    Sortiraj:{' '}
                    {sortByPrice === 'asc'
                      ? 'pri najskuplje'
                      : 'pri najeftinije'}
                  </span>
                </button>
              </div>

              <Modal
                open={isOpen}
                onCancel={toggleModal}
                footer={null}
                closable={true}
                width='100%'
                className={`${classes.mobileModal} mobileModal`}
                closeIcon={<CloseOutlined style={{ color: 'white' }} />}
              >
                <CategoriesSidebar
                  className={classes.mobileCategories}
                  allCategories={
                    shopType
                      ? allCategories?.paginatedData
                          ?.filter(
                            (category) => category?.typeShop === shopType
                          )
                          ?.filter(
                            (cat) =>
                              cat?.name !== 'OSTAVI ADMINU DA ODREDI KATEGORIJU'
                          )
                      : allCategories?.paginatedData?.filter(
                          (cat) =>
                            cat?.name !== 'OSTAVI ADMINU DA ODREDI KATEGORIJU'
                        )
                  }
                  setSelectedCategory={setSelectedCategory}
                  selectedCategory={selectedCategory}
                  setSelectedProduct={setSelectedProduct}
                  setSearchParams={setSearchParams}
                />
              </Modal>
            </>
          ) : (
            <CategoriesSidebar
              allCategories={
                shopType
                  ? allCategories?.paginatedData
                      ?.filter((category) => category?.typeShop === shopType)
                      ?.filter(
                        (cat) =>
                          cat?.name !== 'OSTAVI ADMINU DA ODREDI KATEGORIJU'
                      )
                  : allCategories?.paginatedData?.filter(
                      (cat) =>
                        cat?.name !== 'OSTAVI ADMINU DA ODREDI KATEGORIJU'
                    )
              }
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
              setSelectedProduct={setSelectedProduct}
              setSearchParams={setSearchParams}
            />
          )}

          <h3 className={classes.porductsHeader}>Izlistano: sve Kategorije</h3>
          <div className={classes.wraperContainer}>
            {selectedProductId ? (
              <ProductPreviewNew
                product={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            ) : (
              <div className={classes.productsContainer}>
                {isLoading ? (
                  <div className={classes.spinner}>
                    <Spinner />
                  </div>
                ) : (
                  allProducts?.paginatedData?.map((product) => (
                    <ProductCardNew
                      product={product}
                      key={product?._id}
                      setSearchParams={setSearchParams}
                    />
                  ))
                )}
                {placeholdersNeeded > 0 &&
                  new Array(placeholdersNeeded)
                    .fill(null)
                    .map((_, index) => (
                      <CardPlaceHolder isShopPage={true} key={index} hideTimer={hideTimer} category={{type:"shop", type:"sport-turist"}}/>
                    ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </BetkoShopLayout>
  )
}

export default BetkoShopPageNew
