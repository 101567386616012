import ForumPageTemplate from './ForumPageTemplate'

const BetPortalSinglePage = () => (
  <ForumPageTemplate
    title='BET PORTAL'
    apiGetPath='betportal'
    apiPostPath='bet-portal-post'
    navigationPath='bet_portal'
    commentType='bet_portal'
    chatImagePath='betportal'
  />
)

export default BetPortalSinglePage
