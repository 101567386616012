import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import classes from './MisterTipster.module.css'
import { useNavigate } from 'react-router-dom'
import MisterTipsterSingleMatch from '../components/MisterTipsterSingleMatch'
import AuthContext from '../context/AuthProvider'
import LoginModal from '../components/LoginModalNovo'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useRefreshToken from '../hooks/useRefreshToken'
import {
  betkoErrorPopup,
  betkoPopup,
  betkoSuccessPopup,
} from '../utils/notifications'
import LayoutWithCalcAndSideImgs from './../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'
import { betkoPopupWithAction } from './../utils/BetkoPopupModal'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const MisterTipster = () => {
  const [pairsForDate, setPairsForDate] = useState(null)
  const [userBets, setUserBets] = useState(null)
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
  const [selectedBets, setSelectedBets] = useState([])
  const navigate = useNavigate()
  const [refetchTrigger, setRefetchTrigger] = useState(false)
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useContext(AuthContext)
  const refresh = useRefreshToken()
  const hasPlacedBets = userBets && userBets[0]?.bets?.length > 0
  const isLogged = auth && Object.keys(auth).length > 0
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const isMobile = window.innerWidth <= 768

  const [blockedMatches, setBlockedMatches] = useState([])
  const [loadedMatches, setLoadedMatches] = useState(false)

  const handleBlockMatch = (match, isBlocked) => {
    setBlockedMatches((prevBlocked) => {
      if (isBlocked) {
        return [...prevBlocked, match]
      } else {
        return prevBlocked.filter(
          (m) =>
            m.league !== match.league ||
            m.club1 !== match.club1 ||
            m.club2 !== match.club2
        )
      }
    })
  }

  const handleOpenLoginModal = () => {
    if (!isLogged) {
      setIsOpenLoginModal(true)
      return true
    }
    return false
  }

  const navigateToProfile = () => {
    if (handleOpenLoginModal()) return

    navigate(`/mister_tipster/profil?user=${auth._id}`)
  }

  const handlePlaceBets = async () => {
    if (handleOpenLoginModal()) return

    if (isLogged && !auth.isMisterTipster) {
      navigate('/moj_nalog')
    }

    try {
      await axios.post(`${SERVER_URL}/create-bet`, {
        bets: selectedBets,
        userId: auth?._id,
      })
      betkoSuccessPopup('Uspešno ste odigrali današnji tiket!')
      setRefetchTrigger((curr) => !curr)
    } catch (err) {
      console.log(err)
      betkoErrorPopup(
        err?.response?.data?.message || 'Greška pri igranju tiketa!'
      )
    }
  }

  useEffect(() => {
    const getPairsForDate = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/get-pair-for-today`)
        setPairsForDate(response?.data?.pairsForDate)
        setLoadedMatches(true)
      } catch (err) {
        console.error('Error fetching pairs:', err)
      }
    }

    const getBetsByUser = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/getBetsByUser/${auth?._id}`
        )
        setUserBets(response?.data)
      } catch (err) {
        console.error('Error fetching pairs:', err)
      }
    }

    auth._id && getBetsByUser()
    getPairsForDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchTrigger])

  useEffect(() => {
    if (!loadedMatches || blockedMatches.length === 0) return

    if (blockedMatches.length === 5) {
      betkoPopup('Obaveštenje', 'Svi mečevi su počeli.', '')
    } else {
      const blockedMatchesString = blockedMatches
        .map((match) => `- ${match.league}: ${match.club1} - ${match.club2}`)
        .join('\n')

      betkoPopup(
        'Obaveštenje',
        'Sledeći od mečeva su počeli:',
        blockedMatchesString
      )
    }
  }, [blockedMatches, loadedMatches])

  const handleEmailSubscription = async () => {
    if (handleOpenLoginModal()) return

    try {
      const response = await axiosPrivate.put(
        `${SERVER_URL}/update-email-notification/${auth?._id}`,
        {
          misterTipsterNotification: !auth?.misterTipsterNotification,
        }
      )
      betkoPopup(
        'Uspeh',
        '',
        response?.data?.message || 'Uspešno ste se pretplatili!'
      )
      refresh()
    } catch (err) {
      betkoPopup(
        'Uspeh',
        '',
        err?.response?.data?.message || 'Greška, pokušajte kasnije !'
      )
    }
  }

  return (
    <LayoutWithCalcAndSideImgs title='MISTER TIPSTER'>
      <button
        onClick={() => navigateToProfile()}
        className={classes.backButton}
      >
        <img src='/icons/white-left-arrow.svg' alt='nazad' />
        Nazad na profil
      </button>
      <div className={classes.contentContainer}>
        <div className={classes.topButtonsContainer}>
          <div className={`${classes.topButtons} ${classes.menuButton}`}>
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={isMenuOpen ? classes.activeButton : ''}
            >
              Rangiranje & uopšteno
              <img
                src='/icons/submenu-active.svg'
                alt='submenu'
                className={isMenuOpen ? classes.submenuActive : ''}
              />
            </button>
          </div>
          {(isMenuOpen || !isMobile) && (
            <>
              <div className={classes.topButtons}>
                <span>Rangiranje</span>
                <button
                  onClick={() =>
                    navigate('/mister_tipster/tabele?table=rang&page=1')
                  }
                >
                  <img src='/icons/tipster/calendar.svg' alt='calendar' />
                  Tabela ranga
                </button>
                <button
                  onClick={() =>
                    navigate('/mister_tipster/tabele?table=month&page=1')
                  }
                >
                  <img src='/icons/tipster/calendar.svg' alt='calendar' />
                  Mesečna tabela
                </button>
                <button
                  onClick={() =>
                    navigate('/mister_tipster/tabele?table=year&page=1')
                  }
                >
                  <img src='/icons/tipster/calendar.svg' alt='calendar' />
                  Godišnja tabela
                </button>
              </div>
              <div className={classes.topButtons}>
                <span>Uopšteno</span>
                <button onClick={() => navigateToProfile()}>
                  <img src='/icons/tipster/profile-icon.svg' alt='calendar' />
                  Moj tipster profil
                </button>
                <button
                  onClick={() => navigate('/mister_tipster/pravila-igre')}
                >
                  <img src='/icons/tipster/rules-icon.svg' alt='calendar' />
                  Pravila igre
                </button>
                <button onClick={() => navigate('/mister_tipster/nagrade')}>
                  <img src='/icons/tipster/fund-icon.svg' alt='calendar' />
                  Fond za osvojene nagrade
                </button>
              </div>
            </>
          )}
        </div>
        <div className={classes.tipsterHeader}>
          <img src='/icons/tipster/thunder.svg' alt='thunder' />
          MOJ DANAŠNJI TIPSTER MATCH
          <img src='/icons/tipster/thunder.svg' alt='thunder' />
        </div>
        {pairsForDate?.length > 0 ? (
          <>
            <div className={classes.tipsterMatchContainer}>
              <span>DANAŠNJI PAROVI</span>
              <div className={classes.headerSelection}>1</div>
              <div className={classes.headerSelection}>X</div>
              <div className={classes.headerSelection}>2</div>
            </div>
            {pairsForDate?.map((pair, index) => (
              <MisterTipsterSingleMatch
                key={pair._id}
                pair={pair}
                selectedBets={selectedBets}
                setSelectedBets={setSelectedBets}
                isMatchPage
                userBets={userBets}
                index={index}
                onBlockMatch={handleBlockMatch}
                onOpenLoginModal={handleOpenLoginModal}
              />
            ))}
          </>
        ) : (
          <div className={classes.empty}>
            <h1>Nemate meč za danas!</h1>
          </div>
        )}

        {!hasPlacedBets && pairsForDate?.length > 0 && (
          <div className={classes.confirmContainer}>
            <span>Ukoliko si siguran/na</span>
            <button
              className={classes.placeYourBetsBtn}
              onClick={() => handlePlaceBets()}
            >
              <img src='/icons/tipster/checkmark.svg' alt='checkmark' />
              Potvrdi tipove
            </button>
          </div>
        )}

        <div className={classes.mailNotificationCheckbox}>
          <input
            checked={auth?.misterTipsterNotification || false}
            onClick={() => handleEmailSubscription()}
            type='checkbox'
            name='mailNotificationCheckbox'
            id='mailNotificationCheckbox'
          />
          <label htmlFor='mailNotificationCheckbox'>
            Želim da primam email obaveštenja za sve promene na ovoj stranici
          </label>
        </div>
      </div>

      <LoginModal
        setIsOpen={setIsOpenLoginModal}
        isOpen={isOpenLoginModal}
        title='Da bi ste nastavili morate biti ulogovani!'
      />
    </LayoutWithCalcAndSideImgs>
  )
}

export default MisterTipster
