import { useState } from 'react'
import { useEffect } from 'react'
import axios from '../config/axios'
import { useQuery } from '@tanstack/react-query'
import SinglePost from '../components/SinglePost'
import Spinner from '../components/Spinner'
import Modal from 'antd/es/modal/Modal'
import classes from './korisnickiPaketiListaNew.module.css'
import { useNavigate, useLocation } from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons'
import LayoutWithCalcAndSideImgs from '../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'
import ProductCardNew from '../components/BetkoShop/ProductCardNew'
import { useSearchParams } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import UserPackages from '../components/UserPackages'
import { betkoErrorPopup, betkoInfoPopup } from './../utils/notifications'
import { IS_PROD } from '../helpers/urls'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const KorisnickiPaketiListaNew = () => {
  const [posts, setPosts] = useState([])
  const navigate = useNavigate()
  const [isMsg, setIsMsg] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedPackage, setSelectedPackage] = useState(null)

  const [allPackages, setAllPackages] = useState([])

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const getAllKorisnickiPaketi = async () => {
    const { data } = await axios.get('/users-packages')
    data.msg ? setIsMsg(true) : setPosts(data.result)
    !data.msg ? setIsMsg(false) : setPosts([])
    return data
  }
  const allKorisnickiPaketi = useQuery({
    queryKey: ['korisnickiPaketi'],
    queryFn: getAllKorisnickiPaketi,
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
  })
  const { data: allPackagesData, isLoading: isPackagesLoading } = useQuery({
    queryKey: ['allPackages'],
    queryFn: async () => {
      const response = await axios.get(`${SERVER_URL}/get-all-package`)
      return response.data.allPackages
    },
  })

  useEffect(() => {
    if (allPackagesData) setAllPackages(allPackagesData)
  }, [allPackagesData])

  const handlePackageSelect = (selected) => {
    setSelectedPackage(selected) // Ispravan način postavljanja state-a
    localStorage.setItem('selectedPackage', JSON.stringify(selected))
    window.location.href = `/kupi-paket/${selected._id}` // Ili useNavigate za navigaciju
  }

  return (
    <LayoutWithCalcAndSideImgs title={'KORISNIČKI PAKETI'}>
      <div className={classes.mainContainer}>
        <div className={classes.chosePackage}>
          <div className={classes.imgWrapper}>
            <div className={classes.imgContainer}>
              <img src='/icons/green-fire.svg' alt='korisnicki paketi' />
            </div>
            <div className={classes.textContainer}>
              <p>KAKO? BRZO I LAKO!</p>
              <span>KUPI BETKO PAKETE</span>
            </div>
          </div>
          <button
            onClick={() => {
              console.log(IS_PROD)
              if (IS_PROD) {
                betkoInfoPopup('USKORO! NA BETKOTIP.COM')
              } else {
                openModal()
              }
            }}
          >
            Izaberi paket
          </button>
        </div>
        <div className={classes.singlePostContainer}>
          {allKorisnickiPaketi.isLoading || posts.length === 0 ? (
            <div className={classes.spinner}>
              <Spinner />
            </div>
          ) : (
            <>
              {!isMsg ? (
                posts.map((post) => {
                  return (
                    <SinglePost
                      key={post._id}
                      postId={post._id}
                      post={post}
                      type='korisnickiPaketi'
                    />
                  )
                })
              ) : (
                <div className={classes.msg}>
                  <p>{allKorisnickiPaketi.data.msg}</p>
                </div>
              )}
            </>
          )}
        </div>
        <Modal
          open={isOpen}
          onCancel={() => {
            closeModal()
            setSelectedPackage(null)
          }}
          footer={null}
          closeIcon={
            <CloseOutlined style={{ width: '24px', color: 'white' }} />
          }
          className={classes.modalBody}
          setSelectedPackage={setSelectedPackage}
        >
          <div className={classes.modalHeader}>
            <h2>Izaberi paket</h2>
          </div>
          <div className={classes.packageContainer}>
            {allPackages.length === 0 ? (
              <div className={classes.spinner}>
                <Spinner />
              </div>
            ) : (
              <UserPackages
                allPackages={allPackages}
                setSelectedPackage={setSelectedPackage}
                onPackageSelect={handlePackageSelect}
              />
            )}
          </div>
        </Modal>
      </div>
    </LayoutWithCalcAndSideImgs>
  )
}
export default KorisnickiPaketiListaNew
