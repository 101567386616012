import React, { useContext, useEffect, useState } from 'react'
import BetkoShopLayout from './BetkoShopLayout'
import classes from './BetkoCartNew.module.css'
import { Form, Input, Tooltip, message } from 'antd'
import CartContext from '../../context/CartContext'
import { FaInfo, FaMinus, FaPlus } from 'react-icons/fa'
import { RxTriangleLeft } from 'react-icons/rx'
import AuthContext from '../../context/AuthProvider'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useNavigate, useLocation } from 'react-router-dom'
import formatPrice from '../../utils/FormatPriceCurrency'
import { betkoSuccessPopup, betkoErrorPopup } from '../../utils/notifications'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const BetkoCartNew = () => {
  const {
    cart,
    cartWithData,
    addToCart,
    removeFromCart,
    totalAmount,
    resetCart,
  } = useContext(CartContext)
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const { auth } = useContext(AuthContext)
  const [form] = Form.useForm()
  const location = useLocation()
  const [tourQuantity, setTourQuantity] = useState(
    location?.state?.tourQuantity
  )
  const auction = location?.state?.auction
  console.log('auction: ', auction)
  const sportTour = location?.state?.sportTour
  const [bidPrice, setBidPrice] = useState(0)

  useEffect(() => {
    if (!auth?._id || (cartWithData?.length === 0 && !auction && !sportTour)) {
      navigate(-1)
    }
  }, [cartWithData])

  const handleCreateOrder = async () => {
    const reqValues = await form.validateFields()

    if (bidPrice > 999999999999) {
      message.error('Maksimalan iznost licitacije je 999.999.999.999 RSD!')
      return
    }

    if (auction) {
      try {
        await axiosPrivate.put(`${SERVER_URL}/product-new-bid`, {
          productId: auction._id,
          userId: auth._id,
          price: bidPrice,
          customer: {
            ...reqValues,
          },
        })
        betkoSuccessPopup('Čestitamo, uspešno ste licitirali!')
        navigate(-1)
      } catch (error) {
        betkoErrorPopup(
          error?.response?.data?.message ||
            'Došlo je do greške! Molimo pokušajte kasnije ili kontaktirajte našu korisničku podršku! '
        )
      }
      return
    }

    let orderedProducts = cartWithData?.map((product) => {
      return {
        productId: product?._id,
        quantity: product.quantity,
        atrDesc: product.atrDesc,
      }
    })

    if (sportTour) {
      orderedProducts = [
        {
          productId: sportTour._id,
          quantity: tourQuantity,
          atrDesc: location?.state?.tourDesc || '',
        },
      ]
    }

    const reqBody = {
      customer_id: auth?._id,
      orderedProducts,
      customer: {
        ...reqValues,
      },
    }

    try {
      await axiosPrivate.post(`${SERVER_URL}/create-order`, reqBody)
      betkoSuccessPopup(
        'Čestitamo, uspešno ste obavili kupovinu! Sve potrebne informacije biće Vam dostavljene na Vašu email adresu!'
      )
      navigate(-1)
      resetCart()
    } catch (error) {
      betkoErrorPopup(
        error?.response?.data?.message ||
          'Došlo je do greške! Molimo pokušajte kasnije ili kontaktirajte našu korisničku podršku! '
      )
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      name: auth?.name,
      address: auth?.address,
      place: auth?.place,
      zipCode: auth?.zipCode,
      phoneNumber: auth?.phoneNumber,
    })
  }, [])

  return (
    <BetkoShopLayout>
      <div className={classes.cartHolder}>
        <div className={classes.cartContainer}>
          <div className={classes.cartHeader}>
            <h3>Unesite podatke potrebne za porudžbinu/dostavu</h3>
          </div>
          <div>
            <Form
              form={form}
              className={classes.userInfoForm}
              onFinish={handleCreateOrder}
              initialValues={{ name: auth?.name, isPopular: false }}
            >
              <div className={classes.formItem}>
                <label>Ime i Prezime</label>
                <Form.Item
                  name='name'
                  rules={[
                    {
                      required: true,
                      min: 2,
                      message: 'Molimo unesite ispravno ime i prezime!',
                    },
                  ]}
                >
                  <Input className={classes.input} />
                </Form.Item>
              </div>

              <div className={classes.formItem}>
                <label>Adresa</label>
                <Form.Item
                  name='address'
                  rules={[
                    {
                      required: true,
                      min: 2,
                      message: 'Molimo unesite ispravnu adresu!',
                    },
                  ]}
                >
                  <Input className={classes.input} />
                </Form.Item>
              </div>

              <div className={classes.formItem}>
                <label>Mesto</label>
                <Form.Item
                  name='place'
                  rules={[
                    {
                      required: true,
                      min: 2,
                      message: 'Molimo unesite ispravno mesto stanovanja!',
                    },
                  ]}
                >
                  <Input className={classes.input} />
                </Form.Item>
              </div>

              <div className={classes.formItem}>
                <label>Poštanski broj</label>
                <Form.Item
                  name='zipCode'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo unesite poštanski broj!',
                    },
                    {
                      len: 5,
                      message: 'Poštanski broj mora imati tačno 5 cifara!',
                    },
                    {
                      pattern: /^\d{5}$/,
                      message:
                        'Molimo unesite ispravan poštanski broj (5 cifara)!',
                    },
                  ]}
                >
                  <Input
                    maxLength={5}
                    type='number'
                    className={classes.input}
                  />
                </Form.Item>
              </div>

              <div className={classes.formItem}>
                <label>Broj telefona</label>
                <Form.Item
                  name='phoneNumber'
                  rules={[
                    {
                      required: true,
                      pattern: /^[0-9]{9,11}$/,
                      message:
                        'Molimo unesite ispravan broj telefona (9-11 cifara)!',
                    },
                  ]}
                >
                  <Input className={classes.input} />
                </Form.Item>
              </div>
            </Form>
            <div className={classes.orderInfo}>
              <p>{auction ? 'Informacije o licitaciji' : sportTour ? 'Informacije o rezervaciji : ' : 'Informacije o dostavi'}</p>
              <p>
                {auction
                  ? 'Za sve informacije vezane za vašu licitaciju, po potrebi će vas direktno kontaktirati izlagač licitacije.'
                  : sportTour
                  ? 'Za sve informacije vezane za vašu rezervaciju, direktno će vas kontaktirati izlagač ponude i u dogovoru sa vama utanačiti sve potrebne detalje.'
                  : 'Za sve informacije vezane za dostavu vaše porudžbine na vašu adresu, direktno će vas kontaktirati izlagač proizvoda i u dogovoru sa vama, prema vama odgovarajućem načinu dostave, utanačiti detalje pošiljke ka vama.'}
              </p>
            </div>
          </div>
        </div>

        {/* Cart details*/}
        <div className={classes.cartSection}>
          <div className={classes.cartTitle}>
            <span>
              Tvoja{' '}
              {auction
                ? `LICITACIJA`
                : sportTour
                ? `SPORT TURA:`
                : 'KORPA'}
            </span>
          </div>

          {auction && (
            <div className={classes.auctionForm}>
              <span>Iznos koji licitiraš :</span>
              <input
                value={bidPrice}
                type='text'
                placeholder='UNESITE IZNOS'
                onChange={(e) => setBidPrice(e.target.value)}
              />
             <button
                    type='submit'
                    className={classes.submitBtn}
                    onClick={handleCreateOrder}
                  >
                    Klikni i potvrdi{' '}
                    {auction
                      ? `LICITACIJU`
                      : sportTour
                      ? 'REZERVACIJU'
                      : 'PORUDŽBINU'}
                  </button>
            </div>
          )}
          {sportTour && (
            <div className={classes.auctionForm}>
              <div className={classes.formItem}>
                 <span>Iznos ture :</span>
              <span>
                      {auction
                        ? formatPrice(bidPrice)
                        : sportTour
                        ? formatPrice(sportTour?.price * tourQuantity)
                        : totalAmount}
                    </span>
              </div>
             
             <button
                    type='submit'
                    className={`${classes.submitBtn} ${classes.sportTourBtn}`}
                    onClick={handleCreateOrder}
                  >
                    Klikni i potvrdi{' '}
                    {auction
                      ? `LICITACIJU`
                      : sportTour
                      ? 'REZERVACIJU'
                      : 'PORUDŽBINU'}
                  </button>
            </div>
          )}

          {cartWithData?.length > 0 && !auction && !sportTour && (
            <div className={classes.cartCard}>
              {cartWithData.map((product) => (
                <div key={product._id} className={classes.cartItem}>
                  <div className={classes.cartWrapper}>
                    <div className={classes.productImage}>
                      <img
                        src={
                          product?.image ? product.image : '/football-image.png'
                        }
                        alt={product.title}
                      />
                    </div>

                    <div className={classes.productDetails}>
                      <span className={classes.productTitle}>
                        {product.title}
                      </span>

                      <div className={classes.quantitySection}>
                        <p>Količina:</p>
                        <div className={classes.quantityControls}>
                          <button
                            className={classes.quantityBtn}
                            onClick={() => removeFromCart(product._id)}
                            type='button'
                          >
                            <FaMinus className={classes.quantityIcon} />
                          </button>
                          <span className={classes.quantity}>
                            {product.quantity}
                          </span>
                          <button
                            className={classes.quantityBtn}
                            onClick={() =>
                              addToCart(
                                product._id,
                                +product.quantity + 1,
                                product?.atrDesc
                              )
                            }
                            type='button'
                          >
                            <FaPlus className={classes.quantityIcon} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className={classes.divider} />
                  <div className={classes.totalSum}>
                    <p>Ukupno:</p>
                    <span>
                      {auction
                        ? formatPrice(bidPrice)
                        : sportTour
                        ? formatPrice(sportTour?.price * tourQuantity)
                        : totalAmount}
                    </span>
                  </div>

                  <button
                    type='submit'
                    className={classes.submitBtn}
                    onClick={handleCreateOrder}
                  >
                    Klikni i potvrdi{' '}
                    {auction
                      ? `LICITACIJU`
                      : sportTour
                      ? 'REZERVACIJU'
                      : 'PORUDŽBINU'}
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </BetkoShopLayout>
  )
}
export default BetkoCartNew
