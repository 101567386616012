import React, { useEffect, useState, useRef, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import BetkoShopLayout from './BetkoShopLayout'
import ProductCardNew from '../../components/BetkoShop/ProductCardNew'
import CardPlaceHolder from '../../components/BetkoShop/CardPlaceHolder'
import classes from './betkoShopHomeNew.module.css'
import AuthContext from '../../context/AuthProvider'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const BetkoShopHomeNew = ({
  setIsOpenLoginModal,
  onChangeSearchTerm,
  searchTerm,
}) => {
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const navigate = useNavigate()
  const location = useLocation()
  const { auth } = useContext(AuthContext)
  const isLogged = auth && Object.keys(auth).length > 0
  const searchTermRef = useRef(null)
  const from = location?.state?.from
  const [popularProducts, setPopularProducts] = useState(null)
  const [selectedTab, setSelectedTab] = useState('')


  useEffect(() => {
    const getAllProducts = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/get-all-popular-products`
        )
        setPopularProducts(response.data)
      } catch (err) {
        console.error('Popular products error:', err)
      }
    }
    getAllProducts()
  }, [])

  useEffect(() => {
    if (searchTermRef?.current && from === 'nonShopPage') {
      searchTermRef?.current.focus()
    }
  }, [from])

  const handleNavigation = (shopType) => {
    setSelectedTab(shopType)
    navigate(`/betko_shop/shop?shopType=${shopType}`)
  }

  return (
    <BetkoShopLayout>
      <div className={classes.right}>
        <div className={classes.desktopWrapper}>
          <div className={classes.headerWrapper}>
            <div className={classes.headerNavigation}>
              <div
                 className={`${classes.navItem} ${selectedTab === 'shop' ? classes.active : ''}`}
                onClick={() => handleNavigation('shop')}
              >
                <img src='/icons/shop/shoping-cart.svg' alt='Korpa' />
                <p>Shop</p>
              </div>
              <div
                 className={`${classes.navItem} ${selectedTab === 'auction' ? classes.active : ''}`}
                onClick={() => handleNavigation('auction')}
              >
                <img src='/icons/shop/licitacije.svg' alt='Licitacije' />
                <p>Licitacije</p>
              </div>
              <div
                className={`${classes.navItem} ${selectedTab === 'sport-tourist' ? classes.active : ''}`}
                onClick={() => handleNavigation('sport-tourist')}
              >
                <img src='/icons/shop/sport-turist.svg' alt='Shop-turist' />
                <p>Shop turist</p>
              </div>
            </div>
            <div className={classes.searchContainer}>
              <div className={classes.inputWrapper}>
                <img
                  src='/icons/shop/search.svg'
                  alt='Search'
                  className={classes.searchIcon}
                />
                <input
                  type='text'
                  placeholder='Pretraga'
                  value={searchTerm}
                  onChange={onChangeSearchTerm}
                  ref={searchTermRef}
                  className={classes.searchInput}
                />
              </div>
            </div>
          </div>

          <div className={classes.desktopWrapper}>
            <div className={classes.productContainer}>
              <div className={classes.singleProduct}>
                {popularProducts?.shopPopularProduct?._id ? (
                  <ProductCardNew
                    isHomePage={true}
                    product={popularProducts?.shopPopularProduct}
                  />
                ) : (
                  <CardPlaceHolder />
                )}
              </div>
              <div className={classes.singleProduct}>
                {popularProducts?.auctionPopularProduct?._id ? (
                  <ProductCardNew
                    isHomePage={true}
                    product={popularProducts?.auctionPopularProduct}
                  />
                ) : (
                  <CardPlaceHolder />
                )}
              </div>
              <div className={classes.singleProduct}>
                {popularProducts?.sportTouristPopularProduct?._id ? (
                  <ProductCardNew
                    isHomePage={true}
                    product={popularProducts?.sportTouristPopularProduct}
                  />
                ) : (
                  <CardPlaceHolder />
                )}
              </div>
            </div>
          </div>
          <div className={classes.contactContainer}>
            <div className={classes.textContainer}>
              <p>
                Ukoliko želite da budete deo ove priče i da koristite naše
                usluge i postanete
                <br className={classes.break} /> prodavac, liciter ili
                turistički organizator kontaktirajte nas.
              </p>
              <button onClick={() => navigate('/saradnja')}>
                Kontaktiraj za saradnju
              </button>
            </div>
          </div>
        </div>{' '}
        <div className={classes.sectionMenu}>
          {[
            { type: 'shop', icon: 'shoping-cart-green', title: 'Shop' },
            {
              type: 'licitacije',
              icon: 'licitacije-green',
              title: 'Licitacije',
            },
            {
              type: 'sport-turist',
              icon: 'sport-turist-green',
              title: 'Sport turist',
            },
          ].map(({ type, icon, title }) => (
            <div key={type}>
              <div className={classes.shopSectionHeader}>
                <img src={`/icons/shop/${icon}.svg`} alt={title} />
                <h2>{title}</h2>
              </div>
              <div className={classes.sectionButtonsWrapper}>
                <button
                  onClick={() =>
                    navigate(
                      `/betko_shop/uslovi?menu=${type}&title=uslovi-za-korisnike`
                    )
                  }
                >
                  Uslovi za korisnike
                </button>
                <button
                  onClick={() =>
                    navigate(
                      `/betko_shop/uslovi?menu=${type}&title=saznaj-vise`
                    )
                  }
                >
                  Saznaj više
                </button>
                <button
                  onClick={() =>
                    navigate(
                      `/betko_shop/uslovi?menu=${type}&title=uslovi-za-ponudjace`
                    )
                  }
                >
                  Uslovi za ponuđače
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </BetkoShopLayout>
  )
}

export default BetkoShopHomeNew
