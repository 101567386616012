import React, { useContext, useEffect, useRef, useState } from 'react'
import classes from './AdminTeams.module.css'
import Axios from 'axios'
import { Button, Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import { IoMdClose } from 'react-icons/io'
import { FaTrashAlt } from 'react-icons/fa'
import AuthContext from '../context/AuthProvider'
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from 'react-icons/md'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { betkoErrorPopup, betkoSuccessPopup } from './../utils/notifications'

const limit = 7

const PaginationBullet = ({ data, current, onTarget }) => {
  return (
    <>
      {data.map((item, idx) => {
        return (
          <button
            key={idx}
            onClick={() => onTarget(item)}
            className={`${classes.targetPage} ${
              current === item ? classes.isActive : ''
            }`}
          >
            <span>{item}</span>
          </button>
        )
      })}
    </>
  )
}

const Pagination = ({
  current,
  setCurrentPage,
  limitPage,
  buttonLimit,
  pagesCount,
  totalCount,
}) => {
  const onPrev = () => {
    setCurrentPage((prev) => prev - 1)
  }
  const onNext = () => {
    setCurrentPage((prev) => prev + 1)
  }
  const onFirst = () => {
    setCurrentPage(1)
  }
  const onLast = () => {
    setCurrentPage(pagesCount)
  }
  const onTarget = (target) => {
    setCurrentPage(target)
  }

  const numbers = []
  for (let i = 1; i <= Math.ceil(totalCount / limitPage); i++) {
    numbers.push(i)
  }

  const getPaginationButton = () => {
    let numShown = buttonLimit
    let start = Math.min(numShown, numbers.length)
    let first = current - Math.floor(start / 2)
    first = Math.max(first, 1)
    first = Math.min(first, numbers.length - start + 1)

    return [...Array(start)].map((k, i) => i + first)
  }

  const paginationButton = getPaginationButton()
  const maxOfNumbers = Math.max(...numbers)
  const minOfNumbers = Math.min(...numbers)

  return (
    <div className={classes.pagination}>
      <button
        className={`${classes.firstPage} ${
          paginationButton.length > 1 && current !== minOfNumbers
            ? classes.show
            : ''
        }`}
        onClick={() => onFirst()}
      >
        <MdOutlineKeyboardDoubleArrowLeft />
      </button>

      <button
        className={`${classes.pageBack} ${
          current <= 1 ? classes.disabled : ''
        }`}
        onClick={() => onPrev()}
        disabled={`${current <= 1 ? classes.disabled : ''}`}
      >
        <IoIosArrowBack />
      </button>

      <div className={classes.targetPageContainer}>
        <PaginationBullet
          data={paginationButton}
          current={current}
          onTarget={onTarget}
        />
      </div>

      <button
        className={`${classes.pageForward} ${
          current === numbers.length ? classes.disabled : ''
        }`}
        onClick={() => onNext()}
        disabled={`${current === numbers.length ? classes.disabled : ''}`}
      >
        <IoIosArrowForward />
      </button>

      <button
        className={`${classes.lastPage} ${
          paginationButton.length > 1 && current !== maxOfNumbers
            ? classes.show
            : ''
        }`}
        onClick={() => onLast()}
      >
        <MdKeyboardDoubleArrowRight />
      </button>
    </div>
  )
}

function AdminTeams() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL
  // context
  const { auth } = useContext(AuthContext)
  // navigation
  const navigate = useNavigate()
  // ref
  const imageRef = useRef()
  const imageEditRef = useRef()
  // data
  const [teams, setTeams] = useState([])
  const [team, setTeam] = useState({})
  const [imageSrc, setImageSrc] = useState(null)
  const [editTeam, setEditTeam] = useState({})
  // refetch
  const [refetch, setRefetch] = useState(false)
  const [newImage, setNewImage] = useState(false)
  // modals
  const [openDeleteModal, setOpenDleteModal] = useState(false)
  const [createSidebar, setCreateSidebar] = useState(false)
  const [editSidebar, setEditSidebar] = useState(false)
  // overlay
  const [activeOverlay, setActiveOverlay] = useState(false)
  // form create team
  const [form, setForm] = useState({
    name: '',
    image: '',
  })
  // form edit team
  const [editForm, setEditForm] = useState({
    name: '',
    image: '',
  })
  // form validate
  const [errorForm, setErrorForm] = useState({
    name: false,
    image: false,
  })
  // disable button
  const [disableButton, setDisableButton] = useState(false)
  //search
  const [search, setSearch] = useState(null)
  const [searchForm, setSearchForm] = useState({
    query: '',
  })
  // pagination
  const [pageNumber, setPageNumber] = useState(1)
  const [totalCount, setTotalCount] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  // ------- fetch functions -------

  const fetchTeams = async () => {
    const payload = {
      searchTerm: search,
    }
    try {
      await Axios.post(
        `${SERVER_URL}/getTeamsPerPage/${pageNumber}/${limit}/null`,
        payload,
        { withCredentials: false }
      )
        .then((res) => {
          if (res.status === 200) {
            setTeams(res.data.data)
            setTotalCount(res?.data?.totalCount)
            setPageCount(res?.data?.pageCount)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const fetchTeam = async () => {
    const id = team._id
    try {
      await Axios.get(`${SERVER_URL}/getTeam/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${auth.accessToken}` },
      })
        .then((res) => {
          if (res.status === 200) {
            setEditTeam(res.data.data)
            setEditForm({
              name: res.data.data.name,
              image: res.data.data.teamImage,
            })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    } catch (error) {
      console.error(error)
    }
  }

  // ------- useEffect -------

  useEffect(() => {
    fetchTeams()

    return () => {}
  }, [refetch, pageNumber])

  useEffect(() => {
    if (team && Object.keys(team).length > 0) {
      fetchTeam()
    }

    return () => {}
  }, [team])

  useEffect(() => {
    if (teams && teams.length < 1) {
      if (pageNumber === 1) {
        return
      } else {
        setPageNumber((prev) => prev - 1)
      }
    }
  }, [teams])

  // ------- functions -------

  const handleCreateTeam = async () => {
    setCreateSidebar(true)
    setActiveOverlay(true)
  }

  const handleEditTeam = (item) => {
    setTeam(item)
    setEditSidebar(true)
    setActiveOverlay(true)
  }

  const handleOnSubmitSearch = async (e) => {
    e.preventDefault()
    setSearch(searchForm.query)
    setRefetch((prev) => !prev)
  }

  const handleChangeSearch = (e) => {
    const { value } = e.target

    setSearchForm({ query: value })
  }

  const handleDeleteTeam = async ({ team, event }) => {
    const id = team._id
    try {
      await Axios.delete(`${SERVER_URL}/deleteTeam/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${auth.accessToken}` },
      })
        .then((res) => {
          setRefetch((prev) => !prev)
          setOpenDleteModal(false)
          setTeam({})
          // notification
          betkoSuccessPopup('Tim uspešno izbrisan.')
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleChange = (e) => {
    const { value, name } = e.target

    switch (name) {
      case 'teamName':
        setForm((prev) => {
          return {
            ...prev,
            name: value,
          }
        })
        break
      case 'image':
        setForm((prev) => {
          return {
            ...prev,
            image: imageRef.current.files[0],
          }
        })
        setNewImage(true)
        const file = e.target.files[0]
        const reader = new FileReader()

        reader.onload = () => {
          setImageSrc(reader.result)
        }

        if (file) {
          reader.readAsDataURL(file)
        }
        break
      case 'editName':
        setEditForm((prev) => {
          return {
            ...prev,
            name: value,
          }
        })
        break
      case 'editImage':
        setEditForm((prev) => {
          return {
            ...prev,
            image: imageEditRef.current.files[0],
          }
        })
        setNewImage(true)
        const editFile = e.target.files[0]
        const editReader = new FileReader()

        editReader.onload = () => {
          setImageSrc(editReader.result)
        }

        if (editFile) {
          editReader.readAsDataURL(editFile)
        }
        break
      default:
        console.log('Default state of handleChange')
    }
  }

  const handleButtonClick = (e) => {
    e.preventDefault()
    imageRef.current.click()
  }
  const handleEditButtonClick = (e) => {
    e.preventDefault()
    imageEditRef.current.click()
  }

  const closeSidebarCreate = () => {
    setCreateSidebar(false)
    setForm({
      name: '',
      image: '',
    })
    setTeam({})
    setActiveOverlay(false)
  }

  const closeSidebarEdit = () => {
    setEditSidebar(false)
    setTeam({})
    setActiveOverlay(false)
  }

  const handleRemoveImage = () => {
    if (team && Object.keys(team).length > 0) {
      imageEditRef.current.value = ''
      setImageSrc(null)
      setEditForm((prev) => {
        return {
          ...prev,
          image: '',
        }
      })
    } else {
      imageRef.current.value = ''
      setImageSrc(null)
      setForm((prev) => {
        return {
          ...prev,
          image: '',
        }
      })
    }
  }

  const handleValidate = (data) => {
    let checkCase = false

    let checkName = false
    let checkImage = false

    if (data.name.length < 3) {
      checkName = false
      setErrorForm((prev) => {
        return {
          ...prev,
          name: true,
        }
      })
    } else {
      checkName = true
      setErrorForm((prev) => {
        return {
          ...prev,
          name: false,
        }
      })
    }

    if (!data.image) {
      checkImage = false
      setErrorForm((prev) => {
        return {
          ...prev,
          image: true,
        }
      })
    } else {
      checkImage = true
      setErrorForm((prev) => {
        return {
          ...prev,
          image: false,
        }
      })
    }

    if (!checkName && !checkImage) {
      setErrorForm((prev) => {
        return {
          ...prev,
          name: true,
          image: true,
        }
      })
    }

    if (checkName && checkImage) {
      checkCase = true
    }

    return checkCase
  }

  const handleSubmit = async ({ e, data }) => {
    e.preventDefault()
    const { name } = e.target

    const isValid = handleValidate(data)

    switch (name) {
      case 'createTeam':
        if (isValid) {
          const formDataImage = new FormData()
          formDataImage.append('file', imageRef.current.files[0])
          formDataImage.imageType = 'team'

          const formData = new FormData()
          formData.append('teamName', form.name)

          setDisableButton(true)
          try {
            const response = await Axios.post(
              `${SERVER_URL}/images/upload-image`,
              formDataImage,
              {
                withCredentials: false,
                headers: {
                  type: 'team',
                },
              }
            )
            formData.append('logo', response.data.data._id)

            await Axios.post(`${SERVER_URL}/addTeam`, formData, {
              withCredentials: false,
              headers: { Authorization: `Bearer ${auth.accessToken}` },
            })
              .then((res) => {
                if (res.status === 200) {
                  setCreateSidebar(false)
                  setForm({
                    name: '',
                    image: '',
                  })
                  handleRemoveImage()
                  setRefetch((prev) => !prev)
                  setDisableButton(false)
                  setActiveOverlay(false)
                  // notification
                  betkoSuccessPopup('Uspešno kreiran tim.')
                }
              })
              .catch((error) => {
                console.error(error)
                setDisableButton(false)
                betkoErrorPopup(error.response.data.message)
              })
          } catch (error) {
            console.error(error)
            setDisableButton(false)
            betkoErrorPopup(error.response.data.message)
          }
        }
        break
      case 'editTeam':
        if (isValid) {
          console.log('edit', editForm)
          const formDataImage = new FormData()
          formDataImage.append('file', imageEditRef.current.files[0])
          formDataImage.imageType = 'team'

          const formData = new FormData()
          formData.append('name', editForm.name)

          setDisableButton(true)
          try {
            if (newImage) {
              const response = await Axios.post(
                `${SERVER_URL}/images/upload-image`,
                formDataImage,
                {
                  withCredentials: false,
                  headers: {
                    type: 'team',
                  },
                }
              )
              formData.append('teamImage', response.data.data._id)
            }

            await Axios.put(
              `${SERVER_URL}/updateTeam/${editTeam._id}`,
              formData,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${auth.accessToken}` },
              }
            )
              .then((res) => {
                if (res.status === 200) {
                  setEditSidebar(false)
                  setNewImage(false)
                  setEditForm({
                    name: '',
                    image: '',
                  })

                  handleRemoveImage()
                  setRefetch((prev) => !prev)
                  setDisableButton(false)
                  setActiveOverlay(false)
                  betkoSuccessPopup('Uspešno ažuriran tim.')
                }
              })
              .catch((error) => {
                console.error(error)
                setDisableButton(false)
                betkoErrorPopup(error.response.data)
              })
          } catch (error) {
            console.error(error)
            setDisableButton(false)
            betkoErrorPopup(error.response.data)
          }
        }
        break
      default:
        console.log('Default state of submit')
    }
  }

  // ------- props and values -------

  const editImageSource = imageSrc
    ? imageSrc
    : `${SERVER_URL}/${editForm?.image?.url}`

  const pagesCountComments = pageCount
  const totalCountComments = totalCount

  // props
  const paginationProps = {
    current: pageNumber,
    setCurrentPage: setPageNumber,
    limitPage: limit,
    buttonLimit: 3,
    pagesCount: pagesCountComments,
    totalCount: totalCountComments,
  }

  return (
    <div className={classes.container}>
      <div className={classes.teamsHeader}>
        <h1>Svi Timovi</h1>

        <form onSubmit={(e) => handleOnSubmitSearch(e)}>
          <input
            value={searchForm.query || ''}
            type='text'
            onChange={(e) => handleChangeSearch(e)}
            placeholder='search...'
          />
          <div>
            <button type='submit'>Search</button>
          </div>
        </form>

        <div className={classes.addButtonContainer}>
          <button
            onClick={() => handleCreateTeam()}
            className={classes.addButton}
          >
            Dodaj Tim
          </button>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <table className={classes.teamsTable}>
          <thead>
            <tr>
              <th>Naziv</th>
              <th>Kreiran</th>
              <th>Ažuriran</th>
              <th>Akcije</th>
            </tr>
          </thead>
          <tbody>
            {teams && teams.length > 0 ? (
              teams.map((item, index) => {
                const createdAt = new Date(item.createdAt).toLocaleDateString()
                const updatedAt = new Date(item.updatedAt).toLocaleDateString()
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{createdAt}</td>
                    <td>{updatedAt}</td>
                    <td>
                      <div className={classes.actionContainer}>
                        <button
                          onClick={() => handleEditTeam(item)}
                          className={classes.actionButtonEdit}
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => {
                            setOpenDleteModal(true)
                            setTeam(item)
                          }}
                          className={classes.actionButtonDelete}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    letterSpacing: '2px',
                  }}
                  colSpan={4}
                >
                  Nema Timova
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <Pagination {...paginationProps} />
      </div>

      <div
        className={`${classes.sidebarCreateTeam} ${
          createSidebar && classes.active
        }`}
      >
        <div
          style={{
            textAlign: 'right',
            borderBottom: '1px solid lightgray',
            padding: '10px 20px',
          }}
        >
          <button
            className={classes.closeButton}
            type='button'
            onClick={() => closeSidebarCreate()}
          >
            <IoMdClose className={classes.closeButtonCreateTeam} />
          </button>
        </div>

        <form
          name='createTeam'
          onSubmit={(e) => handleSubmit({ e: e, data: form })}
          className={classes.sidebarCreateTeamForm}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}
          >
            <div style={{ position: 'relative' }}>
              <label htmlFor='teamName'>Team Name:</label>
              <input
                className={`${classes.nameInput} ${
                  errorForm.name && classes.active
                }`}
                type='text'
                id='teamName'
                name='teamName'
                value={form.name}
                onChange={(e) => handleChange(e)}
              />
              <p
                className={`${classes.errorMsg} ${
                  errorForm.name && classes.active
                }`}
              >
                *This field is required!
              </p>
            </div>

            <div>
              <label htmlFor='image'>Team Image:</label>
              <input
                className={classes.imageInput}
                ref={imageRef}
                type='file'
                id='image'
                name='image'
                accept='image/*'
                onChange={(e) => handleChange(e)}
              />
              <button
                className={classes.buttonAddImage}
                type='button'
                onClick={(e) => handleButtonClick(e)}
              >
                Dodaj Sliku
              </button>
              <p
                className={`${classes.errorMsg} ${
                  errorForm.image && classes.active
                }`}
              >
                *Image is required!
              </p>
              {form.image && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div className={classes.imagePreview}>
                    <img
                      className={classes.readImage}
                      src={imageSrc}
                      alt='selected'
                    />
                    <button
                      onClick={() => handleRemoveImage()}
                      type='button'
                      className={classes.removeImageBtn}
                    >
                      <FaTrashAlt />
                    </button>
                  </div>
                  <span
                    style={{
                      fontSize: '18px',
                      fontWeight: '700',
                      marginTop: '10px',
                      textAlign: 'center',
                    }}
                  >
                    {form.image.name}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div>
            {disableButton ? (
              <span className={classes.loadingButton}>Kreiranje...</span>
            ) : (
              <button type='submit' className={classes.submitButton}>
                Kreiraj Tim
              </button>
            )}
          </div>
        </form>
      </div>

      <div
        className={`${classes.sidebarCreateTeam} ${
          editSidebar && classes.active
        }`}
      >
        <div
          style={{
            textAlign: 'right',
            borderBottom: '1px solid lightgray',
            padding: '10px 20px',
          }}
        >
          <button
            className={classes.closeButton}
            type='button'
            onClick={() => closeSidebarEdit()}
          >
            <IoMdClose className={classes.closeButtonCreateTeam} />
          </button>
        </div>

        <form
          name='editTeam'
          onSubmit={(e) => handleSubmit({ e: e, data: editForm })}
          className={classes.sidebarCreateTeamForm}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}
          >
            <div style={{ position: 'relative' }}>
              <label htmlFor='editName'>Team Name:</label>
              <input
                className={`${classes.nameInput} ${
                  errorForm.name && classes.active
                }`}
                type='text'
                id='editName'
                name='editName'
                value={editForm.name}
                onChange={(e) => handleChange(e)}
              />
              <p
                className={`${classes.errorMsg} ${
                  errorForm.name && classes.active
                }`}
              >
                *This field is required!
              </p>
            </div>

            <div>
              <label htmlFor='editImage'>Team Image:</label>
              <input
                className={classes.imageInput}
                ref={imageEditRef}
                type='file'
                id='editImage'
                name='editImage'
                accept='image/*'
                onChange={(e) => handleChange(e)}
              />
              <button
                className={classes.buttonAddImage}
                type='button'
                onClick={(e) => handleEditButtonClick(e)}
              >
                Dodaj Sliku
              </button>
              <p
                className={`${classes.errorMsg} ${
                  errorForm.image && classes.active
                }`}
              >
                *Image is required!
              </p>
              {editForm.image && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div className={classes.imagePreview}>
                    <img
                      className={classes.readImage}
                      src={editImageSource}
                      alt='selected'
                    />
                    <button
                      onClick={() => handleRemoveImage()}
                      type='button'
                      className={classes.removeImageBtn}
                    >
                      <FaTrashAlt />
                    </button>
                  </div>
                  <span
                    style={{
                      fontSize: '18px',
                      fontWeight: '700',
                      marginTop: '10px',
                      textAlign: 'center',
                    }}
                  >
                    {form.image.name}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div>
            {disableButton ? (
              <span className={classes.loadingButton}>Ažuriranje...</span>
            ) : (
              <button type='submit' className={classes.submitButton}>
                Ažuriraj Tim
              </button>
            )}
          </div>
        </form>
      </div>

      <Modal
        footer={[]}
        open={openDeleteModal}
        title='Da li ste sigurni da želite da obrišete tim?'
        onCancel={() => {
          setOpenDleteModal(false)
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '20px',
          }}
        >
          <Button onClick={(e) => handleDeleteTeam({ team: team, event: e })}>
            Obriši
          </Button>
          <Button
            onClick={(e) => {
              setOpenDleteModal(false)
              setTeam({})
            }}
          >
            Odustani
          </Button>
        </div>
      </Modal>
      <div
        onClick={() => {
          closeSidebarCreate()
          closeSidebarEdit()
        }}
        className={`${classes.overlayTeamPage} ${
          activeOverlay && classes.active
        }`}
      />
    </div>
  )
}

export default AdminTeams
