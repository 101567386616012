import React from 'react'
import SideImages from '../SideImages/SideImages'
import classes from './LayoutWithCalcAndSideImgs.module.css'
import SideImagesWide from '../../SideImagesWide'

const LayoutWithCalcAndSideImgs = ({ children, title }) => {
  const shouldShowBackButton =
    window.location.pathname === '/semafori' ||
    window.location.pathname === '/korisnicki_paketi' ||
    window.location.pathname === '/mister_tipster/tabele' ||
    window.location.pathname === '/mister_tipster/pravila-igre'
  const isSemaforiPage = window.location.pathname === '/semafori'
  const isMisterTipsterPage = window.location.pathname === '/mister_tipster'
  const handleBackButtonClick = () => {
    window.history.back()
  }
  return (
    <div className={classes.container}>
      <div className={classes.sideImagesContainer}>
        <SideImages />
      </div>
      <div
        className={`${classes.postsPageContainer} ${
          isSemaforiPage ? classes.semaforPostPageContainer : ''
        } ${isMisterTipsterPage ? classes.misterTipsterPostPageContainer : ''
        } `}
      >
        <div
          className={`${classes.pageTitleContainer} ${
            isSemaforiPage ? classes.semaforiTitleContainer : ''
          } ${isMisterTipsterPage ? classes.misterTipsterTitleContainer : ''
          }`}
        >
          {shouldShowBackButton && (
            <button
              onClick={handleBackButtonClick}
              className={classes.backButton}
            >
              <img src='/icons/white-left-arrow.svg' alt='nazad' />
              Nazad
            </button>
          )}
          <h1 className={`${classes.pageTitle} `}>{title}</h1>
        </div>
        <div
          className={`${classes.postsAndCalculatorWrapper} ${
            isSemaforiPage ? classes.semaforpostsAndCalculatorWrapper : ''
          } ${isMisterTipsterPage ? classes.misterTipsterpostsAndCalculatorWrapper : ''
          }`}
        >
          <div className={classes.postsContainer}>{children}</div>
          {/* <CalculatorImage maxHeight={280} maxWidth={464} /> */}
          <div className={classes.adsContainer}>
            <SideImagesWide side='comments' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayoutWithCalcAndSideImgs
