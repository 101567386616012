import { useState } from "react"
import classes from './Redesign/SingleComment/SingleComment.module.css'

const RatingPopup = ({ closePopup, comment, handleRatingClick }) => {
  const [selectedRating, setSelectedRating] = useState(0)

  return (
    <div className={classes.ratingPopupWrapper}>
      <div className={classes.starsWrapper}>
        {[1, 2, 3, 4, 5].map((num) => (
          <button
            key={num}
            onClick={() => setSelectedRating(num)}
            className={`${classes.ratingNumber} ${selectedRating >= num ? classes.activeStar : ""}`}
          >
            <img
              src={selectedRating >= num ? "/icons/rating-star-filled.svg" : "/icons/rating-star-empty.svg"}
              alt={`${num} stars`}
              className={classes.starIcon}
            />
          </button>
        ))}
      </div>

      <button
        className={classes.confirmButton}
        onClick={() => {
          if (selectedRating > 0) {
            handleRatingClick(selectedRating, comment)
            closePopup()
          }
        }}
        disabled={selectedRating === 0}
      >
        Ostavi Ocenu
      </button>
    </div>
  )
}

export default RatingPopup
