import { Fragment } from 'react'

const TextToLink = ({ text }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g

  const parts = text.split(urlRegex).map((part, index) => {
    if (part.match(urlRegex)) {
      return (
        <a key={index} href={part} target='_blank' rel='noopener noreferrer'>
          {part}
        </a>
      )
    }
    return part.split('\n').map((line, i) => (
      <Fragment key={`${index}-${i}`}>
        {i > 0 && <br />}
        {line}
      </Fragment>
    ))
  })

  return <div>{parts}</div>
}

export default TextToLink
