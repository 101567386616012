import React, { useEffect, useRef } from 'react'
import classes from './Pagination.module.css'
import { useLocation } from 'react-router-dom'

const TargetButtons = ({ data, onTarget, currentPage, setButtonClicked }) => {
  return (
    <div className={classes.targetButtonsContainer}>
      {data.map((btn, idx) => (
        <button
          key={idx}
          className={`${classes.pageButton} ${
            btn === currentPage ? classes.active : ''
          }`}
          onClick={() => {
            if (typeof btn === 'number') {
              setButtonClicked(true)
              onTarget(btn)
            }
          }}
          disabled={btn === '...'}
        >
          {btn}
        </button>
      ))}
    </div>
  )
}

const Pagination = ({ setCurrentPage, currentPage, totalPages, topRef }) => {
  const goNext = () => {
    setButtonClicked(true)
    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
  }

  const goBack = () => {
    setButtonClicked(true)
    setCurrentPage((prev) => Math.max(prev - 1, 1))
  }

  const goTarget = (target) => {
    setButtonClicked(true)
    setCurrentPage(target)
  }

  const buttonClickedRef = useRef(false)
  const setButtonClicked = (state) => (buttonClickedRef.current = state)

  useEffect(() => {
    if (buttonClickedRef.current && topRef?.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      buttonClickedRef.current = false
    }
  }, [currentPage, topRef])

  const getPaginationButtons = () => {
    const pages = []

    if (totalPages <= 5) {
      return Array.from({ length: totalPages }, (_, i) => i + 1)
    }

    pages.push(1)

    if (currentPage > 3) {
      pages.push('...')
    }

    let start = Math.max(2, currentPage - 1)
    let end = Math.min(totalPages - 1, currentPage + 1)

    for (let i = start; i <= end; i++) {
      pages.push(i)
    }

    if (currentPage < totalPages - 2) {
      pages.push('...')
    }

    pages.push(totalPages)

    return pages
  }

  return (
    <>
      {totalPages > 0 && (
        <div className={classes.paginationContainer}>
          <button
            className={classes.cornerButton}
            disabled={currentPage <= 1}
            onClick={goBack}
          >
            ←
          </button>

          <TargetButtons
            data={getPaginationButtons()}
            onTarget={goTarget}
            currentPage={currentPage}
            setButtonClicked={setButtonClicked}
          />

          <button
            className={classes.cornerButton}
            disabled={currentPage >= totalPages}
            onClick={goNext}
          >
            →
          </button>
        </div>
      )}
    </>
  )
}

export default Pagination
