import classes from './kupiPaket.module.css'
import { Form, Input, Tooltip, message, Modal } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import WsPayFrom from '../components/WsPayFrom'
import { useState, useEffect } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { betkoErrorPopup } from '../utils/notifications'
import axios from '../config/axios'
import getCountryCode from '../utils/GetCountryCode'
const SERVER_URL = process.env.REACT_APP_SERVER_URL
const KupiPaket = (pkg) => {
  const [formValues, setFormValues] = useState(null)
  const navigate = useNavigate()
  const [allPackages, setAllPackages] = useState([])
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [form] = Form.useForm()
  const [isCvcModalOpen, setIsCvcModalOpen] = useState(false)
  const [isDateModalOpen, setIsDateModalOpen] = useState(false)
  const [isCardNumberModalOpen, setIsCardNumberModalOpen] = useState(false)

  const openCvcModal = () => setIsCvcModalOpen(true)
  const closeCvcModal = () => setIsCvcModalOpen(false)

  const openDateModal = () => setIsDateModalOpen(true)
  const closeDateModal = () => setIsDateModalOpen(false)

  const openCardNumberModal = () => setIsCardNumberModalOpen(true)
  const closeCardNumberModal = () => setIsCardNumberModalOpen(false)
  const onFinish = async (values) => {
    if (!selectedPackage) {
      betkoErrorPopup('Molimo odaberite paket!')
      return
    }
    try {
      const response = await axios.post(`${SERVER_URL}/create-key`, {
        packageId: selectedPackage._id,
      })
      console.log('API response:', response.data)
      localStorage.setItem('userPaymentInfo', JSON.stringify(values))

      const paymentData = {
        ...values,
        CustomerCountry: getCountryCode(values?.CustomerCountry),
        Signature: response.data.hash,
        ShoppingCartID: response.data.shoppingCartID,
      }

      setFormValues(paymentData)
      localStorage.setItem('userPaymentInfo', JSON.stringify(paymentData))
    } catch (error) {
      console.error(error)
      message.error('Došlo je do greške pri slanju podataka.')
    }
  }
  // Set form values from previous transaction
  useEffect(() => {
    const userPaymentInfo = localStorage.getItem('userPaymentInfo')
    form.setFieldsValue(JSON.parse(userPaymentInfo))
  }, [])

  useEffect(() => {
    const getAllPackages = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/get-all-package`)
        setAllPackages(response?.data?.allPackages)
      } catch (err) {
        console.error('Error fetching packages:', err)
      }
    }

    getAllPackages()
  }, [])
  useEffect(() => {
    const storedPackage = localStorage.getItem('selectedPackage')
    if (storedPackage) {
      setSelectedPackage(JSON.parse(storedPackage))
    }
  }, [])
  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <h1>KUPI PAKET</h1>
        </div>

        <div className={classes.contentWrapper}>
          <Form
            form={form}
            name='paymentForm'
            className={classes.userInfoForm}
            onFinish={onFinish}
          >
            <div className={classes.inputContainer}>
              <div className={classes.infoText}>
                <p>Unesite podatke potrebne za kupovinu paketa</p>
              </div>

              <div className={classes.formItem}>
                <label>Ime</label>
                <Form.Item
                  name='CustomerFirstName'
                  rules={[
                    {
                      required: true,
                      min: 2,
                      message: 'Molimo unesite ispravno ime!',
                    },
                  ]}
                >
                  <Input placeholder='Ime' className={classes.input} />
                </Form.Item>
              </div>

              <div className={classes.formItem}>
                <label>Prezime</label>
                <Form.Item
                  name='CustomerLastName'
                  rules={[
                    {
                      required: true,
                      min: 2,
                      message: 'Molimo unesite ispravno prezime!',
                    },
                  ]}
                >
                  <Input placeholder='Prezime' className={classes.input} />
                </Form.Item>
              </div>
              <div className={classes.formItem}>
                <label>Mesto</label>
                <Form.Item
                  name='CustomerCity'
                  rules={[
                    {
                      required: true,
                      min: 2,
                      message: 'Molimo unesite ispravano mesto stanovanja!',
                    },
                  ]}
                >
                  <Input placeholder='Mesto' className={classes.input} />
                </Form.Item>
              </div>
              <div className={classes.formItem}>
                <label>Adresa</label>
                <Form.Item
                  name='CustomerAddress'
                  rules={[
                    {
                      required: true,
                      min: 2,
                      message: 'Molimo unesite ispravanu adresu!',
                    },
                  ]}
                >
                  <Input
                    placeholder='Naziv ulice i kućni broj'
                    className={classes.input}
                  />
                </Form.Item>
              </div>
              <div className={classes.formItem}>
                <label>Drzava</label>
                <Form.Item
                  name='CustomerCountry'
                  rules={[
                    {
                      required: true,
                      min: 2,
                      message: 'Molimo unesite ispravanu drzavu!',
                    },
                  ]}
                >
                  <Input placeholder='Srbija' className={classes.input} />
                </Form.Item>
              </div>

              <div className={classes.formItem}>
                <label>Poštanski broj</label>
                <Form.Item
                  name='CustomerZIP'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo unesite poštanski broj!',
                    },
                    {
                      len: 5,
                      message: 'Poštanski broj mora imati tačno 5 cifara!',
                    },
                    {
                      pattern: /^\d{5}$/,
                      message:
                        'Molimo unesite ispravan poštanski broj (5 cifara)!',
                    },
                  ]}
                >
                  <Input
                    placeholder='24xxx'
                    maxLength={5}
                    type='number'
                    className={classes.input}
                  />
                </Form.Item>
              </div>

              <div className={classes.formItem}>
                <label>Broj telefona</label>
                <Form.Item
                  name='CustomerPhone'
                  rules={[
                    {
                      required: true,
                      pattern: /^[0-9]{9,11}$/,
                      message:
                        'Molimo unesite ispravan broj telefona (9-11 cifara)!',
                    },
                  ]}
                >
                  <Input
                    placeholder='+381 6X XXXX XXX'
                    className={classes.input}
                  />
                </Form.Item>
              </div>
              <div className={classes.formItem}>
                <label>Email</label>
                <Form.Item
                  name='CustomerEmail'
                  rules={[
                    {
                      required: true,
                      min: 2,
                      message: 'Molimo unesite ispravan Email!',
                    },
                  ]}
                >
                  <Input
                    placeholder='primer@gmail.com'
                    className={classes.input}
                  />
                </Form.Item>
              </div>
            </div>

            <div className={classes.choseContainer}>
              <p>Ili odaberi jednu od ove dve opcije</p>
              <div className={classes.buttonContainer}>
                <button
                  type='button'
                  onClick={() =>
                    navigate('/korisnicki_paketi/65bac125a86ff9a51a6b110f')
                  }
                >
                  Uplati uplatnicom
                </button>
                <button type='button'>
                  {' '}
                  <a href='http://m.me/betkobet1x2' target='_blank'>
                    Uplata Marketinškog prostora{' '}
                  </a>
                </button>
              </div>
            </div>
            <div className={classes.usefulInofrmationContainer}>
              <p>Korisne informacije</p>
              <div className={classes.infoContainer}>
                <div onClick={openCvcModal}>
                  <p>
                    CVC kontrolni broj{' '}
                    <img src='/icons/information-icon.svg' alt='info' />
                  </p>
                </div>
                <div onClick={openDateModal}>
                  <p>
                    Datum <img src='/icons/information-icon.svg' alt='info' />
                  </p>
                </div>
                <div onClick={openCardNumberModal}>
                  <p>
                    Broj kartice{' '}
                    <img src='/icons/information-icon.svg' alt='info' />
                  </p>
                </div>
              </div>
            </div>
          </Form>

          <div className={classes.desktopHolder}>
            <div className={classes.cartTitle}>
              <span>Tvoja korpa</span>
              <button onClick={() => navigate(-1)}>Promeni paket</button>
            </div>
            <hr className={classes.divider} />
            <div className={classes.totalSum}>
              <p>Ukupno:</p>
              <span>{selectedPackage?.price || '0'} €</span>
            </div>
            <button
              className={classes.continuePaymentButton}
              onClick={() => form.submit()}
            >
              Nastavi na plaćanje
            </button>
            <div className={classes.paymentImg}>
              <img src='/placanje.png' alt='placanje' />
            </div>
          </div>
        </div>
        <Modal
          open={isCvcModalOpen}
          onCancel={closeCvcModal}
          footer={null}
          style={{
            background: 'linear-gradient(135deg, #40445E, #111219)',
            borderRadius: '0.5rem',
            minWidth: '986px',
          }}
          closeIcon={
            <CloseOutlined style={{ width: '24px', color: 'white' }} />
          }
        >
          <div className={classes.modalBody}>
            <div className={classes.modalHeader}>
              <h2>CVC</h2>
            </div>
            <div className={classes.modalContent}>
              <p>
                Kontrolni broj je trocifreni ili četvorocifreni broj otisnut na
                kartici, koji se ne čuva na magnetnoj traci kartice niti se
                pojavljuje na računima i potvrdama trgovaca. Kontrolni broj
                služi kao dokaz da fizički posedujete karticu u trenutku online
                kupovine i smanjuje mogućnost zloupotrebe.
                <br />
                <br /> Položaj kontrolnog broja zavisi od kartice.
                <br />
                <br />
                Prilikom plaćanja MasterCard, Maestro ili Visa karticama
                potrebno je upisati trocifreni broj koji je otisnut na poleđini
                kartice u polju za potpis. Ako na Maestro kartici nije otisnut
                kontrolni broj, njegov upis nije obvezan.
              </p>
              <img
                src='/CVC-broj.png'
                alt='CVC objasnjenje'
                className={classes.cvcNumber}
              />
            </div>
          </div>
        </Modal>
        <Modal
          open={isDateModalOpen}
          onCancel={closeDateModal}
          footer={null}
          centered
          style={{
            background: 'linear-gradient(135deg, #40445E, #111219)',
            borderRadius: '0.5rem',
          }}
          closeIcon={
            <CloseOutlined style={{ width: '24px', color: 'white' }} />
          }
        >
          <div className={classes.modalBody}>
            <div className={classes.modalHeader}>
              <h2>Datum na kartici</h2>
            </div>
            <div className={classes.modalContent}>
              <p>
                Datum na platnoj kartici je informacija koja označava period
                validnosti kartice, odnosno datum do kojeg je kartica važeća.
                Datum se koristi kao jedna od sigurnosnih mera pri online
                plaćanjima i omogućava potvrdu da je kartica aktivna.
                <br />
                <br />
                Datum se sastoji od meseca i godine i najčešće je prikazan u
                formatu MM/GG (na primer, 08/26 za avgust 2026. godine).
              </p>
              <img src='/date-card.png' alt='Datum na kartici' />
            </div>
          </div>
        </Modal>
        <Modal
          open={isCardNumberModalOpen}
          onCancel={closeCardNumberModal}
          footer={null}
          centered
          style={{
            background: 'linear-gradient(135deg, #40445E, #111219)',
            borderRadius: '0.5rem',
          }}
          closeIcon={
            <CloseOutlined style={{ width: '24px', color: 'white' }} />
          }
        >
          <div className={classes.modalBody}>
            <div className={classes.modalHeader}>
              <h2>Broj kartice</h2>
            </div>
            <div className={classes.modalContent}>
              <p>
                Broj kartice na platnoj kartici predstavlja jedinstveni
                identifikator kartice, koji omogućava identifikaciju korisnika i
                povezanog računa u banci. Broj kartice je ključan za obavljanje
                transakcija, bilo na prodajnim mestima, bankomatima ili prilikom
                online plaćanja.
              </p>
              <img src='/card-number.png' alt='broj kartice' />
            </div>
          </div>
        </Modal>
      </div>
      <WsPayFrom formValues={formValues} TotalAmount={selectedPackage?.price} />
    </>
  )
}
export default KupiPaket
