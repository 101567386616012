import LayoutWithCalcAndSideImgs from '../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'
import ForumPostList from '../components/ForumPostList'

const BetPortal = () => {
  return (
    <LayoutWithCalcAndSideImgs title='BET PORTAL'>
      <ForumPostList postUrl='/getAllBetPortalPosts' type='betPortal' />
    </LayoutWithCalcAndSideImgs>
  )
}

export default BetPortal
