import React, { useState, useContext } from 'react'
import classes from './loginModalNovo.module.css'
import axios from '../config/axios'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import AuthContext from '../context/AuthProvider'
import { Modal } from 'antd'
import { betkoErrorPopup } from '../utils/notifications'

const LoginModalNovo = ({ isOpen, setIsOpen }) => {
  const location = useLocation()
  const [login, setLogin] = useState(true)

  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    loginEmail: '',
    loginPassword: '',
  })

  const [checked, setChecked] = useState(false)
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [error, setError] = useState('')
  const { setAuth, setRefetch } = useContext(AuthContext)
  const navigate = useNavigate()

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  const isValidPassword = (password) =>
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[\W]).{8,}$/.test(password)

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleRegistration = (e) => {
    e.preventDefault()
    const { userName, email, password, confirmPassword } = formData

    if (!userName) return setError('Username polje je obavezno')
    if (!isValidEmail(email)) return setError('Email nije ispravan')
    if (!isValidPassword(password))
      return betkoErrorPopup(
        'Lozinka mora biti minimum 8 karaktera, sadržati bar jedno veliko slovo, malo slovo, broj i specijalan karakter'
      )
    if (password !== confirmPassword)
      return betkoErrorPopup('Lozinke se ne podudaraju')
    if (!checked) return setError('Niste potvrdili broj godina')
    if (!checkedTerms) return setError('Niste potvrdili opšte uslove')

    registerMutation.mutate({ userName, email, password })
  }

  const handleLogin = (e) => {
    e.preventDefault()
    const { loginEmail, loginPassword } = formData

    if (!isValidEmail(loginEmail)) return setError('Email nije ispravan')
    if (!isValidPassword(loginPassword))
      return betkoErrorPopup(
        'Lozinka mora biti minimum 8 karaktera, sadržati bar jedno veliko slovo, malo slovo, broj i specijalan karakter'
      )

    loginMutation.mutate({ email: loginEmail, password: loginPassword })
  }

  const registerMutation = useMutation({
    mutationFn: (userData) => axios.post('/register', userData),
    onSuccess: () => {
      setLogin(true)
      setError('')
      setFormData({
        userName: '',
        email: '',
        password: '',
        confirmPassword: '',
        loginEmail: '',
        loginPassword: '',
      })
      setIsOpen(false)
    },
    onError: (error) => {
      const status = error.response?.status
      if (status === 409) setError('Email već postoji')
      if (status === 410) setError('Username već postoji')
    },
  })

  const loginMutation = useMutation({
    mutationFn: (userData) =>
      axios.post('/auth', userData, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }),
    onSuccess: (data) => {
      const { accessToken, roles, email, username, _id, paidToDate, ...rest } =
        data.data

      setAuth({
        accessToken,
        roles,
        email,
        username,
        _id,
        paidToDate: new Date(paidToDate).getTime(),
        ...rest,
      })
      setRefetch((prev) => !prev)
      navigate(location.pathname)
      setIsOpen(false)
    },
    onError: (error) => {
      if (error.response?.status === 401)
        setError('Pogrešan email ili password.')
      if (error.response?.status === 429)
        setError('Previse pokušaja logovanja. Pokušajte opet za 60 sekundi.')
    },
  })

  if (!isOpen) return null

  //   const handleOverlayClick = (e) => {
  //     if (e.target === e.currentTarget) onClose()
  //   }

  return (
    <div className={classes.modalContainer}>
      <Modal
        open={isOpen}
        footer={null}
        className={`${classes.loginModal} `}
        mask={false}
        closable={false}
        onCancel={() => {
          setIsOpen(false)
        }}
        centered
        style={{
          background: 'linear-gradient(135deg, #40445E, #111219)',
          borderRadius: '0.5rem',
          maxWidth: '342px',
        }}
        styles={{
          content: {
            padding: '1rem',
          },
        }}
      >
        <div className={classes.overlay}>
          <div
            className={`${classes.authContainer} ${
              login ? classes.loginHeight : classes.registrationHeight
            }`}
          >
            <div className={classes.authTabs}>
              <button
                className={`${classes.tabButton} ${
                  !login ? classes.activeTab : ''
                }`}
                onClick={() => setLogin(false)}
              >
                Registracija
              </button>
              <button
                className={`${classes.tabButton} ${
                  login ? classes.activeTab : ''
                }`}
                onClick={() => setLogin(true)}
              >
                Logovanje
              </button>
            </div>

            {error && <div className={classes.errorMessage}>{error}</div>}

            {!login ? (
              <form
                className={classes.registrationForm}
                onSubmit={handleRegistration}
              >
                <input
                  className={classes.input}
                  type='text'
                  name='userName'
                  placeholder='Username'
                  value={formData.userName}
                  onChange={handleInputChange}
                />
                <input
                  className={classes.input}
                  type='email'
                  name='email'
                  placeholder='Email'
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <input
                  className={classes.input}
                  type='password'
                  name='password'
                  placeholder='Lozinka'
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <input
                  className={classes.input}
                  type='password'
                  name='confirmPassword'
                  placeholder='Ponovite Lozinku'
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
                <p className={classes.green}>
                  Napomena: Nova lozinka mora imati minimum 8 karaktera i mora
                  sadržati bar jedno veliko slovo, bar jedno malo slovo, bar
                  jedan broj i bar jedan specijalan karakter.
                </p>
                <div className={classes.checkboxContainer}>
                  <input
                    type='checkbox'
                    id='ageConfirm'
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                  <label htmlFor='ageConfirm'>
                    Potvrđujem da imam više od 18 godina.
                  </label>
                </div>
                <div className={classes.checkboxContainer}>
                  <input
                    type='checkbox'
                    id='terms'
                    checked={checkedTerms}
                    onChange={() => setCheckedTerms(!checkedTerms)}
                  />
                  <label htmlFor='terms'>
                    Prihvatam{' '}
                    <Link to='/opsti_uslovi' target='_blank'>
                      opšte uslove
                    </Link>{' '}
                    i{' '}
                    <Link to='/politika-privatnosti' target='_blank'>
                      politiku privatnosti
                    </Link>
                  </label>
                </div>
                <button
                  className={classes.submitButton}
                  type='submit'
                  disabled={registerMutation.isLoading}
                >
                  Registracija
                </button>
              </form>
            ) : (
              <form className={classes.loginForm} onSubmit={handleLogin}>
                <input
                  className={classes.input}
                  type='email'
                  name='loginEmail'
                  placeholder='Email'
                  value={formData.loginEmail}
                  onChange={handleInputChange}
                />
                <input
                  className={classes.input}
                  type='password'
                  name='loginPassword'
                  placeholder='Lozinka'
                  value={formData.loginPassword}
                  onChange={handleInputChange}
                />
                <div className={classes.forgotPassword}>
                  <p onClick={() => navigate('/zaboravio_sam_lozinku')}>
                    Zaboravio sam lozinku
                  </p>
                </div>
                <button
                  className={classes.submitButton}
                  type='submit'
                  disabled={loginMutation.isLoading}
                >
                  Login
                </button>
              </form>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default LoginModalNovo
