export const menuItems = [
  {
    name: 'Pomoć',
    icon: 'help',
    children: [
      { name: 'Video tutorijali - korisnička podrška', url: '/helpTemp' },
      { name: 'Video tutorijali za sve BET kalkulatore', url: '/helpTemp' },
    ],
  },
  {
    name: 'Betko Family',
    icon: 'betko-family',
    children: [
      { name: 'Šta je betko family ?', url: '/betko_family' },
      { name: 'Šta je Betko plus ?', url: '/betko_plus' },
    ],
  },
  {
    name: 'Betkogram',
    icon: 'betkogram',
    children: [
      { name: 'Glavne kartice', url: '/glavne-kartice' },
      { name: 'Sportske priče', url: '/sportske-price' },
      { name: 'Semafori', url: '/semafori' },
      { name: 'Info blok', url: '/info_blok' },
      { name: 'Bet portal', url: '/bet_portal' },
      { name: 'Statistika', url: '/statistika' },
      { name: 'Vaši predlozi', url: '/vasi_predlozi' },
      { name: 'BET', url: '/bet_portal/653e47fa52a098b10de971f2' },
      { name: 'STAT', url: '/bet_portal/6770743ea1304b4e5cf5784f' },
      { name: 'NET', url: '/bet_portal/65611f28f4ca9761eb5249ad' },
    ],
  },
  {
    name: 'Korisnički paketi',
    icon: 'korisnicki-paketi',
    children: [
      { name: 'BET KALKULATORI', url: '/bet_kalkulatori' },
      { name: 'BET KURS', url: '/bet_kurs' },
      { name: 'PREMIUM', url: '/bet_kurs_novo' },
      { name: 'Šta je Bet kurs ?', url: '/bet_kurs_tutorijal' },

      {
        name: 'Opis BET kalkulatora',
        url: '/bet_kalkulatori_tutorijal',
      },
    ],
  },
  {
    name: 'Kupi paket',
    icon: 'kupi-paket',
    url: '/kupi_paket',
    // children: [
    //   { name: 'Kupi paket', url: '/korisnicki_paketi' },
    //   {
    //     name: 'Kupovina putem uplatnice',
    //     url: '/korisnicki_paketi/65bac125a86ff9a51a6b110f',
    //   },
    // ],
  },
  {
    name: 'Otvori i osvoji',
    icon: 'otvori-i-osvoji',
    url: '/mister_tipster/match',
  },
  {
    name: 'Betko shop',
    icon: 'betko-shop',
    children: [
      { name: 'Betko shop', url: '/betko_shop' },
      { name: 'Shop ', url: '/betko_shop/shop?shopType=shop' },
      { name: 'Licitacije', url: '/betko_shop/shop?shopType=auction' },
      { name: 'Sport Turist', url: '/betko_shop/shop?shopType=sport-tourist' },
    ],
  },
  {
    name: 'Rezultati uživo',
    icon: 'rezultati-uzivo',
    url: '/rezultati_uzivo',
  },
  {
    name: 'Info',
    icon: 'info',
    children: [
      {
        name: 'O nama',
        url: '/o_nama',
      },
      {
        name: 'Kontakt',
        url: '/kontakt',
      },
      {
        name: 'Saradnja',
        url: '/saradnja',
      },
      {
        name: 'Opšti uslovi',
        url: '/korisnicki_paketi/65bac1bba86ff9a51a6b1185',
      },
      {
        name: 'Politika privatnosti',
        url: '/politika-privatnosti',
      },
    ],
  },
]

export const socialMediaData = [
  {
    name: 'facebook',
    url: 'https://www.facebook.com/share/1GuWdyGKQo/',
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/betkotipcom_official?igsh=MWM1aHFyYmdtcm1saQ==',
  },
  {
    name: 'tiktok',
    url: 'https://www.tiktok.com/@betkotipcom2?_t=ZM-8uU1Uh4axDp&_r=1',
  },
  {
    name: 'telegram',
    url: 'https://t.me/+noxLV5bw8eM0MWE0',
  },
  {
    name: 'youtube',
    url: 'https://www.youtube.com/@betkotip-nm4yk/featured',
  },
]
