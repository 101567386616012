import classes from "./betKalkulatori.module.css";
import { useState } from "react";
import { ReactComponent as PlayIcon } from "../assets/icons/play.svg";
import { betkoPopupWithAction } from "../utils/BetkoPopupModal";

const BetKalkulatori = () => {
  const [isListVisible, setIsListVisible] = useState(false);

  const items = [
    {
      title: "Bet Kalkulatori",
      items: [
        { id: "intro", title: "Uvod U Kalkulatore", type: "intro" }
      ]
    },
    {
      title: "Kalkulatori Procene",
      items: [
        { id: "fudbal", title: "Fudbal Sve Igre", type: "soon" },
        { id: "rezultat", title: "Procena Tačnog Rezultata", type: "soon" },
        { id: "live", title: "Live Interaktiv", type: "soon" },
        { id: "kosarka", title: "Košarka", type: "soon" },
        { id: "kosarka-poeni", title: "Košarka Poeni Igrača", type: "soon" },
        { id: "hokej", title: "Hokej", type: "soon" },
        { id: "americki-fudbal", title: "Američki Fudbal", type: "soon" },
        { id: "bejzbol", title: "Bejzbol", type: "soon" },
        { id: "stoni-tenis", title: "Stoni Tenis", type: "soon" },
        { id: "tenis", title: "Tenis", type: "soon" },
        { id: "vaterpolo", title: "Vaterpolo", type: "soon" },
        { id: "rukomet", title: "Rukomet", type: "soon" },
        { id: "badminton", title: "Badminton", type: "soon" },
        { id: "odbojka", title: "Odbojka", type: "soon" },
        { id: "odbojka-plaza", title: "Odbojka Na Pesku", type: "soon" },
        { id: "futsal", title: "Futsal", type: "soon" },
        { id: "boks", title: "Boks", type: "soon" },
        { id: "mma", title: "Mma", type: "soon" },
        { id: "bilijar", title: "Bilijar", type: "soon" },
        { id: "esports", title: "Esports", type: "soon" },
        { id: "pikado", title: "Pikado", type: "soon" }
      ]
    },
    {
      title: "Kalkulatori Statistike",
      items: [
        { id: "projekcija", title: "Projekcija Utakmice", type: "soon" },
        { id: "sudije", title: "Statistika Sudija", type: "soon" }
      ]
    },
    {
      title: "Kalkulatori Kvota",
      items: [
        { id: "nivelacija", title: "Nivelacija Kvota", type: "soon" },
        { id: "tiketi", title: "Procena Vrednosti Tiketa", type: "soon" },
        { id: "odbrana", title: "Odbrana Tiketa", type: "soon" }
      ]
    },
    {
      title: "Kalkulatori Strategije",
      items: [
        { id: "d-alembert", title: "D Alembert", type: "soon" },
        { id: "paroli", title: "Paroli", type: "soon" }
      ]
    },
    {
      title: "Kalkulatori Progresije",
      items: [
        { id: "pozitivna-linija", title: "Pozitivna Linija", type: "soon" },
        { id: "pozitivna-progresija", title: "Pozitivna Progresija", type: "soon" },
        { id: "arbitraza", title: "Arbitražni Kalkulator", type: "soon" },
        { id: "negativna-progresija", title: "Negativna Progresija", type: "soon" }
      ]
    },
    {
      title: "Kalkulatori Sistema",
      items: [
        { id: "isplative-kvote", title: "Isplative Kvote I Broj Kombinacija", type: "soon" },
        { id: "tablica-grupisani", title: "Tablica Grupisanih Betko Sistema", type: "soon" },
        { id: "uslovne-kvote", title: "Tablica Uslovnih Kvota I Broj Kombinacija", type: "soon" },
        { id: "puni-sistemi", title: "Puni Sistemi", type: "soon" },
        { id: "puni-grupisani", title: "Puni Grupisani Betko Sistemi", type: "soon" },
        { id: "sistemi-u-sistemu", title: "Sistemi U Sistemu", type: "soon" },
        { id: "skraceni-sistemi", title: "Skraćeni Sistemi", type: "soon" },
        { id: "skraceni-grupisani", title: "Skraćeni Grupisani Betko Sistemi", type: "soon" },
        { id: "jednotrecinski", title: "Jednotrećinski Betko Sistemi", type: "soon" },
        { id: "procentualni", title: "Procentualni Betko Sistemi", type: "soon" },
        { id: "combo-bombo", title: "Combo Bombo", type: "soon" },
        { id: "zatvaranje", title: "Kombinovani Sistemi", type: "soon" }
      ]
    }
  ];

  const toggleListVisibility = () => {
    setIsListVisible((prev) => !prev);
  };

  const getButtonClass = (type) => {
    return type !== 'intro' ? classes.soonButton : '';
  };

  const handleItemClick = (item) => {
    betkoPopupWithAction(
      "Kalkulator u izradi",
      `${item.title} će uskoro biti dostupan.`,
      null
    );
  };

  return (
    <>
      <div className={classes.toggleButtonContainer}>
        <button onClick={toggleListVisibility} className={classes.toggleButton}>
          {isListVisible ? "Zatvori listu kalkulatora" : "Pogledaj listu kalkulatora"}
        </button>
      </div>
      <div className={`${classes.container} ${isListVisible ? classes.visible : ""}`}>
        <ul>
          {items.map((item, index) => (
            <div key={index}>
              <li className={classes.liTitle}>
                <div className={classes.itemTitle}>{item.title}</div>
              </li>
              {item.items.map((calculatorItem, calculatorIndex) => (
                <li
                  key={calculatorIndex}
                  className={`${classes.liItem} ${calculatorItem.id === 'intro' ? classes.selected : ''}`}
                >
                  <div className={classes.itemTitle}>{calculatorItem.title}</div>
                  {calculatorItem.type !== 'intro' && (
                    <button 
                      className={getButtonClass(calculatorItem.type)}
                      onClick={() => handleItemClick(calculatorItem)}
                    >
                      <PlayIcon className={classes.playIcon} />
                      Uskoro
                    </button>
                  )}
                </li>
              ))}
            </div>
          ))}
        </ul>
      </div>
    </>
  );
};

export default BetKalkulatori;
