const getCountryCode = (countryName) => {
  const countryMap = {
    slovenija: 'SI',
    hrvatska: 'HR',
    'bosna i hercegovina': 'BA',
    'severna makedonija': 'MK',
    'crna gora': 'ME',
    srbija: 'RS',
  }

  return countryMap[countryName?.toLowerCase()] || countryName
}
export default getCountryCode
