import ForumPageTemplate from './ForumPageTemplate'

const NasiPredloziSinglePage = () => (
  <ForumPageTemplate
    title='VAŠI PREDLOZI'
    apiGetPath='yoursuggestions'
    apiPostPath='your-suggestions-post'
    navigationPath='nasi_predlozi'
    commentType='vasi_predlozi'
    chatImagePath='yoursuggestions'
  />
)

export default NasiPredloziSinglePage
