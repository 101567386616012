import React, { useState } from 'react'


import classes from './BetkoFamily.module.css'
import { hr } from 'date-fns/locale'

const BetkoFamily = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleButton = (index) => {
    setIsOpen(isOpen === index ? null : index)
  }
  return (
    <div className={`${classes.content}`}>
      <div className={classes.headerContainer}>
        <h1>ŠTA JE BETKO FAMILY ?</h1>
      </div>
      
      <div className={classes.contentContainer}>
        <button
          onClick={() => toggleButton(1)}
          className={`${classes.dropdownButton} ${isOpen === 1 ? classes.active : ''}`}
        >
          <div className={classes.titleWrapper}>
            <span>Betting (R)evolucija !</span>
            <img src={isOpen === 1 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"} 
                 alt={isOpen === 1 ? "Sakrij" : "Prikaži"} 
                 className={classes.toggleIcon}
            />
          </div>
          {isOpen === 1 && (
            <>
              <hr className={classes.divider}/>
              <div className={classes.textWrapper}>
                <p>
                  Pridruži se našim projektima !
                  Kreiraj budućnost ''Betting-a'' !
                  Postani deo nje !
                </p>
              </div>
            </>
          )}
        </button>
        <button onClick={() => toggleButton(2)}
          className={`${classes.dropdownButton} ${isOpen === 2 ? classes.active : ''}`}
          >
          <div className={classes.titleWrapper}>
            <span>Betko ti nudi, deo Betko familije budi !</span>
            <img src={isOpen === 2 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"} 
                 alt={isOpen === 2 ? "Sakrij" : "Prikaži"} 
                 className={classes.toggleIcon}
              />
          </div>
          {isOpen === 2 && (
            <>
              <hr className={classes.divider}/>
              <div className={classes.textWrapper}>
                <p>Sve što je potrebno jeste da nas kontaktirate ! Imamo projekte spremne za vas !</p>
              </div>
            </>
            
          )}
        </button>
        <button onClick={() => toggleButton(3)} className={`${classes.dropdownButton} ${isOpen === 3 ? classes.active : ''}`}>
          <div className={classes.titleWrapper}>
            <span>Inovantne, Viralne, Interaktivne, Drugačije, Jedinstvene !</span>
            <img src={isOpen === 3 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"} 
                 alt={isOpen === 3 ? "Sakrij" : "Prikaži"} 
                 className={classes.toggleIcon}
              />
          </div>
          {isOpen === 3 && (
            <>
              <hr className={classes.divider}/>
              <div className={classes.textWrapper}>
                <p>...I do sada nigde viđene na tržištu sportskog klađenja !</p>
              </div>
            </>
          )}
        </button>
        <button onClick={() => toggleButton(4)} className={`${classes.dropdownButton} ${isOpen === 4 ? classes.active : ''}`}>
          <div className={classes.titleWrapper}>
            <span>Ukoliko se bavite betting-om...</span>
            <img src={isOpen === 4 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"} 
                 alt={isOpen === 4 ? "Sakrij" : "Prikaži"} 
                 className={classes.toggleIcon}
              />
          </div>
          {isOpen === 4 && (
            <>
              <hr className={classes.divider}/>
              <div className={classes.textWrapper}>
                <p>...U bilo kojoj oblasti delovanja pri istom, sa našim idejnim rešenjima i sa našim projektima, uz Vašu podršku, oni će postati i Vaši !</p>
              </div>
            </>
          )}
        </button>
        <button onClick={() => toggleButton(5)} className={`${classes.dropdownButton} ${isOpen === 5 ? classes.active : ''}`}>
          <div className={classes.titleWrapper}>
            <span>Ukoliko ste ''Igrač''...</span>
            <img src={isOpen === 5 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"} 
                 alt={isOpen === 5 ? "Sakrij" : "Prikaži"} 
                 className={classes.toggleIcon}
              />
          </div>
          {isOpen === 5 && (
            <>
              <hr className={classes.divider}/>
              <div className={classes.textWrapper}>
                <p>...I imate ambiciju da postanete više od toga, sa Betkom se može, sve što se poželi !</p>
              </div>
            </>
          )}
        </button>
        <button onClick={() => toggleButton(6)} className={`${classes.dropdownButton} ${isOpen === 6 ? classes.active : ''}`}>
          <div className={classes.titleWrapper}>
            <span>Ukoliko zastupate ''Sportsku kladionicu''...</span>
            <img src={isOpen === 6 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"} 
                 alt={isOpen === 6 ? "Sakrij" : "Prikaži"} 
                 className={classes.toggleIcon}
              />
          </div>
          {isOpen === 6 && (
            <>
              <hr className={classes.divider}/>
              <div className={classes.textWrapper}>
                <p>...I želite da postanete lider svih kladionica na Balkanu, a vremenom i na celokupnom svetskom tržištu, šta čekate ? Imate li zaista šta da izgubite ?</p>
              </div>
            </>
          )}
        </button>
        <button onClick={() => toggleButton(7)} className={`${classes.dropdownButton} ${isOpen === 7 ? classes.active : ''}`} style={{marginBottom:'16px'}}>
          <div className={classes.titleWrapper}>
            <span>Mi znamo put do velikih stvari ! </span>
            <img src={isOpen === 7 ? "/icons/mister-tipster/minus-icon.svg" : "/icons/mister-tipster/plus-icon.svg"} 
                 alt={isOpen === 7 ? "Sakrij" : "Prikaži"} 
                 className={classes.toggleIcon}
              />
          </div>
          {isOpen === 7 && (
            <>
              <hr className={classes.divider}/>
              <div className={classes.textWrapper}>
                <p>Vaše je samo da nam se javite !</p>
                <p>To možete inicijalno učiniti na chatu ''PITAJ BETKA'' u okviru našeg sajta, ili zvaničnim putem e-maila, na našu e-mail adresu :</p>
                <p>betko.podrska@gmail.com</p>
              </div>
            </>
          )}
        </button>
      
      </div>
      
    </div>
  )
}

export default BetkoFamily
