import React from 'react'
import classes from './loginPageImage.module.css'
import { useNavigate } from 'react-router-dom'

const LoginPageImage = ({ maxHeight = 280, maxWidth = 343 }) => {
  const navigate = useNavigate()

  return (
    <div
      className={classes.loginPageImage}
      style={{ maxWidth: maxWidth, maxHeight: maxHeight }}
    >
      <img
        onClick={() => navigate('/mister_tipster/match')}
        src={maxWidth === 343 ? '/login-image-big-new2.jpg' : ''}
        alt='LoginImage'
      />
    </div>
  )
}
export default LoginPageImage
