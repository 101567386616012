import classes from '../pages/MisterTipsterAwardsNew.module.css'

const sections = [
  { key: 'year', title: 'GODIŠNJA TABELA' },
  { key: 'month', title: 'UKUPNA MESEČNA TABELA' },
  { key: 'rank', title: 'TABELA RANGA' },
]

const medalMapping = {
  firstPlace: 'gold',
  secondPlace: 'silver',
  thirdPlace: 'bronze',
}

const MisterTipsterAwardsArchiveTable = ({ award }) => (
  <div>
    {sections.map(({ key, title }) => {
      const sectionData = award[key]

      if (!sectionData || sectionData.length === 0) return null

      const { period, ...places } = sectionData[0]

      return (
        <>
          <div className={classes.awardsItemHeader}>
            <h4>{title}</h4>
            <p className={classes.period}>{period}</p>
          </div>
          <div key={key} className={classes.awardsItem}>
            <ul>
              {Object.entries(places).map(([place, url], index) => {
                if (!url || place === '_id') return null

                return (
                  <li key={index}>
                    <img
                      src={`/icons/mister-tipster/${
                        medalMapping[place] || 'gold'
                      }-medal.svg`}
                      alt={`${place}-medal`}
                    />
                    <a href={url} target='_blank' rel='noopener noreferrer'>
                      {place === 'firstPlace'
                        ? 'Nagrada za prvo mesto'
                        : place === 'secondPlace'
                        ? 'Nagrada za drugo mesto'
                        : 'Nagrada za treće mesto'}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </>
      )
    })}
  </div>
)

export default MisterTipsterAwardsArchiveTable
