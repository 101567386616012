import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classes from './Sidebar.module.css'
import { menuItems, socialMediaData } from './SidebarData'
import { betkoInfoPopup, betkoPopup } from '../../../utils/notifications'
import { betkoPopupWithAction } from '../../../utils/BetkoPopupModal'
import useChatImage from '../../../hooks/useChatImage'
import { useAuthContext } from '../../../context/AuthProvider'
import { IS_PROD } from '../../../helpers/urls'

const Sidebar = ({ onClose }) => {
  const [activeMenuItem, setActiveMenuItem] = useState(null)
  const navigate = useNavigate()
  const chatImageLink = useChatImage('infoblock')
  const { auth } = useAuthContext()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  const toggleMenu = (menuItem) => {
    setActiveMenuItem((prev) => (prev === menuItem ? null : menuItem))
  }

  const handleNavigation = (url) => {
    // TODO: delete these checks when actual routes are made
    if (url === '/helpTemp') {
      betkoPopup('USKORO!', '', 'NA BETKOTIP.COM')
      return
    } else if (url === '/tipsterTemp') {
      betkoInfoPopup('Uskoro! "Mister Tipster !", "Igra pobednika !"')
      return
    }

    const betkoShopRoutes = [
      '/betko_shop',
      '/betko_shop/shop?shopType=shop',
      '/betko_shop/shop?shopType=auction',
      '/betko_shop/shop?shopType=sport-tourist',
    ]

    if (IS_PROD && betkoShopRoutes.includes(url)) {
      betkoPopup('USKORO!', '', 'NA BETKOTIP.COM')
      return
    }

    navigate(url)
    onClose()
  }

  return (
    <div className={classes.sidebar}>
      <div className={classes.authBtnWrapper}>
        {auth && Object.keys(auth).length < 1 ? (
          <button
            onClick={() => {
              navigate('/registracija_i_logovanje')
              onClose()
            }}
            className={classes.authBtn}
          >
            Prijavi se
          </button>
        ) : (
          <button
            onClick={() => {
              navigate('/moj_nalog')
              onClose()
            }}
            className={classes.authBtn}
          >
            Moj nalog
          </button>
        )}

        <button
          className={classes.addShortcut}
          onClick={(e) =>
            betkoPopupWithAction(
              'Instaliraj Betko prečicu na svoj telefon',
              '',
              () => (
                <div>
                  <p
                    style={{
                      color: '#d6ff00',
                      fontWeight: 'bold',
                      marginTop: '-10px',
                    }}
                  >
                    ANDROID
                  </p>
                  <p>
                    - Uđi na <a href='betkotip.com'>betkotip.com</a> u
                    pretraživaču na svom mobilnom telefonu.
                    <br />
                    - Klikni na tri tačkice u gornjem desnom uglu.
                    <br />
                    - Pronađi opciju "Dodaj na početni ekran".
                    <br />- Postavi Betko ikonicu na svoj mobilni telefon.
                  </p>

                  <p
                    style={{
                      color: '#d6ff00',
                      fontWeight: 'bold',
                      marginTop: '10px',
                    }}
                  >
                    IPHONE
                  </p>
                  <p>
                    Ukoliko koristiš iPhone, da bi postavio Betko ikonicu na
                    svoj početni ekran, potreban ti je “Safari” pretraživač.
                    <br />- Uđi na <a href='betkotip.com'>betkotip.com</a> u
                    pretraživaču na svom mobilnom telefonu.
                    <br />
                    - Klikni na dnu ekrana na navigacioni meni sa strelicom na
                    gore.
                    <br />
                    - Pronađi meni "Add to Home screen".
                    <br />- Postavi Betko ikonicu na svoj mobilni telefon.
                  </p>

                  <p
                    style={{
                      color: '#d6ff00',
                      fontWeight: 'bold',
                      marginTop: '10px',
                    }}
                  >
                    Napomena:
                  </p>
                  <p>
                    Podešavanja zavise od pretraživača, i mogu se od
                    pretraživača do pretraživača razlikovati, ali je proces
                    postavke Betko ikonica na tvoj telefon, veoma lak, brz i
                    jednostavan.
                  </p>
                </div>
              )
            )
          }
        >
          Instaliraj Betko ikonicu na svoj mobilni telefon
        </button>
        <button
          className={classes.askBetko}
          onClick={() => window.open(chatImageLink, '_blank')}
        >
          Pitaj Betka
        </button>
      </div>

      <div className={classes.linksContainer}>
        {menuItems.map((menuItem, index) => (
          <div key={index} className={classes.menuItem}>
            <button
              onClick={() => {
                if (menuItem.children) {
                  toggleMenu(menuItem.name)
                } else {
                  handleNavigation(menuItem.url)
                }
              }}
              className={`${classes.menuButton} ${
                activeMenuItem === menuItem.name ? classes.activeMenuItem : ''
              }`}
            >
              <div className={`imageWrapper ${classes.itemIconWrapper}`}>
                <img
                  src={`/icons/sidebar/${menuItem.icon}.svg`}
                  alt={menuItem.name}
                />
              </div>
              {menuItem.name}
              {menuItem.children && (
                <div className={`imageWrapper ${classes.submenuIconWrapper}`}>
                  <img src='/icons/submenu-active.svg' alt='submenu icon' />
                </div>
              )}
            </button>
            {activeMenuItem === menuItem.name && menuItem.children && (
              <div className={classes.submenu}>
                {menuItem.children.map((child, childIndex) => (
                  <button
                    key={childIndex}
                    onClick={() => handleNavigation(child.url)}
                    className={classes.submenuButton}
                  >
                    {child.name}
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={classes.sidebarFooter}>
        <div className={classes.sidebarSocialMediaBtns}>
          {socialMediaData.map((item, index) => (
            <a target='_blank' key={index} href={item.url}>
              <img
                src={`/icons/social-media/${item.name}.svg`}
                alt={item.name}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
