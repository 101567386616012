import React, { useEffect, useRef, useState } from 'react'
import SinglePost from '../components/SinglePost'
import Spinner from '../components/Spinner'
import Pagination from '../components/Pagination'
import classes from '../pages/cardList.module.css'
import { axiosPrivate } from '../config/axios'
import { useQuery } from '@tanstack/react-query'

const ForumPostList = ({
  postUrl,
  type,
  isInfoWall = false,
  hasPagination = false,
  isSportsStories = false,
}) => {
  const [isMsg, setIsMsg] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const limit = 50
  const containerRef = useRef(null)

  const titleRefs = useRef([])
  const [maxHeight, setMaxHeight] = useState(0)

  const fetchPosts = async ({ queryKey }) => {
    const [, page] = queryKey

    let response
    if (hasPagination) {
      response = await axiosPrivate.get(`${postUrl}/${page}/${limit}`)
    } else {
      response = await axiosPrivate.get(postUrl)
    }

    const { data } = response

    if (data.msg) {
      setIsMsg(true)
    } else {
      setIsMsg(false)
    }

    return {
      posts: data.data || data.result || [],
      totalCount: data.totalCount || 0,
      totalPages: data.totalPages || 0,
    }
  }

  const { data, isLoading } = useQuery({
    queryKey: hasPagination ? [type, currentPage] : [type],
    queryFn: fetchPosts,
    staleTime: 0,
  })

  useEffect(() => {
    if (data?.posts?.length > 0 && titleRefs.current.length > 0) {
      const heights = titleRefs.current.map((ref) => ref?.offsetHeight || 0)
      const newMaxHeight = Math.max(...heights)
      if (newMaxHeight !== maxHeight) {
        setMaxHeight(newMaxHeight)
      }
    }
  }, [data?.posts, maxHeight])

  return (
    <div ref={containerRef} className={classes.container}>
      {isLoading || !data?.posts?.length ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        <>
          {!isMsg ? (
            data.posts.map((post, index) => {
              let postId
              let postType

              if (isInfoWall || isSportsStories) {
                postId = post?.link?.match(
                  /\/([a-f0-9]{24}(?:\?commentId=[a-f0-9]{24})?)$/
                )?.[1] // dynamically extracted id with an optional commentId
                if (index === 0) console.log(post?.link, post?.title)
                postType = post.postType
                  .normalize('NFD') // decompose letters into base letter + diacritics
                  .replace(/[\u0300-\u036f]/g, '') // removes diacritics
              } else {
                postId = post?._id
                postType = type
              }

              return (
                <SinglePost
                  key={post._id}
                  postId={postId}
                  post={post}
                  type={postType}
                  ref={(el) => (titleRefs.current[index] = el)}
                  customTitleHeight={maxHeight}
                  {...(isInfoWall
                    ? { updatedAt: post.updatedAt }
                    : { commentNumber: post.commentsNumber })}
                />
              )
            })
          ) : (
            <div className={classes.msg}>
              <p>{data.msg}</p>
            </div>
          )}

          {hasPagination && (
            <Pagination
              setCurrentPage={setCurrentPage}
              limit={limit}
              currentPage={currentPage}
              totalCount={data?.totalCount || 0}
              totalPages={data?.totalPages || 0}
              buttonLimit={3}
              topRef={containerRef}
            />
          )}
        </>
      )}
    </div>
  )
}

export default ForumPostList
