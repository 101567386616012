import LayoutWithCalcAndSideImgs from '../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'
import ForumPostList from '../components/ForumPostList'

const GlavneKartice = () => {
  return (
    <LayoutWithCalcAndSideImgs title='GLAVNE KARTICE'>
      <ForumPostList
        postUrl={`/get-betko-info-wall`}
        type='glavneKartice'
        isInfoWall={true}
      />
    </LayoutWithCalcAndSideImgs>
  )
}

export default GlavneKartice
