import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import classes from './News.module.css'
import { useNavigate } from 'react-router-dom'
import { betkoInfoPopup } from '../utils/notifications'

const News = () => {
  const newsContentRef = useRef(null)
  const [allNews, setAllNews] = useState(null)
  const navigate = useNavigate()
  const SERVER_URL = process.env.REACT_APP_SERVER_URL

  const handleHelpButton = () => {
    betkoInfoPopup('USKORO NA BETKOTIP.COM')
  }

  useEffect(() => {
    const getAllNews = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/get-all-news`)
        setAllNews(response.data)
      } catch (err) {
        console.error('Error fetching news:', err)
      }
    }

    getAllNews()
  }, [])

  // Duplicate content to avoid animation gap
  useEffect(() => {
    const newsContent = newsContentRef.current

    const duplicateContent = () => {
      if (!newsContent.dataset.duplicated) {
        const originalContent = newsContent.innerHTML
        newsContent.innerHTML += originalContent
        newsContent.dataset.duplicated = 'true'
      }
    }

    const setAnimationDuration = () => {
      const contentWidth = newsContent.scrollWidth
      const speed = 100 // pixels per second
      const duration = contentWidth / speed

      newsContent.style.animationDuration = `${duration}s`
    }

    if (allNews?.allNews.length > 0) {
      duplicateContent()
      setAnimationDuration()
    }
  }, [JSON.stringify(allNews?.allNews)])
  // calc(100vw - (100vw - 1200px) / 2)

  return (
    <div className={classes.newsOuterWrapper}>
      <div className={classes.newsContainer}>
        <div className={classes.newsBtnWrapper}>
          <button 
            onClick={() => handleHelpButton()}
          >
            <img src='/icons/question-mark.svg' alt="?" /> Pomoć
          </button>
          <span className={classes.seperatorLine} />
        </div>
        <div style={{ display: 'flex' }}>
          <div className={classes.newsContent} ref={newsContentRef}>
            {allNews?.allNews?.map((newsItem) => (
              <div key={newsItem._id} className={classes.newsItem}>
                <span>{newsItem.content}</span>
                <div className={classes.redDotWrapper}>
                  <img src='/icons/red-dot.svg' alt="dot" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default News
