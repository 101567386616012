import React, { useContext, useEffect, useState } from 'react'
import classes from './RangTabela.module.css'
import axios from 'axios'
import AuthContext from '../context/AuthProvider'
import MisterTipsterDefaultTable from '../components/MisterTipsterDefaultTable'
import dayjs from 'dayjs'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const RangTabela = () => {
  const [rankTableData, setRankTableDate] = useState([[], []])
  const [searchTerm, setSearchTerm] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { auth } = useContext(AuthContext)
  const searchParams = new URLSearchParams(window.location.search)
  const page = searchParams.get('page')

  const period = searchParams.get('period')
  const periodParts = period ? period.split('-').map((part) => part.trim()) : []

  const startDate =
    periodParts.length === 6
      ? dayjs(
          `${periodParts[2]}-${periodParts[1]}-${periodParts[0]}`,
          'YYYY-MM-DD'
        ).format('YYYY-MM-DD')
      : null

  const endDate =
    periodParts.length === 6
      ? dayjs(
          `${periodParts[5]}-${periodParts[4]}-${periodParts[3]}`,
          'YYYY-MM-DD'
        ).format('YYYY-MM-DD')
      : null

  useEffect(() => {
    const fetchTableData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${SERVER_URL}/user/get-ten-day-table/${
            auth?._id || 'noId'
          }/${page}/${startDate}/${endDate}/${searchTerm || null}`
        )
        setRankTableDate(response?.data)
      } catch (error) {
        setRankTableDate([[], []])
        console.log(error)
      }
      setIsLoading(false)
    }

    console.log(startDate, endDate)
    if (page && startDate) {
      fetchTableData()
    }
  }, [startDate, endDate, period, page, searchTerm])

  return (
    <div className={classes.rangTableContainer}>
      <MisterTipsterDefaultTable
        data={rankTableData}
        period='rang'
        searchTerm={searchTerm}
        isLoading={isLoading}
        setSearchTerm={setSearchTerm}
      />
    </div>
  )
}

export default RangTabela
