import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactPlayer from 'react-player'
import classes from './forumNavigation.module.css'
import axios from '../config/axios'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const ForumNavigation = ({
  data,
  chatImage,
  auth,
  commentCounter,
  apiPath,
  navigationPath,
  scrollToComments,
  isVisible,
}) => {
  const navigate = useNavigate()

  const [previousPost, setPreviousPost] = useState({})
  const [nextPost, setNextPost] = useState({})
  const [threeBefore, setThreeBefore] = useState({})
  const [threeNext, setThreeNext] = useState({})

  const [commentNotification, setCommentNotification] = useState(false)

  const emailCheckedForPost =
    auth?.emailNotificationPosts &&
    auth?.emailNotificationPosts.includes(data._id)

  useEffect(() => {
    setCommentNotification(auth?.commentNotification)
  }, [auth?.commentNotification])

  const fetchPreviousCard = useCallback(async () => {
    if (!data._id) return
    try {
      const previousPost = await axios.get(
        `/${apiPath}-index?id=${data._id}&&index=-1`
      )
      setPreviousPost(previousPost.data)
    } catch (error) {
      console.error(error)
    }
  }, [data._id, apiPath])

  const fetchNextCard = useCallback(async () => {
    if (!data._id) return
    try {
      const nextCard = await axios.get(
        `/${apiPath}-index?id=${data._id}&&index=+1`
      )
      setNextPost(nextCard.data)
    } catch (error) {
      console.error(error)
    }
  }, [data._id, apiPath])

  const fetchThreeNextCard = useCallback(async () => {
    if (!data._id) return
    try {
      const threenextCard = await axios.get(
        `/${apiPath}-index?id=${data._id}&&index=+3`
      )
      setThreeNext(threenextCard.data)
    } catch (error) {
      console.error(error)
    }
  }, [data._id, apiPath])

  const fetchThreeBeforeCard = useCallback(async () => {
    if (!data._id) return
    try {
      const threebeforecard = await axios.get(
        `/${apiPath}-index?id=${data._id}&&index=-3`
      )
      setThreeBefore(threebeforecard.data)
    } catch (error) {
      console.error(error)
    }
  }, [data._id, apiPath])

  useEffect(() => {
    fetchPreviousCard()
    fetchNextCard()
    fetchThreeNextCard()
    fetchThreeBeforeCard()
  }, [
    fetchPreviousCard,
    fetchNextCard,
    fetchThreeNextCard,
    fetchThreeBeforeCard,
  ])

  const handleEmailNotification = async (e) => {
    const { checked } = e.target

    const payload = {
      emailNotification: checked,
      postId: data._id,
    }
    try {
      await axios
        .post(
          `${SERVER_URL}/user/setEmailNotification/${auth.email}`,
          payload,
          { withCredentials: false }
        )
        .then(() => {
          window.location.reload()
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleCommentEmailNotification = async (e) => {
    const { checked } = e.target
    setCommentNotification(checked)

    const payload = {
      commentNotification: checked,
    }
    try {
      await axios
        .post(
          `${SERVER_URL}/user/setCommentNotification/${auth.email}`,
          payload,
          { withCredentials: false }
        )
        .then(() => {
          window.location.reload()
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className={classes.optionsAndMarketing}>
      <h2 className={classes.postTitle}>{data.title?.toUpperCase()}</h2>
      <div className={classes.marketingMediaWrapper}>
        {data.featureVideo?.url ? (
          <div className={classes.featureVideoDiv}>
            <ReactPlayer
              url={`${SERVER_URL}/${data.featureVideo.url}`}
              playing
              muted
              loop
              width='337px'
              height='267px'
            />
          </div>
        ) : (
          <img
            className={classes.featureImage}
            src={`${SERVER_URL}/${data.featureImage?.url}`}
            alt='featureImage'
          />
        )}

        {data.marketingVideo?.url ? (
          <div className={classes.featureVideoDiv}>
            <a
              className={classes.marketingImageATag}
              href={data.marketingLink}
              target='_blank'
              rel='noreferrer'
            >
              <ReactPlayer
                url={`${SERVER_URL}/${data.marketingVideo.url}`}
                playing
                muted
                loop
                width='337px'
                height='267px'
              />
            </a>
          </div>
        ) : (
          <a
            className={classes.marketingImageATag}
            href={data.marketingLink}
            target='_blank'
            rel='noreferrer'
          >
            <img
              className={classes.marketingImage}
              src={`${SERVER_URL}/${data.marketingImage?.url}`}
              alt='marketingImage'
            />
          </a>
        )}

        <a
          className={classes.marketingImageATag}
          href={chatImage?.link}
          target='_blank'
          rel='noreferrer'
        >
          <img
            className={classes.marketingImage}
            src={`${SERVER_URL}/${chatImage?.image?.url}`}
            alt='marketingImage'
          />
        </a>
      </div>

      <div className={classes.postOptions}>
        <button
          onClick={() => navigate(`/${navigationPath}/${previousPost._id}`)}
        >
          <img src='/icons/arrow-up-single-post.svg' alt='prethodna' />
          <span>Prošla kartica</span>
        </button>
        <button onClick={() => navigate(`/${navigationPath}/${threeNext._id}`)}>
          <img src='/icons/arrow-up-single-post.svg' alt='gore' />
          <span>Kartica gore</span>
        </button>
        <button onClick={() => navigate('/vasi_predlozi')}>
          <img src='/icons/lightning.svg' alt='lightning' />
          <span>Tipuj</span>
        </button>
        <button
          onClick={() => navigate(`/${navigationPath}/${threeBefore._id}`)}
        >
          <img src='/icons/arrow-up-single-post.svg' alt='dole' />
          <span>Kartica dole</span>
        </button>
        <button onClick={() => navigate(`/${navigationPath}/${nextPost._id}`)}>
          <img src='/icons/arrow-up-single-post.svg' alt='sledeca' />
          <span>Sledeća kartica</span>
        </button>
      </div>

      {auth && commentCounter && (
        <div>
          <div
            className={classes.fixedCommentButtonContainer}
            style={{
              top: isVisible ? '0' : '60px',
            }}
          >
            <button
              onClick={scrollToComments}
              className={classes.leaveCommentButton}
            >
              <img
                src='/icons/comments/commentsCloudMessage.svg'
                alt='ostavite komentar'
              />
              Ostavite komentar
            </button>
          </div>

          <div className={classes.commentCounterContainer}>
            <div className={classes.commentCounter}>
              <span className={classes.counterLabel}>Broj komentara: </span>
              <span>{commentCounter.totalComments}</span>
            </div>
            <div className={classes.commentCounter}>
              <span className={classes.counterLabel}>Broj odgovora: </span>
              <span>{commentCounter.answersLength}</span>
            </div>
          </div>

          <div className={classes.leaveCommentContainer}>
            <p>Ostavite komentar na ovu temu</p>
            <button
              onClick={scrollToComments}
              className={classes.leaveCommentButton}
            >
              <img
                src='/icons/comments/commentsCloudMessage.svg'
                alt='ostavite komentar'
              />
              Ostavite komentar
            </button>
          </div>

          <div className={classes.emailNotificationsContainer}>
            <div className={classes.emailNotifications}>
              <input
                disabled={!auth || Object.keys(auth).length < 1}
                type='checkbox'
                checked={emailCheckedForPost}
                name='obavestenja'
                id='obavestenja'
                defaultChecked
                onChange={handleEmailNotification}
              />
              <label htmlFor='emailNotifications'>
                Želim da primam email obaveštenja za sve promene na ovoj
                stranici
              </label>
            </div>
            <div className={classes.emailNotifications}>
              <input
                disabled={!auth || Object.keys(auth).length < 1}
                type='checkbox'
                checked={commentNotification}
                name='commentObavestenje'
                id='commentObavestenje'
                onChange={handleCommentEmailNotification}
              />
              <label htmlFor='commentNotifications'>
                Želim da primam obaveštenja o odgovorima na komentare putem
                emaila
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ForumNavigation
