import React, { useContext, useEffect, useState } from 'react'
import { Table, Button, Modal, Input, message } from 'antd'
import classes from './ShopAdminSellers.module.css'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { FaCheck } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import AuthContext from '../context/AuthProvider'
import { useNavigate } from 'react-router-dom'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const ShopAdminSellers = () => {
  const [isDeleteSellerModalVisible, setIsDeleteSellerModalVisible] =
    useState(false)
  const [selectedSeller, setSelectedSeller] = useState(null)
  const [searchTerm, setSearchTerm] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [sellers, setSellers] = useState([])
  const axiosPrivate = useAxiosPrivate()
  const [showApproved, setShowApproved] = useState(true)
  const [refetchTrigger, setRefetchTrigger] = useState(null)

  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false)
  const { auth } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (!auth.roles.includes(5151)) {
      navigate('/betko-shop-admin/home')
    }
  }, [])

  const handleDeleteSeller = async () => {
    try {
      await axiosPrivate.put(
        `${SERVER_URL}/user/remove-user-access/${selectedSeller?._id}`
      )
      message.success(
        `Uspešno ste uklonili prodavca ${selectedSeller?.companyName}!`
      )
      setSelectedSeller(null)
      setRefetchTrigger((curr) => !curr)
      setIsDeleteSellerModalVisible(false)
    } catch (err) {
      message.error(
        err.response.data.message ||
          'Došlo je do greške prilikom uklanjanja prodavca.'
      )
    }
  }

  const handleApproveSeller = async () => {
    try {
      await axiosPrivate.put(
        `${SERVER_URL}/user/approve-user/${selectedSeller?._id}`
      )
      message.success(
        `Uspešno ste odobrili prodavca ${selectedSeller?.companyName}!`
      )
      setSelectedSeller(null)
      setRefetchTrigger((curr) => !curr)
      setIsApproveModalVisible(false)
    } catch (err) {
      message.error(
        err?.response?.data?.message ||
          'Došlo je do greške prilikom dodavanja prodavca.'
      )
    }
  }

  useEffect(() => {
    const getAllSellers = async () => {
      try {
        const response = await axiosPrivate.get(
          `${SERVER_URL}/user/list-user-for-shop/${currentPage}/10/${
            searchTerm || null
          }/${!showApproved ? 'requested' : null}`
        )

        setSellers(response.data)
      } catch (err) {
        setSellers([])
      }
    }
    getAllSellers()
  }, [searchTerm, currentPage, showApproved, refetchTrigger])

  const columns = [
    {
      title: 'Korisničko ime',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Naziv kompanije',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Adresa',
      dataIndex: 'companyAddress',
      key: 'companyAddress',
    },
    {
      title: 'Mesto',
      dataIndex: 'companyPlace',
      key: 'companyPlace',
    },
    {
      title: 'Matični broj',
      dataIndex: 'companyMIB',
      key: 'companyMIB',
    },
    {
      title: 'PIB',
      dataIndex: 'companyPIB',
      key: 'companyPIB',
    },
    {
      title: 'Akcije',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button
            onClick={() => {
              setIsDeleteSellerModalVisible(true)
              setSelectedSeller(record)
            }}
            icon={<MdDelete />}
            title='Ukloni prodavca'
          />
          {!showApproved && (
            <Button
              icon={<FaCheck />}
              onClick={() => {
                setIsApproveModalVisible(true)
                setSelectedSeller(record)
              }}
              style={{ marginLeft: 10 }}
              title='Odobri Zahtev'
            />
          )}
        </>
      ),
    },
  ]

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <h2>PRODAVCI</h2>
        <Input
          type='text'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder='Pretraži'
          className={classes.searchTermInput}
        />
        <Button
          type='primary'
          onClick={() => setShowApproved((curr) => !curr)}
          className={classes.addButton}
        >
          Prikaži {showApproved ? 'zahteve' : 'odobrene'}
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={sellers?.paginatedData}
        className={classes.sellersTable}
        pagination={{
          current: currentPage,
          pageSize: 10,
          total: sellers?.totalItems,
          onChange: (page) => setCurrentPage(page),
          showSizeChanger: false,
        }}
      />
      <Modal
        title='UKLONI PRODAVCA'
        open={isDeleteSellerModalVisible}
        onOk={handleDeleteSeller}
        onCancel={() => {
          setSelectedSeller(null)
          setIsDeleteSellerModalVisible(false)
        }}
        style={{ color: 'white' }}
      >
        <p>
          Da li ste sigurni da želite da UKLONITE prodavca{' '}
          {selectedSeller?.userName} ?
        </p>
      </Modal>
      <Modal
        title='Odobri zahtev'
        open={isApproveModalVisible}
        onOk={handleApproveSeller}
        onCancel={() => {
          setIsApproveModalVisible(false)
          setSelectedSeller(null)
        }}
      >
        <p>
          Da li ste sigurni da odobrite zahtev prodavca{' '}
          {selectedSeller?.companyName} ?
        </p>
      </Modal>
    </div>
  )
}

export default ShopAdminSellers
