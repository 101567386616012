import React from 'react'
import classes from './CardPlaceHolder.module.css'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CardPlaceHolder = ({ isShopPage, hideTimer}) => {
  const [timeLeft, setTimeLeft] = useState(14 * 24 * 60 * 60); 
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(window.location.search);
  const shopType = queryParams.get('shopType');

  const shouldHideTimer = shopType === 'shop' || shopType === 'sport-turist';

  useEffect(() => {
    if(hideTimer || shouldHideTimer) return;

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer); 
  }, [hideTimer, shouldHideTimer]);

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    

    return `${days}:${hours}:${minutes}`;
  };

  

  return (
    <div
      className={`${classes.cardPlaceholder} ${
        isShopPage ? classes.shopPlaceholder : classes.homePagePlaceholder
      }`}
      onClick={() => {
        if (shopType)
        {
          if (shopType === 'shop'){
            navigate(`/betko_shop/uslovi?menu=shop&title=uslovi-za-ponudjace`)
          }
          if(shopType === 'auction'){
            navigate(`/betko_shop/uslovi?menu=licitacije&title=uslovi-za-ponudjace`)
          }
          if(shopType === 'sport-tourist'){
            navigate(`/betko_shop/uslovi?menu=sport-turist&title=uslovi-za-ponudjace`)
          }
        }
      }}
    >
      {!shouldHideTimer && !hideTimer  && (
      <div className={classes.timer}>
        <img src="/icons/shop/timer.svg" alt="timer" />
        {formatTime(timeLeft)}</div>
        )}
      <div className={classes.imageWrapper}>
        <img src={'/football-image.png'} alt='product placeholder' />
      </div>
      <p className={classes.cardTitle}>Ovo je mesto za tvoju ponudu</p>
      
      <button className={classes.learnMore} >Saznaj više</button>
    </div>
  )
}

export default CardPlaceHolder
