const urlToATag = (text) => {
  // matches url in strings, ends at whitespace
  const urlRegex = /(https?:\/\/[^\s]+)/g
  return text.replace(
    urlRegex,
    (url) =>
      `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
  )
}

export const renderTextWithLinksAndHtml = (comment) => {
  // matches html tags
  const parts = comment.split(/(<[^>]+>)/g)

  const processedParts = parts.map((part) => {
    if (part.startsWith('<')) {
      return part
    } else {
      return urlToATag(part.replace(/\n/g, ' <br /> '))
    }
  })

  return { __html: processedParts.join('') }
}
