import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../config/axios'
import classes from './home.module.css'
import ReactPlayer from 'react-player'
import { SwiperSlide, Swiper } from 'swiper/react'
import AuthContext from '../context/AuthProvider'
import Comments from '../components/Comments'
import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css/pagination'
import RecommendedPosts from '../components/Redesign/RecommendedPosts/RecommendedPosts'
import SideImages from '../components/Redesign/SideImages/SideImages'
import { getLinkTarget } from '../utils/adHelper'
import SideImagesWide from '../components/SideImagesWide'
import { useHomeRef } from '../context/RefContext'
const SERVER_URL = process.env.REACT_APP_SERVER_URL
const Home = () => {
  const navigate = useNavigate()

  const [heroAds, setHeroAds] = useState([])
  const [mobileHeroAds, setMobileHeroAds] = useState([])

  const [currentAds, setCurrentAds] = useState(heroAds)

  const { auth } = useContext(AuthContext)
  const { homeRef } = useHomeRef()

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const { data: allHeroAds } = await axios.get(
          `${SERVER_URL}/get-all-shown-adds`,
          {
            params: { section: 'hero' },
          }
        )

        const hero = allHeroAds?.filter((ad) => !ad.forMobile) || []
        const mobile = allHeroAds?.filter((ad) => ad.forMobile) || []

        setMobileHeroAds(mobile)
        setHeroAds(hero)
      } catch (error) {
        console.error('Fetch error:', error)
      }
    }

    fetchAds()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCurrentAds(mobileHeroAds)
      } else {
        setCurrentAds(heroAds)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [heroAds, mobileHeroAds])

  const screenWidth = window.innerWidth
  const isMobile = screenWidth <= 768
  // const marginValue = screenWidth < 500 ? '1rem' : 'auto'

  const [swiperInstance, setSwiperInstance] = useState(null)
  const [direction, setDirection] = useState(1)
  const intervalTime = 10000

  useEffect(() => {
    if (!swiperInstance) return

    const moveSlide = () => {
      const { activeIndex, slides } = swiperInstance

      if (activeIndex === slides.length - 1) {
        setDirection(-1)
        swiperInstance.slideTo(activeIndex - 1)
      } else if (activeIndex === 0) {
        setDirection(1)
        swiperInstance.slideTo(activeIndex + 1)
      } else {
        swiperInstance.slideTo(activeIndex + direction)
      }
    }

    const interval = setInterval(moveSlide, intervalTime)
    return () => clearInterval(interval)
  }, [swiperInstance, direction])

  const handleSlideChange = (swiper) => {
    if (swiper.activeIndex > swiper.previousIndex) {
      setDirection(1)
    } else {
      setDirection(-1)
    }
  }

  return (
    <div className={classes.pageContainer}>
      <div className={classes.sideImagesContainer}>
        <SideImages />
      </div>
      <div ref={homeRef} className={classes.homePageContainer}>
        <Swiper
          loop={false}
          slidesPerView={1}
          onSwiper={setSwiperInstance}
          onSlideChange={handleSlideChange}
          modules={[Autoplay, Pagination]}
          style={{
            '--swiper-pagination-color': 'white',
            '--swiper-pagination-bullet-inactive-color': '#5C5C5C',
            '--swiper-pagination-bullet-inactive-opacity': '1',
            '--swiper-pagination-bullet-size': '8px',
            '--swiper-pagination-bullet-horizontal-gap': '6px',
            '--swiper-pagination-bottom': 'auto',
            '--swiper-pagination-top': '8px',
            borderRadius: '12px',
            marginTop: '24px',
            border: '2px solid #353945',
            ...(isMobile && {
              marginTop: '0px',
              marginBottom: '-5px',
              // marginLeft: marginValue,
              // marginRight: marginValue,
              maxWidth: '500px',
              border: 'none',
            }),
          }}
          pagination={{
            clickable: true,
            type: 'bullets',
          }}
          className='custom-swiper'
        >
          {currentAds.map((ad, index) => (
            <SwiperSlide
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // height: '300px',
                width: '100%',
              }}
              onClick={() => {
                window.open(ad.link, getLinkTarget(ad.link))
              }}
            >
              {ad.localPath && ad.type === 'video' ? (
                <ReactPlayer
                  url={`${SERVER_URL}/${ad.localPath}`}
                  playing={true}
                  muted={true}
                  loop
                  onContextMenu={(event) => event.preventDefault()}
                  style={{
                    objectFit: 'fill',
                    cursor: 'pointer',
                  }}
                  width={'100%'}
                  height={'300px'}
                />
              ) : (
                <img
                  src={`${SERVER_URL}/${ad.localPath}`}
                  alt={ad.altTitle || 'Hero Ad'}
                  style={{
                    width: '100%',
                    height: '300px',
                    objectFit: 'fill',
                    cursor: 'pointer',
                  }}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>

        <div className={classes.promoContainer}>
          <button onClick={() => navigate('/promo')}>
            <span>TOP TIP</span>
            <div className={classes.promoHeader}>AKTUELNO</div>
          </button>
        </div>

        <RecommendedPosts />
        <div className={classes.containerInner}>
          <div className={classes.commentsAndCalculatorWrapper}>
            <div className={classes.commentsand}>
              <div className={classes.desktopShortcutBtns}>
                <button onClick={() => navigate('/vasi_predlozi')}>
                  Vaši predlozi
                </button>
                <button
                  onClick={() =>
                    navigate('/nasi_predlozi/655a9ed4e1a1ab2a0a7a0bde')
                  }
                >
                  Tiket mania
                </button>

                <button
                  onClick={() =>
                    navigate('/bet_portal/653e3c6e52a098b10de970f1')
                  }
                >
                  Uz pivce
                </button>
              </div>
              <Comments
                commentType={'bet_kurs_novo'}
                adminComment={false}
                user={auth}
                title={'Comments'}
                id={''}
                isHomePage={true}
              />
            </div>
            {/* <CalculatorImage /> */}
            <div className={classes.adsContainer}>
              <SideImagesWide side='comments' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
