import React, { useEffect, useState } from 'react'
import classes from './MisterTipsterAwardsNew.module.css'
import axios from '../config/axios'
import { useNavigate } from 'react-router-dom'
import SideImages from '../components/Redesign/SideImages/SideImages'
import MisterTipsterAwardsArchiveTable from '../components/MisterTipsterAwardsArchiveTable'
import PaginationAlt from './../components/PaginationAlt'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const MisterTipsterAwardsNew = () => {
  const [awards, setAwards] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentAward, setCurrentAward] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    const getAwards = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/get-all-awards`)

        setAwards(response?.data)
        setCurrentAward(response?.data ? response.data[currentPage - 1] : null)
      } catch (err) {
        console.log(err)
      }
    }

    getAwards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCurrentAward(awards ? awards[currentPage - 1] : null)
  }, [currentPage, awards])

  return (
    <div className={classes.mainContainer} id='tipsterAwards'>
      <SideImages />
      <div className={classes.container}>
        <div className={classes.pageHeader}>
          <h1>
            <button
              className={classes.backButtonDesktop}
              onClick={() => navigate(-1)}
            >
              <img src='/icons/arrow-left.svg' alt='backButton' />
              Nazad
            </button>
            FOND TAKMIČARSKIH IGARA
            <button
              onClick={() => navigate('/mister_tipster/spisak_dobitnika')}
              className={classes.listOfWinnersButtonDesktop}
            >
              Spisak dobitnika
            </button>
          </h1>
          <button
            className={classes.backButtonMobile}
            onClick={() => navigate(-1)}
          >
            <img src='/icons/arrow-left.svg' alt='back button' />
            Nazad
          </button>
          <button
            className={classes.listOfWinnersButtonMobile}
            onClick={() => navigate('/mister_tipster/spisak_dobitnika')}
          >
            Spisak dobitnika
          </button>
        </div>

        <div className={classes.awards}>
          {currentAward ? (
            <>
              <MisterTipsterAwardsArchiveTable award={currentAward} />

              <div className={classes.pagination}>
                <PaginationAlt
                  currentPage={currentPage}
                  totalPages={awards ? awards.length : 1}
                  onPageChange={(number) =>
                    setCurrentPage((prev) => prev + number)
                  }
                />
              </div>
            </>
          ) : (
            <div className={classes.noAwards}>NEMA NAGRADA ZA PRIKAZ</div>
          )}
        </div>
      </div>
    </div>
  )
}
export default MisterTipsterAwardsNew
