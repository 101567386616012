import React, { useEffect, useRef, useState } from 'react'
import classes from './SingleComment.module.css'
import { SERVER_URL } from '../../../helpers/urls'
import { useLocation, useNavigate } from 'react-router-dom'
import { dateWithHours } from '../../../utils/DateWithHours'
import { renderTextWithLinksAndHtml } from '../../../utils/renderTextWithLinksAndHtml'
import { RxTriangleUp } from 'react-icons/rx'
import { betkoPopupWithAction } from '../../../utils/BetkoPopupModal'
import RatingPopup from '../../RatingPopup'
import { betkoInfoPopup } from '../../../utils/notifications'
import TextToLink from '../../../utils/TextToLink'

const SingleComment = ({
  item,
  isHomePage,
  handleGiftPress,
  commentIndex,
  editComment,
  setEditComment,
  editCommentValue,
  setEditCommentValue,
  handleSaveComment,
  handleDeleteComment,
  showComments,
  handleShowComments,
  handleReactToComment,
  user,
  handleAddComment,
  comment,
  setComment,
  handleAnswerOnComment,
  answersParentComment,
  setAnswersParentComment,
  answersPage,
  setAnswersPage,
  handleRateComment,
  changeRatingColor,
  changeRatingBackGroundColor,
  alreadyRatedComment,
  activeRateColor,
  currentPage,
  setIsLoginModalOpen,
  handleReportCommentPress,
  handleBlockUserPress,
}) => {
  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false)
  const [averageRating, setAverageRating] = useState(0)
  const menuRef = useRef(null)
  const commentRef = useRef()
  const commentEditRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const isHiddenComment = location.pathname === '/rezultati_uzivo'
  const [highlight, setHighlight] = useState(false)

  const commentDate = item.editDate ? item.editDate : item.createdAt
  const cuttedInnerHtml = item?.comment.substring(0, 450)
  const innerHtml = item?.comment
  const commentLikes = +item.likes.length + +item.adminLikes
  const commentDislikes = +item.dislikes.length + +item.adminDislikes
  const alreadyLiked = !!item.likes.includes(user?._id)
  const alreadyDisliked = !!item.dislikes.includes(user?._id)

  const calculateAverageRating = (ratings) => {
    if (!ratings?.length) return 0 // Avoid division by zero
    return (
      ratings.reduce((acc, ratingObj) => acc + ratingObj?.rating, 0) /
      ratings.length
    )
  }

  const handleRatingClick = async (num, item) => {
    const success = await handleRateComment({ number: num, comment: item })

    if (success) {
      setAverageRating(calculateAverageRating)
      const updatedRatings = [...item.ratings, { rating: num }]
      setAverageRating(calculateAverageRating(updatedRatings).toFixed(2))
    }
  }

  const toggleMenu = (e) => {
    e.stopPropagation()
    setIsOptionsMenuOpen((prev) => !prev)
  }

  useEffect(() => {
    if (highlight) {
      commentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [highlight])

  useEffect(() => {
    if (item) {
      setAverageRating(calculateAverageRating(item.ratings))
    }
  }, [item])

  useEffect(() => {
    if (editComment && commentEditRef.current) {
      commentEditRef.current.focus()
    }
  }, [editComment])

  const handleCancelEdit = () => {
    setEditComment(false)
    setEditCommentValue(item)
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('commentId') === String(item._id)) {
      setHighlight(true)
    }
  }, [item._id])

  const copyLink = () => {
    // could be a temp fix
    let type = item.type
    if (type === 'vasi_predlozi') type = 'nasi_predlozi'

    const url = `${window.location.origin}/${type}/${item.post}?commentId=${item._id}`
    navigator.clipboard.writeText(url)
    betkoInfoPopup('Link komentara kopiran!')
  }

  const handleDeleteCommentPress = (comment) => {
    betkoPopupWithAction(
      'Brisanje komentara',
      'Jesi li siguran da želiš obrisati ovaj komentar?',
      (closePopup) => (
        <div className={classes.commentEditSaveButtonWrapper}>
          <button
            onClick={() => {
              handleDeleteComment(comment)
              closePopup()
            }}
          >
            Obriši
          </button>
          <button onClick={closePopup}>Odustani</button>
        </div>
      )
    )
  }

  const handleRateCommentPress = (comment) => {
    betkoPopupWithAction(
      'Oceni komentar',
      'Koliko ste zadovoljni sa ovom analizom?',
      (closePopup) => (
        <RatingPopup
          closePopup={closePopup}
          comment={comment}
          handleRatingClick={handleRatingClick}
        />
      )
    )
  }

  const answersShown = answersParentComment?.parent === item._id

  const hideSpans = location.pathname === '/rezultati_uzivo'
  return (
    <div
      id={`comment-${item._id}`}
      className={`${classes.singleComment} ${
        answersShown ? classes.withAnswersShown : ''
      } ${highlight ? classes.highlighted : ''}`}
      ref={commentRef}
    >
      <div className={classes.singleCommentInner}>
        {isHomePage && currentPage === 1 && item.isPinned && (
          <div
            className={`${classes.adminCommentMessage} ${classes.pinnedAdminComment}`}
            onClick={() => navigate(`/bet_portal/653e47fa52a098b10de971f2`)}
          >
            <div className='imageWrapper'>
              <img
                src='/icons/comments/commentsAdminMessage.svg'
                alt='Poruka admina'
              />
            </div>
            <div className={classes.adminCommentMessageContent}>
              <span className={classes.adminCommentTitle}>PORUKA OD BETKA</span>
              <span className={classes.adminCommentText}>
                {item?.adminMessage}
              </span>
            </div>
          </div>
        )}
        {!isHiddenComment && !item.isPinned && isHomePage && (
          <div className={classes.commentLinkToPost}>
            <span className={classes.commentType}>
              {item?.type?.toUpperCase().replace(/_/g, ' ')} - {item?.postTitle}
            </span>
            <button onClick={() => navigate(`/${item?.linkToPost}`)}>
              <span>Postavite komentar</span>
              <img
                src='/icons/comments/commentsArrowUp.svg'
                alt='ostavi komentar'
              />
            </button>
          </div>
        )}
        {item.adminComment && !item.isPinned && (
          <div className={classes.adminCommentMessage}>
            <div className='imageWrapper'>
              <img
                src='/icons/comments/commentsAdminMessage.svg'
                alt='Poruka admina'
              />
            </div>
            <div className={classes.adminCommentMessageContent}>
              <span className={classes.adminCommentTitle}>PORUKA OD BETKA</span>
              <span className={classes.adminCommentText}>
                {/* {'JOS MALO I PRELAZIS IGRICU SAMO TAKO NASTAVI!'} */}
                {item.adminComment}
              </span>
            </div>
          </div>
        )}
        <div className={classes.commentHeader}>
          <div className={classes.logoWrapper}>
            {item?.owner?.team && item?.owner?.team?.teamImage ? (
              <img
                src={`${SERVER_URL}/${item?.owner?.team?.teamImage?.url}`}
                alt='Dres Tima'
              />
            ) : (
              <img src='../teamPlaceholder.png' alt='Dres Tima' />
            )}
          </div>
          <div className={classes.userInfo}>
            <span className={classes.userName}>{item?.owner?.userName}</span>
            <span className={classes.userRole}>
              <div className={classes.userIcon}>
                <img src='/icons/comments/user.svg' alt='korisnik' />
              </div>
              <span>
                {item?.owner?.status === 'Admin'
                  ? 'Administrator'
                  : item?.owner?.team?.name}{' '}
              </span>
            </span>
          </div>
          <div className={classes.commentHeaderBtns} ref={menuRef}>
            <button
              className={classes.tipsterBtn}
              onClick={
                () =>
                  navigate(`/mister_tipster/profil?user=${item?.owner?._id}`)
                // betkoInfoPopup('Uskoro! "Mister Tipster profil !"')
              }
            >
              <img
                src='/icons/comments/commentsTipster.svg'
                alt='Mister tipster'
              />
            </button>

            <button
              className={classes.giftBtn}
              onClick={(e) => {
                handleGiftPress(item?.gift)
              }}
            >
              <img
                className={item?.gift !== undefined ? classes.hasGift : ''}
                src='/icons/comments/commentsGift.svg'
                alt='poklon'
              />
            </button>
            <button onClick={(e) => toggleMenu(e)}>
              <img
                src='/icons/comments/commentsMoreOptions.svg'
                alt='vise opcija'
              />
            </button>
            {isOptionsMenuOpen && (
              <ul className={classes.optionsMenu}>
                <li>
                  <button
                    disabled={item?.reports?.includes(user?._id)}
                    onClick={() => handleReportCommentPress(item)}
                  >
                    <img
                      src='/icons/comments/commentsReport.svg'
                      alt='prijavi korisnika'
                    />
                    <span>Prijavi korisnika</span>
                  </button>
                </li>
                <li>
                  <button
                    disabled={item?.owner?.blockedBy?.some(
                      (owner) => owner.id === user?._id
                    )}
                    onClick={() => handleBlockUserPress(item?.owner)}
                  >
                    <img
                      src='/icons/comments/commentsBlockUser.svg'
                      alt='blokiraj korisnika'
                    />
                    <span>Blokiraj korisnika</span>
                  </button>
                </li>
                <li className={classes.idLi}>
                  <button>
                    <span>ID: {item?.owner?._id}</span>
                  </button>
                </li>
                <li className={classes.idLi}>
                  <button>
                    <span>Broj ocena: {item?.ratings?.length || 0}</span>
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className={classes.commentTimeAndEditWrapper}>
          <div className={classes.commentTime}>
            Komentar br. {commentIndex} • {dateWithHours(commentDate)}
          </div>
          {user && user._id === item.owner._id && (
            <div className={classes.commentEditButtonWrapper}>
              <span
                onClick={() => {
                  setEditComment(true)
                  setEditCommentValue(item)
                }}
              >
                Uredi
              </span>
              <span onClick={() => handleDeleteCommentPress(item)}>Obriši</span>
            </div>
          )}
        </div>
        <div className={classes.commentBody}>
          {editComment && editCommentValue._id === item._id ? (
            <div className={classes.commentEditWrapper}>
              <textarea
                className={classes.textAreaWrapper}
                ref={commentEditRef}
                name='editComment'
                value={editCommentValue?.comment || ''}
                rows={5}
                onChange={(e) =>
                  setEditCommentValue((prev) => {
                    return {
                      ...prev,
                      comment: e.target.value,
                    }
                  })
                }
              />
              <div className={classes.commentEditSaveButtonWrapper}>
                <button onClick={() => handleSaveComment(item)}>Sačuvaj</button>
                <button onClick={handleCancelEdit}>Otkaži</button>
              </div>
            </div>
          ) : showComments.includes(item._id) ? (
            <div className={classes.commentContent}>
              {item.owner.roles?.Admin === 5151 ? (
                <div
                  dangerouslySetInnerHTML={renderTextWithLinksAndHtml(
                    innerHtml
                  )}
                />
              ) : (
                <TextToLink text={innerHtml} />
              )}
            </div>
          ) : (
            <div className={classes.commentContent}>
              {item.owner.roles?.Admin === 5151 ? (
                <div
                  dangerouslySetInnerHTML={renderTextWithLinksAndHtml(
                    cuttedInnerHtml
                  )}
                />
              ) : (
                <TextToLink text={cuttedInnerHtml} />
              )}
            </div>
          )}
        </div>
        <div className={classes.commentShowMore}>
          {item?.comment?.length >= 170 && (
            <button
              type='button'
              className={classes.showMoreButton}
              onClick={() => handleShowComments(item)}
            >
              {showComments.includes(item._id) ? (
                <span>...Sakrij</span>
              ) : (
                <span>...Prikaži još</span>
              )}
            </button>
          )}
        </div>
        <div className={classes.commentFeedback}>
          <div className={classes.feedbackGroup}>
            <div className={classes.feedbackLike}>
              <div>
                <img src='/icons/comments/commentsLike.svg' alt='like' />
              </div>
              <span>{commentLikes}</span>
            </div>
            <div className={classes.feedbackDislike}>
              <div>
                <img src='/icons/comments/commentsDislike.svg' alt='dislike' />
              </div>
              <span>{commentDislikes}</span>
            </div>
            <div className={classes.feedbackRating}>
              <div
                className={changeRatingBackGroundColor({
                  averageRating: averageRating,
                })}
                // className={changeRatingColor({ averageRating: averageRating })}
              >
                <img src='/icons/comments/commentsRating.svg' alt='rating' />
              </div>
              <span>{averageRating.toFixed(2)}</span>
            </div>
          </div>
          <div onClick={copyLink} className={classes.feedbackLink}>
            <img src='/icons/comments/link.svg' alt='link' />
          </div>
        </div>
        <div className={classes.commentFeedbackOptions}>
          {/* like */}
          <button
            className={alreadyLiked ? classes.alreadyLiked : ''}
            disabled={alreadyLiked}
            onClick={(e) =>
              handleReactToComment({
                type: 'like',
                action: { payload: item },
              })
            }
          >
            <img src='/icons/comments/commentsLike.svg' alt='like' />
            {!hideSpans && <span>Sviđa mi se</span>}
          </button>
          {/* dislike */}
          <button
            className={alreadyDisliked ? classes.alreadyDisliked : ''}
            disabled={alreadyDisliked}
            onClick={(e) =>
              handleReactToComment({
                type: 'dislike',
                action: { payload: item },
              })
            }
          >
            <img src='/icons/comments/commentsDislike.svg' alt='dislike' />
            {!hideSpans && <span>Ne sviđa mi se</span>}
          </button>
          {/* rate */}
          <button
            className={`${classes.ratingWrapper} ${activeRateColor(item)}`}
            onClick={() => {
              if (!user || Object.keys(user).length === 0) {
                setIsLoginModalOpen(true)
                return
              }

              if (alreadyRatedComment(item)) {
                betkoInfoPopup('Već ste dali ocenu ovom komentaru')
                return
              }

              handleRateCommentPress(item)
            }}
          >
            <img src='/icons/comments/commentsRating.svg' alt='rating' />
            {!hideSpans && <span>Oceni</span>}
          </button>
        </div>
      </div>
      <div className={classes.commentInputContainer}>
        <div
          className={classes.textAreaWrapper}
          onClick={(e) => handleAnswerOnComment({ e: e, item: item })}
        >
          <div className={classes.messageIcon}>
            <img src='/icons/comments/message.svg' alt='Poruka' />
          </div>
          <textarea
            placeholder='Unesi odgovor'
            id={`${item._id}-textarea`}
            onFocus={(e) => {
              if (!user || Object.keys(user).length === 0) {
                setIsLoginModalOpen(true)
                e.target.blur()
              }
            }}
            onChange={(e) => {
              const newValue = e.target.value
              setComment((curr) => ({ ...curr, [item?._id]: newValue }))

              const storedUserId = localStorage.getItem('activeAnswerUserId')
              const storedUsername = localStorage.getItem(
                'activeAnswerUsername'
              )
              const usernameMatch = newValue.match(/^@(\S+)\s/)

              if (!usernameMatch && storedUserId) {
                localStorage.removeItem('activeAnswerUserId')
                localStorage.removeItem('activeAnswerUsername')
              } else if (usernameMatch && storedUsername) {
                const typedUsername = usernameMatch[1]
                if (!storedUsername.startsWith(typedUsername)) {
                  localStorage.removeItem('activeAnswerUserId')
                  localStorage.removeItem('activeAnswerUsername')
                }
              }
            }}
            value={comment[item._id] || ''}
          />
          <button
            className={classes.sendIcon}
            onClick={(e) => {
              handleAddComment({ e: e, type: 'answer', commentId: item._id })
              setComment((curr) => ({ ...curr, [item._id]: '' }))
            }}
          >
            <img src='/icons/comments/send.svg' alt='Posalji poruku' />
          </button>
        </div>
        {item?.repliesCount > 0 && !answersShown && (
          <button
            onClick={() =>
              setAnswersParentComment({
                post: item.post,
                parent: item._id,
              })
            }
            className={classes.showCommentsBtn}
          >
            <span>Učitaj još ({item?.repliesCount}) odgovora</span>
          </button>
        )}
        {answersParentComment?.parent === item._id && answersPage > 1 && (
          <div
            onClick={() => setAnswersPage((page) => page - 1)}
            className={`${classes.showAnswersContainer} ${classes.showPrevAnswers}`}
          >
            <div>
              <RxTriangleUp />
            </div>
            <span>Prikaži prethodne odgovore</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default SingleComment
