import { useState, useEffect, useContext, useMemo } from 'react'
import CartContext from '../../context/CartContext'
import AuthContext from '../../context/AuthProvider'
import { message } from 'antd'
import useCountdown from '../../hooks/useCountdown'
import classes from './ProductPreviewNew.module.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { format } from 'date-fns'
import LoginModalNovo from '../LoginModalNovo'
import formatPrice from '../../utils/FormatPriceCurrency'
import { betkoErrorPopup, betkoInfoPopup } from '../../utils/notifications'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const ProductPreviewNew = ({ product }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [atrDesc, setAtrDesc] = useState('')
  const { addToCart, cart } = useContext(CartContext)
  const timeRemaining = useCountdown(product?.expireDate)
  const [searchParams, setSearchParams] = useSearchParams()
  const isExpired = timeRemaining === '00:00:00'

  // Ensure product is defined before accessing its properties
  const isSportTourist = product?.shopType === 'sport-tourist'
  const isAuction = product?.shopType === 'auction'
  const isExcludedCategory = product?.category.name === 'HUMANITARNA SMS POMOĆ' || product?.category.name === 'HUMANA POMOĆ NA RAČUN';
  const hideTimer = isAuction && isExcludedCategory;
  console.log("Kategorija:", product?.category?.name);
  console.log("isExcludedCategory:", isExcludedCategory);
  console.log("hideTimer:", hideTimer);


  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
  const { auth } = useContext(AuthContext)

  const navigate = useNavigate()

  const displayDescription = (text) => {
    return isExpanded
      ? text
      : `${text?.slice(0, 160)}${text.length > 160 ? '...' : ''}`
  }

  const existingProduct = useMemo(
    () => (product ? cart.find((item) => item.id === product._id) : null),
    [cart, product?._id]
  )

  useEffect(() => {
    if (existingProduct) {
      setQuantity(existingProduct.quantity || 1)
      setAtrDesc(existingProduct.atrDesc || '')
    }
  }, [existingProduct])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!auth?._id) {
      setIsOpenLoginModal(true)
      return
    }
    betkoInfoPopup('Uspešno ste dodali proizvod u korpu!')
    addToCart(product._id, quantity, atrDesc)
  }
  useEffect(() => {
    console.log("Product:", product);
    console.log("Product.sms:", product?.sms);
    console.log(product?.cardNumber, 'asdasdasd')
  }, [product]);
  
  return (
    <div className={classes.productPreview}>
      <div className={classes.productImageWrapperDesktop}>
        <img
          src={
            product?.images?.url
              ? `${SERVER_URL}/${product.images.url}`
              : '/football-image.png'
          }
          alt={product?.title || 'Product Image'}
          onError={(e) => {
            e.target.src = '/football-image.png'
          }}
        />
      </div>
      <div className={classes.productInfo}>
        <div className={classes.productImageWrapperMobile}>
          <img
            src={
              product?.images?.url
                ? `${SERVER_URL}/${product.images.url}`
                : '/football-image.png'
            }
            alt={product?.title || 'Product Image'}
            onError={(e) => {
              e.target.src = '/football-image.png'
            }}
          />
        </div>
        <div
          className={classes.productStock }
        >
          <p className={classes.productTitle}>{product?.title}</p>
          {!hideTimer && (
            <p className={`${classes.onStock} ${isAuction && isExpired ? classes.outOfStock : ''}`}>
            <img src='/icons/sidebar/rezultati-uzivo.svg' alt='ima na stanju' />
            {isAuction
              ? timeRemaining
              : product?.onStock
              ? `${isSportTourist ? 'REZERVIŠI MESTO' : 'Ima na stanju'}`
              : `${isSportTourist ? 'POPUNJENO' : 'NEMA NA STANJU'}`}
          </p>
          )}
          
        </div>
        <div className={classes.productDescriptionContainer}>
          <p className={classes.productDescription}>
            {displayDescription(product?.description || 'sdfsdfgsfs')}
          </p>
          {product?.description?.length > 160 && (
            <button
              className={classes.seeMoreBtn}
              onClick={() => setIsExpanded((curr) => !curr)}
            >
              {isExpanded ? 'Prikaži manje' : 'Saznaj više'}
            </button>
          )}
        </div>
        {product?.attributes?.length > 0 && !isAuction && (
          <table className={classes.attributesTable}>
            <thead>
              <tr>
                <th className={classes.headerCell}>Atributi</th>
                <th className={classes.headerCell}>Dostupno</th>
              </tr>
            </thead>
            <tbody>
              {product.attributes.map((atr) => (
                <tr key={atr._id}>
                  <td className={classes.attributeCell}>{atr.name}</td>
                  <td className={classes.valueCell}>{atr.value.join(', ')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {product?.onStock && !isAuction && (
          <form onSubmit={handleSubmit} className={classes.attributesForm}>
            <div className={classes.formDescItem}>
              <textarea
                id='atrDesc'
                name='atrDesc'
                rows='7'
                value={atrDesc}
                placeholder={`\nKLIKNI I UPIŠI\n\nPRIMER UPISA\n...Želim da poručim majicu crvene boje veličine XL, i majicu zelene boje veličine XXL... `}
                onChange={(e) => setAtrDesc(e.target.value)}
              />
            </div>
            <div className={classes.formQuantityItem}>
              <div className={classes.atrTitle}>Unesite količinu </div>
              <div className={classes.atrQuantityField}>
                <input
                  type='text'
                  id='quantity'
                  name='quantity'
                  pattern='[0-9]*'
                  min='1'
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  onInvalid={(e) =>
                    e.target.setCustomValidity('Molimo unesite ispravan broj')
                  }
                  onInput={(e) => e.target.setCustomValidity('')}
                  required
                />
              </div>
            </div>
            <div className={classes.cartContainer}>
              <div className={classes.productPrice}>
              <span>
              {product?.cardNumber || product?.sms || formatPrice(product?.price)}
          </span>
              </div>
              {isSportTourist ? (
                <button
                  className={classes.addToCartBtn}
                  type='button'
                  onClick={() => {
                    if (auth?._id) {
                      navigate('/betko_shop/moja-korpa', {
                        state: {
                          sportTour: product,
                          tourQuantity: quantity,
                          tourDesc: atrDesc,
                        },
                      })
                    } else {
                      betkoErrorPopup('Da biste rezervisali morate biti ulogovani!')
                      setIsOpenLoginModal(true)
                    }
                  }}
                >
                  <span>KLIKNI I REZERVIŠI</span>
                </button>
              ) : (
                <button type='button' className={classes.addToCartBtn} onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (auth?._id) {
                    handleSubmit(e)
                    betkoInfoPopup('Uspešno ste dodali proizvod u korpu!')
                  } else {
                    betkoErrorPopup('Da biste dodali proizvod u korpu morate biti ulogovani!')
                    setIsOpenLoginModal(true)
                  }
                }}>
                  <span>Dodaj u korpu</span>
                </button>
              )}
            </div>
          </form>
        )}
        {isAuction && !isExpired && !product.cardNumber && !product?.sms && !hideTimer &&(
          <button
            className={classes.addToCartBtn}
            type='button'
            onClick={() => {
              if (auth._id) {
                navigate('/betko_shop/moja-korpa', {
                  state: { auction: product },
                })
              } else {
                betkoErrorPopup('Da biste licitirali morate biti ulogovani!')
                setIsOpenLoginModal(true)
              }
            }}
          >
            <span>KLIKNI I LICITIRAJ</span>
          </button>
        )}
      </div>
      <LoginModalNovo
        setIsOpenLoginModal={setIsOpenLoginModal}
        isOpenLoginModal={isOpenLoginModal}
        title='Da bi ste nastavili morate biti ulogovani !'
      />
    </div>
  )
}

export default ProductPreviewNew
