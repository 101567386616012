import classes from "./textPages.module.css";

const TermOfUse = () => {
  return (
    <div className={classes.content}>
      <div className={classes.headerContainer}>
        <h1>PRISTUP SAJTU</h1>
      </div>
      <p>
        Naš sajt se bavi sportskim konsultacijama, razmenom mišljenja korisnika o sportskim događajima, i temama koje su bazirane u okviru sporta i matematike koja se veže za sport i sportske događaje.<br />
        Ukoliko imate manje od 18 godina, naš sajt vas usmerava ka sportu, kao vidu zabave, i istovremeno upozorava od svih opasnosti koje su vezane za kocku, kao društveni oblik nečega što prevazilazi igru.<br />
        Naš sajt u svojim okvirima ne organizuje igre na sreću sa ulaganjem realnog novca, što je model prilagođen svim uzrastima.
      </p>
      <div className={classes.headerContainer}>
        <h1>KLADITE SE ODGOVORNO!</h1>
      </div>
      <p>
        Naš sajt nema za usmerenje da Vas motiviše i inspiriše na bilo kakav i
        na bilo koji vid kocke ! Naprotiv tome, želimo da Vam ukažemo na važnost
        znanja i uticaj istog, kao i odsustva istog na ishode ispred svega
        sportskog klađenja, a iznad svega i bilo kog vida kocke. Kocka je
        onoliko koliko nije znanje. Odnos tog procenta nije jednak u slučaju
        svakoga ko bi pristupio igri. Igra ispred svega treba da bude vid
        zabave, a ne pitanje elementarnih stvari i bolesti zavisti. Veoma je
        važno da o igri i o svemu znate veoma mnogo, kao i o matematici u igri,
        kako biste shvatili važnost odgovornosti pri istoj. Društvena odgvornost
        prema Vama samima i prema Vašoj okolini svakako je elementarno pitanje
        vezano za Vašu igru. Granice između igre kao vida zabave koji Vas
        ispunjava i kocke, su veoma tanke. Od posebne je važnosti, da na njih
        obratite pažnju, kako ih ne biste prešli. Kocka u bilo kom obliku
        svakako može da postane Vaš veliki životni neprijatelj. Ukoliko se ne
        kladite, nemojte ni počinjati da radite to, ali ukoliko već volite da se
        kladite, vodite strogo računa o sopstvenoj samokontroli, disciplini i
        strpljenju, kao i opštem znanju o samoj igri i o uticaju matematike na
        igru. Kladite se odgovorno!
      </p>
    </div>
  );
};

export default TermOfUse;
