import React from 'react'
import classes from './DeniedPayment.module.css'
import { GiCancel } from 'react-icons/gi'
import { useNavigate } from 'react-router-dom'

const DeniedPayment = () => {
  const navigate = useNavigate()

  return (
    <div className={classes.deniedPageContianer}>
      <div className={classes.iconWrapper}>
        <img src="/icons/denied-payment.svg" alt="Denied payment" />
      </div>
      <h2>
      Nalog za plaćanje nije uspešno poslat!
      </h2>
      <p>
      Da bi iste ispravili pogrešne unose, vratite se korak u nazad klikom na dugme “proveri unose za uplatu”.
      </p>
    
      <p>
      Izvršite uplatu pravilno i vaš pretplanički paket će biti aktiviran.
      </p>
      <button onClick={() => navigate('/korisnicki_paketi/kupi-paket')}>
      Proveri unose za uplatu
      </button>
    </div>
  )
}

export default DeniedPayment
