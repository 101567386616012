import React, { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import ReactPlayer from 'react-player'
import AuthContext from '../../../context/AuthProvider'
import classes from './RecommendedPosts.module.css'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const handleContextMenu = (event) => {
  event.preventDefault()
}

const RecommendedPosts = () => {
  const navigate = useNavigate()
  const [recommendations, setRecommendations] = useState([])
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)
  const sliderRef = useRef(null)
  const { refetch } = useContext(AuthContext)
  const titleRef = useRef(null)

  useEffect(() => {
    fetchRecommendations()
  }, [refetch])

  useEffect(() => {
    updateArrows()
    const slider = sliderRef.current
    if (slider) {
      slider.addEventListener('scroll', updateArrows)
      return () => slider.removeEventListener('scroll', updateArrows)
    }
  }, [recommendations])

  const fetchRecommendations = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/posts-latestcomments`)
      setRecommendations(response.data.items)
    } catch (error) {
      console.error('Failed to fetch recommendations:', error)
    }
  }

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -300, behavior: 'smooth' })
    }
  }

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 300, behavior: 'smooth' })
    }
  }

  const updateArrows = () => {
    const slider = sliderRef.current
    if (slider) {
      setCanScrollLeft(slider.scrollLeft > 0)
      setCanScrollRight(
        slider.scrollLeft < slider.scrollWidth - slider.clientWidth
      )
    }
  }

  const handleCardClick = (item) => {
    switch (item.type) {
      case 'infoblock':
        navigate(`/info_blok/${item._id}`)
        break
      case 'betportals':
        navigate(`/bet_portal/${item._id}`)
        break
      case 'statistics':
        navigate(`/statistika/${item._id}`)
        break
      case 'betcourse':
        navigate(`/bet_kurs/${item._id}`)
        break
      case 'betcoursenew':
        navigate(`/bet_kurs_novo/${item._id}`)
        break
      case 'yoursuggestions':
        navigate(`/nasi_predlozi/${item._id}`)
        break
      case 'tutorials':
        navigate(`/bet_kalkulatori_tutorijal/${item._id}`)
        break
      default:
        break
    }
  }

  return (
    <div className={classes.recommendationsContainer}>
      <button
        className={`${
          !canScrollLeft ? classes.hidden : classes.recommendationsArrow
        } ${classes.leftArrow}`}
        onClick={scrollLeft}
      >
        <img src='/icons/arrowRec.svg' alt='arrow' />
      </button>
      <div className={classes.slider} ref={sliderRef}>
        {recommendations.map((item, index) => (
          <div
            key={index}
            className={classes.card}
            onClick={() => handleCardClick(item)}
          >
            <div className={classes.videoWrapper}>
              {item.featureVideo?.url ? (
                <ReactPlayer
                  url={`${SERVER_URL}/${item.featureVideo.url}`}
                  loop
                  onContextMenu={handleContextMenu}
                  width='100%'
                  height='106px'
                />
              ) : (
                <img
                  src={`${SERVER_URL}/${item.featureImage?.url}`}
                  alt={item.title}
                  className={classes.featuredImage}
                />
              )}
            </div>
            <span ref={titleRef} className={classes.featuredTitle}>
              {item.title?.charAt(0).toUpperCase() +
                item.title?.slice(1).toLowerCase()}
            </span>
          </div>
        ))}
      </div>
      <button
        className={`${
          !canScrollRight ? classes.hidden : classes.recommendationsArrow
        }`}
        onClick={scrollRight}
      >
        <img src='/icons/arrowRec.svg' alt='arrow' />
      </button>
    </div>
  )
}

export default RecommendedPosts
