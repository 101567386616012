import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import classes from './adminvideogallery.module.css'
import Axios from 'axios'
import { Flex, Modal, Button } from 'antd'
import { betkoErrorPopup, betkoSuccessPopup } from './../utils/notifications'

const AdminHomeVideoGallery = () => {
  const imageRef = useRef(null)
  const SERVER_URL = process.env.REACT_APP_SERVER_URL
  const [videos, setVideos] = useState([])

  const [selectedItem, setSelectedItem] = useState()
  const [isDelModalVisible, setIsDelModalVisible] = useState(false)

  const handleVideoUpload = async () => {
    try {
      const file = imageRef.current.files[0]

      // Size limit in bytes (example: 10MB)
      const maxSize = 2 * 1024 * 1024

      if (file.size > maxSize) {
        betkoErrorPopup('Veličina videa ne može biti veća od 2mb.')
        return
      }

      const formData = new FormData()
      formData.append('file', imageRef.current.files[0])

      const response = await Axios.post(
        `${SERVER_URL}/upload-home-page-video`,
        formData,
        {
          withCredentials: false,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      betkoSuccessPopup('Video uspešno objavljen')

      setTimeout(() => {
        window.location.reload()
      }, 400)
    } catch (error) {
      console.error('Upload Error: ', error)
      // Optionally: Provide user feedback on failure.
    }
  }

  const fetchVideos = async () => {
    try {
      const videosArr = await Axios.get(`${SERVER_URL}/home-page-videos`, {
        withCredentials: false,
      })

      setVideos(videosArr.data)
    } catch (error) {
      console.log('err', error)
    }
  }

  useEffect(() => {
    fetchVideos()
  }, [])

  const handleDeleteItem = async (itemId) => {
    await Axios.delete(`${SERVER_URL}/delete-home-page-video/${itemId}`, {
      withCredentials: false,
    })

    betkoSuccessPopup('Video uspešno obrisan')

    setTimeout(() => {
      window.location.reload()
    }, 300)
  }

  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1 style={{ marginRight: '15px' }}>Video Galerija: </h1>

        <input
          id='fileInput'
          className={classes.imageInput}
          type='file'
          ref={imageRef}
          onChange={handleVideoUpload}
          accept='video/*'
        />
        <label htmlFor='fileInput'>Dodaj novi video</label>

        <h1 style={{ marginLeft: '15px' }}>{videos.length} videa</h1>
      </div>

      <div className={classes.imagesList}>
        {videos &&
          videos.length > 0 &&
          videos.map((item, index) => {
            return (
              <div className={classes.imageItem}>
                <video
                  width='320'
                  height='240'
                  controls
                  controlsList='nodownload'
                >
                  <source src={`${SERVER_URL}/${item.url}`} type='video/mp4' />
                </video>
                <button
                  onClick={(e) => {
                    setSelectedItem(item._id)
                    setIsDelModalVisible(true)
                  }}
                  className={classes.delButton}
                >
                  <span>Obriši</span>
                </button>
              </div>
            )
          })}
      </div>

      <Modal
        footer={[]}
        open={isDelModalVisible}
        title='Da li ste sigurni da želite da obrišete sliku?'
        onCancel={() => {
          setIsDelModalVisible(false)
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '20px',
          }}
        >
          <Button
            onClick={(e) => {
              handleDeleteItem(selectedItem)
              setIsDelModalVisible(false)
            }}
          >
            Obriši
          </Button>
          <Button
            onClick={(e) => {
              setIsDelModalVisible(false)
            }}
          >
            Odustani
          </Button>
        </div>
      </Modal>

      <div className={classes.videosList}></div>
    </div>
  )
}

export default AdminHomeVideoGallery
