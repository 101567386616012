import React, { useState, useEffect } from 'react'
import { Table, Button, Modal, Input, Radio, message, Form } from 'antd'
import classes from './ShopAdminCategories.module.css'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import axios from 'axios'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { betkoErrorPopup } from './../utils/notifications'
const { Group: RadioGroup, Button: RadioButton } = Radio
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const ShopAdminCategories = () => {
  const [allCategories, setAllCategories] = useState({
    paginatedData: [],
    totalCount: 0,
    totalPages: 0,
  })
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [newCategory, setNewCategory] = useState({ name: '', typeShop: '' })
  const [currentPage, setCurrentPage] = useState(1)
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [refectTrigger, setRefetchTrigger] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [editCategoryName, setEditCategoryName] = useState('')

  const axiosPrivate = useAxiosPrivate()
  const [form] = Form.useForm()

  useEffect(() => {
    const getAllCategories = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/categories-get-all/${currentPage}/10/`
        )
        setAllCategories(response?.data)
        setCurrentPage((curr) => Math.min(curr, response?.data?.totalPages))
      } catch (error) {
        setAllCategories([])
        message.error(
          error?.response?.data?.message || 'Greška pri preuzimanju kategorija!'
        )
      }
    }

    getAllCategories()
  }, [currentPage, refectTrigger])

  const handleDeleteCategory = async () => {
    try {
      await axiosPrivate.delete(
        `${SERVER_URL}/delete-category/${selectedCategory._id}`
      )
      message.success(`Uspešno ste obrisali kategoriju ${selectedCategory._id}`)
      setIsDeleteModalVisible(false)
      setRefetchTrigger((curr) => !curr)
    } catch (error) {
      message.error(
        error?.response?.data?.message || 'Greška pri uklanjanju kategorije!'
      )
    }
    setSelectedCategory(null)
  }
  const handleEditCategory = async () => {
    if (!editCategoryName) {
      betkoErrorPopup('Ime kategorije ne može biti prazno!')
      return
    }
    try {
      await axiosPrivate.put(
        `${SERVER_URL}/update-category/${selectedCategory?._id}`,
        {
          name: editCategoryName,
        }
      )
      message.success(`Uspešno ste ažuriranju kategoriju ${editCategoryName}`)
      setIsEditModalVisible(false)
      setRefetchTrigger((curr) => !curr)
    } catch (error) {
      message.error(
        error?.response?.data?.message || 'Greška pri ažuriranju kategorije!'
      )
    }
    setSelectedCategory(null)
  }

  const handleAddNewCategory = async () => {
    try {
      await form.validateFields()
      await axiosPrivate.post(`${SERVER_URL}/create-category`, newCategory)
      message.success(`Uspešno ste dodali kategoriju ${newCategory?.name}!`)
      setNewCategory({ name: '', typeShop: '' })
      setIsAddModalVisible(false)
      setRefetchTrigger((curr) => !curr)
      form.resetFields()
    } catch (error) {
      message.error(
        error?.response?.data?.message || 'Greška pri dodavanju kategorija!'
      )
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewCategory((prev) => ({ ...prev, [name]: value }))
  }

  const handleRadioChange = (e) => {
    setNewCategory((prev) => ({ ...prev, typeShop: e.target.value }))
  }

  const columns = [
    {
      title: 'Naziv',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Vrsta Prodavnice',
      dataIndex: 'typeShop',
      key: 'typeShop',
    },
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'Akcije',
      key: 'actions',
      render: (_, record) => (
        <div className={classes.actionButtons}>
          <Button
            onClick={() => {
              setSelectedCategory(record)
              setEditCategoryName(record.name)
              setIsEditModalVisible(true)
            }}
            type='link'
            icon={<EditOutlined />}
          />
          <Button
            onClick={() => {
              setSelectedCategory(record)
              setIsDeleteModalVisible(true)
            }}
            type='link'
            icon={<DeleteOutlined />}
          />
        </div>
      ),
    },
  ]

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <h2 className={classes.title}>KATEGORIJE</h2>
        <Button
          type='primary'
          onClick={() => setIsAddModalVisible(true)}
          className={classes.addButton}
        >
          Dodaj kategoriju
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={allCategories?.paginatedData}
        className={classes.categoriesTable}
        pagination={{
          currentPage,
          pageSize: 10,
          total: allCategories.totalCount,
          onChange: (page) => {
            setCurrentPage(page)
          },
          position: ['bottomCenter'],
        }}
      />
      <Modal
        title='UKLONI KATEGORIJU'
        open={isDeleteModalVisible}
        onOk={handleDeleteCategory}
        onCancel={() => setIsDeleteModalVisible(false)}
        style={{ color: 'white' }}
      >
        <p>
          Da li ste sigurni da želite da uklonite kategoriju{' '}
          {selectedCategory?.name}?
        </p>
      </Modal>
      <Modal
        title='Dodaj kategoriju'
        open={isAddModalVisible}
        onOk={handleAddNewCategory}
        onCancel={() => {
          setIsAddModalVisible(false)
          form.resetFields()
        }}
      >
        <Form form={form} layout='vertical'>
          <Form.Item
            label='Naziv kategorije'
            name='name'
            rules={[
              { required: true, message: 'Naziv kategorije je obavezan!' },
            ]}
          >
            <Input
              placeholder='Naziv kategorije'
              name='name'
              value={newCategory.name}
              onChange={handleInputChange}
              className={classes.input}
            />
          </Form.Item>
          <Form.Item
            label='Tip prodavnice'
            name='typeShop'
            rules={[
              {
                required: true,
                message: 'Tip prodavnice kategorije je obavezan!',
              },
            ]}
          >
            <RadioGroup
              value={newCategory.typeShop}
              onChange={handleRadioChange}
              className={classes.radioGroup}
            >
              <RadioButton value='shop'>SHOP</RadioButton>
              <RadioButton value='auction'>LICITACIJA</RadioButton>
              <RadioButton value='sport-tourist'>SPORT TURIST</RadioButton>
            </RadioGroup>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title='Izmeni kategoriju'
        open={isEditModalVisible}
        onOk={handleEditCategory}
        onCancel={() => {
          setIsEditModalVisible(false)
          setSelectedCategory(null)
          form.resetFields()
        }}
      >
        <input
          value={editCategoryName}
          onChange={(e) => setEditCategoryName(e.target.value)}
          placeholder='Ime kategorije'
          className={classes.editInput}
        />
      </Modal>
    </div>
  )
}

export default ShopAdminCategories
