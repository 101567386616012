import classes from './paginationAlt.module.css'

const PaginationAlt = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className={classes.pagination}>
      <button onClick={() => onPageChange(-1)} disabled={currentPage <= 1}>
        Prethodna
      </button>
      <span>
        Stranica {currentPage} od {totalPages}
      </span>
      <button
        onClick={() => onPageChange(1)}
        disabled={currentPage >= totalPages}
      >
        Sledeća
      </button>
    </div>
  )
}

export default PaginationAlt
