import { Modal, Button, Upload } from 'antd'
import React, { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import classes from './AdminMobileApk.module.css'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import downloadApk from '../utils/DownloadApk'
import { betkoErrorPopup, betkoSuccessPopup } from '../utils/notifications'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const AdminMobileApk = () => {
  const [fileList, setFileList] = useState([])
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const axiosPrivate = useAxiosPrivate()

  const handleUpdateApkUrl = async () => {
    if (fileList.length === 0) {
      betkoErrorPopup('Molimo vas da odaberete APK fajl za upload')
      return
    }

    const file = fileList[0]?.originFileObj
    const apkVersion = fileList[0]?.name // Extract filename as apkVersion
    console.log(file)
    const formData = new FormData()
    formData.append('file', file)
    formData.append('apkVersion', apkVersion)

    try {
      await axiosPrivate.post(`${SERVER_URL}/add-apk-url`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      betkoSuccessPopup('Uspešno ste ažurirali APK fajl')
      setIsEditModalVisible(false)
    } catch (error) {
      betkoErrorPopup(
        error.response?.data?.message ||
          'Došlo je do greške prilikom ažuriranja APK fajla'
      )
    }
  }

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList.slice(-1)) // Keep only the latest file
  }

  return (
    <div className={classes.apkUrlPageWrapper}>
      <div className={classes.topWrapper}>
        <h2>APK Fajl za aplikaciju</h2>
        <button onClick={downloadApk}>Preuzmite trenutni APK</button>
        <button onClick={() => setIsEditModalVisible(true)}>
          Ažuriraj APK
        </button>
      </div>

      {/* Edit apkUrl Modal */}
      <Modal
        title='Ažuriraj APK fajl'
        open={isEditModalVisible}
        onOk={handleUpdateApkUrl}
        onCancel={() => setIsEditModalVisible(false)}
        className={classes.apkUrlModal}
        okText='Ažuriraj'
        cancelText='Otkaži'
      >
        <div className={classes.upload}>
          <Upload
            accept='.apk'
            beforeUpload={() => false} // Prevent auto upload
            fileList={fileList}
            onChange={handleFileChange}
            maxCount={1} // Only allow one file
          >
            <Button icon={<UploadOutlined />}>Odaberite APK fajl</Button>
          </Upload>
        </div>
      </Modal>
    </div>
  )
}

export default AdminMobileApk
