import React, { useEffect, useState } from 'react'
import classes from './MisterTipsterAwardsNew.module.css'
import classesW from './misterTipsterWinners.module.css'
import axios from '../config/axios'
import { useNavigate } from 'react-router-dom'
import SideImages from '../components/Redesign/SideImages/SideImages'
import PaginationAlt from './../components/PaginationAlt'
import { betkoPopupWithAction } from '../utils/BetkoPopupModal'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const MisterTipsterWinners = () => {
  const [awardArchive, setAllAwardsArchive] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()

  useEffect(() => {
    const getAllAwardsArchive = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/get-all-user-awards/${currentPage}/7`
        )

        setAllAwardsArchive(response?.data)
      } catch (error) {
        setAllAwardsArchive([])
        console.log(error)
      }
    }

    getAllAwardsArchive()
  }, [currentPage])

  const handleDescriptionClick = (description, award) => {
    betkoPopupWithAction('Opis Nagrade', description, () => (
      <span
        onClick={() => window.open(award, '_blank')}
        className={classesW.awardDescription}
      >
        KLIKNI I POGLEDAJ NAGRADU
      </span>
    ))
  }

  return (
    <div className={classes.mainContainer} id='tipsterAwards'>
      <SideImages />
      <div className={classes.container}>
        <div className={classes.pageHeader}>
          <h1>
            <button
              className={classes.backButtonDesktop}
              onClick={() => navigate(-1)}
            >
              <img src='/icons/arrow-left.svg' alt='backButton' />
              Nazad
            </button>
            SPISAK DOBITNIKA
          </h1>
          <button
            className={classes.backButtonMobile}
            onClick={() => navigate(-1)}
          >
            <img src='/icons/arrow-left.svg' alt='back button' />
            Nazad
          </button>
        </div>

        {awardArchive?.paginatedData ? (
          <>
            <div className={classesW.table}>
              <div className={classesW.tableHeader}>
                <span>Nagrada</span>
                <span>Takmičar</span>
                <span>Takmičenje</span>
                <span className={classesW.competitionPeriod}>
                  Period takmičenja
                </span>
                <span className={classesW.awardDescriptionHeader}>
                  Opis nagrade
                </span>
              </div>
              <ul>
                {awardArchive?.paginatedData?.map((award, index) => (
                  <li key={index} className={classesW.tableItem}>
                    <img
                      src={`/icons/mister-tipster/${
                        index === 0 ? 'gold' : index === 1 ? 'silver' : 'bronze'
                      }-medal.svg`}
                      alt={`${index + 1}-place-medal`}
                      className={classes.medalIcon}
                    />

                    <div className={`${classes.userInfo} ${classesW.userInfo}`}>
                      <img
                        src={
                          award?.user?.team?.teamImage?.url
                            ? `${SERVER_URL}/${award?.user?.team?.teamImage?.url}`
                            : '/teamPlaceholder.png'
                        }
                        alt='User Team'
                        className={classes.userImage}
                      />
                      <span className={classes.userName}>
                        {award.user?.userName}
                      </span>
                    </div>

                    <span className={classes.competitionType}>
                      {award.table === 'year'
                        ? 'Godišnje'
                        : award.table === 'month'
                        ? 'Mesečno'
                        : 'Rang'}
                    </span>

                    <span className={classesW.competitionPeriod}>
                      {award.period}
                    </span>

                    <span
                      onClick={() =>
                        handleDescriptionClick(award.description, award.award)
                      }
                      className={classesW.awardDescription}
                    >
                      Pročitaj opis
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className={classes.pagination}>
              <PaginationAlt
                currentPage={currentPage}
                totalPages={awardArchive ? awardArchive.totalPages : 1}
                onPageChange={(number) =>
                  setCurrentPage((prev) => prev + number)
                }
              />
            </div>
          </>
        ) : (
          <div className={classes.noAwards}>NEMA DOBITNIKA ZA PRIKAZ</div>
        )}
      </div>
    </div>
  )
}
export default MisterTipsterWinners
