import React from 'react'
import SingleComment from './Redesign/SingleComment/SingleComment'
import SideImages from './Redesign/SideImages/SideImages'
import SingleAnswerComment from './Redesign/SingleAnswerComment/SingleAnswerComment'

const CommentWithAnswers = ({
  item,
  commentIndex,
  isHomePage,
  editComment,
  setEditComment,
  setEditCommentValue,
  editCommentValue,
  handleSaveComment,
  handleDeleteComment,
  showComments,
  handleShowComments,
  handleReactToComment,
  user,
  handleAddComment,
  comment,
  setComment,
  handleAnswerOnComment,
  answersParentComment,
  setAnswersParentComment,
  answersPage,
  setAnswersPage,
  changeRatingColor,
  changeRatingBackGroundColor,
  handleRateComment,
  alreadyRatedComment,
  activeRateColor,
  currentPage,
  setIsLoginModalOpen,
  handleReportCommentPress,
  handleBlockUserPress,
  handleGiftPress,
  answers,
  handleChangeReplyPage,
  classes,
  commentIdFromUrl,
  index,
}) => {
  if (!item || !item?._id) return
  if (!item.owner || item.owner?.id) return

  return (
    <>
      <SingleComment
        item={item}
        commentIndex={commentIndex}
        isHomePage={isHomePage}
        editComment={editComment}
        setEditComment={setEditComment}
        setEditCommentValue={setEditCommentValue}
        editCommentValue={editCommentValue}
        handleSaveComment={handleSaveComment}
        handleDeleteComment={handleDeleteComment}
        showComments={showComments}
        handleShowComments={handleShowComments}
        handleReactToComment={handleReactToComment}
        user={user}
        handleAddComment={handleAddComment}
        comment={comment}
        setComment={setComment}
        handleAnswerOnComment={handleAnswerOnComment}
        answersParentComment={answersParentComment}
        setAnswersParentComment={setAnswersParentComment}
        answersPage={answersPage}
        setAnswersPage={setAnswersPage}
        changeRatingColor={changeRatingColor}
        changeRatingBackGroundColor={changeRatingBackGroundColor}
        handleRateComment={handleRateComment}
        alreadyRatedComment={alreadyRatedComment}
        activeRateColor={activeRateColor}
        currentPage={
          commentIdFromUrl && commentIdFromUrl === item._id ? 1 : currentPage
        }
        setIsLoginModalOpen={setIsLoginModalOpen}
        handleReportCommentPress={handleReportCommentPress}
        handleBlockUserPress={handleBlockUserPress}
        handleGiftPress={handleGiftPress}
      />

      {item._id === answersParentComment?.parent && answers?.length > 0 && (
        <div className={classes.answersContainer}>
          {answers.slice(0, (answersPage[item._id] || 1) * 10).map((c, idx) => {
            if (c?.owner?.blockedBy?.some((k) => k.id === user._id)) {
              return null
            }
            return (
              <SingleAnswerComment
                key={c._id}
                item={c}
                parentIndex={commentIndex}
                isHomePage={isHomePage}
                editComment={editComment}
                setEditCommentValue={setEditCommentValue}
                editCommentValue={editCommentValue}
                setEditComment={setEditComment}
                handleDeleteComment={handleDeleteComment}
                handleSaveComment={handleSaveComment}
                showComments={showComments}
                handleShowComments={handleShowComments}
                handleReactToComment={handleReactToComment}
                user={user}
                handleAddComment={handleAddComment}
                comment={comment}
                setComment={setComment}
                handleAnswerOnComment={handleAnswerOnComment}
                answersParentComment={answersParentComment}
                setAnswersParentComment={setAnswersParentComment}
                handleRateComment={handleRateComment}
                alreadyRatedComment={alreadyRatedComment}
                handleReportCommentPress={handleReportCommentPress}
                handleBlockUserPress={handleBlockUserPress}
                handleGiftPress={handleGiftPress}
              />
            )
          })}

          <div className={classes.showAnswersContainer}>
            {(answersPage[item._id] || 1) > 1 && (
              <button
                onClick={() => handleChangeReplyPage(item._id, -1)}
                className={classes.showPrevAnswers}
              >
                Sakrij odgovore
              </button>
            )}

            {item.repliesCount > (answersPage[item._id] || 1) * 10 && (
              <button
                onClick={() => handleChangeReplyPage(item._id, 1)}
                className={classes.showNextAnswers}
              >
                Učitaj još (
                {Math.min(
                  10,
                  item.repliesCount - (answersPage[item._id] || 1) * 10
                )}
                ){' '}
                {Math.min(
                  10,
                  item.repliesCount - (answersPage[item._id] || 1) * 10
                ) === 1
                  ? 'odgovor'
                  : 'odgovora'}
              </button>
            )}
          </div>
        </div>
      )}

      {index === 0 && (
        <div className={classes.mobileOnlyAds}>
          <SideImages mobileView={true} />
        </div>
      )}
    </>
  )
}

export default CommentWithAnswers
