import LayoutWithCalcAndSideImgs from '../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'
import ForumPostList from '../components/ForumPostList'

const InfoBlok = () => {
  return (
    <LayoutWithCalcAndSideImgs title='INFO BLOK'>
      <ForumPostList postUrl='/getAllInfoBlokPosts' type='infoBlok' />
    </LayoutWithCalcAndSideImgs>
  )
}

export default InfoBlok
