import { useNavigate } from 'react-router-dom'
import classes from './singlePost.module.css'
import ReactPlayer from 'react-player'
import { forwardRef, useMemo } from 'react'
import { dateWithHours } from '../utils/DateWithHours'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const SinglePost = forwardRef(
  (
    { postId, type, post, commentNumber, updatedAt, customTitleHeight },
    titleRef
  ) => {
    const navigate = useNavigate()
    // const titleRef = useRef(null)

    const handleContextMenu = (event) => {
      event.preventDefault()
    }

    const containerClass = useMemo(() => {
      if (type === 'korisnickiPaketi') return classes.korisnickiPaketi
      return ''
    })

    return (
      <div
        className={`${classes.container} ${containerClass}`}
        onClick={() => {
          if (type === 'infoBlok') {
            navigate(`/info_blok/${postId}`)
          } else if (type === 'betPortal') {
            navigate(`/bet_portal/${postId}`)
          } else if (type === 'statistika') {
            navigate(`/statistika/${postId}`)
          } else if (type === 'betKursNovo') {
            navigate(`/bet_kurs_novo/${postId}`)
          } else if (type === 'nasiPredlozi') {
            navigate(`/nasi_predlozi/${postId}`)
          } else if (type === 'betKalkulatoriTutorijal') {
            navigate(`/bet_kalkulatori_tutorijal/${postId}`)
            // window.open(
            //   `https://betkotip.com/bet_kalkulatori_tutorijal/${postId}`,
            //   '_blank'
            // )
          } else if (type === 'korisnickiPaketi') {
            navigate(`/korisnicki_paketi/${postId}`)
          } else {
            navigate(`/${type}/${postId}`)
          }
        }}
      >
        {post && post.featureVideo && post.featureVideo.url ? (
          <div className={classes.featureVideoDiv}>
            <ReactPlayer
              url={`${SERVER_URL}/${post.featureVideo?.url}`}
              playing={true} // Enables autoplay
              muted={true} // Mutes the video
              loop
              width='320px'
              height='267px'
              onContextMenu={handleContextMenu}
            />
          </div>
        ) : (
          <img
            className={classes.featureImage}
            src={`${SERVER_URL}/${post.featureImage?.url}`}
            alt='featureImage'
          ></img>
        )}

        <div
          ref={titleRef}
          className={classes.title}
          style={{
            minHeight: customTitleHeight ? `${customTitleHeight}px` : 'auto',
          }}
        >
          <h2>{post.title}</h2>
        </div>
        {(commentNumber || commentNumber === 0) && (
          <div className={`${classes.infoSection} ${classes.commentCount}`}>
            <span>br. kom {commentNumber}</span>
          </div>
        )}

        {updatedAt && (
          <div className={`${classes.infoSection} ${classes.dateAndTime}`}>
            <span>{dateWithHours(updatedAt)}</span>
          </div>
        )}
      </div>
    )
  }
)

export default SinglePost
