import ReactPlayer from 'react-player'
import classes from './forumNavigation.module.css'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const ForumContent = ({ description, items }) => {
  return (
    <div className={classes.optionsAndMarketing}>
      <h2 className={`${classes.postDescription} ${classes.postDescription}`}>
        {description}
      </h2>
      <div className={classes.contentRow}>
        {items &&
          items.length > 0 &&
          items.map((item, index) => {
            if (item.type === 'text') {
              return (
                <div
                  key={index}
                  style={{
                    color: item.color,
                    fontWeight: item.bold ? 'bolder' : false,
                    fontSize: item.fontSize ? `${item.fontSize}px` : '16px',
                    fontStyle: item.italic ? 'italic' : false,
                    marginTop: item.marginTop,
                    marginBottom: item.marginBottom,
                  }}
                >
                  {item.description}
                </div>
              )
            } else if (item.type === 'video') {
              return (
                <div>
                  <ReactPlayer
                    url={`${SERVER_URL}/${item?.video?.url}`}
                    playing={true}
                    muted={true}
                    style={{ marginBottom: '10px' }}
                    loop
                  />
                </div>
              )
            } else if (item.type === 'img') {
              return (
                <a
                  target='_blank'
                  href={item?.imageLink}
                  className={classes.itemImageATag}
                  rel='noreferrer'
                >
                  <img
                    className={classes.itemImage}
                    src={`${SERVER_URL}/${item.image?.url}`}
                    alt='img'
                  />
                </a>
              )
            } else {
              return null
            }
          })}
      </div>
    </div>
  )
}

export default ForumContent
