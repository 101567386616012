import LayoutWithCalcAndSideImgs from '../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'
import ForumPostList from '../components/ForumPostList'

const NasiPredlozi = () => {
  return (
    <LayoutWithCalcAndSideImgs title='VAŠI PREDLOZI'>
      <ForumPostList postUrl='/getAllNasiPredloziPosts' type='nasiPredlozi' />
    </LayoutWithCalcAndSideImgs>
  )
}

export default NasiPredlozi
