import React, { useContext, useEffect, useState } from 'react'
import classes from './MisterTipsterDefaultTable.module.css'
import { DatePicker } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Spinner from '../components/Spinner'
import AuthContext from '../context/AuthProvider'
import PaginationAlt from './PaginationAlt'

dayjs.extend(utc)

const MisterTipsterDefaultTable = ({
  data,
  period,
  searchTerm,
  setSearchTerm,
  isLoading,
}) => {
  const navigate = useNavigate()
  const { auth } = useContext(AuthContext)
  const searchParams = new URLSearchParams(window.location.search)
  const periodParams = searchParams.get('period')
  const pageParams = searchParams.get('page')
  const [from, setFrom] = useState(null)
  const locationState = useLocation().state
  const SERVER_URL = process.env.REACT_APP_SERVER_URL

  useEffect(() => {
    locationState?.from && setFrom(locationState?.from)
  }, [locationState])

  const defaultPeriod =
    period === 'year'
      ? dayjs().format('YYYY')
      : period === 'global'
      ? null
      : period === 'rang'
      ? `${dayjs().subtract(10, 'days').format('DD-MM-YYYY')}-${dayjs().format(
          'DD-MM-YYYY'
        )}`
      : dayjs().format('MM-YYYY')
  const [selectedPeriod, setSelectedPeriod] = useState(
    periodParams || defaultPeriod
  )
  const [currentPage, setCurrentPage] = useState(
    pageParams ? parseInt(pageParams) : 1
  )

  const handlePeriodChange = (date, dateString) => {
    let formattedRange = ''

    if (period === 'year') {
      formattedRange = dayjs(date).format('YYYY')
    } else if (period === 'rang') {
      formattedRange = `${dayjs(date)
        .subtract(10, 'days')
        .format('DD-MM-YYYY')}-${dayjs(date).format('DD-MM-YYYY')}`
    } else {
      formattedRange = dayjs(date).format('MM-YYYY')
    }

    setSelectedPeriod(formattedRange)
  }

  // Update URL when currentPage or selectedPeriod changes
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    // Preserve existing query parameters (e.g., "table")
    if (currentPage) params.set('page', currentPage)
    if (selectedPeriod && period !== 'global')
      params.set('period', selectedPeriod)
    else params.delete('period')

    navigate(`?${params.toString()}`, { replace: true })
  }, [currentPage, selectedPeriod, navigate])

  const formattedPeriod =
    period === 'year'
      ? dayjs(periodParams, 'YYYY').isValid()
        ? dayjs(periodParams, 'YYYY')
        : dayjs()
      : period === 'rang'
      ? dayjs()
      : periodParams
      ? dayjs(periodParams, period === 'month' ? 'MM-YYYY' : 'DD-MM-YYYY')
      : dayjs()

  const pointsPerPeriod =
    period === 'year'
      ? 'yearPoints'
      : period === 'month'
      ? 'monthPoints'
      : 'totalPoints'

  return (
    <div className={classes.container}>
      <div className={classes.componentHeader}>
        <div className={classes.leftButtons}>
          <button
            className={classes.infoButton}
            onClick={() => navigate(`/mister_tipster/opis-igre`)}
          >
            Opis igre
          </button>
          {!(period === 'global') && (
            <DatePicker
              picker={
                period === 'year'
                  ? 'year'
                  : period === 'rang'
                  ? 'date'
                  : 'month'
              }
              placeholder={
                period === 'year'
                  ? 'GODINA'
                  : period === 'rang'
                  ? 'DATUM'
                  : 'MESEC'
              }
              onChange={handlePeriodChange}
              defaultValue={formattedPeriod}
              className={classes.tablePeriodPicker}
              format={
                period === 'year'
                  ? 'YYYY'
                  : period === 'rang'
                  ? 'DD-MM-YYYY'
                  : 'MM-YYYY'
              }
            />
          )}
        </div>
        <div className={classes.searchBar}>
          <button className={classes.searchButton}>
            <img src='/icons/tipster/magnifier.svg' alt='search' />
          </button>
          <input
            type='text'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder='Pretraga'
          />
        </div>
      </div>
      <div className={classes.tableHeader}>
        <h3>
          {period === 'year'
            ? 'GODIŠNJA'
            : period === 'month'
            ? 'MESEČNA'
            : period === 'rang'
            ? 'RANG'
            : 'GLOBALNA'}{' '}
          TABELA
        </h3>

        {data?.paginatedData?.length > 0 && (
          <div className={classes.tableHeaderTotalScore}>Zbir.kv.</div>
        )}
      </div>

      <div className={classes.tableContainer}>
        {data?.currentUser?._id && currentPage === 1 && (
          <div
            className={`${classes.tableItem} ${classes.currentUserTableItem}`}
          >
            <div className={classes.tableInfo}>
              <div className={classes.tableItemRank}>
                #{data?.currentPosition || data?.currentUser?.position}
              </div>
              <div className={classes.tableItemTeam}>
                <img
                  src={
                    data?.currentUser?.team?.teamImage?.url
                      ? `${SERVER_URL}/${data?.currentUser?.team?.teamImage?.url}`
                      : '/teamPlaceholder.png'
                  }
                  alt='team'
                />
              </div>
              <div
                className={`${classes.tableItemPlayer} ${classes.tableCurrentUser}`}
                onClick={() =>
                  navigate(
                    `/mister_tipster/profil?user=${data?.currentUser?._id}`
                  )
                }
              >
                <span>{data?.currentUser?.userName} </span>
              </div>
            </div>
            <div className={classes.tableItemTotalScore}>
              {data?.currentUser?.tablePoints[pointsPerPeriod] || 0}
            </div>
          </div>
        )}

        {isLoading ? (
          <div className={classes.spinner}>
            <Spinner />
          </div>
        ) : (
          <>
            <div className={classes.tableBody}>
              {data?.paginatedData?.map((user, index) => (
                <div className={classes.tableItem} key={user._id}>
                  <div className={classes.tableInfo}>
                    <div className={classes.tableItemRank}>
                      {currentPage === 1 && index < 3 ? (
                        <img
                          src={`/icons/tipster/${
                            ['gold', 'silver', 'bronze'][index]
                          }-medal.svg`}
                          alt={['gold', 'silver', 'bronze'][index]}
                        />
                      ) : (
                        `#${(currentPage - 1) * 10 + index + 1}`
                      )}
                    </div>
                    <div className={classes.tableItemTeam}>
                      <img
                        src={
                          user?.team?.teamImage?.url
                            ? `${SERVER_URL}/${user?.team?.teamImage?.url}`
                            : '/teamPlaceholder.png'
                        }
                        alt='team'
                      />
                    </div>
                    <div
                      className={classes.tableItemPlayer}
                      onClick={() => {
                        navigate(
                          `/mister_tipster${
                            auth?._id !== user?._id
                              ? `/profil?user=${user._id}`
                              : ''
                          }`
                        )
                      }}
                    >
                      {user?.userName}
                    </div>
                  </div>

                  <div className={classes.tableItemTotalScore}>
                    {user?.tablePoints[pointsPerPeriod] || 0}
                  </div>
                </div>
              ))}
            </div>
            {data?.paginatedData?.length > 0 ? (
              <div className={classes.pagination}>
                <PaginationAlt
                  currentPage={currentPage}
                  totalPages={data?.pagesCount}
                  onPageChange={(number) =>
                    setCurrentPage((prev) => prev + number)
                  }
                />
              </div>
            ) : (
              <p className={classes.noData}>
                Nema podataka za izabrani period!
              </p>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default MisterTipsterDefaultTable
