import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import AuthContext from '../context/AuthProvider'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useQuery } from '@tanstack/react-query'
import useLogout from '../hooks/useLogout'
import { betkoInfoPopup } from '../utils/notifications'

const RequireAuth = ({ allowedRoles, adminPanel, shopAdminPanel }) => {
  const { auth } = useContext(AuthContext)
  const location = useLocation()
  const axiosPrivate = useAxiosPrivate()
  const logout = useLogout()
  const userEmail = auth?.email

  const [unauthorized, setUnauthorized] = useState(false)
  const [navigateTo, setNavigateTo] = useState(null)

  const getUserData = async (userEmail) => {
    const { data } = await axiosPrivate.get(`/user/getPaidDate/${userEmail}`)
    return data
  }

  const refreshPage = () => {
    window.location.reload()
  }

  const userDataQuery = useQuery({
    queryKey: ['userData', userEmail],
    queryFn: () => getUserData(userEmail),
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
    // onSuccess: () => {
    //   console.log("sada sam uspesan");
    // },
    onError: (error) => {
      console.log('error: ', error)
      logout()
      refreshPage()
      ;<Navigate
        to='/registracija_i_logovanje'
        state={{ from: location }}
        replace
      />
    },
  })

  useEffect(() => {
    if (unauthorized) {
      betkoInfoPopup('Nisi ovlašćen da pristupiš ovom sadržaju')
    }
  }, [unauthorized])

  if (userDataQuery.isError && userDataQuery.error.response?.status === 401) {
    logout()
    refreshPage()
    setUnauthorized(true)
    setNavigateTo('/registracija_i_logovanje')
  }

  if (navigateTo) {
    return <Navigate to={navigateTo} state={{ from: location }} replace />
  }

  return auth?.roles?.find((role) => allowedRoles?.includes(role)) ||
    (adminPanel === true && auth.moderator === true) ||
    (shopAdminPanel && auth.accessAdminShop === 'approved') ? (
    <Outlet />
  ) : auth?.accessToken ? (
    (() => {
      setUnauthorized(true)
      setNavigateTo('/')
      return null
    })()
  ) : (
    <Navigate
      to='/registracija_i_logovanje'
      state={{ from: location }}
      replace
    />
  )
}
export default RequireAuth
