import LayoutWithCalcAndSideImgs from '../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'
import ForumPostList from '../components/ForumPostList'

const Statistika = () => {
  return (
    <LayoutWithCalcAndSideImgs title='STATISTIKA'>
      <ForumPostList postUrl='/getAllStatistikaPosts' type='statistika' />
    </LayoutWithCalcAndSideImgs>
  )
}

export default Statistika
