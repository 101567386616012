import React from 'react'
import classes from './CategoriesSidebar.module.css'

const CategoriesSidebar = ({
  allCategories,
  setSelectedCategory,
  selectedCategory,
  setSelectedProduct,
  setSearchParams,
}) => {
  const handleChangeCategory = (category) => {
    setSelectedProduct(null)
    setSelectedCategory(category)
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams)
      newParams.delete('productId')
      return newParams
    })
  }

  return (
    <div className={classes.categoriesSidebar}>
      <h3>Kategorije</h3>
      <ul>
        <li
          onClick={() => handleChangeCategory(null)}
          className={!selectedCategory ? classes.selectedCategory : ''}
        >
          Svi proizvodi
        </li>
        {allCategories?.map((category) => (
          <li
            key={category?._id}
            className={
              selectedCategory === category?._id ? classes.selectedCategory : ''
            }
            onClick={() => handleChangeCategory(category?._id)}
          >
            {category?.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CategoriesSidebar
