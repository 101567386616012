import { useState, useContext } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import axios from '../config/axios'
import AuthContext from '../context/AuthProvider'
import classes from './authNew.module.css'
import { betkoErrorPopup, betkoInfoPopup, betkoSuccessPopup } from '../utils/notifications'
import { Input } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const Auth = () => {
  const { setAuth, login, setLogin } = useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [checked, setChecked] = useState(false)
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoginError, setIsLoginError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')

  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const rp =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
  const validEmail = re.test(email)
  const validPassword = rp.test(password)
  const validLoginEmail = re.test(loginEmail)
  const validLoginPassword = rp.test(loginPassword)

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const handleRegistration = (e) => {
    e.preventDefault()

    if (!userName) {
      setIsError(true)
      betkoErrorPopup('Username polje je obavezno')
    }
    if (validEmail && validPassword && checked && userName && checkedTerms) {
      setIsError(false)
      setErrorMessage('')
      registerMutation.mutate({ userName, email, password })
    }
    if (!checked) {
      setIsError(true)
      betkoErrorPopup('Niste potvrdili broj godina')
    }
    if (!checkedTerms) {
      setIsError(true)
      betkoErrorPopup('Niste potvrdili opšte uslove i politiku privatnosti')
    }
    if (password !== confirmPassword) {
      setIsError(true)
      betkoErrorPopup('Lozinke se ne podudaraju')
    }
    if (!validPassword) {
      setIsError(true)
      betkoErrorPopup(
        'Lozinka mora biti minimum 8 karaktera, sadržati bar jedno veliko slovo, malo slovo, broj i specijalan karakter'
      )
    }
    if (!validEmail) {
      setIsError(true)
      betkoErrorPopup('Email nije ispravan')
    }
  }
  const handleLogin = (e) => {
    e.preventDefault()
    if (validLoginEmail && validLoginPassword) {
      setIsLoginError(false)
      setLoginErrorMessage('')
      loginMutation.mutate({ email: loginEmail, password: loginPassword })
    }
    if (!validLoginPassword) {
      setIsLoginError(true)
      betkoErrorPopup(
        'Lozinka mora biti minimum 8 karaktera, sadržati bar jedno veliko slovo, malo slovo, broj i specijalan karakter'
      )
    }
    if (!validLoginEmail) {
      setIsLoginError(true)
      betkoErrorPopup('Email nije ispravan')
    }
  }

  const registerMutation = useMutation({
    mutationFn: (userData) => {
      return axios.post('/register', userData)
    },
    onSuccess: () => {
      setLogin(true)
      betkoSuccessPopup('Uspesno ste se registrovali')
      // exact: true;
    },
    onError: (error) => {
      console.log(error)
      if (error.response.status === 410) {
        setIsError(true)
        betkoErrorPopup('Username već postoji')
      }
      if (error.response.status === 409) {
        setIsError(true)
        betkoErrorPopup('Ovaj email je već registrovan')
      }
      if (error.response.status === 401) {
        setIsError(true)
        betkoErrorPopup('Email nije registrovan')
      }
      if (error.response.status === 429) {
        setIsError(true)
        betkoErrorPopup(
          'Previse pokusaja logovanja, pokusajte opet za 60 sekundi.'
        )
      }
    },
  })
  const loginMutation = useMutation({
    mutationFn: async (userData) => {
      const response = await axios.post('/auth', userData, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      const accessToken = response?.data?.accessToken
      const roles = response?.data?.roles
      const paidToDate = new Date(response?.data?.paidToDate).getTime()
      const email = response?.data?.email
      const _id = response?.data?._id
      const commentRestricted = response?.data?.commentRestricted
      const status = response?.data?.status
      const username = response?.data?.username
      const emailNotification = response?.data?.emailNotification
      const commentNotification = response?.data?.commentNotification
      const moderator = response?.data?.moderator
      const emailNotificationPosts = response?.data?.emailNotificationPosts
      const isMisterTipster = response?.data?.isMisterTipster
      const name = response?.data?.name
      const address = response?.data?.address
      const place = response?.data?.place
      const zipCode = response?.data?.zipCode
      const phoneNumber = response?.data?.phoneNumber
      const misterTipsterNotification =
        response?.data?.misterTipsterNotification
      const accessAdminShop = response?.data?.accessAdminShop

      setAuth({
        email,
        accessToken,
        roles,
        paidToDate,
        _id,
        commentRestricted,
        status,
        username,
        emailNotification,
        commentNotification,
        moderator,
        emailNotificationPosts,
        isMisterTipster,
        name,
        address,
        place,
        zipCode,
        phoneNumber,
        misterTipsterNotification,
        accessAdminShop,
      })
    },
    onSuccess: () => {
      navigate(from, { replace: true })
    },
    onError: (error) => {
      if (error.response.status === 401) {
        setIsLoginError(true)

        betkoErrorPopup('Pogrešan mail ili lozinka!')
      }
      if (error.response.status === 429) {
        setIsLoginError(true)
        betkoErrorPopup(
          'Previše pokušaja logovanja, pokušajte opet za 60 sekundi.'
        )
      }
    },
  })

  return (
    <div
      className={`${classes.authContainer} ${
        login ? classes.loginHeight : classes.registrationHeight
      }`}
    >
      <div className={classes.authTabs}>
        <button
          className={`${classes.tabButton} ${!login ? classes.activeTab : ''}`}
          onClick={() => setLogin(false)}
        >
          Registracija
        </button>
        <button
          className={`${classes.tabButton} ${login ? classes.activeTab : ''}`}
          onClick={() => setLogin(true)}
        >
          Logovanje
        </button>
      </div>

      {!login ? (
        <div className={classes.registrationForm}>
          <form onSubmit={handleRegistration}>
            <input
              className={classes.input}
              type='text'
              placeholder='Username'
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <input
              className={classes.input}
              type='email'
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div>
            <Input.Password
              className={classes.input}
              placeholder='Lozinka'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              iconRender={(visible) =>
                visible ? <EyeOutlined style={{ color: '#fff' }} /> : <EyeInvisibleOutlined style={{ color: 'white' }} />
              }
            />
            </div>
           
            <Input.Password
              className={classes.input}
              placeholder='Ponovite Lozinku'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              iconRender={(visible) =>
                visible ? <EyeOutlined style={{ color: '#fff' }} /> : <EyeInvisibleOutlined style={{ color: 'white' }} />
              }
            />
            <p className={classes.green}>
              Napomena: Nova lozinka mora imati minimum 8 karaktera i mora
              sadržati bar jedno veliko slovo, bar jedno malo slovo, bar jedan
              broj i bar jedan specijalan karakter.
            </p>
            <div className={classes.checkboxContainer}>
              <input
                type='checkbox'
                id='ageConfirm'
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
              <label htmlFor='ageConfirm'>
                Potvrđujem da imam više od 18 godina.
              </label>
            </div>
            <div className={classes.checkboxContainer}>
              <input
                type='checkbox'
                id='terms'
                checked={checkedTerms}
                onChange={() => setCheckedTerms(!checkedTerms)}
              />
              <label htmlFor='terms'>
                Saglasan sam i prihvatam{' '}
                <Link to='/opsti_uslovi' target='_blank'>
                  opšte uslove
                </Link>{' '}
                i{' '}
                <Link to='/politika-privatnosti' target='_blank'>
                  politiku privatnosti
                </Link>{' '}
                korišćenja betkotip.com
              </label>
            </div>
            <button
              className={classes.submitButton}
              type='submit'
              disabled={registerMutation.isLoading}
            >
              Registracija
            </button>
          </form>
        </div>
      ) : (
        <div className={classes.loginForm}>
          <form onSubmit={handleLogin}>
            <input
              className={classes.input}
              type='email'
              placeholder='Email'
              value={loginEmail}
              onChange={(e) => setLoginEmail(e.target.value)}
            />
            <Input.Password
              className={classes.input}
              placeholder='Lozinka'
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
              iconRender={(visible) =>
                visible ? <EyeOutlined style={{ color: '#fff' }} /> : <EyeInvisibleOutlined style={{ color: 'white' }} />
              }
            />
            <div className={classes.forgotPassword}>
              <p onClick={() => navigate('/zaboravio_sam_lozinku')}>
                Zaboravio sam lozinku
              </p>
            </div>
            <button
              className={classes.submitButton}
              type='submit'
              disabled={loginMutation.isLoading}
            >
              Login
            </button>
          </form>
        </div>
      )}
    </div>
  )
}

export default Auth
