import { Outlet, useLocation } from 'react-router-dom'
import classes from './homeLayout.module.css'
import Header from '../components/Redesign/Header/Header'
import News from '../components/News'
import Sidebar from '../components/Redesign/Sidebar/Sidebar'
import { useEffect, useState } from 'react'
import SideImagesWide from '../components/SideImagesWide'
import Footer from '../components/Footer'
import { RefProvider } from '../context/RefContext'
// import PWAPullToRefresh from '../utils/PWAPullToRefresh'

const HomeLayout = ({ betkoShop }) => {
  const [isSidebarActive, setIsSidebarActive] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const location = useLocation()
  const isHomePage = location.pathname === '/'

  const closeSidebar = () => setIsSidebarActive(false)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const widthTreshold = location.pathname === '/rezultati_uzivo' ? 1850 : 1600
  const showRightSideAds = windowWidth >= widthTreshold

  return (
    <RefProvider>
      <div className={classes.outerContainer}>
        <div style={{ position: 'relative', zIndex: '100' }}>
          <Header
            setIsSidebarActive={setIsSidebarActive}
            isSidebarActive={isSidebarActive}
          />
          {isSidebarActive && <Sidebar onClose={closeSidebar} />}
          {isHomePage && <News />}
        </div>
        {/* <PWAPullToRefresh> */}
        <div
          className={`${classes.container} ${
            isHomePage ? '' : classes.noNewsBar
          }`}
        >
          <div className={classes.sideImage}>
            <SideImagesWide side={showRightSideAds ? 'left' : undefined} />
          </div>

          <div className={classes.containerInner}>
            {betkoShop || <Outlet />}
          </div>

          {showRightSideAds && (
            <div className={classes.sideImage}>
              <SideImagesWide side='right' />
            </div>
          )}
        </div>
        {/* </PWAPullToRefresh> */}
        <Footer />
      </div>
    </RefProvider>
  )
}

export default HomeLayout
