const PravilaIgreData = {
  'Najvažnija-pravila':{
    title: "Najvažnija pravila",
    texts: [
      'Platforma betkotip.com (u daljem tekstu: Organizator), organizuje takmičenje iz oblasti poznavanja sporta, pod nazivom „Mister Tipster“. Ovo je takmičenje u kom pravo učešća imaju svi punoletni građani koji se registruju na platformi  https://www.betkotip.com/. Takmičenje "Mister Tipster" ne smatra se igrom na sreću u smislu Zakona o igrama na sreću, s obzirom na to da učesnici ne plaćaju bilo kakvu naknadu za učešće u takmičenju, dok osvajanje nagrade zavisi isključivo od znanja učesnika. (u daljem tekstu: Učesnik ili Igrač).',
      'Uslov za učešće u takmičenju je registracija učesnika na platformi betkotip.com.',
      'U slučaju da je dodeljivač "Freebet" nagrada ili bilo kojih drugih nagrada "Organizator igara na sreću" (u daljem tekstu kladionica), učesnik u takmičenju mora imati registrovan nalog kod dodeljivača sa istom, jedinstvenom e-mail adresom na kojoj mu je registrovan i nalog na platformi betkotip.com',
      'Nalog kod dodeljivača nagrade mora biti registrovan najkasnije do kraja prve polovine aktuelnog takmičenja :',
      'Rang takmičenje - zaključno sa istekom 5-tog danom istog.',
      'Mesečno takmičenje - Zaključno sa istekom 15-tog dana istog.',
      'Godišnje takmičenje - Zaključno sa istekom 6-tog meseca istog.',
      'Učešćem u takmičenju "Mister Tipster", prihvatate svoju apsolutnu saglasnost vezano za sva navedena pravila, uključujući kako iznad  navedena najvažnija, tako i sva ostala pravila koja možete pročitati unutar menija na stranici menija "Otvori i osvoji / Pravila igre". '
    ]
  },
  'otvaranje-profila': {
    title: 'Otvaranje profila',
    texts: [
      'Jednostavnim procesom registracije na betkotip.com automatski se otvara i vaš profil za “Mister Tipster” igru, sa čim postajete takmičar u okviru iste.',
    ],
  },
  'email-notifikacije': {
    title: 'E-mail notifikacije',
    texts: [
      'Na svom “Mister Tipster” profilu možete da isključite e-mail obaveštenja za čekiranje opcije. To nije preporuka, jer vam obaveštenja pomažu u sprovođenju igre i daju vam takmičarsku prednost za osvajanje takmičarskih nagrada, jer ste sa svakim neodigranim “Tipster Match-om” u zaostatku za ostalim takmičarima.',
      'Registracijom na betkotip.com startna postavka može biti da Vam stižu e-mailovi obaveštenja ili ne, na dnevnom nivou, za svakodnevni "Tipster Match" program.',
    ],
  },
  'tipster-match': {
    title: 'Tipster match',
    texts: [
      'Svakodnevno imate svoj “Tipster Match”, koji je potrebno da odigrate tako što ćete jednim klikom prognozirati ishode utakmica koje su vam postavljene za taj dan.',
      'Zbir kvota parova koje ste pogodili, prenosi se u tabele takmičenja, na osnovu čega se prema ukupnom zbiru u okviru svakog od takmičenja određuje vaš i plasman drugih igrača.',
      'Na ”Tipster Tabelama” pobeđuje onaj takmičar čiji je zbir pogođenih kvota najveći u odnosu na druge takmičare u “Tipster Match-evima”.',
      'Ukoliko je zbir pogođenih kvota u “Tipster Tabelama” jednak kod dva ili više učesnika "Mister-Tipster takmičenja", prednost na tabeli u plasmanu imaju takmičari koji su se ranije registrovali na betkotip.com.',
      'U slučaju odložene fudbalske utakmice koja se nalazi u ”Tipster Match-u”, za istu se računa kvota 0.00, kao i u slučaju prekida iste u bilo kom periodu utakmice.',
      'Ukoliko se takmičar ne pojavi i ne odigra svoj ”Tipster Match”, on neće dobiti poene, jer ih nije ni osvojio, shodno tome da nije postavio svoje tipove. Igrajte kako biste osvajali poene i bili konkurenti u takmičenjima za nagrade.',
      'Takmičar u ”Tipster Match-u” samo jedan put može da obeleži izabrani tip na prikazanu utakmicu. Takmičar ne može da menja tip nakon što se odlučio za svoj izbor istog. Nakon što takmičar obeleži tip, na svakom obeleženom tipu kockica koju je obeležio postaće žute boje.',
      'Nakon završetka “Tipster Match-a” pogođeni ishodi će biti obeleženi zelenom bojom kockice, a promašeni crvenom bojom kockice.',
      'Ishod svog ”Tipster Match-a” možete pogledati direktnom posetom betkotip.com, ulazom na svoj “Mister Tipster” nalog. Nakon što se utakmice završe, postavkom novih "Tipster Match-eva za sledeći dan, utakmice od prošlog dana nalaziće se na vašem "Mister Tipster profilu", u arhiviranom delu utakmica.',
    ],
  },
  'tipster-rang-takmicenje': {
    title: 'Tipster rang takmičenje',
    texts: [
      'Ciklus jednog “Tipster rang takmičenja” traje 10 dana u kojima takmičar tipuje ishode postavljenih utakmica sa postavljenim Betko kvotama.',
      'Pozicije na tabeli “Rang takmičenja” određuje broj osvojenih bodova (zbir pogođenih kvota) iz ciklusa aktuelne lige u “Tipster Match” takmičenju.',
      'Ukoliko dva takmičara osvoje isti broj bodova, prednost u višem plasmanu ima takmičar koji se ranije registrovao na betkotip.com.',
      'Pozicije u rangovima se menjaju kontinuirano. Naspram rezultata koje ostvaruju takmičari, pozicije se menjaju iz momenta u momenat.',
      'Nakon završenog ciklusa od deset dana, bodovi u rang tabeli se anuliraju, takmičari započinju novi desetodnevni ciklus takmičenja, na potpuno ravnopravnim bodovnim, anuliranim osnovama.',
      'Prvi takmičar iz “Ranga 1” nakon svakog završenog desetodnevnog ciklusa dobija osvojenu takmičarsku nagradu, postavljenu i određenu za svaki ciklus pre početka takmičenja u meniju "Fond takmičarskih nagrada".',
      'Tabela iz prethodnog ciklusa ostaje u prikazu u arhivi, dobitnik je dužan da se javi do isteka roka za preuzimanje nagrada (korisnik je dužan da se javi administraciji sajta e-mailom na kom mu je registrovan nalog na betkotip.com u roku od najviše 10 dana za preuzimanje ili dostavu nagrada).',
    ]
    
  },
  'tipster-mesecno-takmicenje': {
    title: 'Tipster mesečno takmičenje',
    texts: [
      'U “Tipster mesečnom takmičenju” učestvuju svi takmičari.',
      'U “Tipster mesečnom takmičenju” tabela je ujedinjena za sve takmičare.',
      'U “Tipster mesečnom takmičenju” zbrajaju se svi osvojeni bodovi na mesečnom nivou u okviru svih odigranih “Tipster match-eva”, svih takmičara iz svih rangova istog u istaknutom trajanju takmičarske igre ("Fond za takmičarske nagrade").',
      'Pozicije na tabeli “Tipster mesečnog takmičenja” određuje broj osvojenih bodova (zbir pogođenih kvota) iz ciklusa u “Tipster match” takmičenju u roku trajanja takmičenja.',
      'Ukoliko dva takmičara osvoje isti broj bodova (zbir pogođenih kvota), prednost u višem plasmanu ima takmičar koji se ranije registrovao na betkotip.com.',
      'Ciklus jednog “Tipster mesečnog takmičenja” traje okvirno od 1. dana u mesecu do 1. dana u sledećem mesecu, tj. precizno tri ciklusa završenog rang takmičenja, nakon čega se tabela anulira, pri čemu sledeći ciklus svi takmičari počinju na ravnopravnim osnovama sa istim brojem bodova, nula (0).',
      'Prve tri pozicije na tabeli “Tipster mesečnog takmičenja” osvajaju nagrade prikazane u fondu nagrada pre početka takmičenja.',
      'Tabela iz prethodnog mesečnog ciklusa ostaje u prikazu, korisnici su dužni da se jave do isteka roka za preuzimanje nagrada (korisnik je dužan da se javi administraciji sajta e-mailom na kom mu je registrovan nalog na betkotip.com u roku od najviše 25 dana za preuzimanje ili dostavu nagrada).',
    ]
    
  },
  'tipster-godisnje-takmicenje': {
    title: 'Tipster godišnje takmičenje',
    texts: [
      'U “Tipster godišnjem takmičenju” učestvuju svi takmičari.',
      'U “Tipster godišnjem takmičenju” tabela je ujedinjena za sve takmičare.',
      'U “Tipster godišnjem takmičenju” zbrajaju se svi osvojeni poeni na mesečnom nivou u okviru svih odigranih “Tipster match”, svih takmičara iz svih rangova istog u naznačenom trajanju godišnjeg ciklusa.',
      'Pozicije na tabeli “Tipster godišnjeg takmičenja” određuje broj osvojenih bodova (zbir pogođenih kvota) iz ciklusa u “Tipster match” takmičenju u naznačenom periodu takmičenja ("Fond za osvojene nagrade").',
      'Ukoliko dva takmičara osvoje isti broj bodova (isti zbir pogođenih kvota), prednost u višem plasmanu ima takmičar koji se ranije registrovao na betkotip.com.',
      'Ciklus jednog “Tipster godišnjeg takmičenja” traje okvirno od 1. dana u godini do 1. dana u sledećoj godini, sa završenim ciklusom mesečnog takmičenja i poslednjeg rang takmičenja u okviru mesečnog takmičenja, nakon čega se tabela anulira, pri čemu sledeći ciklus svi takmičari počinju na ravnopravnim osnovama sa istim brojem bodova, nula (0).',
      'Prve tri pozicije na tabeli “Tipster godišnjeg takmičenja” osvajaju nagrade prikazane u "Fondu nagrada pre početka takmičenja".',
      'Tabela iz prethodnog godišnjeg ciklusa ostaje u prikazu, korisnici su dužni da se jave do isteka roka za preuzimanje nagrada (korisnik je dužan da se javi administraciji sajta e-mailom na kom mu je registrovan nalog na betkotip.com u roku od najviše 25 dana za preuzimanje ili dostavu nagrada).',
    ],
  },
  'tipster-globalno-takmicenje': {
    title: 'Tipster globalno takmičenje',
    texts: [
      '“Tipster globalno takmičenje” se odvija na globalnom nivou, revijalnog je karaktera i u tabelarnom prikazu broji sve osvojene bodove takmičara na globalnom nivou, kroz istoriju celokupne "Mister Tipster" igre.',
    ],
  },
  'periodi-takmicenja-i-takmicarske-nagrade': {
    title: 'Uslovi i periodi takmičenja i takmičarske nagrade',
    texts: [
      'Za svako od takmičenja organizator takmičarske igre unapred određuje period trajanja takmičenja, tj. ciklus od kada do kada traje takmičenje.',
      'Za svako od takmičenja organizator takmičarske igre unapred određuje takmičarske nagrade po osvojenom plasmanu. Promene nagrada nisu moguće.',
      'Sva tipster takmičenja na betkotip.com podrazumevaju da je reč o takmičarskim igrama u oblasti znanja i veštine prognoziranja ishoda sportskih događaja. Takmičari nemaju nikakva materijalna ulaganja.',
      'Svaki registrovani korisnik na sajtu betkotip.com i na mobilnoj aplikaciji betko automatski postaje i takmičar u svim takmičarskim igrama koje se odvijaju na betkotip.com sajtu, uz opciju sopstvenog izbora da ne mora da se takmiči, što postiže jednostavno tako što neće sprovoditi takmičarske aktivnosti.',
      'Ukoliko u organizaciji sportskog događaja dođe do promene vremena početka istog, za taj sportski događaj će se računati koeficijent 0 (slovima : 0) u zbiru kvota, osim ukoliko betkotip.com ne odredi drugačije (moguć primer: sportski događaj je trebao da počne u 12:00 časova, a početak istog je pomeren za 13:00 časova).',
      'U slučaju promene mesta odigravanja sportskog događaja, pravila igre naznačuju da to ne podrazumeva i promene kvota, tj. koeficijenata čiji zbir ulazi u takmičarske rezultate, sa čim se štiti regularnost takmičenja, usklađeno sa činjenicom da su neki od takmičara mogli odigrati taj sportski događaj u okviru svog mister tipster meča, pre nego što je došlo do promene mesta odigravanja sportskog događaja.',
      'Bet coin, token i freebet su oznake za fiktivna sredstva iskazane u nepostojećoj monetarnoj valuti. Kada su ona prikazana kao takmičarske nagrade od strane bilo kog dodeljivača istih, to podrazumeva da je takmičar dužan, ukoliko želi da ih preuzme, odgovori na sve uslove dodeljivača takmičarskih nagrada, i da se takmičar apsolutno slaže sa tim. Betkotip.com ne preuzima odgovornost za eventualno nedodeljivanje takmičarskih nagrada od strane treće strane, tj. dodeljivača istih, kao ni za manjkavosti i neispunjavanje uslova od strane takmičara ka dodeljivaču osvojenih takmičarskih nagrada.',
      'Bet coin, token, bonus i freebet služe isključivo za sprovođenje takmičarske igre, i na betkotip.com ih je nemoguće isplatiti u bilo kojoj monetarnoj valuti niti ih zameniti za novac. Ukoliko je dodeljivač takmičarske nagrade treća strana, sva pravila podležu pravilima iste, utvrđeno i usklađeno sa pravilima sajta ili platforme dodeljivača takmičarske nagrade ili takmičarskih nagrada.',
      'Bet coin, token i freebet su lična stvar takmičara, i ne mogu se prebacivati na drugog takmičara ili na treće lice.',
      'Ukoliko je treća strana obeležena kao dodeljivač osvojene nagrade ili su više strana obeležene kao dodeljivači nagrada organizatori igre, potrebno je da takmičar ima registrovan nalog uz ispunjene uslove svake od strana dodeljivača nagrada na platformama istih i da ispunjava sve postavljene uslove pri registraciji i eventualno verifikaciji svog naloga, kako bi mogao da potražuje i preuzme osvojenu nagradu.',
      'Treća strana kao dodeljivač takmičarske nagrade, može da obustavi dodelu iste, ukoliko takmičar iz bilo kog razloga ne ispunjava uslove pravila iste, koji su jasno naznačeni na sajtovima i na platformama treće strane.',
      'Ukoliko dodeljivači nagrada kao treće strane, za iste postavljaju bet coine, tokene, bonuse ili freebet sa svojih sajtova, u slučaju da su to na istima oznake za fiktivna sredstva iskazana u nepostojećoj monetarnoj valuti, smatra se da preuzimaju svoju odgovornost za postavku takvih nagrada i za dodelu istih.',
      'Zbog specifičnosti takmičarskih igara i mogućnosti uključenja u iste u svakom trenutku njihovog odvijanja (takmičar koji se naknadno uključuje u takmičarsku igru ne može biti u prednosti u odnosu na takmičare koji su se pre uključili u istu, shodno tome da se zbrajaju ukupni bodovi pogođenih kvota/koeficijenata, te naknadna uključenja u takmičarsku igru ne remete regularnost takmičenja) takmičar može da registruje i verifikuje i naknadno nalog ukoliko nema isti, kod dodeljivača nagrada, bez obzira da li je to uradio pre početka takmičenja (ukoliko nema nalog) ili u toku istog.',
      'Ukoliko su dodeljivači nagrada treće strane, tj. ukoliko nagrade ne dodeljuje direktno, fizički ili na bilo koji drugi način betkotip.com, koji je u ovom slučaju samo posrednik u takmičarskoj igri za koju nagrade dodeljuju treće strane, sve eventualne poreske obaveze i troškove vezano za iste na sebe preuzimaju dodeljivači nagrada.',
      'U slučaju sumnji na prevaru, zloupotrebu pravila aktuelne takmičarske igre ili tehničkih problema, kao i faktora koji su van nadležnosti organizatora takmičarske igre, a mogu uticati na integritet i pravilno funkcionisanje takmičarske igre, betkotip.com zadržava pravo nedodeljivanja osvojene nagrade takmičaru, kao i sve takmičarske dobitke proistekle iz igre u kojoj se sumnja na zloupotrebu pravila takmičarske igre.',
      'Betkotip.com kao organizator takmičarske igre zadržava pravo da u svakom trenutku, bez prethodne najave, privremeno ili u potpunosti prekine takmičarsku igru celini ili na određeno vreme, isključivo u slučaju uočavanja nepravilnosti pri istoj, elementarnih nepogoda ili bilo kakvih nepredviđenih tehničkih problema ili slično, ukoliko isti ili bilo šta ugrožavaju regularnost bilo koje od takmičarskih igara.',
      'Takmičari koji su osvojili takmičarske nagrade, kako je to naznačeno u pravilima za svaku od takmičarskih igara pojedinačno, dužni su da se u preciziranom roku jave sa svog e-maila na koji su registrovani na e-mail betko.podrska@gmail.com radi preuzimanja svoje nagrade.',
      'Definicije pojmova u takmičarskoj igri \n   1 - Pobeda domaće ekipe ili tima 1 \n   X - Nerešen rezultat / sportski događaj nema pobednika \n   2 - Pobeda gostujuće ekipe ili tima 2 \n   Utakmica je počela - Ukoliko je sportski događaj počeo, takmičar gubi pravo na odigravanje istog, i taj sportski događaj se softverski blokira za dalje odigravanje',
    ]
    
  },
  'tipster-profil': {
    title: 'Tipster profil',
    texts: [
      'Verifikaciju e-mail obaveštenja možete da obeležite ili da uklonite jedino sa svog "Tipster Match profila". Ostali podaci su transparentni i dostupni svim takmičarima.',
      'U okviru menija “Tipster profil” možete da pogledate svoje, ali i rezultate svih drugih registrovanih korisnika, koji sa registracijom na BetkoTip.com po automatizmu učestvuju u “Mister Tipster” takmičenjima.',
    ],
    
  },
}

export default PravilaIgreData
