import classesBK from './betKalkulatoriTutorial.module.css'
import LayoutWithCalcAndSideImgs from '../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'
import PostList from '../components/ForumPostList'

const BetKalkulatoriTutorial = () => {
  return (
    <LayoutWithCalcAndSideImgs title={'BET KALKULATORI TUTORIJAL'}>
      <div className={classesBK.infoContainer}>
        <h2>Poštovani korisnici.</h2>
        <span>
          Trenutno su u toku radovi na redizajnu "Bet Kalkulatora". Opise novih,
          modernih i osveženih kalkulatora koji su ( ili će biti ) pušteni u
          rad, možete pogledati u meniju "KORISNIČKI PAKETI" - "BET
          KALKULATORI", klikom na "TUTORIJAL" na svakom kalkulatoru pojedinačno
          nakon što bude pušten u rad.
        </span>
        <span>Hvala Vam na razumevanju.</span>
      </div>

      <PostList
        postUrl='/getAllBetKalkulatoriTutorijalPosts'
        type='betKalkulatoriTutorijal'
      />
    </LayoutWithCalcAndSideImgs>
  )
}

export default BetKalkulatoriTutorial
