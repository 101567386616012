import 'swiper/css/pagination'
import LayoutWithCalcAndSideImgs from '../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'
import ForumPostList from '../components/ForumPostList'

const SportskePricePage = () => {
  return (
    <LayoutWithCalcAndSideImgs title='SPORTSKE PRIČE'>
      <ForumPostList
        postUrl='/betko-info-wall-pagination'
        type='sportskePrice'
        isSportsStories={true}
        hasPagination={true}
      />
    </LayoutWithCalcAndSideImgs>
  )
}

export default SportskePricePage
