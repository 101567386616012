import React from 'react'
import classes from './productCardNew.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import useCountdown from '../../hooks/useCountdown'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const ProductCardNew = ({ product, isHomePage, onSelectProduct}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isAuction = product?.shopType === 'auction'
  const isSportTourist = product?.shopType === 'sport-tourist'

  const hiddenAuctionCategories = ['HUMANITARNA SMS POMOĆ', 'HUMANA POMOĆ NA RAČUN']

  const shouldShowTimer = 
  isSportTourist || (isAuction && !hiddenAuctionCategories.includes(product?.category?.name))

  const timeRemaining = useCountdown(product?.expireDate)

  return (
    <div
      className={`${classes.singleProductCard} ${
        !isHomePage ? classes.shopPage : classes.homePage
      }`}
      onClick={() => {
        if (onSelectProduct) {
          onSelectProduct(product)
        } else {
          const params = new URLSearchParams(location.search)
          params.set('productId', product?._id)
          params.set('shopType', product?.shopType)

          const newPath = isHomePage
            ? `/betko_shop/shop?${params.toString()}`
            : `?${params.toString()}`

          navigate(newPath)
        }
      }}
    >
      <div className={classes.cardHeader}>
      {shouldShowTimer && <div className={classes.timer}>{timeRemaining}</div>}
        <img src={`${SERVER_URL}/${product?.images?.url}` || '/tiger.webp'} alt="product image" />
      </div>
      <div className={classes.cardBody}>
      <h3>{product?.title}</h3>
      <h2>{product?.cardNumber
            ? product.cardNumber
            : product.sms
            ? product?.sms
            : `${product?.price} RSD`}{' '}</h2>
      <button className={classes.buyButton}>Klikni i saznaj više</button>
      </div>
    </div>
  )
}

export default ProductCardNew
