import { useNavigate, useLocation } from 'react-router-dom'
import classes from './adminEditPostTutorijal.module.css'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useState, useRef, useEffect } from 'react'
import { Modal, Input, InputNumber, Select, Button, Checkbox } from 'antd'
import Axios from 'axios'
import { DeleteOutlined } from '@ant-design/icons'
import svggore from './assets/arrowup.svg'
import svgdole from './assets/arrowdown.svg'
import Pagination from '../components/Pagination'
import { betkoErrorPopup, betkoSuccessPopup } from './../utils/notifications'

const { TextArea } = Input
const { Option } = Select
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const AdminEditPost = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const axiosPrivate = useAxiosPrivate()
  const id = location.state.postId
  const type = location.state.type
  const [reRender, setReRender] = useState()
  //////
  const [data, setData] = useState()
  const [filteredImages, setFilteredImages] = useState([])
  const [title, setTitle] = useState()
  const [pageTitle, setPageTitle] = useState()
  const [imageType, setImageType] = useState('')
  const [textField, setTextField] = useState('')
  const [calcType, setCalcType] = useState()
  const [calculatorTypes, setCalculatorTypes] = useState()
  const [selectedItem, setSelectedItem] = useState()
  const [isDelModalVisible, setIsDelModalVisible] = useState(false)
  const [images, setImages] = useState([])
  const [selectedFeatureImage, setSelectedFeatureImage] = useState()
  const [selectedMarketingImage, setSelectedMarketingImage] = useState()
  const [newItems, setNewItems] = useState([])
  const [oldItems, setOldItems] = useState([])
  const [marketingLink, setMarketingLink] = useState('')
  const [itemIndex, setItemIndex] = useState([])
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [isVisibleModalOldItems, setIsVisibleModalOldItems] = useState(false)
  const [videos, setVideos] = useState([])
  const [isVisibleVideoModal, setIsVisibleVideoModal] = useState(false)
  const [isVisibleNewVideoModal, setIsVisibleNewVideoModal] = useState(false)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)
  const [videoType, setVideoType] = useState('')
  const [selectedFeatureVideo, setSelectedFeatureVideo] = useState()
  const [selectedMarketingVideo, setSelectedMarketingVideo] = useState()

  const handleShowVideoModal = () => {
    setIsVisibleVideoModal(true)
  }

  const handleShowNewVideoModal = () => {
    setIsVisibleNewVideoModal(true)
  }

  const handleSelectVideo = (video) => {
    if (videoType == 'featureVideo') {
      setSelectedFeatureVideo(video)
      setSelectedFeatureImage(null)
      setIsVisibleVideoModal(false)
      setReRender(!reRender)
    } else if (videoType == 'marketingVideo') {
      setSelectedMarketingVideo(video)
      setSelectedMarketingImage(null)
      setIsVisibleVideoModal(false)
      setReRender(!reRender)
    } else {
      let arr = oldItems

      arr[itemIndex].video = video

      setOldItems(arr)

      setIsVisibleVideoModal(false)
      setReRender(!reRender)
    }
  }

  const handleSelectNewVideo = (video) => {
    let arr = newItems

    arr[itemIndex].video = video

    setNewItems(arr)

    setIsVisibleNewVideoModal(false)
    setReRender(!reRender)
  }

  const handleRemoveFromAdding = (index) => {
    let array = newItems
    array.splice(index, 1)

    setNewItems(array)
    setReRender(!reRender)
  }

  const fetchCalculatorTypes = async () => {
    try {
      const calcTypes = await Axios.get(`${SERVER_URL}/calculator-types-free`, {
        withCredentials: false,
      })

      setCalculatorTypes(calcTypes.data)
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleSubmit = async () => {
    try {
      const data = {
        title,
        featureImage: selectedFeatureImage?._id
          ? selectedFeatureImage?._id
          : null,
        marketingImage: selectedMarketingImage?._id
          ? selectedMarketingImage?._id
          : null,
        featureVideo: selectedFeatureVideo ? selectedFeatureVideo._id : null,
        marketingVideo: selectedMarketingVideo
          ? selectedMarketingVideo._id
          : null,
        marketingLink: marketingLink,
        textField: textField,
        newItems,
        oldItems,
      }

      if (!data.title) {
        betkoErrorPopup('Unesite naslov posta!')
        return
      }
      if (!data.featureImage && !data.featureVideo) {
        betkoErrorPopup('Izaberite naslovnu sliku/video!')
        return
      }
      if (!data.marketingImage && !data.marketingVideo) {
        betkoErrorPopup('Izaberite marketing sliku/video!')
        return
      }
      if (!data.marketingLink) {
        data.marketingLink = 'https://betkotip.com'
      }
      if (!data.textField) {
        betkoErrorPopup('Unesite text polje!')
        return
      }

      if (data.newItems) {
        for (let i = 0; i < data.newItems.length; i++) {
          if (data.newItems[i].type === 'img') {
            if (data.newItems[i].image === '') {
              betkoErrorPopup('Stavka ne može postojati bez izabrane slike!')
              return
            }
          }

          if (data.newItems[i].type === 'text') {
            if (data.newItems[i].description === '') {
              betkoErrorPopup('Stavka ne može postojati bez opisa!')
              return
            }
            if (data.newItems[i].color === '') {
              betkoErrorPopup('Stavka ne može postojati bez odabrane boje!')
              return
            }
          }
        }
      }

      if (data.oldItems) {
        for (let i = 0; i < data.oldItems.length; i++) {
          if (data.oldItems[i].type === 'img') {
            if (data.oldItems[i].image === '') {
              betkoErrorPopup('Stavka ne može postojati bez izabrane slike!')
              return
            }
          }

          if (data.oldItems[i].type === 'text') {
            if (data.oldItems[i].description === '') {
              betkoErrorPopup('Stavka ne može postojati bez opisa!')
              return
            }
            if (data.oldItems[i].color === '') {
              betkoErrorPopup('Stavka ne može postojati bez odabrane boje!')
              return
            }
          }
        }
      }

      setIsSaveButtonDisabled(true)
      await axiosPrivate.put(`${SERVER_URL}/tutorials-post/${id}`, data)

      betkoSuccessPopup('Uspešno izmenjen tutorijal.')

      setTimeout(() => {
        window.location.reload()
      }, 300)
    } catch (error) {
      betkoErrorPopup(error)
    }
  }

  const handleShowModal = (e) => {
    if (e === 'featureVideo') {
      setIsVisibleVideoModal(true)
      setVideoType(e)
    } else if (e === 'marketingVideo') {
      setIsVisibleVideoModal(true)
      setVideoType(e)
    } else {
      setImageType(e)
      setIsVisibleModal(true)
    }
  }

  const handleShowModalOldItems = (e) => {
    setIsVisibleModalOldItems(true)
  }

  const handleCancel = () => {
    setIsVisibleModal(false)
    setIsVisibleVideoModal(false)
  }

  const handleCancelOldItems = () => {
    setIsVisibleModalOldItems(false)
  }

  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(null)

  const paginationProps = {
    currentPage: pageNumber,
    setCurrentPage: setPageNumber,
    buttonLimit: 3,
    totalPages: totalPages,
  }

  const fetchImages = async () => {
    try {
      const imagesArr = await Axios.get(
        `${SERVER_URL}/images/get-all?page=${pageNumber}&limit=50`,
        {
          withCredentials: false,
        }
      )
      setImages(imagesArr.data.results)
      setFilteredImages(imagesArr.data.results)
      setTotalPages(imagesArr.data.totalPages)
    } catch (error) {
      console.log('err', error)
    }
  }

  const fetchData = async () => {
    let singlePost = await axiosPrivate.get(
      `${SERVER_URL}/tutorial/${id}`,
      data
    )

    setData(singlePost.data.post)
    setTitle(singlePost.data.post.title)
    setCalcType(singlePost.data.post.calculatorType)
    setTextField(singlePost.data.post.textField)
    setPageTitle(singlePost.data.post.title)
    setSelectedFeatureImage(singlePost.data.post.featureImage)
    setSelectedMarketingImage(singlePost.data.post.marketingImage)
    setSelectedFeatureVideo(singlePost.data.post.featureVideo)
    setSelectedMarketingVideo(singlePost.data.post.marketingVideo)
    setMarketingLink(singlePost.data.post.marketingLink)
    setOldItems(singlePost.data.post.items)
  }

  const filterImages = (e) => {
    if (e) {
      let filtered = images.filter((item) => item.imageType === e)

      setFilteredImages(filtered)
    } else {
      setFilteredImages(images)
    }
  }

  useEffect(() => {
    fetchImages()
    fetchCalculatorTypes()
    fetchVideos()
    fetchData()
  }, [pageNumber])

  const fetchVideos = async () => {
    try {
      const videosArr = await Axios.get(`${SERVER_URL}/videos/get-all`, {
        withCredentials: false,
      })

      setVideos(videosArr.data)
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleChangeImageLink = (e, index) => {
    let array = newItems
    array[index].imageLink = e.target.value

    setNewItems(array)
  }

  const handleChangeImageLinkOldItem = (e, index) => {
    let array = oldItems
    array[index].imageLink = e.target.value

    setOldItems(array)
  }

  const handleSelectImage = (image) => {
    if (imageType === 'featureImage') {
      setSelectedFeatureImage(image)
    } else if (imageType === 'marketingImage') {
      setSelectedMarketingImage(image)
    } else if (imageType === 'itemImage') {
      let arr = newItems

      arr[itemIndex].image = image

      setNewItems(arr)
    }
    setIsVisibleModal(false)
  }

  const handleSelectImageOldItem = (image) => {
    let arr = oldItems

    arr[itemIndex].image = image

    setOldItems(arr)
    setIsVisibleModalOldItems(false)
  }

  const handleChangeTextArea = (e, index) => {
    let array = newItems
    array[index].description = e.target.value

    setNewItems(array)
  }

  const handleChangeTextAreaOldItem = (e, index) => {
    let array = oldItems

    array[index].description = e.target.value

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleChangeTextColor = (e, index) => {
    let array = newItems
    array[index].color = e

    setNewItems(array)
  }

  const handleChangeTextColorOldItem = (e, index) => {
    let array = oldItems
    array[index].color = e

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleChangeTextFontSizeOldItem = (e, index) => {
    let array = oldItems
    array[index].fontSize = e

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleChangeBold = (e, index) => {
    let array = newItems
    array[index].bold = !array[index].bold

    setNewItems(array)
  }

  const handleChangeTextFontSize = (e, index) => {
    let array = newItems
    array[index].fontSize = e

    setNewItems(array)
    setReRender(!reRender)
  }

  const handleChangeItalic = (e, index) => {
    let array = newItems
    array[index].italic = !array[index].italic

    setNewItems(array)
  }

  const handleChangeMarginTop = (e, index) => {
    let array = newItems
    array[index].marginTop = e

    setNewItems(array)
  }

  const handleChangeMarginBottom = (e, index) => {
    let array = newItems
    array[index].marginBottom = e

    setNewItems(array)
  }

  const handleChangeMarginTopOldItem = (e, index) => {
    let array = oldItems
    array[index].marginTop = e

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleChangeMarginBottomOldItem = (e, index) => {
    let array = oldItems
    array[index].marginBottom = e

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleChangeMarketingLink = (e) => {
    setMarketingLink(e.target.value)
  }

  const handleChangeTextField = (e) => {
    setTextField(e.target.value)
  }

  const handleChangeBoldOldItem = (e, index) => {
    let array = oldItems
    array[index].bold = !array[index].bold

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleChangeItalicOldItem = (e, index) => {
    let array = oldItems
    array[index].italic = !array[index].italic

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleRemoveExistingItem = async (itemId) => {
    await axiosPrivate.delete(`${SERVER_URL}/tutorial-item/${id}/${itemId}`)

    betkoSuccessPopup('Uspešno obrisana stavka')

    setTimeout(() => {
      window.location.reload()
    }, 300)
  }

  const handleMoveItem = async (itemId, direction) => {
    try {
      await axiosPrivate.put(
        `${SERVER_URL}/tutorials-moveitem/${id}?direction=${direction}&&itemId=${itemId}`
      )

      betkoSuccessPopup('Uspešno pomerena stavka')

      setTimeout(() => {
        window.location.reload()
      }, 300)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={classes.container}>
      <h1 style={{ marginLeft: '10px' }}>Izmena objave: {pageTitle}</h1>
      <div className={classes.main}>
        <p>Naslov:</p>
        <input
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        ></input>
      </div>
      <div className={classes.main}>
        <p>Naslovna slika:</p>
        {selectedFeatureImage && selectedFeatureImage.url && (
          <img
            style={{ width: '50px', borderRadius: '10px' }}
            src={`${SERVER_URL}/${selectedFeatureImage.url}`}
          />
        )}
        <button
          className={classes.addImageBtn}
          onClick={() => {
            handleShowModal('featureImage')
          }}
        >
          {selectedFeatureImage && selectedFeatureImage.url
            ? selectedFeatureImage.url
            : 'Izaberite sliku'}
        </button>
        {selectedFeatureImage && selectedFeatureImage.url && (
          <Button
            icon={<DeleteOutlined />}
            type='primary'
            style={{
              padding: '0px 0px 0px 20px',
              width: '60px',
              marginLeft: '15px',
              marginTop: '8px',
              display: 'flex',
              alignItems: 'center',
            }}
            danger
            onClick={() => setSelectedFeatureImage(null)}
          ></Button>
        )}
        <button
          className={classes.addImageBtn}
          onClick={() => {
            handleShowModal('featureVideo')
          }}
        >
          {selectedFeatureVideo && selectedFeatureVideo.url
            ? selectedFeatureVideo.url
            : 'Izaberite video'}
        </button>
        {selectedFeatureVideo && selectedFeatureVideo.url && (
          <Button
            icon={<DeleteOutlined />}
            type='primary'
            style={{
              padding: '0px 0px 0px 20px',
              width: '60px',
              marginLeft: '15px',
              marginTop: '8px',
              display: 'flex',
              alignItems: 'center',
            }}
            danger
            onClick={() => setSelectedFeatureVideo(null)}
          ></Button>
        )}
      </div>
      <div className={classes.main}>
        <p>Marketing slika:</p>
        {selectedMarketingImage && selectedMarketingImage.url && (
          <img
            style={{ width: '50px', borderRadius: '10px' }}
            src={`${SERVER_URL}/${selectedMarketingImage.url}`}
          />
        )}
        <button
          className={classes.addImageBtn}
          onClick={() => {
            handleShowModal('marketingImage')
          }}
        >
          {selectedMarketingImage && selectedMarketingImage.url
            ? selectedMarketingImage.url
            : 'Izaberite sliku'}
        </button>
        {selectedMarketingImage && selectedMarketingImage.url && (
          <Button
            icon={<DeleteOutlined />}
            type='primary'
            style={{
              padding: '0px 0px 0px 20px',
              width: '60px',
              marginLeft: '15px',
              marginTop: '8px',
              display: 'flex',
              alignItems: 'center',
            }}
            danger
            onClick={() => setSelectedMarketingImage(null)}
          ></Button>
        )}
        <button
          className={classes.addImageBtn}
          onClick={() => {
            handleShowModal('marketingVideo')
          }}
        >
          {selectedMarketingVideo && selectedMarketingVideo.url
            ? selectedMarketingVideo.url
            : 'Izaberite video'}
        </button>
        {selectedMarketingVideo && selectedMarketingVideo.url && (
          <Button
            icon={<DeleteOutlined />}
            type='primary'
            style={{
              padding: '0px 0px 0px 20px',
              width: '60px',
              marginLeft: '15px',
              marginTop: '8px',
              display: 'flex',
              alignItems: 'center',
            }}
            danger
            onClick={() => setSelectedMarketingVideo(null)}
          ></Button>
        )}
      </div>
      <div className={classes.main}>
        <p>Marketing slika - link:</p>
        <Input
          className={classes.inputMarketingLink}
          value={marketingLink}
          onChange={(e) => {
            handleChangeMarketingLink(e)
          }}
        ></Input>
      </div>
      <div className={classes.main}>
        <p>Text polje (ispod komentariši):</p>
        <Input
          value={textField}
          className={classes.inputMarketingLink}
          onChange={(e) => {
            handleChangeTextField(e)
          }}
        ></Input>
      </div>
      <div className={classes.calcTypeMain}>
        <p>Kalkulator na koji se odnosi:</p>
        <Input
          value={calcType?.type}
          disabled
          className={classes.selectCalculatorType}
        ></Input>
      </div>
      <div>
        <button
          className={classes.addImageBtn}
          onClick={() => {
            setNewItems([
              ...newItems,
              {
                type: 'text',
                description: '',
                color: '',
                bold: false,
                italic: false,
                marginTop: 0,
                marginBottom: 0,
              },
            ])
          }}
        >
          Dodaj tekst
        </button>
        <button
          className={classes.addImageBtn}
          onClick={() => {
            let array = newItems
            let obj = { type: 'img', image: '' }
            array.push(obj)

            setNewItems([...array])
          }}
        >
          Dodaj sliku
        </button>
        <button
          className={classes.addImageBtn}
          onClick={() => {
            let array = newItems
            let obj = { type: 'video', video: '' }
            array.push(obj)

            setNewItems([...array])
          }}
        >
          Dodaj video
        </button>
      </div>

      {newItems && newItems.length > 0 && (
        <div className={classes.labelRow}>Nove stavke </div>
      )}

      {newItems &&
        newItems.length > 0 &&
        newItems.map((item, index) => {
          if (item.type === 'text') {
            return (
              <div className={classes.textitem}>
                <div className={classes.item}>
                  <p>Tekst:</p>
                  <TextArea
                    className={classes.textAreaInput}
                    onChange={(e) => {
                      handleChangeTextArea(e, index)
                    }}
                  ></TextArea>
                </div>
                <div className={classes.item}>
                  <p>Bold:</p>
                  <Checkbox
                    onChange={(e) => {
                      handleChangeBold(e, index)
                    }}
                    type='checkbox'
                  ></Checkbox>
                </div>
                <div className={classes.item}>
                  <p>Italic:</p>
                  <Checkbox
                    onChange={(e) => {
                      handleChangeItalic(e, index)
                    }}
                    type='checkbox'
                  ></Checkbox>
                </div>
                <div className={classes.item}>
                  <p>Boja teksta:</p>
                  <Select
                    onChange={(e) => {
                      handleChangeTextColor(e, index)
                    }}
                    className={classes.colorSelect}
                  >
                    <Option value='#D6FF00'>Florascent žuta</Option>
                    <Option value='#FFFFFF'>Bela</Option>
                    <Option value='#DBDBDD'>Tamnija bela</Option>
                    <Option value='#454A59'>Svetlo siva</Option>
                    <Option value='#353945'>Srednja siva</Option>
                    <Option value='#2B3240'>Tamno siva</Option>
                    <Option value='#000000'>Crna</Option>
                    <Option value='#2E3551'>Verzija plave</Option>
                    <Option value='#CABB90'>Bež</Option>
                  </Select>
                </div>
                <div className={classes.item}>
                  <p>Veličina teksta:</p>
                  <Select
                    value={item.fontSize}
                    onChange={(e) => {
                      handleChangeTextFontSize(e, index)
                    }}
                    className={classes.colorSelect}
                  >
                    <Option value='10'>10px</Option>
                    <Option value='16'>16px</Option>
                    <Option value='20'>20px</Option>
                    <Option value='30'>30px</Option>
                    <Option value='40'>40px</Option>
                    <Option value='50'>50px</Option>
                  </Select>
                </div>
                <div className={classes.item}>
                  <p>Margine (top-bottom):</p>
                  <InputNumber
                    onChange={(e) => {
                      handleChangeMarginTop(e, index)
                    }}
                    type='number'
                    max={10}
                    className={classes.inputNumberMargin}
                    min={0}
                  ></InputNumber>
                  <InputNumber
                    onChange={(e) => {
                      handleChangeMarginBottom(e, index)
                    }}
                    type='number'
                    max={10}
                    className={classes.inputNumberMargin}
                    min={0}
                  ></InputNumber>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => handleRemoveFromAdding(index)}
                ></Button>
              </div>
            )
          } else if (item.type === 'img') {
            return (
              <div className={classes.imageitem}>
                <button
                  className={classes.addImageBtn}
                  onClick={() => {
                    handleShowModal('itemImage')
                    setItemIndex(index)
                  }}
                >
                  {newItems[index] && newItems[index].image.url
                    ? newItems[index].image.url
                    : 'Izaberite sliku'}
                </button>
                <Input
                  onChange={(e) => {
                    handleChangeImageLink(e, index)
                  }}
                  value={item.imageLink}
                  className={classes.inputNumberMargin}
                  style={{ marginRight: '10px' }}
                ></Input>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => handleRemoveFromAdding(index)}
                ></Button>
              </div>
            )
          } else {
            return (
              <div className={classes.imageitem}>
                <div className={classes.videoItemLeft}>
                  {item && item.video && item.video.url && (
                    <video
                      key={`${SERVER_URL}/${item.video.url}`}
                      width='320'
                      height='240'
                      controls
                    >
                      <source
                        src={`${SERVER_URL}/${item.video.url}`}
                        type='video/mp4'
                      />
                    </video>
                  )}
                  <button
                    className={classes.addImageBtn}
                    onClick={() => {
                      handleShowNewVideoModal()
                      setItemIndex(index)
                    }}
                  >
                    {newItems[index] && newItems[index].video.url
                      ? newItems[index].video.url
                      : 'Izaberite video'}
                  </button>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => handleRemoveFromAdding(index)}
                ></Button>
              </div>
            )
          }
        })}

      {oldItems && oldItems.length > 0 && (
        <div className={classes.labelRow}>Stare stavke </div>
      )}

      {oldItems &&
        oldItems.length > 0 &&
        oldItems.map((item, index) => {
          if (item.type === 'text') {
            return (
              <div className={classes.textitem}>
                <div className={classes.textItemLeft}>
                  <div className={classes.arrows}>
                    {index !== 0 && (
                      <img
                        className={classes.svgimg}
                        src={svggore}
                        onClick={() => {
                          handleMoveItem(item._id, 'moveleft')
                        }}
                      />
                    )}
                    {index !== oldItems.length - 1 && (
                      <img
                        className={classes.svgimg}
                        src={svgdole}
                        onClick={() => {
                          handleMoveItem(item._id, 'moveright')
                        }}
                      />
                    )}
                  </div>
                  <div className={classes.item}>
                    <p>Tekst:</p>
                    <TextArea
                      className={classes.textAreaInput}
                      value={item.description}
                      onChange={(e) => {
                        handleChangeTextAreaOldItem(e, index)
                      }}
                    ></TextArea>
                  </div>
                  <div className={classes.item}>
                    <p>Bold:</p>
                    <Checkbox
                      checked={item.bold}
                      onChange={(e) => {
                        handleChangeBoldOldItem(e, index)
                      }}
                      type='checkbox'
                    ></Checkbox>
                  </div>
                  <div className={classes.item}>
                    <p>Italic:</p>
                    <Checkbox
                      checked={item.italic}
                      onChange={(e) => {
                        handleChangeItalicOldItem(e, index)
                      }}
                      type='checkbox'
                    ></Checkbox>
                  </div>
                  <div className={classes.item}>
                    <p>Boja teksta:</p>
                    <Select
                      value={item.color}
                      onChange={(e) => {
                        handleChangeTextColorOldItem(e, index)
                      }}
                      className={classes.colorSelect}
                    >
                      <Option value='#D6FF00'>Florascent žuta</Option>
                      <Option value='#FFFFFF'>Bela</Option>
                      <Option value='#DBDBDD'>Tamnija bela</Option>
                      <Option value='#454A59'>Svetlo siva</Option>
                      <Option value='#353945'>Srednja siva</Option>
                      <Option value='#2B3240'>Tamno siva</Option>
                      <Option value='#000000'>Crna</Option>
                      <Option value='#2E3551'>Verzija plave</Option>
                      <Option value='#CABB90'>Bež</Option>
                    </Select>
                  </div>
                  <div className={classes.item}>
                    <p>Veličina teksta:</p>
                    <Select
                      value={item.fontSize}
                      onChange={(e) => {
                        handleChangeTextFontSizeOldItem(e, index)
                      }}
                      className={classes.colorSelect}
                    >
                      <Option value='10'>10px</Option>
                      <Option value='16'>16px</Option>
                      <Option value='20'>20px</Option>
                      <Option value='30'>30px</Option>
                      <Option value='40'>40px</Option>
                      <Option value='50'>50px</Option>
                    </Select>
                  </div>
                  <div className={classes.item}>
                    <p>Margine (top-bottom):</p>
                    <InputNumber
                      value={item.marginTop}
                      onChange={(e) => {
                        handleChangeMarginTopOldItem(e, index)
                      }}
                      type='number'
                      max={10}
                      className={classes.inputNumberMargin}
                      min={0}
                    ></InputNumber>
                    <InputNumber
                      value={item.marginBottom}
                      onChange={(e) => {
                        handleChangeMarginBottomOldItem(e, index)
                      }}
                      type='number'
                      max={10}
                      className={classes.inputNumberMargin}
                      min={0}
                    ></InputNumber>
                  </div>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => {
                    setSelectedItem(item._id)
                    setIsDelModalVisible(true)
                  }}
                ></Button>
              </div>
            )
          } else if (item.type === 'img') {
            return (
              <div className={classes.imageitem}>
                <div className={classes.imageitemLeft}>
                  <div className={classes.arrows}>
                    {index !== 0 && (
                      <img
                        className={classes.svgimg}
                        src={svggore}
                        onClick={() => {
                          handleMoveItem(item._id, 'moveleft')
                        }}
                      />
                    )}
                    {index !== oldItems.length - 1 && (
                      <img
                        className={classes.svgimg}
                        src={svgdole}
                        onClick={() => {
                          handleMoveItem(item._id, 'moveright')
                        }}
                      />
                    )}
                  </div>
                  <img
                    style={{ width: '50px', borderRadius: '10px' }}
                    src={`${SERVER_URL}/${item.image.url}`}
                  />
                  <Input
                    onChange={(e) => {
                      handleChangeImageLinkOldItem(e, index)
                    }}
                    value={item.imageLink}
                    className={classes.inputNumberMargin}
                    style={{ marginRight: '10px' }}
                  ></Input>
                  <button
                    className={classes.addImageBtn}
                    onClick={() => {
                      handleShowModalOldItems('itemImage')
                      setItemIndex(index)
                    }}
                  >
                    {oldItems[index] && oldItems[index].image.url
                      ? oldItems[index].image.url
                      : 'Izaberite sliku'}
                  </button>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => {
                    setSelectedItem(item._id)
                    setIsDelModalVisible(true)
                  }}
                ></Button>
              </div>
            )
          } else {
            return (
              <div className={classes.imageitem}>
                <div className={classes.videoItemLeft}>
                  <div className={classes.arrows}>
                    {index !== 0 && (
                      <img
                        className={classes.svgimg}
                        src={svggore}
                        onClick={() => {
                          handleMoveItem(item._id, 'moveleft')
                        }}
                      />
                    )}
                    {index !== oldItems.length - 1 && (
                      <img
                        className={classes.svgimg}
                        src={svgdole}
                        onClick={() => {
                          handleMoveItem(item._id, 'moveright')
                        }}
                      />
                    )}
                  </div>
                  <video
                    key={`${SERVER_URL}/${item.video.url}`}
                    width='320'
                    height='240'
                    controls
                  >
                    <source
                      src={`${SERVER_URL}/${item.video.url}`}
                      type='video/mp4'
                    />
                  </video>
                  <button
                    className={classes.addImageBtn}
                    onClick={() => {
                      handleShowVideoModal()
                      setItemIndex(index)
                    }}
                  >
                    {oldItems[index] && oldItems[index].video.url
                      ? oldItems[index].video.url
                      : 'Izaberite video'}
                  </button>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => {
                    setSelectedItem(item._id)
                    setIsDelModalVisible(true)
                  }}
                ></Button>
              </div>
            )
          }
        })}

      <Modal
        width={'80%'}
        footer={[]}
        title='Kliknite sliku da biste je odabrali'
        visible={isVisibleModal}
        onCancel={handleCancel}
      >
        <div>
          <Select
            style={{ minWidth: '200px' }}
            onChange={(e) => {
              filterImages(e)
            }}
            defaultValue={''}
          >
            <Option value=''>Sve</Option>
            <Option value='home'>Početna strana</Option>
            <Option value='gallery'>Gallery</Option>
          </Select>
        </div>
        <div className={classes.imagesList}>
          {filteredImages &&
            filteredImages.length > 0 &&
            filteredImages.map((item, index) => {
              return (
                <div className={classes.imageItem}>
                  <img
                    onClick={() => {
                      handleSelectImage(item)
                    }}
                    className='img'
                    src={`${SERVER_URL}/${item.url}`}
                    alt={item.url}
                  />
                </div>
              )
            })}
          <Pagination {...paginationProps} />
        </div>
      </Modal>

      <Modal
        width={'80%'}
        footer={[]}
        title='Kliknite sliku da biste je odabrali'
        visible={isVisibleModalOldItems}
        onCancel={handleCancelOldItems}
      >
        <div>
          <Select
            style={{ minWidth: '200px' }}
            onChange={(e) => {
              filterImages(e)
            }}
            defaultValue={''}
          >
            <Option value=''>Sve</Option>
            <Option value='home'>Početna strana</Option>
            <Option value='gallery'>Gallery</Option>
          </Select>
        </div>
        <div className={classes.imagesList}>
          {filteredImages &&
            filteredImages.length > 0 &&
            filteredImages.map((item, index) => {
              return (
                <div className={classes.imageItem}>
                  <img
                    onClick={() => {
                      handleSelectImageOldItem(item)
                    }}
                    className='img'
                    src={`${SERVER_URL}/${item.url}`}
                    alt={item.url}
                  />
                </div>
              )
            })}
          <Pagination {...paginationProps} />
        </div>
      </Modal>
      <div className={classes.statistikaFooter}>
        <button
          className={classes.submitButton}
          disabled={isSaveButtonDisabled}
          onClick={() => {
            handleSubmit()
          }}
        >
          Izmeni
        </button>
      </div>
      <Modal
        width={'80%'}
        footer={[]}
        title='VIDEO GALERIJA'
        visible={isVisibleVideoModal}
        onCancel={handleCancel}
      >
        <div className={classes.videosList}>
          {videos &&
            videos.length > 0 &&
            videos.map((item, index) => {
              return (
                <div className={classes.videoItem}>
                  <video width='320' height='240' controls>
                    <source
                      src={`${SERVER_URL}/${item.url}`}
                      type='video/mp4'
                    />
                  </video>
                  <Button
                    onClick={() => {
                      handleSelectVideo(item)
                    }}
                    className={classes.selectVideoButton}
                  >
                    Izaberite video
                  </Button>
                </div>
              )
            })}
        </div>
      </Modal>

      <Modal
        width={'80%'}
        footer={[]}
        title='VIDEO GALERIJA'
        visible={isVisibleNewVideoModal}
        onCancel={handleCancel}
      >
        <div className={classes.videosList}>
          {videos &&
            videos.length > 0 &&
            videos.map((item, index) => {
              return (
                <div className={classes.videoItem}>
                  <video width='320' height='240' controls>
                    <source
                      src={`${SERVER_URL}/${item.url}`}
                      type='video/mp4'
                    />
                  </video>
                  <Button
                    onClick={() => {
                      handleSelectNewVideo(item)
                    }}
                    className={classes.selectVideoButton}
                  >
                    Izaberite video
                  </Button>
                </div>
              )
            })}
        </div>
      </Modal>
      <Modal
        footer={[]}
        open={isDelModalVisible}
        title='Da li ste sigurni da želite da obrišete stavku?'
        onCancel={() => {
          setIsDelModalVisible(false)
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '20px',
          }}
        >
          <Button
            onClick={(e) => {
              handleRemoveExistingItem(selectedItem)
              setIsDelModalVisible(false)
            }}
          >
            Obriši
          </Button>
          <Button
            onClick={(e) => {
              setIsDelModalVisible(false)
            }}
          >
            Odustani
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default AdminEditPost
