import { Modal, ConfigProvider } from 'antd'
import { createRoot } from 'react-dom/client'

const BetkoPopupModal = ({
  title,
  description,
  actionContent,
  visible,
  onClose,
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            maskBg: 'rgba(0, 0, 0, 0.6)',
            contentBg: 'linear-gradient(135deg, #40445E, #111219)',
            borderRadius: 12,
            padding: 16,
            colorText: 'white',
            textAlign: 'center',
            colorIcon: 'white',
            colorIconHover: 'white',
          },
        },
      }}
    >
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        closable={true}
        maskClosable={true}
        centered
        width='auto'
        style={{
          background: 'linear-gradient(135deg, #40445E, #111219)',
          borderRadius: '0.5rem',
          minWidth: '250px',
          maxWidth: '650px',
        }}
      >
        <span style={{ color: '#D6FF00', fontSize: '18px' }}>{title}</span>
        <div style={{ borderTop: '1px solid #D6FF00', paddingTop: '16px' }}>
          <div style={{ fontSize: '16px', marginBottom: '16px' }}>
            {description}
          </div>
          <div
            style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}
          >
            {typeof actionContent === 'function'
              ? actionContent(() => onClose())
              : actionContent}
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  )
}

export const betkoPopupWithAction = (title, description, actionContent) => {
  const container = document.createElement('div')
  document.body.appendChild(container)
  const root = createRoot(container)

  const closePopup = () => {
    root.unmount()
    document.body.removeChild(container)
  }

  root.render(
    <BetkoPopupModal
      title={title}
      description={description}
      actionContent={actionContent}
      visible={true}
      onClose={closePopup}
    />
  )
}
