import classes from './footer.module.css'
import { useEffect, useState } from 'react'
import axios from '../config/axios'
import useChatImage from '../hooks/useChatImage'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const Footer = () => {
  const [backgroundIndex, setBackgroundIndex] = useState(0)
  const [location, setLocation] = useState('world')
  const [footerImages, setFooterImages] = useState([])
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const chatImageLink = useChatImage('infoblock')

  useEffect(() => {
    const handleIP = async () => {
      const res = await axios.get('https://api.ipify.org/?format=json')
      // const res2 = await Axios.get(
      //   `https://pro.ip-api.com/json/${res.data.ip}?key=uVgMOy8R7sWnktl`
      // )
      const res2 = await axios.get(`http://ip-api.com/json/${res.data.ip}`)

      // TO DO:
      setLocation(res2.data.countryCode)
      localStorage.setItem('countryCode', res2.data.countryCode)
    }

    handleIP()
  }, [])

  useEffect(() => {
    const fetchForAll = async () => {
      const res = await axios.get(`${SERVER_URL}/footer-images`)

      if (res && res.data && res.data.items) {
        setFooterImages(res.data.items)
      }
    }

    const fetchData = async () => {
      if (location) {
        const res = await axios.get(
          `${SERVER_URL}/footer-images-code/${location}`
        )

        if (!res.data || !res.data.items || res.data.items.length === 0) {
          fetchForAll()
          return
        }

        setFooterImages(res.data)
      } else {
        fetchForAll()
      }
    }

    fetchData()
  }, [location])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (footerImages.length === 0) return // Avoid running if no images

    const duration = footerImages[backgroundIndex]?.duration || 5 // Default duration if missing

    const timeout = setTimeout(() => {
      setBackgroundIndex((prevIndex) => (prevIndex + 1) % footerImages.length)
    }, duration * 1000)

    return () => clearTimeout(timeout) // Cleanup on unmount
  }, [backgroundIndex, footerImages])

  return (
    <div className={classes.footer}>
      <a
        target='_blank'
        rel='noreferrer'
        href={footerImages[backgroundIndex]?.url}
      >
        <img
          className={classes.img}
          src={`${SERVER_URL}/${
            isMobile
              ? footerImages[backgroundIndex]?.mobileImage?.url
              : footerImages[backgroundIndex]?.image?.url
          }`}
          alt='img'
        ></img>
      </a>

      <a
        className={classes.askBetko}
        href={chatImageLink}
        target='_blank'
        rel='noreferrer'
      >
        <img src='/icons/betko-chat-icon.svg' alt='askbetko' />
      </a>
    </div>
  )
}

export default Footer
