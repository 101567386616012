import React, { useEffect, useState } from 'react'
import classes from './SideImages.module.css'
import AdVideoPlayer from '../../AdVideoPlayer'

import { useNavigate } from 'react-router-dom'
import axios from '../../../config/axios'
import { getLinkTarget } from '../../../utils/adHelper'

const SERVER_URL = process.env.REACT_APP_SERVER_URL
const placeholderAdPath = '/tiger.webp'

const SideImages = ({ mobileView = false }) => {
  const [ads, setAds] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    const fetchShownAds = async () => {
      try {
        const response = await axios.get(
          SERVER_URL + '/get-all-shown-adds?section=main'
        )
        const fetchedAds = response.data || []

        const maxPosition = Math.max(
          ...fetchedAds
            .map((ad) => ad.position?.main)
            .filter((pos) => pos !== -1),
          2
        )

        const updatedAds = Array(maxPosition + 1).fill(null)

        fetchedAds.forEach((ad) => {
          const position = ad.position?.main
          if (position !== undefined && position !== -1) {
            updatedAds[position] = ad
          }
        })

        setAds(updatedAds)
      } catch (error) {
        console.error('Error fetching ads:', error)
      }
    }

    fetchShownAds()
  }, [])

  const handleVideoClick = (ad) => {
    if (ad?.link) {
      window.open(ad.link, getLinkTarget(ad.link), 'noopener,noreferrer')
    }
  }

  return (
    <div
      className={`${
        mobileView ? classes.mobileSideImages : classes.sideImages
      } sideImagesTipster`}
    >
      {[...ads, ...Array(Math.max(0, 3 - ads.length)).fill(null)].map(
        (ad, index) => (
          <div className={classes.adItem} key={index}>
            {ad ? (
              ad.type === 'video' ? (
                <div onClick={() => handleVideoClick(ad)}>
                  <AdVideoPlayer src={`${SERVER_URL}/${ad.localPath}`} />
                </div>
              ) : (
                <a
                  href={ad.link}
                  target={getLinkTarget(ad.link)}
                  rel='noopener noreferrer'
                >
                  <img src={`${SERVER_URL}/${ad.localPath}`} alt='Ad' />
                </a>
              )
            ) : (
              <img src={placeholderAdPath} alt='Ad' />
            )}
          </div>
        )
      )}
      <button
        className={classes.semaforiButton}
        onClick={() => navigate('/semafori')}
      >
        SEMAFORI
      </button>
    </div>
  )
}

export default SideImages
