import classes from "./betKalkulatori.module.css";
import betKalkulatori from "../images/betKalkulatori.png";
import BetKalkulatori from "../components/BetKalkulatori";

const BetKalkulatoriPage = () => {
  return (
      <div className={classes.mainContainer}>
        <div className={classes.headerContainer}>
          <h1>BET KALKULATORI</h1>
        </div>
        <div className={classes.contentWrapper}>
           <div>
          <BetKalkulatori/>
        </div>
        <div className={classes.textContainer}>
        <p>
          Ključna definicija sportskog klađenja jeste ta, da nepostoji segment
          istog koji nije vezan za matematiku. Nepoznavaoci osnova istog će uvek
          imati negaciju pri ovoj činjenici, verujući da su okolnosti
          preovladavajući faktor u igri, međutim ni jedan od segmenata koji
          hijerarhijski vodi od proizvodnje samih koeficijenata (kvota) pa na
          dalje, do same igre, ničim neizostavlja činjenicu da je faktor broj
          jedan u svim obračunima procene, matematika u svakom smislu te reči, tj.
          definicije. Kompletan proces, od nastajanja koeficijenata, algoritama
          koji se koriste u tu svrhu, obrade ogromnog broja podataka, kalkulacija
          vezanih za očekivane uplate po događajima, dalje kalkulacije promena i
          varijacija kvota prema uplatama, varijacija koeficijenata u igri uživo i
          još mnogo toga, svodi se samo na jedno, a to jedno je matematička obrada
          podataka, od koje zavisi i ponuda, i sve vezano za ono što Vam se
          stavlja unutar menija samih organizatora igre, za Vašu degustaciju iste.
        </p>
        <p>
          Ukoliko znate da se organizatori igre vode čistom matematikom, te da u
          okviru ponude nisu skloni kontaktiranju vidovnjaka, da li zaista možete
          da zamislite da osnova Vaše igre treba da bude bazirana na Vašim
          proročanskim sposobnostima, apstraktnim pogledima na budućnost, ili se
          ipak slažete sa nama, da Vam takav način sagledavanja igre neće doneti
          boljitke u njoj, i da je apsolutno pogrešan. Onoga trenutka kada počnete
          da sagledavate igru iz ugla onih koji Vam je i priređuju, i koji
          funkcionišu apsolutno i sa razlogom, na matematičkim osnovama iste, Vaš
          put ka boljitcima u Vašoj igri postajaće sve postojaniji i postojaniji,
          dok će Vaša ulaganja u igru postajati smislenija.
        </p>
        <p>
          Koliko se više uključite u primenu matematičkih faktora u igri, proceni,
          strategiji, analitici podataka, i koliko više statistiku kroz analitiku
          učinite manje konfuznom, toliko ćete pre doći do osnova razlika u igri,
          između kocke, koja je apsolutno vezana za nezavisne faktore u igri i za
          odsustvo znanja o njoj, i faktora koji smanjuju uticaj sreće u vašim
          procenama i načinima na koje sprovodite igru.
        </p>
        <p>
          U tom smeru, u okviru našeg sajta, nudimo Vam paket BET KALKULATORA,
          specijalizovanih namena za sportsko klađenje, koji pokrivaju segmente
          nivelacija koeficijenata, procene, strategije, kombinatorike i igre
          uživo.
        </p>
        <p>
          Istovremeno, kroz upotrebu BET KALKULATORA, pruža Vam se direktna
          mogućnost da i pre same igre i potrošnje na istu, kroz korišćenje istih
          Van odigravanja tiketa, jednostavnim unosom testiranja bez ulaganja u
          igru, uvidite šta bi Vam koja strategija i način igre donela, a šta
          odnela bez potrošnje. Na taj način možete preskočiti bez posledica po
          Vaš novčanik sve opasnosti koje u odsustvu korišćenja alata koje Vam
          nudimo i matematičkih obrada uopšteno mogu biti dobar put ka lošoj
          destinaciji.
        </p>
        <p>
          Nasuprot tome, upotrebom BET KALKULATORA Van igre i kroz testiranje
          unosa, možete uvideti i gde Vam igra pruža prostor da budete u
          prednosti, tj. drugim rečima kroz korišćenje BET KALKULATORA možete
          pronaći model igre koji Vam personalno odgovara, i koji je prilagođen
          Vašem načinu igre. Počev od nivelacija kvota, preko procena, preko
          organizacije igre kroz sisteme u ponudi, do realizacije igre, BET
          KALKULATORI će Vam ponuditi mnogo više od onoga što ste očekivali da
          dobijete od njih. Sa svim oko osnova njihove upotrebe se možete upoznati
          u okviru menija BET KALKULATORI TUTORIJAL, dok kroz samu upotrebu istih
          možete doći do rešenja podobnih za Vašu igru, i svakako izraženije
          mogućnosti da budete daleko bolji pri sprovođenju iste nego li što je to
          slučaj bez njih.
        </p>
        <p>
          BET KALKULATORI Vam ujedno nude i kontrolu igre u sprovođenju, pri čemu
          neki od istih, direktno prikazuju detalje koji su bitni za kontrolu Vaše
          igre, od recimo minimalnih uslovnih kvota u slučaju sistema (kalkulatori
          sistema nisu isključivo kalkulatori sistemskih tiketa, već imaju daleko
          širu primenu od iste), do usmerenja vezanih za svaki Vaš sledeći potez u
          igri, odnosa isplativih kvota i uloga u međusobnom prelamanju, i još
          mnogo toga.
        </p>
        <p>
          BET KALKULATORI nisu besplatan alat, i u ponudi su u više opcija pri
          kojima ih kao pretplatnik nakon realizacije uplate možete koristiti.
          Smatramo da igrači mnogo veća sredstva troše na igru koja nije
          racionalna, od simbolične cene koju smo odredili za korišćenje alata
          koji ima za jasnu tendneciju da Vam donese boljitke u igri i potpunu
          kontrolu nad istom, kao i nad Vašim budžetima. Sa BET KALKULATORIMA
          možete samo dobiti na boljoj igri, a samim tim i na povoljnijem bilansu
          iste u njoj.
        </p>
        <p>
          BET KALKULATORE takođe nismo stavili na besplatnu upotrebu iz dodatnih
          razloga, od kojih je jedan taj da naš sajt nema želju da Vas preplavi
          reklamama kladionica, a ponajmanje onim iskačućim, od kojih bi verovatno
          morao da živi da smo razmišljali drugačije od onoga što bi kao korisniku
          moglo da Vam smeta.
        </p>
        <p>
          BET KALKULATORE nismo stavili na besplatnu upotrebu i iz razloga što je
          psihološka tendencija ljudi uopšteno, da ih je na bilo šta što je
          besplatno teže motivisati da ulože svoje vreme u to. Ukoliko ne uložite
          određeni trud u nešto, svakako je da ćete i do uspeha u tome nečemu teže
          doći. Simbolična cena upotrebe BET KALKULATORA čini samo usmerenje ka
          tome da ih intenzivnije koristite, i da na taj način lakše shvatite da
          je vrednost njihove upotrebe po Vas svakako daleko veća od cene po kojoj
          Vam nudimo opciju upotrebe funkcionalnih i praktičnih kalkulatora za
          Vašu igru.
        </p>

        <img src={betKalkulatori} alt="betKalkulatori" />
        </div>
        </div>
       
       
      
      {/* <p>
        Cene BET KALKULATORA na mesečnom nivou neprevazilaze cene nekih osnovnih
        artikala, bilo onih bez koji se može ili ne. Primera radi, osnovna
        mesečna cena neograničene upotrebe BET KALKULATORA iznosi 10 eura (u
        dinarskoj protivvrednost - uvek preračunajte iznos upisan u eurima u
        dinarsku protivrednost iste prema srednjem kursu), što na dnevnom nivou
        iznosi 0.33 eura dnevno (40 RSD), za koliko objektivno skoro da ni žvake
        ne mogu da se kupe. Ukoliko uzimate paket BET KALKULATORA na duži
        period, što je pretplata na duži period, to je popust na BET KALKULATORE
        veći, pa tako recimo pretplata na BET KALKULATORE na godišnjem nivo
        iznosi prosečnih 0,164 e dnevno (19.7 RSD), za šta smatramo da zaista
        nije mnogo za izdvojiti naspram onogag što veliki broj Vas izdvaja na
        svojim tiketima u igri koja veoma često nije kontrolisana i nije
        bazirana na matematičkim tendencijama.
      </p> */}
      {/* <h2>CENE UPOTREBE BET KALKULATORA :</h2>
      <h3>
        - 1 MESEC - 10 e (u dinarskoj protivvrednosti) - prosečna dnevna cena
        0.33 e (40 RSD)
      </h3>
      <h3>
        - 3 MESECA - 20 e (u dinarskoj protivvrednosti) - prosečna dnevna cena
        0.22 e (26.4 RSD) Prosečna mesečna cena - 6.66 e (u dinarskoj
        protivvrednosti)
      </h3>
      <h3>
        - 6 MESECI - 35 e (u dinarskoj protivvrednosti) - prosečna dnevna cena
        0.194 e (23.3 RSD) Prosečna mesečna cena - 5.83 e (u dinarskoj
        protivvrednosti)
      </h3>
      <h3>
        - 9 MESECI - 50 e (u dinarskoj protivvrednosti) - prosečna dnevna cena
        0.185 e (22.2 RSD) Prosečna mesečna cena - 5.55 e (u dinarskoj
        protivvrednosti)
      </h3>
      <h3>
        - 12 MESECI - 60 e (u dinarskoj protivvrednosti) - prosečna dnevna cena
        0.164 e (19,7 RSD) Prosečna mesečna cena - 5 e (u dinarskoj
        protivvrednosti)
      </h3>
      <h3>
        UPLATOM NA BET KALKULATORE DIREKTNO OTVARATE I KOMPLETAN BET KURS
        MATERIJAL{" "}
      </h3> */}
    </div>
  );
};

export default BetKalkulatoriPage;
