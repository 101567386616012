import ForumPageTemplate from './ForumPageTemplate'

const KorisnickiPaketiSinglePage = () => (
  <ForumPageTemplate
    title='KORISNIČKI PAKETI'
    apiGetPath='users-package'
    apiPostPath='users-package'
    navigationPath='korisnicki_paketi'
    chatImagePath='userspackages'
  />
)

export default KorisnickiPaketiSinglePage
