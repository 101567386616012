import { useState, useEffect, useContext, useRef } from 'react'
import { useParams } from 'react-router-dom'
import axios from '../config/axios'
import Spinner from '../components/Spinner'
import Comments from '../components/Comments'
import AuthContext from '../context/AuthProvider'
import SideImages from '../components/Redesign/SideImages/SideImages'
import RecommendedPosts from '../components/Redesign/RecommendedPosts/RecommendedPosts'
import ForumNavigation from '../components/ForumNavigation'
import ForumContent from '../components/ForumContent'
import classes from './forumPage.module.css'
import SideImagesWide from '../components/SideImagesWide'

const ForumPageTemplate = ({
  title,
  apiGetPath,
  apiPostPath,
  chatImagePath,
  navigationPath,
  commentType,
}) => {
  const { id } = useParams()
  const { auth } = useContext(AuthContext)
  const commentsRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const [data, setData] = useState({})
  const [chatImage, setChatImage] = useState()
  const [commentCounter, setCommentCounter] = useState({
    totalComments: 0,
    commentsLength: 0,
    answersLength: 0,
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!commentsRef.current) return

      const targetElement = commentsRef.current

      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(entry.isIntersecting)
        },
        { threshold: 0.1 }
      )

      observer.observe(targetElement)

      return () => observer.disconnect()
    }, 1000)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsRef.current])

  const scrollToComments = () => {
    if (commentsRef.current) {
      commentsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/${apiPostPath}/${id}`)

        if (response.data && response.data.post) {
          setData(response.data.post)
        } else {
          setData({})
        }
      } catch (err) {
        console.error('Error fetching post:', err)
        setData({})
      }
    }

    const fetchChatImage = async () => {
      const img = await axios.get(`/chat-image/${chatImagePath}`)
      setChatImage(img.data)
    }

    fetchData()
    fetchChatImage()
  }, [id, apiGetPath, apiPostPath, chatImagePath])

  const adminCommentOnly = data?.adminCommentOnly || false

  return (
    <div className={classes.container}>
      <div className={classes.sideImagesContainer}>
        <SideImages />
      </div>
      <div className={classes.postsPageContainer}>
        <h1>{title}</h1>
        <RecommendedPosts />
        <div className={classes.postsAndCalculatorWrapper}>
          <div className={classes.postsContainer}>
            <ForumNavigation
              data={data}
              chatImage={chatImage}
              commentCounter={commentType ? commentCounter : undefined}
              auth={auth}
              apiPath={apiGetPath}
              navigationPath={navigationPath}
              scrollToComments={scrollToComments}
              isVisible={isVisible}
            />

            <ForumContent description={data.textField} items={data.items} />
            {commentType && !data ? (
              <div className={classes.spinner}>
                <Spinner />
              </div>
            ) : (
              commentType && (
                <Comments
                  commentType={commentType}
                  adminComment={adminCommentOnly}
                  user={auth}
                  id={id}
                  title={data.title}
                  setCommentCounter={setCommentCounter}
                  ref={commentsRef}
                />
              )
            )}
          </div>
          {/* <CalculatorImage maxHeight={280} maxWidth={434} /> */}
          <div className={classes.adsContainer}>
            <SideImagesWide side='comments' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForumPageTemplate
