import React, { useContext, useEffect, useState } from 'react'
import classes from './MesecnaTabela.module.css'
import MisterTipsterDefaultTable from '../components/MisterTipsterDefaultTable'
import axios from 'axios'
import AuthContext from '../context/AuthProvider'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const MesecnaTabela = () => {
  const [monthTableData, setMonthTableDate] = useState([[], []])
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const searchParams = new URLSearchParams(window.location.search)
  const page = searchParams.get('page')
  const period = searchParams.get('period')
  const [month, year] = period
    ? period.split('-').map((part) => part.trim())
    : ['', '']

  const { auth } = useContext(AuthContext)

  useEffect(() => {
    const fetchTableData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${SERVER_URL}/user/get-month-table/${
            auth?._id || 'noId'
          }/${page}/${year}/${month}/${searchTerm || null}`
        )
        setMonthTableDate(response?.data)
      } catch (error) {
        setMonthTableDate([[], []])

        console.log(error)
      }
      setIsLoading(false)
    }

    if (month && year && period && page) {
      fetchTableData()
    }
  }, [month, year, period, page, searchTerm])

  return (
    <div className={classes.yearTableContainer}>
      <MisterTipsterDefaultTable
        data={monthTableData}
        period='month'
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isLoading={isLoading}
      />
    </div>
  )
}

export default MesecnaTabela
