import { useNavigate } from 'react-router-dom'
import classes from './shopAdminHeader.module.css'

const ShopAdminHeader = () => {
  const navigate = useNavigate()
  return (
    <div className={classes.container}>
      <h1>
        <span onClick={() => navigate('/')}>Admin Panel - Betko Shop</span>
      </h1>
    </div>
  )
}

export default ShopAdminHeader
